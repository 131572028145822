import React, { memo, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { cancelRide } from "../../redux/admin/action";

function Index({ order, show, onHide }) {
  const [
    reason, setreason
  ] = useState("");
  const dispatch = useDispatch();
  const [
    errors, seterrors
  ] = useState([]);
  const [
    deduction, setdeduction
  ] = useState({
    dedc: false,
    fee: 0,
    from: "",
  });
  const [
    loader, setLoader
  ] = useState(false);

  const cancelledRide = (e) => {
    e.preventDefault();

    let error = [];
    if (reason === "") {
      error.push("reasonEmpty");
    }
    if (deduction.dedc && deduction.fee === "") {
      error.push("feeEmpty");
    }
    if (deduction.dedc && deduction.from === "") {
      error.push("fromEmpty");
    }

    if (error.length) {
      seterrors(error);
    } else {
      seterrors([]);
      setLoader(true);
      const data = {...order, deduction, reason, status: "cancelled"}

      dispatch(cancelRide(data, data.id, stopLoader));
      onHide()
    }
  };
  const stopLoader = () => {
    setLoader(false);

    onHide()
  };
  useEffect(() => {
    if (order) {
      setreason(order.deduction?.reason);
      setdeduction({
        ...order.deduction,
      });
    }
  }, [order]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Mark As Cancelled
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={cancelledRide}>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Add deductions"
              name="dedc"
              checked={deduction.dedc}
              onChange={(e) => {
                var { name, checked } = e.target;
                setdeduction((p) => ({ ...p, [name]: checked }));
              }}
            />
          </Form.Group>
          {deduction.dedc && (
            <>
              <Form.Group className="mt-3"
                controlId="formBasicemail">
                <Form.Label>
Deduction from:
                </Form.Label>
                <Form.Control
                  as="select"
                  name="from"
                  value={deduction.from}
                  onChange={(e) => {
                    var { name, value } = e.target;
                    setdeduction((p) => ({ ...p, [name]: value }));
                  }}
                >
                {/* TODO: Need to change deduction values */}
                  <option>Choose one...</option>
                  <option value="rider">Driver</option>
                  <option value="restaurant">Rider</option>
                  <option value="own">Own</option>
                </Form.Control>
                {errors.includes("fromEmpty") && (
                  <span
                    className="w-100 text-danger"
                    style={ { fontSize: "12px" } }
                  >
                    Choose one
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mt-3"
                controlId="formBasicemail">
                <Form.Label>
Deduction fee:
                </Form.Label>
                <Form.Control
                  type="number"
                  name="fee"
                  value={deduction.fee}
                  onChange={(e) => {
                    var { name, value } = e.target;
                    setdeduction((p) => ({ ...p, [name]: value }));
                  }}
                />
                {errors.includes("feeEmpty") && (
                  <span
                    className="w-100 text-danger"
                    style={ { fontSize: "12px" } }
                  >
                    Enter fee
                  </span>
                )}
              </Form.Group>
            </>
          )}
          <Form.Group className="mt-3"
            controlId="formBasicemail">
            <Form.Label>
Reason:
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reason}
              onChange={(e) => {
                setreason(e.target.value);
              }}
            />
            {errors.includes("reasonEmpty") && (
              <span className="w-100 text-danger"
                style={ { fontSize: "12px" } }>
                Enter reason
              </span>
            )}
          </Form.Group>
          <Button
            variant="warning"
            className="fw-bolder text-white mt-3"
            disabled={loader}
            type="submit"
          >
            {loader ? "Processing..." : "Cancel"}
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          className="btn btn-warning fw-bolder text-white "
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(Index);
