import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { showErrorToast } from "../../components/toaster";
import { addCreateHistory, addUpdateHistory } from "../../utils/convertions";
import * as constants from "./constants";
import UserApi from "./userApi";

const userApi = new UserApi();

export const getInvoicesByUserId = (cb) => async (dispatch, getState) => {
  const id = getState().auth.user.id;
  try {
    const invoices = await userApi.getInvoicesByUserId(id);

    dispatch({ type: constants.GET_INVOICES, payload: invoices });

    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getUserAddresses = (cb) => async (dispatch, getState) => {
  const id = getState().admin.profileUser.id;
  try {
    const addresses = await userApi.getUserAddresses(id);

    cb?.(addresses);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const removeUserAddress =
  (address, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileUser.id;
    try {
      const newAdress = await userApi.removeUserAddress(id, address);

      cb?.(newAdress);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const getUserVouchers = (cb) => async (dispatch, getState) => {
  const id = getState().admin.profileUser.id;
  try {
    const user = await userApi.getUserVouchers(id);
    const all = await userApi.getVouchersForAll();
    const vouchers = all.concat(user);

    cb?.(vouchers);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getUserNotes = (cb) => async (dispatch, getState) => {
  const id = getState()?.admin?.profileUser?.id;

  try {
    const info = await userApi.getUserNotes(id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const addNoteForUser = (data, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileUser.id;
  const user = getState().auth.user;

  try {
    const info = await userApi.addNoteForUser(
      addCreateHistory(data, user),
      id,
      uuidv4()
    );

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const saveUserAddress = (address, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileUser.id;
  const user = getState().auth.user;

  const addressId = uuidv4();
  try {
    const newAddress = await userApi.saveUserAddress(
      id,
      addCreateHistory(address, user),
      addressId
    );

    cb?.(newAddress);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const updateUserAddress =
  (address, addressId, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileUser.id;
    const user = getState().auth.user;

    try {
      const newAddress = await userApi.updateUserAddress(
        id,
        addUpdateHistory(address, user),
        addressId
      );

      cb?.(newAddress);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const setUserAddressAsPrimary =
  (address, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileUser.id;
    const user = getState().auth.user;

    try {
      const newAddress = await userApi.setAddressAsPrimary(
        id,
        addUpdateHistory(address, user)
      );

      cb?.(newAddress);
    } catch (error) {
      cb(error);
    }
  };

export const updateProfileImage =
  (images, cb) => async (dispatch, getState) => {
    try {
      const id = getState().auth.user.id;
      const imageUrls = await userApi.uploadImage(images);
      const isupdated = await userApi.saveImageFirstore(imageUrls[0], id);
      if (isupdated) {
        dispatch({
          type: constants.UPDATE_USER,
          payload: { photoURL: imageUrls[0] },
        });
      }
      cb(isupdated);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const removeProfileImage = (cb) => async (dispatch, getState) => {
  try {
    const id = getState().auth.user.id;

    const isupdated = await userApi.removeImageFirstore(id);
    if (isupdated) {
      dispatch({
        type: constants.UPDATE_USER,
        payload: { photoURL: null },
      });
    }
    cb(isupdated);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const updateUserProfile = (data, cb) => async (dispatch, getState) => {
  try {
    const id = getState().auth.user.id;

    const isupdated = await userApi.updateProfile(data, id);
    if (isupdated) {
      dispatch({
        type: constants.UPDATE_USER,
        payload: data,
      });
      cb(isupdated);
    } else {
      cb();
    }
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getUserOrders = (cb) => async (dispatch, getState) => {
  try {
    const email = getState().admin.profileUser.email;

    if (email) {
      const orders = await userApi.getUserOrders(email);
      dispatch({ type: constants.GET_ORDERS, payload: orders });
    }

    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

//  Get User Rides
export const getUserRides = (cb) => async (dispatch, getState) => {
  try {
    const email = getState().admin.profileUser.email;
    // console.log("useer email", email);

    if (email) {
      const rides = await userApi.getUserRides(email);
      // console.log(" chek risde", rides);
      dispatch({ type: constants.GET_RIDES, payload: rides });
    }
    
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const CancelOrderByUser =
  (paymentId, orderId, cb) => async (dispatch, getState) => {
    try {
      const id = getState().auth.user.id;
      const orderObject = {
        paid: "cancelled",
        status: "rejected",
        kitchen: "rejected",
        rejected: {
          by: id,
          status: "customer",
          at: moment().format("x"),
        },
      };

      const { data } = await userApi.cancelPayment({ paymentId });

      const isAdded =
        data?.status === "CANCELED"
          ? await userApi.updateOrderFirestore(orderObject, orderId)
          : null;
      const isUpdated = isAdded
        ? await userApi.removeOrderDatabase(orderId)
        : false;

      if (isUpdated) {
        dispatch({
          type: constants.UPDATE_ORDER,
          payload: { ...orderObject, id: orderId },
        });
        cb(isUpdated);
        return;
      }
      cb();
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const getUserById = (id, cb) => async () => {
  try {
    const rest = await userApi.getUserById(id);

    cb(rest);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
