import React, { Fragment, memo, Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router";

import { profileRoutes } from "../../utils/viewPages";
import riderRoutes from "./routes";

function FilterRoutes({ routes = [] }) {
  const [
    finalRoutes, setFinalRoutes
  ] = useState([]);
  useEffect(() => {
    setFinalRoutes(profileRoutes("adminTabs", "admin", routes));
  }, [
    routes, finalRoutes
  ]);

  return (
    <Suspense>
      <Routes>
        {finalRoutes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          const Component = route.component || Fragment;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              element={<Guard>
                <Layout>
                  {route?.children ? (
                    <SubRoutes routes={route?.children} />
                  ) : (
                    <Component />
                  )}
                </Layout>
              </Guard>}
            />
          );
        })}
      </Routes>
    </Suspense>
  );
}

function SubRoutes({ routes = [] }) {
  return (
    <Routes>
      {routes.map((route, i) => {
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            element={<Component />}
          />
        );
      })}
    </Routes>
  );
}

function Index() {
  return <FilterRoutes routes={riderRoutes} />;
}

export default memo(Index);
