import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import loaderLogo from "../assets/images/loaderLogo.gif";
import { getSiteRoles } from "../redux/admin/action";
import { getCurrentUser, getSiteSetting } from "../redux/auth/action";

function Index({ children }) {
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(true);

  const getRoles = useCallback(() => {
    dispatch(getSiteRoles(stopLoader));
  }, [dispatch]);

  const getSettings = useCallback(
    (user) => {
      if (!user) {
        stopLoader();
        return;
      }

      dispatch(getSiteSetting(getRoles));
    },
    [
      dispatch, getRoles
    ]
  );

  useEffect(() => {
    setLoader(true);

    dispatch(getCurrentUser(getSettings));
  }, [
    dispatch, getSettings
  ]);
  const stopLoader = () => {
    setLoader(false);
  };

  if (loader) {
    return (
      <div
        style={ {
          width: "100vw",
          height: "100%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        } }
      >
        <img src={loaderLogo}
          alt="loader" />
      </div>
    );
  }

  return children;
}
export default memo(Index);
