import { DeleteOutlined } from "@ant-design/icons";
import { Edit } from "@mui/icons-material";
import { Input, Table, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ActionBtn from "../../components/actionBtn";
import LiveItemsbox from "../../components/LiveItemsbox";
import AsCancel from "../../modals/CancelOrder";
import AsComplete from "../../modals/CompleteOrder";
import OrderItem from "../../modals/OrderItem";
import { 
  deleteRide, 
  getRides , 
  getVehicleTypeById, 
  getAllVehicleTypes,
} from "../../redux/admin/action";
import RideStatusTag from "../../components/RideStatusTag/RideStatusTag";

const { Search } = Input;

function Index() {
  const [modalShow, setModalShow] = useState(false);
  const [items] = useState(null);
  const [modalCompShow, setmodalCompShow] = useState(false);
  const [modalCancShow, setmodalCancShow] = useState(false);
  const [order] = useState(null);
  const [searchedText, setSearchedText] = useState("");
  
  // const [ vehicleTypeName , setVehicleTypeName ] = useState(null);

  const deleteRideRecord = (ride) => (cb) => {
    dispatch(deleteRide(ride.id, cb));
  };

  const columns = [
    {
      title: "Ride Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      className: "capitalize",
      width: 150,
      render: (status) => <RideStatusTag status={status} /> 
    },
    {
      title: "Ride #",
      align: "center",
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 120,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    {
      title: "Ride Date",
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (_, record) => record.date,
    },
    {
      title: "Pickup time",
      align: "center",
      dataIndex: "when",
      key: "when",
      className: "capitalize",
      width: 150,
      render: (_, record) => record.time,
    },
    {
      title: "Rider",
      align: "center",
      dataIndex: "name",
      key: "name",
      width: 220,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record.vehicleType)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record.driver).toLowerCase().includes(value.toLowerCase()) ||
          String(record.origin?.address)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.destination?.address)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.phone).toLowerCase().includes(value.toLowerCase()) ||
          String(record.orderNumber)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.id.toString().split("-")[0])
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      render: (_, record) => record?.passenger?.name || "Not Provided",
    },
    {
      title: "Pickup location",
      align: "center",
      dataIndex: "origin",
      key: "origin",
      className: "capitalize",
      width: 120,
      render: (origin) => origin?.address,
    },
    {
      title: "Destination",
      align: "center",
      dataIndex: "destination",
      key: "destination",
      className: "capitalize",
      width: 120,
      render: (destination) => destination?.address,
    },
    {
      title: "Phone No",
      align: "center",
      dataIndex: "phoneNo",
      key: "phoneNo",
      width: 220,
      render: (_, record) => ( record?.passenger?.phoneNumber || "Not Provided" ),
    },
    {
      title: "Email",
      align: "center",
      dataIndex: "email",
      key: "email",
      width: 220,
      render: (_, record) => ( record?.passenger?.email || "Not Provided" ),
    },
    {
      title: "Driver",
      align: "center",
      dataIndex: "drivers",
      key: "drivers",
      width: 220,
      render: (drivers) => {
        return Array.isArray(drivers) ? ( drivers.map((driver) => (<div>{driver.label}</div>)) ) : ("Not Provided");
      },
    },
    // {
    //   title: "Type of Vehicle",
    //   align: "center",
    //   dataIndex: "vehicleType",
    //   key: "vehicleType",
    //   width: 120,
    //   className: "capitalize",
    //   render: (vehicleType) => {
    //     return vehicleType;
    //   },
    // },
    // {
    //   title: "Vehicle Type",
    //   align: "center",
    //   dataIndex: "vehicleType",
    //   key: "vehicleType",
    //   width: 120,
    //   render: (vehicleType) => {
    //     const type = useVehicleTypeById(vehicleType);
    //     return type;
    //   }
    // },

    // Change Vehice=le Type
    {
      title: "Vehicle Type",
      align: "center",
      dataIndex: "vehicleType",
      key: "vehicleType",
      width: 120,
      render: (vehicleType) => {
        return vehicleType;
      },
    },

    {
      title: "No of Bags",
      align: "center",
      dataIndex: "noOfBags",
      key: "noOfBags",
      width: 120,
      render: (noOfBags) => {
        return noOfBags;
      },
    },
    {
      title: "No of Luggages",
      align: "center",
      dataIndex: "noOfLuggage",
      key: "noOfLuggage",
      width: 120,
      render: (noOfLuggage) => {
        return noOfLuggage;
      },
    },
    {
      title: "No of Passengers",
      align: "center",
      dataIndex: "noOfPass",
      key: "noOfPass",
      width: 120,
      render: (noOfPass) => {
        return noOfPass;
      },
    },
    {
      title: "Payment",
      align: "center",
      dataIndex: "paid",
      key: "paid",
      width: 120,
      className: "capitalize",
      render: (e) => (e ? "Paid" : "Pending"),
    },
    {
      title: "Fare",
      align: "center",
      dataIndex: "cost",
      key: "cost",
      width: 120,
      render: (_, record) => (
        <>
          {" "}
          &pound;
          {record.estimatedCost || record.cost
            ? record.estimatedCost
              ? record.estimatedCost
              : record.cost
            : 0}
        </>
      ),
    },
    {
      title: "Actions",
      key: "operation",

      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <div className="d-flex flex-col align-items-center justify-content-center">
            {/* <Edit /> */}
            <ActionBtn
              action={deleteRideRecord(record)}
              confirmMsg={"Do you want to delete this ride?"}
            >
              <Tooltip title={"delete"}>
                <DeleteOutlined
                  style={{ color: "red", fontSize: 15, margin: "2px" }}
                />
              </Tooltip>
            </ActionBtn>
          </div>
        );
      },
    },
  ];

  const [loader, setLoader] = useState(false);
  const [finalRides, setFinalRides] = useState([]);
  const dispatch = useDispatch();

  const stopLoader = () => {
    setLoader(false);
  };

  const { rides , vehicleTypes} = useSelector((state) => state.admin);

  // Get all Rides
  useEffect(() => {
    dispatch(getRides(stopLoader));
  }, [dispatch]);

  // Get all vehicle types
  useEffect(() => {
    setLoader(true);
    dispatch(getAllVehicleTypes(stopLoader));
  }, [dispatch]);

  // useEffect(() => {
  //   if (rides) {
  //     const filteredRides = rides?.filter((ride) => {
  //       return ride.date > moment().format("DD MMM YYYY");
  //     });

  //     let finalSortedRides = filteredRides?.sort((a, b) => {
  //       if (
  //         moment(a.date, "DD MMM YYYY").isSame(moment(b.date, "DD MMM YYYY"))
  //       ) {
  //         return moment(a.time, "HH:mm") - moment(b.time, "HH:mm");
  //       } else if (
  //         moment(a.date, "DD MMM YYYY") > moment(b.date, "DD MMM YYYY")
  //       ) {
  //         return 1;
  //       } else {
  //         return -1;
  //       }
  //     });
  //     setFinalRides(finalSortedRides);
  //   }
  // }, [rides]);

  useEffect(() => {
    if (rides) {
      const filteredRides = rides?.filter((ride) => {
        return ride.date > moment().format("DD MMM YYYY");
      });
  
      const updateVehicleTypeName = () => {
        const updatedRides = filteredRides.map((ride) => {
          const vehicleTypeId = ride.vehicleType;
          const vehicleType = vehicleTypes.find((type) => type.id === vehicleTypeId);
          const vehicleTypeName = vehicleType ? vehicleType.name : ride?.vehicleType || 'Unknown';
  
          return { ...ride, vehicleType: vehicleTypeName };
        });
  
        let finalSortedRides = updatedRides.sort((a, b) => {
          if (
            moment(a.date, "DD MMM YYYY").isSame(moment(b.date, "DD MMM YYYY"))
          ) {
            return moment(a.time, "HH:mm") - moment(b.time, "HH:mm");
          } else if (
            moment(a.date, "DD MMM YYYY") > moment(b.date, "DD MMM YYYY")
          ) {
            return 1;
          } else {
            return -1;
          }
        });
  
        setFinalRides(finalSortedRides);
      };
  
      updateVehicleTypeName();
    }
  }, [rides, vehicleTypes]);

  // console.log("Riders data:", finalRides);
  
  return (
    <>
      <Container fluid className=" w-100  pb-5">
        <Row className="gap-3">
          <Col lg={12} className=" pl-0 ">
            <h2 className="fw-bold">Pre Bookings</h2>
          </Col>
          <LiveItemsbox
            lg={4}
            headerClass="admin_mainColor"
            title={"Prebook Rides"}
            renderData={(data) => {
              return <div>{finalRides.length || "Empty"} </div>;
            }}
          />
          <Col lg={12} className="shadow rounded-3 bg-white p-3">
            <Search
              placeholder="Search rides..."
              allowClear
              size="large"
              onSearch={(value) => setSearchedText(value)}
              onChange={(e) => setSearchedText(e.target.value)}
              style={{
                width: 300,
                marginBottom: "10px",
                float: "right",
              }}
              className="searchBar"
            />
            <Table
              bordered
              className="table-curved adminTable"
              columns={columns}
              dataSource={finalRides}
              pagination={false}
              loading={loader}
              scroll={{ x: 1500, y: 500 }}
            />
          </Col>
        </Row>
      </Container>
      {modalShow && (
        <OrderItem
          items={items}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
      {modalCompShow && (
        <AsComplete
          order={order}
          show={modalCompShow}
          onHide={() => setmodalCompShow(false)}
        />
      )}
      {modalCancShow && (
        <AsCancel
          order={order}
          show={modalCancShow}
          onHide={() => setmodalCancShow(false)}
        />
      )}
    </>
  );
}

export default memo(Index);
