import { BookOutlined, HomeOutlined } from "@ant-design/icons";
import {
  FaBiking,
  FaBookmark,
  FaCalendarCheck,
  FaCarAlt,
  FaCaravan,
  FaCarSide,
  FaCity,
  FaCogs,
  FaCommentDots,
  FaCreditCard,
  FaFileInvoice,
  FaIdCardAlt,
  FaImages,
  FaInfoCircle,
  FaMailBulk,
  FaPager,
  FaRegBell,
  FaShoppingBasket,
  FaUserAlt,
  FaEdit,
  // FaStar,
  FaUsers,
  FaUtensils,
  FaChartBar,
  FaDollarSign,
  FaPoundSign,
  FaChartLine,
} from "react-icons/fa";

const AdminLinks = [
  {
    id: 1,
    url: "/view",
    name: "dashboard",
    text: "Dashboard",
    icon: HomeOutlined,
  },
  {
    id: 2,
    url: "/prebookings",
    name: "prebookings",
    text: "Pre Bookings",
    icon: BookOutlined,
  },
  {
    id: 3,
    url: "/ridehistory",
    name: "rideHistory",
    text: "Rides History",
    icon: FaShoppingBasket,
  },

  {
    id: 8,
    url: "/chatHistory",
    name: "chatHistory",
    text: "Chat History",
    icon: FaCommentDots,
  },
  {
    id: 8,
    url: "/notifications",
    name: "notifications",
    text: "Notifications",
    icon: FaRegBell,
  },
  {
    id: 8,
    url: "/admins",
    name: "admins",
    text: "Admins",
    icon: FaUserAlt,
  },
  {
    id: 4,
    url: "/drivers",
    name: "drivers",
    text: "Drivers",
    icon: FaBiking,
  },
  {
    id: 5,
    url: "/clients",
    name: "clients",
    text: "Riders",
    icon: FaUsers,
  },
  {
    id: 6,
    url: "/schedules",
    name: "schedules",
    text: "Schedules",
    icon: FaCalendarCheck,
  },
  {
    id: 90,

    name: "vehicles",
    text: "Vehicles",
    icon: FaCaravan,
    Children: [
      {
        id: 9,
        url: "/vehicles/cars",
        name: "vehicles-cars",
        text: "Cars",
        icon: FaCarSide,
      },
      {
        id: 9,
        url: "/vehicles/types",
        name: "vehicles-types",
        text: "Types",
        icon: FaCarAlt,
      },
    ],
  },

  {
    id: 90,
    url: "/cities",

    name: "cities",
    text: "Cities",
    icon: FaCity,
  },

  {
    id: 8,
    url: "/vouchers",
    name: "vouchers",
    text: "Vouchers",
    icon: FaIdCardAlt,
  },

  {
    id: 9,
    url: "/earnings",
    name: "earnings",
    text: "Earnings",
    icon: FaPoundSign,
    Children: [
      {
        id: 9,
        url: "/earnings/rides-summary",
        name: "rides-summary",
        text: "Rides Summary",
        icon: FaChartBar,
      },
      {
        id: 9,
        url: "/earnings/rides-trends",
        name: "rides-trends",
        text: "Rides Trends",
        icon: FaChartLine,
      },

      {
        id: 9,
        url: "/earnings/invoices",
        name: "Invoices",
        text: "Invoices",
        icon: FaFileInvoice,
      },
    ],
  },
  {
    id: 8,
    url: "/roles",
    name: "roles",
    text: "Roles",
    icon: FaPager,
  },

  {
    id: 10,
    url: "/site-settings",
    name: "site-settings",
    text: "Site Settings",
    icon: FaCogs,
    Children: [
      {
        id: 9,
        url: "/site-settings/site-info",
        name: "site-settings--site-info",
        text: "Site Info",
        icon: FaInfoCircle,
      },

      {
        id: 9,
        url: "/site-settings/smtp",
        name: "site-settings--smtp",
        text: "SMTP",
        icon: FaMailBulk,
      },

      {
        id: 9,
        url: "/site-settings/payment-methods",
        name: "site-settings--payment-methods",
        text: "Payment methods",
        icon: FaCreditCard,
      },
      {
        id: 9,
        url: "/site-settings/images",
        name: "site-settings--images",
        text: "Images",
        icon: FaImages,
      },
      {
        id: 9,
        url: "/site-settings/career",
        name: "site-settings--career",
        text: "Career",
        icon: FaBookmark,
      },
      {
        id: 10,
        url: "/site-settings/blog",
        name: "site-settings--blog",
        text: "Blog",
        icon: FaEdit,
      },
    ],
  },
];
export default AdminLinks;
