import moment from "moment";

export const getDateRange = (type, date) => {
  let data = null;
  switch (type) {
  case "day":
    data = {
      current: {
        start: moment(date).startOf("day").format("x"),
        end: moment(date).endOf("day").format("x"),
      },
      prevoius: {
        start: moment(date).subtract(1, "day").startOf("day").format("x"),
        end: moment(date).subtract(1, "day").endOf("day").format("x"),
      },
    };
    return data;
  case "week":
    data = {
      current: {
        start: moment(date).startOf("week").startOf("day").format("x"),
        end: moment(date).endOf("week").endOf("day").format("x"),
      },
      prevoius: {
        start: moment(date)
          .startOf("week")
          .subtract(1, "day")
          .startOf("week")
          .startOf("day")
          .format("x"),
        end: moment(date)
          .startOf("week")
          .subtract(1, "day")
          .endOf("week")
          .endOf("day")
          .format("x"),
      },
    };
    return data;
  case "month":
    data = {
      current: {
        start: moment(date).startOf("month").startOf("day").format("x"),
        end: moment(date).endOf("month").endOf("day").format("x"),
      },
      prevoius: {
        start: moment(date)
          .startOf("month")
          .subtract(1, "day")
          .startOf("month")
          .startOf("day")
          .format("x"),
        end: moment(date)
          .startOf("month")
          .subtract(1, "day")
          .endOf("month")
          .endOf("day")
          .format("x"),
      },
    };
    return data;
  case "year":
    data = {
      current: {
        start: moment(date).startOf("year").startOf("day").format("x"),
        end: moment(date).endOf("year").endOf("day").format("x"),
      },
      prevoius: {
        start: moment(date)
          .startOf("year")
          .subtract(3, "day")
          .startOf("year")
          .startOf("day")
          .format("x"),
        end: moment(date)
          .startOf("year")
          .subtract(3, "day")
          .endOf("year")
          .endOf("day")
          .format("x"),
      },
    };
    return data;
  default:
    return data;
  }
};

export const getLablesByCategory = (type) => {
  switch (type) {
  case "day":
    return {
      labels: [
        "00", "03", "06", "09", "12", "15", "18", "21"
      ],
      datasets: [],
    };
  case "week":
    return {
      labels: [
        "1", "2", "3", "4", "5", "6", "7"
      ],
      datasets: [],
    };
  case "month":
    return {
      labels: [
        "1 week", "2 weeks", "3 weeks", "4 weeks"
      ],
      datasets: [],
    };

  case "year":
    return {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [],
    };
  default:
    return null;
  }
};
const getIntervels = (startDate, type) => {
  let loop = 7;

  if (type === "month") {
    loop = 5;
  } else if (type === "year") {
    loop = 12;
  } else if (type === "day") {
    loop = 8;
  }

  return Array.from(Array(loop).keys()).map((e) => {
    let value = "days";
    if (type === "month") {
      value = "weeks";
    } else if (type === "year") {
      value = "months";
    } else if (type === "day") {
      value = "hours";
    }

    return moment(startDate)
      .add(value === "hours" ? e * 3 : e, value)
      .format("x");
  });
};

export const filterLineCart = (type, data) => {
  if (!data) return;
  
  const { start, rides } = data;

  const days = getIntervels(Number(start), type);
  // console.log("inside dateRanges days ", days);

  const finalOrders = days?.map((p, i, arr) => {
    let filterOrders = [];
    if (arr.length === 7) {
      filterOrders = rides?.filter((e) => {
        if (arr[i + 1]) {
          return (
            e?.paid === true &&
            moment(Number(e?.createdAt)).isAfter(moment(Number(p))) &&
            moment(Number(e?.createdAt)).isBefore(moment(Number(arr[i + 1])))
          );
        }
        return (
          e?.paid === true &&
          moment(Number(e?.createdAt)).isAfter(moment(Number(p)))
        );
      });
    } else if (arr.length === 5) {
      filterOrders = rides?.filter((e) => {
        if (arr[i + 1]) {
          return (
            e?.paid === true &&
            moment(Number(e?.createdAt)).isAfter(moment(Number(p))) &&
            moment(Number(e?.createdAt)).isBefore(moment(Number(arr[i + 1])))
          );
        }
        return (
          e?.paid === true &&
          moment(Number(e?.createdAt)).isAfter(moment(Number(p)))
        );
      });
    } else if (arr.length === 12) {
      filterOrders = rides?.filter((e) => {
        if (arr[i + 1]) {
          return (
            e?.paid === true &&
            moment(Number(e?.createdAt)).isAfter(moment(Number(p))) &&
            moment(Number(e?.createdAt)).isBefore(moment(Number(arr[i + 1])))
          );
        }
        return (
          e?.paid === true &&
          moment(Number(e?.createdAt)).isAfter(moment(Number(p)))
        );
      });
    } else if (arr.length === 8) {
      filterOrders = rides?.filter((e) => {
        if (arr[i + 1]) {
          return (
            e?.paid === true &&
            moment(Number(e?.createdAt)).isAfter(moment(Number(p))) &&
            moment(Number(e?.createdAt)).isBefore(moment(Number(arr[i + 1])))
          );
        }
        return (
          e?.paid === true &&
          moment(Number(e?.createdAt)).isAfter(moment(Number(p)))
        );
      });
    }

    const allEarnOrder = filterOrders?.map((e) => {
      // const platfee = (parseFloat(e?.subtotal) * parseFloat(e?.platformfee)) / 100;
      // const subTotal = parseFloat(e?.subtotal) || 0.0;
      // const additionalfee = parseFloat(e?.additionalfee) || 0.0;

      // const ownRiderdeliveryfree = e?.restaurant?.deliverby?.rider === "ownriders" && e?.type === "delivery" ? parseFloat(e?.deliveryfree) : 0.0;
      // const boomaRiderdeliveryfree =e?.restaurant?.deliverby?.rider === "boomariders" &&e?.type === "delivery"? parseFloat(e?.deliveryfree) || 0: 0.0;

      // const restDeduction = e?.deduction?.from === "restaurant"? parseFloat(e?.deduction?.fee) : 0.0;
      // const riderDeduction = e?.deduction?.from === "rider" ? parseFloat(e?.deduction?.fee) : 0.0;
      // const riderTip = parseFloat(e?.ridertip).toFixed(2) || 0.0;

      // const restPay = subTotal - platfee + additionalfee + ownRiderdeliveryfree - restDeduction;

      // const riderPay = e?.restaurant?.deliverby?.rider === "boomariders" && e?.type === "delivery" ? boomaRiderdeliveryfree + parseFloat(riderTip) - riderDeduction : 0.0;

      // const totalIncome1 = e.total - restPay - riderPay;

      const rideCost = parseFloat(e?.cost);
      const platformfee = (parseFloat(rideCost) * parseFloat(e?.platformfee)) / 100;
      const discount = parseFloat(e?.discount || 0);
      const driverTake = parseFloat(e?.driverTake || 0);
      const riderTake = parseFloat(e?.riderTake || 0);

      const totalIncome = parseFloat(riderTake) -  parseFloat(driverTake) - parseFloat(discount);

      // const totalIncome = e.total - restPay - riderPay;


      return totalIncome;
    });

    return {
      rides: filterOrders?.length || 0,
      earn: allEarnOrder?.reduce((e, a) => e + a, 0) || 0,
    };
  });

  return finalOrders;
};


// export const restfilterLineCart = (type, data) => {
//   if (!data) return;
//   const { start, rides } = data;

//   const days = getIntervels(Number(start), type);

//   const finalOrders = days?.map((p, i, arr) => {
//     let filterOrders = [];
//     if (arr.length === 7) {
//       filterOrders = rides?.filter((e) => {
//         if (arr[i + 1]) {
//           return (
//             e?.paid === true &&
//             moment(Number(e?.createdAt)).isAfter(moment(Number(p))) &&
//             moment(Number(e?.createdAt)).isBefore(moment(Number(arr[i + 1])))
//           );
//         }
//         return (
//           e?.paid === true &&
//           moment(Number(e?.createdAt)).isAfter(moment(Number(p)))
//         );
//       });
//     } else if (arr.length === 5) {
//       filterOrders = rides?.filter((e) => {
//         if (arr[i + 1]) {
//           return (
//             e?.paid === true &&
//             moment(Number(e?.createdAt)).isAfter(moment(Number(p))) &&
//             moment(Number(e?.createdAt)).isBefore(moment(Number(arr[i + 1])))
//           );
//         }
//         return (
//           e?.paid === true &&
//           moment(Number(e?.createdAt)).isAfter(moment(Number(p)))
//         );
//       });
//     } else if (arr.length === 12) {
//       filterOrders = rides?.filter((e) => {
//         if (arr[i + 1]) {
//           return (
//             e?.paid === true &&
//             moment(Number(e?.createdAt)).isAfter(moment(Number(p))) &&
//             moment(Number(e?.createdAt)).isBefore(moment(Number(arr[i + 1])))
//           );
//         }
//         return (
//           e?.paid === true &&
//           moment(Number(e?.createdAt)).isAfter(moment(Number(p)))
//         );
//       });
//     } else if (arr.length === 8) {
//       filterOrders = rides?.filter((e) => {
//         if (arr[i + 1]) {
//           return (
//             e?.paid === true &&
//             moment(Number(e?.createdAt)).isAfter(moment(Number(p))) &&
//             moment(Number(e?.createdAt)).isBefore(moment(Number(arr[i + 1])))
//           );
//         }
//         return (
//           e?.paid === true &&
//           moment(Number(e?.createdAt)).isAfter(moment(Number(p)))
//         );
//       });
//     }

//     const allEarnOrder = filterOrders?.map((e) => {
//       const platfee = (parseFloat(e?.subtotal) * parseFloat(e?.platformfee)) / 100;
//       const deductions = e?.deduction?.from === "restaurant" ? parseFloat(e?.deduction?.fee) : 0;
//       const diliveryFee = e?.riderdetail?.type === "Own Rider" ? parseFloat(e?.deliveryfree) : 0;

//       return parseFloat(e?.subtotal) - platfee - deductions + diliveryFee;
//     });

//     return {
//       rides: filterOrders?.length || 0,
//       earn: allEarnOrder?.reduce((e, a) => e + a, 0) || 0,
//     };
//   });

//   return finalOrders;
// };
