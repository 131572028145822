import {
  DeleteOutlined,
  EditOutlined,
  InteractionOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Badge, Button, Space, Table, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ActionBtn from "../../../components/actionBtn";
import AddbaseModal from "../../../modals/AddbaseModal";
import {
  deleteBaseOffice,
  getCityBases,
  updateBaseOffice,
} from "../../../redux/admin/action";

const renderStatus = (value) => {
  return (
    <Space>
      {value ? (
        <Badge count="Active"
          style={ { backgroundColor: "#52c41a" } } />
      ) : (
        <Badge count="Inactive" />
      )}
    </Space>
  );
};
function Index() {
  const { slug } = useParams();
  const [
    bases, setBases
  ] = useState([]);
  const [
    loader, setLoader
  ] = useState(false);
  const [
    showModel, setShowModel
  ] = useState(false);
  const [
    selectedBase, setSelectedBase
  ] = useState(false);
  const city = useSelector((state) => state.admin.city?.[slug]);
  const dispatch = useDispatch();
  const deleteBaseHandler = useCallback(
    (admin) => (cb) => {
      dispatch(
        deleteBaseOffice(city?.id, admin.id, () => {
          const filteredBases = bases.filter((base) => base.id !== admin.id);
          setBases(filteredBases);
          cb();
        })
      );
    },
    [
      dispatch, bases, city
    ]
  );
  const changeBaseStatusHandler = useCallback(
    (admin) => (cb) => {
      dispatch(
        updateBaseOffice(
          { isActive: !admin.isActive },
          admin.id,
          city?.id,
          () => {
            const filteredBases = bases.map((base) => {
              if (base.id === admin.id) {
                return {
                  ...base,
                  isActive: !admin.isActive,
                };
              }
              return base;
            });
            setBases(filteredBases);
            cb();
          }
        )
      );
    },
    [
      bases, city, dispatch
    ]
  );

  const onBaseAddHandler = useCallback((base) => {
    setBases((prev) => [
      ...prev, base
    ]);
  }, []);
  const onBaseUpdateHandler = useCallback(
    (base) => {
      const updateBases = bases.map((value) => {
        if (value.id === base.id) {
          return {
            ...value,
            ...base,
          };
        }
        return value;
      });
      setBases(updateBases);
    },
    [bases]
  );
  const columns = [
    {
      title: "Name",
      align: "center",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 120,
    },

    {
      title: "Created at",
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,

      render: (e) => moment(Number(e)).format("DD-MMM-YY/hh:mm A"),
    },

    {
      title: "Status",
      align: "center",
      dataIndex: "isActive",
      key: "isActive",
      width: 120,

      render: renderStatus,
    },
    {
      title: "Action",
      align: "center",
      width: 120,

      render: (_, record) => {
        return (
          <div className="d-flex align-items-center justify-content-center z-50">
            <Tooltip title={"Edit"}>
              <EditOutlined
                onClick={() => {
                  setSelectedBase(record);
                  setShowModel(true);
                }}
                style={ {
                  color: "green",
                  fontSize: 15,
                  margin: "2px",
                } }
              />
            </Tooltip>
            <ActionBtn
              action={changeBaseStatusHandler(record)}
              confirmMsg={`Want to ${
                !record.isActive ? "activate" : "de-activate"
              } this driver?`}
            >
              <Tooltip title={record.isActive ? "de-activate" : "activate"}>
                <InteractionOutlined
                  style={ {
                    color: record.isActive ? "red" : "green",
                    fontSize: 15,
                    margin: "2px",
                  } }
                />
              </Tooltip>
            </ActionBtn>
            <ActionBtn
              action={deleteBaseHandler(record)}
              confirmMsg={"Want to delete this driver?"}
            >
              <Tooltip title={"delete"}>
                <DeleteOutlined
                  style={ { color: "red", fontSize: 15, margin: "2px" } }
                />
              </Tooltip>
            </ActionBtn>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (city) {
      setLoader(true);
      dispatch(
        getCityBases(city.id, (data) => {
          setLoader(false);
          setBases(data);
        })
      );
    }
  }, [
    dispatch, city
  ]);
  return (
    <Container fluid>
      <Row className=" gap-2">
        <Col lg={12}>
          <h2 className="fw-bold">
Bases
          </h2>
        </Col>
        <Col lg={12}
          className=" flex justify-end p-0">
          <Button
            onClick={() => {
              setSelectedBase(null);
              setShowModel(true);
            }}
            type="primary"
            className=" d-flex fw-bold algin-items-center mx-2"
          >
            <PlusOutlined className="m-auto" />
            Add base
          </Button>
        </Col>
        <Col lg={12}
          className=" bg-white ">
          <Table
            bordered
            className="table-curved adminTable cursor-pointer"
            columns={columns}
            dataSource={bases}
            pagination={false}
            loading={loader}
            scroll={ { y: 600 } }
          />
        </Col>
      </Row>
      {showModel && (
        <AddbaseModal
          base={selectedBase}
          show={showModel}
          cityId={city?.id}
          onUpdate={onBaseUpdateHandler}
          onChange={onBaseAddHandler}
          onHide={() => {
            setShowModel(false);
          }}
        />
      )}
    </Container>
  );
}

export default memo(Index);
