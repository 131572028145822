import React, { memo, useEffect, useMemo, useState } from "react";
import Select from "react-select";

function Index({ onChange, value, bases = [], loader , rideData }) {
  const [initialValue, setInitialValue] = useState(null);

  // console.log("Inside Base selection ==== > ", rideData);

  const baseOfficeOptions = useMemo(() => {
    return bases.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
  }, [bases]);

  // useEffect(() => {
  //   if (initialValue?.value !== value) {
  //     onChange(initialValue?.value);
  //   }
  // }, [ initialValue, onChange, value ]);

  // useEffect(() => {
  //   if (rideData && rideData.selectedBase) {
  //     setInitialValue(baseOfficeOptions.find((e) => e.value === rideData.selectedBase));
  //   } else if (value) {
  //     setInitialValue(baseOfficeOptions.find((e) => e.value === value));
  //   }
  // }, [rideData , value, baseOfficeOptions]);

  // Add Ride Case
  useEffect(() => {
    if (value) {
      setInitialValue(baseOfficeOptions.find((e) => e.value === value));
    }
  }, [value, baseOfficeOptions]);

  // Edit Ride Case
  useEffect(() => {
    if (rideData && rideData?.selectedBase) {
      setInitialValue(baseOfficeOptions.find((e) => e.value === rideData?.selectedBase));
    }
  }, [rideData, baseOfficeOptions]);



  // useEffect(() => {
  //   if (loader) {
  //     setInitialValue(null);
  //   }
  // }, [loader]);

  return (
    <Select
      className="w-100 h-12"
      value={initialValue}
      isLoading={loader}
      styles={ {
        control: (styles) => ({
          ...styles,
          height: "3rem",
        }),
      } }
      onChange={(e) => {
        setInitialValue(e);
        onChange(e.value);
      }}
      options={baseOfficeOptions}
      placeholder="Select base"
      isSearchable={true}
      name="selectedBase"
    />
  );
}

export default memo(Index);
