import React, { memo, useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import ImagedropMutiple from "../../components/ImagedropMutiple";
import SnackBarCustom from "../../components/SnackBarCustom";
import { addAdminDetail, updateAdminDetail } from "../../redux/admin/action";

function Index({ as, info, show, onHide, onAdd, onUpdate }) {
  const [
    submitloading, setsubmitloading
  ] = useState(false);
  const [
    errors, seterrors
  ] = useState([]);
  const [
    document, setDocument
  ] = useState({
    name: "",
    images: [],
  });
  const stopLoader = (data) => {
    if (data) {
      setAdder(true);
      if (as === "NEW") {
        onAdd(data);
      } else {
        onUpdate(data);
      }
    }
    setsubmitloading(false);
    onHide?.();
  };
  const dispatch = useDispatch();

  const [
    adder, setAdder
  ] = useState(false);
  useEffect(() => {
    if (info) {
      setDocument({
        name: info?.id,
        images: info?.images,
      });
    }
  }, [info]);
  const saveindatabase = (e) => {
    e.preventDefault();
    let error = [];
    if (document.name === "") {
      error.push({ name: true });
    }

    if (!document.images?.length) {
      error.push({ image: true });
    }
    if (error.length < 1) {
      setsubmitloading(true);

      if (as === "NEW") {
        dispatch(
          addAdminDetail(
            document.name,
            {
              images: document.images,
            },
            stopLoader
          )
        );
      }
      dispatch(
        updateAdminDetail(
          document.name,
          {
            images: document.images,
          },
          stopLoader
        )
      );
    } else {
      seterrors(error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addModel"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Others Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          method="post"
          className="row"
          onSubmit={(e) => {
            saveindatabase(e);
          }}
        >
          <Col md={12}>
            <Form.Group className="my-2 ">
              <Form.Label>
Document Name
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                name="name"
                value={document.name}
                onChange={(e) => {
                  setDocument({ ...document, name: e.target.value });
                }}
              />
            </Form.Group>
            {errors &&
              errors.map(({ name }, index) => {
                if (name) {
                  return (
                    <span
                      key={index}
                      className="w-50 text-danger d-block "
                      style={ { fontSize: "15px" } }
                    >
                      *required
                    </span>
                  );
                }
                return null;
              })}
          </Col>
          <Col lg={12}
            className="text-center ">
            <ImagedropMutiple
              changeAble={true}
              file={document.images}
              onSuccess={(images) => {
                setDocument({ ...document, images });
              }}
              title="Image`s"
            />
            {errors &&
              errors.map(({ images }, index) => {
                if (images) {
                  return (
                    <span
                      key={index}
                      className="w-50  text-danger d-block "
                      style={ { fontSize: "15px" } }
                    >
                      *required
                    </span>
                  );
                }
                return null;
              })}
          </Col>
          <Col lg={12}>
            <Button
              variant="warning"
              className="m-2 fw-bolder text-white"
              disabled={submitloading ? true : false}
              type="submit"
            >
              {submitloading ? "Saving.." : "Save"}
            </Button>
          </Col>
        </form>
      </Modal.Body>
      <SnackBarCustom
        msg={"Item added"}
        open={adder}
        onClose={() => setAdder(false)}
      />
    </Modal>
  );
}

export default memo(Index);
