import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
} from "@react-google-maps/api";
import React, { memo, useMemo, useState } from "react";

function Index({ origin, destination, stops }) {
  const [
    routes, setRoutes
  ] = useState(null);

  // console.log(stops);
  
  const wayPoints = useMemo(() => {
    let finalStops = [];
    stops?.forEach((stop) => {
      if (stop?.latValue) {
        return {
          location: {
            lat: stop?.latValue,
            lng: stop?.lngValue,
          },
          stopover: true,
        };
      }
    });

    return finalStops;
  }, [stops]);

  return (
    <GoogleMap
      center={ {
        lat: origin?.latValue || 52.205337,
        lng: origin?.lngValue || 0.121817,
      } }
      mapContainerStyle={ { width: "100%", height: 300 } }
      zoom={18}
    >
      {origin?.latValue && destination?.latValue && (
        <DirectionsService
          options={ {
            origin: {
              lat: origin?.latValue,
              lng: origin?.lngValue,
            },
            destination: {
              lat: destination?.latValue,
              lng: destination?.lngValue,
            },
            waypoints: wayPoints,
            travelMode: "DRIVING",
          } }
          callback={(result) => {
            if (
              result?.request.destination.location.lat() !==
                routes?.request.destination.location.lat() ||
              result?.request.origin.location.lat() !==
                routes?.request.origin.location.lat()
            ) {
              setRoutes(result);
            }
          }}
        />
      )}
      {routes && (
        <DirectionsRenderer
          directions={routes}
          options={ {
            markerOptions: {
              visible: true,
            },
            polylineOptions: {
              strokeColor: "#6ababd",
              strokeWeight: 5,
              strokeOpacity: 1,
            },
          } }
        />
      )}
    </GoogleMap>
  );
}

export default memo(Index);
