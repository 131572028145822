import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getRiderById } from "../../../redux/admin/action";

function Index({ id }) {
  const [
    rider, setRider
  ] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(
        getRiderById(id, (data) => {
          setRider(data);
        })
      );
    }
  }, [
    dispatch, id
  ]);
  return (
    <a target="_blank"
      rel="noreferrer"
      href={`/rider/${rider?.id}`}>
      {rider?.name}
    </a>
  );
}

export default memo(Index);
