import { DatePicker, Table } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { getDriverSchedulesByIdAndDate } from "../../redux/admin/action";

const { RangePicker } = DatePicker;

function Index({ show, onHide, person }) {
  // console.log("Inside Schedule MOdal ==> ", person);
  const [schedules, setSchedules] = useState([]);

  const [date, setDate] = useState([
    moment(new Date()).startOf("d"),
    moment(new Date()).endOf("d"),
  ]);

  const setDateHandle = (e) => {
    if (!e) return;
    setDate(e);
  };
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);

  const stopLoader = (data) => {
    setSchedules(data);
    setLoader(false);
  };

  useEffect(() => {
    if (person.id && date) {
      setLoader(true);

      dispatch(
        getDriverSchedulesByIdAndDate(
          {
            start: moment(date[0]).startOf("d").format("x"),
            end: moment(date[1]).endOf("d").format("x"),
          },
          person.id,
          stopLoader
        )
      );
    }
  }, [person, date, dispatch]);

  const columns = [
    {
      title: "Date",
      align: "center",
      dataIndex: "date",
      key: "date",

      width: 120,
      render: (e) => `${moment(Number(e)).format("DD-MMM-YY")}`,
    },
    {
      title: "Day",
      align: "center",
      dataIndex: "date",
      key: "date",

      width: 120,
      render: (e) => `${moment(Number(e)).format("dddd")}`,
    },
    {
      title: "Time",
      align: "center",
      dataIndex: "time",
      key: "time",

      width: 120,
      render: (e) => `${e?.[0]} - ${e?.[1]}`,
    },
  ];
  return (
    <Modal show={show}
      onHide={onHide}
      centered>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">
Schedules
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="gap-2">
            <Col lg={12}
              className="p-0 flex items-end">
              <RangePicker
                className="w-75 ms-auto "
                format="DD MMM YYYY"
                value={date}
                onChange={setDateHandle}
              />
            </Col>
            <Col lg={12}
              className="p-0 ">
              <Table
                bordered
                className="table-curved adminTable"
                columns={columns}
                dataSource={schedules}
                pagination={false}
                loading={loader}
                scroll={ { y: 500 } }
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
