/* eslint-disable func-names */
import * as yup from "yup";

import { store } from "../config/store";

yup.addMethod(yup.string, "cityAlready", function (errorMessage) {
  return this.test("email-is-user", errorMessage, async function (value) {
    const { path, createError } = this;

    if (!value) return;

    const cities = store.getState().admin.cities;
    const city = cities.find((c) => c.name?.includes(value));
    if (city) {
      return createError({ path, message: errorMessage });
    } else {
      return value;
    }
  });
});
const schema = yup.object().shape({
  name: yup
    .string()
    .required("Enter name")
    .cityAlready("City is already exist"),
  delay: yup.string(),
  delayMessage: yup.string(),
  VAT: yup
    .number("Enter correct value added tax")
    .required("Enter VAT (Value added tax)")
    .min(0, "Enter correct value added tax"),
  platformFee: yup.object().shape({
    value: yup
      .number("Enter correct platform fee")
      .required("Enter platform fee")
      .min(0, "Enter correct platform fee"),
  }),
  driverFee: yup.object().shape({
    value: yup
      .number("Enter correct driver fee")
      .required("Enter driver fee")
      .min(0, "Enter correct driver fee"),
  })
});

export default schema;
