import { Divider, Popover, Spin } from "antd";
import moment from "moment";
import React, { memo, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";

import Dot from "../../components/dot";
import { AddShift, updateShift } from "../../redux/auth/action";

function Index({ shift }) {
  const { shifts } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };
  const lockInShift = () => {
    if (moment().isBefore(moment(shift?.time?.[0], "HH:mm"))) {
      swal("Oops!", "You can't lock in shift before the start time", "error");
      return;
    }
    if (shifts?.find((e) => e.lockInTime && !e.completed)) {
      swal("Warning", "You can only lock in one shift at a time", "warning");
      return;
    }
    if (window.confirm("Are you sure you want to lock this shift?")) {
      togglePopover?.();
      setLoader(true);
      if (shift?.start) {
        dispatch(
          AddShift(
            {
              lockInTime: moment().format("x"),
              active: true,
              dayId: shift.id,
              time: [
                shift?.start, shift?.end
              ],
              date: moment().format("x"),
            },

            stopLoader
          )
        );
        return;
      }
      dispatch(
        updateShift(
          { lockInTime: moment().format("x"), active: true },
          shift.id,
          stopLoader
        )
      );
    }
  };
  const breakInShfit = () => {
    if (window.confirm("Are you sure you want to break this shift?")) {
      togglePopover?.();
      setLoader(true);
      dispatch(
        updateShift({ breakInTime: moment().format("x") }, shift.id, stopLoader)
      );
    }
  };
  const breakOutShift = () => {
    if (window.confirm("Are you sure you want to continue this shift?")) {
      togglePopover?.();
      setLoader(true);
      dispatch(
        updateShift(
          { breakOutTime: moment().format("x") },
          shift.id,
          stopLoader
        )
      );
    }
  };
  const lockoutShift = async () => {
    // 12  14

    if (moment().isBefore(moment(shift?.time[1], "HH:mm"), "m")) {
      const { value } = await Swal.fire({
        input: "textarea",
        inputLabel: "Reason for early logout",
        inputPlaceholder: "Reason",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        confirmButtonColor: "#F8B615",
        confirmButtonText: "Lock Out",
        inputValidator: (value) => {
          return !value && "Enter reason";
        },
      });
      if (value) {
        setLoader(true);
        dispatch(
          updateShift(
            {
              lockOutTime: moment().format("x"),
              completed: true,
              reason: value,
              active: false,
            },
            shift.id,
            stopLoader
          )
        );
        return;
      }
      return;
    }
    if (window.confirm("Are you sure you want to lockout this shift?")) {
      setLoader(true);
      dispatch(
        updateShift(
          { lockOutTime: moment().format("x"), completed: true, active: false },
          shift.id,
          stopLoader
        )
      );
    }
  };

  const content = (
    <div className="flex flex-col justify-start items-start">
      {shift.lockInTime ? (
        <>
          <button
            onClick={lockoutShift}
            className="hover:text-yellow-400 fw-bold"
          >
            Mark as Completed
          </button>
          <Divider className="m-1" />
          {!shift.breakInTime ? (
            <button
              onClick={breakInShfit}
              className="hover:text-yellow-400 fw-bold"
            >
              Break
            </button>
          ) : (
            <>
              {!shift?.breakOutTime && (
                <button
                  onClick={breakOutShift}
                  className="hover:text-yellow-400 fw-bold "
                >
                  Continue Shift
                </button>
              )}
            </>
          )}
        </>
      ) : (
        <button onClick={lockInShift}
          className="hover:text-yellow-400 fw-bold">
          Login
        </button>
      )}
    </div>
  );
  const [
    showPopover, setShowPopover
  ] = useState(false);
  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  return (
    <div className="border-2 p-3 border-yellow-400 rounded-lg shadow-md bg-white relative w-full  ">
      {shift.lockInTime && (
        <Dot
          className={`absolute  ${
            !shift?.completed && "blinking"
          }  top-1 left-1 `}
          color={shift?.breakInTime && !shift?.breakOutTime
            ? "#F20000"
            : shift?.completed
              ? "lightgreen"
              : "#F8B615"}
        />
      )}
      <Spin spinning={loader}>
        <div className="flex flex-wrap gap-5  ">
          <div>
            <p className="capitalize">
              <span className="w-28 inline-block">
Shift Name:
              </span>
              {shift?.name || moment().format("dddd")}
            </p>
            <p>
              <span className="w-28 inline-block">
Start Time:
              </span>
              {shift?.start || shift?.time[0]}
            </p>
            <p>
              <span className="w-28 inline-block">
End Time:
              </span>
              {shift?.end || shift?.time[1]}
            </p>
            <p>
              <span className="w-28 inline-block">
Working Time:
              </span>
              {shift?.completed
                ? `${moment
                  .utc(
                    moment(Number(shift?.lockOutTime)).diff(
                      moment(Number(shift?.lockInTime)),
                      "seconds"
                    ) * 1000
                  )
                  .format("HH:mm:ss")} `
                : shift?.lockInTime
                  ? moment(Number(shift?.lockInTime)).fromNow(false)
                  : "N/A"}
            </p>
          </div>
          <div>
            <p>
              <span className="w-28 inline-block">
Login:
              </span>
              {shift?.lockInTime
                ? moment(Number(shift?.lockInTime)).format("HH:mm")
                : "N/A"}
            </p>
            <p>
              <span className="w-28 inline-block">
Break Start:
              </span>
              {shift?.breakInTime
                ? `${moment(Number(shift?.breakInTime)).fromNow(
                  false
                )} (${moment(Number(shift?.breakInTime)).format("HH:mm")})`
                : "N/A"}
            </p>
            <p>
              <span className="w-28 inline-block">
Break End:
              </span>
              {shift?.breakOutTime
                ? moment(Number(shift?.breakOutTime)).format("HH:mm")
                : "N/A"}
            </p>
            <p>
              <span className="w-28 inline-block">
Logout:
              </span>
              {shift?.lockOutTime
                ? moment(Number(shift?.lockOutTime)).format("HH:mm")
                : "N/A"}
            </p>
          </div>
        </div>
        {!shift?.completed && (
          <Popover
            content={content}
            trigger="click"
            open={showPopover}
            onOpenChange={togglePopover}
            placement="left"
          >
            <FaEllipsisV
              onClick={togglePopover}
              className="absolute text-yellow-400 top-2 cursor-pointer right-0"
            />
          </Popover>
        )}
      </Spin>
    </div>
  );
}
export default memo(Index);
