import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getDistanceBetweenPointsNew } from "../../utils/getDistance";

function Distances({ base, origin, destination, onDistanceChange, bases, stops }) {
  const [totalDistance, setTotalDistance] = useState(null);
  const [showDistanceHeading, setShowDistanceHeading] = useState(true);

  useEffect(() => {
    // Check if stops is an array and has a length of 0
    setShowDistanceHeading(Array.isArray(stops) && stops.length === 0);
  }, [stops]);

  const baseToOrigin = useMemo(() => {
    const selectedBase = bases?.find((b) => b?.id === base);

    if (selectedBase?.location && origin?.latValue) {
      return parseFloat(
        getDistanceBetweenPointsNew(
          selectedBase.location?.latValue,
          selectedBase.location?.lngValue,
          origin?.latValue,
          origin?.lngValue
        )
      );
    }

    return 0;
  }, [base, origin, bases]);

  const originToDestination = useMemo(() => {
    if (destination?.latValue && origin?.latValue) {
      return parseFloat(
        getDistanceBetweenPointsNew(
          destination.latValue,
          destination.lngValue,
          origin?.latValue,
          origin?.lngValue
        )
      );
    }

    return 0;
  }, [destination, origin]);

  const originToStops = useMemo(() => {
    const stopsWithDistances = [];

    if (stops?.length) {
      for (let index = 0; index < stops.length - 1; index++) {
        const element = stops[index];
        const nextElement = stops[index + 1];

        if (element?.latValue && nextElement?.latValue) {
          stopsWithDistances.push({
            ...element,
            origin: element?.address,
            destination: nextElement?.address,
            distance: parseFloat(
              getDistanceBetweenPointsNew(
                element?.latValue,
                element?.lngValue,
                nextElement?.latValue,
                nextElement?.lngValue
              )
            ),
          });
        }
      }

      if (origin?.latValue && stops?.[0]?.latValue) {
        stopsWithDistances.unshift({
          ...stops[0],
          origin: origin?.address,
          destination: stops[0]?.address,
          distance: parseFloat(
            getDistanceBetweenPointsNew(
              origin?.latValue,
              origin?.lngValue,
              stops[0]?.latValue,
              stops[0]?.lngValue
            )
          ),
        });
      }

      if (destination?.latValue && stops?.[stops.length - 1]?.latValue) {
        stopsWithDistances.push({
          ...stops[stops.length - 1],
          origin: stops[stops.length - 1]?.address,
          destination: destination?.address,
          distance: parseFloat(
            getDistanceBetweenPointsNew(
              stops[stops.length - 1]?.latValue,
              stops[stops.length - 1]?.lngValue,
              destination?.latValue,
              destination?.lngValue
            )
          ),
        });
      }
    }

    return stopsWithDistances;
  }, [stops, origin, destination]);

  useEffect(() => {
    if (baseToOrigin >= 0 && originToDestination >= 0) {
      const stopsDistanceSum = originToStops.reduce(
        (acc, curr) => acc + curr.distance,
        0
      );
      // console.log("Add Stops working ====> ", originToStops);
      // console.log("baseToOrigin Distance ====> ", baseToOrigin);
      // console.log("Add Stops Total Distance ====> ", stopsDistanceSum);
      // console.log("originToDestination Distance ====> ", originToDestination);
      
      const total = stopsDistanceSum ? ( baseToOrigin + stopsDistanceSum ) : ( baseToOrigin + originToDestination );
      setTotalDistance(total);
      onDistanceChange(total);
    }
  }, [baseToOrigin, originToDestination, originToStops, onDistanceChange]);

  // console.log("Total Distance ====> ", totalDistance);

  return (
    <Container>
      <Row>
        <Col lg={12}>
          <br/>
          <p className="fw-bold m-0">
          Distance (miles)
          </p>
        </Col>
        {baseToOrigin > 0 && (
          <Col lg={12}>
          Base to Origin:
            {baseToOrigin.toFixed(2)}
            {" "}
            miles
          </Col>
        )}
        {originToStops.length > 0 && (
          <>
            <p className="fw-bold m-0">Stops</p>
            {originToStops
              ?.filter((e) => e)
              ?.map((stop) => {
                return (
                  <Col lg={12} className="my-2">
                    {stop?.origin} (Origin) to {stop?.destination}{" "}
                    (Destination): {stop?.distance.toFixed(2)} miles
                  </Col>
                );
              })}
          </>
        )}
        
        {showDistanceHeading && 
          originToDestination > 0 && (
            <Col lg={12}>
              Origin to Destination:
              {originToDestination.toFixed(2)}
              {" "}
                miles
            </Col>
          )
        }
        
        {totalDistance && (
          <Col lg={12}>
              Total Estimated Distance:
            {totalDistance.toFixed(2)}
            {" "}
          miles
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default Distances;
