import Admins from "../../views/admins";
import ChatHistory from "../../views/chatHistory";
import Cities from "../../views/Cities";
import Dashboard from "../../views/Dashboard";
import Drivers from "../../views/Drivers";
import Finances from "../../views/Finances";
import LiveOrder from "../../views/LiveOrder";
import Notifications from "../../views/notifications";
import Orders from "../../views/Orders";
import Roles from "../../views/roles";
import Schedules from "../../views/schedules";
import Career from "../../views/Sitesetting/Career";
import EditCareer from "../../views/EditCareer"
import PaymentMethod from "../../views/Sitesetting/Finances";
import Images from "../../views/Sitesetting/Images";
import SiteInfo from "../../views/Sitesetting/siteInfo";
import Smtp from "../../views/Sitesetting/Smtp";
import Users from "../../views/User";
import Cars from "../../views/Vehicles/Cars";
import CarTypes from "../../views/Vehicles/Cartypes";
import Voucher from "../../views/Voucher";
import Blog from "../../views/Blog"
import EditBlog from "../../views/EditBlog"

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
  },
  { path: "/view", name: "dashboard", component: Dashboard },
  {
    path: "/prebookings",
    name: "prebookings",
    component: LiveOrder,
  },
  { path: "/ridehistory", name: "rideHistory", component: Orders },

  { path: "/drivers", name: "drivers", component: Drivers },
  { path: "/clients", name: "clients", component: Users },

  {
    path: "/chatHistory",
    name: "Chat History",
    component: ChatHistory,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/admins",
    name: "admins",
    component: Admins,
  },
  {
    exact: true,
    path: "/blog/edit/:id",
    name: "editblog",
    component: EditBlog,
  },
  {
    exact: true,
    path: "/career/edit/:id",
    name: "career",
    component: EditCareer,
  },
  {
    path: "/schedules",
    name: "schedules",
    component: Schedules,
  },
  {
    path: "/roles",
    name: "roles",
    component: Roles,
  },
  {
    exact: true,
    path: "/earnings/*",
    name: "earnings",

    children: [
      {
        exact: true,
        path: "rides-summary",
        name: "rides-summary",
        component: Finances.SaleSummary,
      },

      {
        exact: true,
        path: "rides-trends",
        name: "rides-trends",
        component: Finances.SalesTrends,
      },
      {
        exact: true,
        path: "invoices",
        name: "Invoices",
        component: Finances.Invoices,
      },
    ],
  },
  {
    exact: true,
    path: "/cities/*",
    name: "cities",

    children: [{
      path: "/",
      name: "cities",
      component: Cities,
    },],
  },
  {
    exact: true,
    path: "/vehicles/*",
    name: "vehicles",

    children: [
      {
        path: "/cars",
        name: "vehicles-cars",
        component: Cars,
      },
      {
        path: "/types",
        name: "vehicles-types",
        component: CarTypes,
      },
    ],
  },
  {
    exact: true,
    path: "/vouchers",
    name: "vouchers",
    component: Voucher,
  },
  {
    exact: true,
    path: "/site-settings/*",
    name: "site-settings",

    children: [
      {
        path: "/site-info",
        name: "site-info",
        component: SiteInfo,
      },

      {
        path: "/smtp",
        name: "smtp",
        component: Smtp,
      },

      {
        path: "/payment-methods",
        name: "payment-methods",
        component: PaymentMethod,
      },
      {
        path: "/images",
        name: "images",
        component: Images,
      },
      {
        path: "/career",
        name: "career",
        component: Career,
      },
      {
        exact: true,
        path: "/blog",
        name: "blog",
        component: Blog,
      },
     
    ],
  },

  {
    path: "*",
    name: "redirect",
  },
];
export default routes;
