import React, { memo } from "react";
import { Button, Modal, Table } from "react-bootstrap";

import SingleItem from "./singleItem";

function Index({ items, show, onHide }) {
  // console.log("ride items ====> ", items);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <b>Ride #{items?.id.toString().split("-")[0]} Details</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped
          bordered
          hover>
          <thead>
            {/* <tr>
              <th>Ride #</th>
              <th>Date</th>
              <th>Time</th>
              <th>Pickup Location</th>
              <th>Stops</th>
              <th>Dropoff Location</th>
              <th>Fare</th>
              <th>Status</th>
            </tr> */}
            <tr>
              <th colSpan={2}>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {/* {items?.map((item, index) => (
              <SingleItem key={index}
                item={item} />
            ))} */}
            <SingleItem 
              key={items?.id}
              item={items}/>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="warning"
          className="fw-bolder text-white"
          onClick={onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(Index);
