export const sortTariffsByDistance = (tariffs = []) => {
  return new Promise((resolve, reject) => {
    try {
      let sortedTariffs = tariffs.sort(
        (a, b) => Number(a?.distance?.max) - Number(b?.distance?.max)
      );

      // Handle undefined distance for the first item
      if (sortedTariffs.length > 0) {
        sortedTariffs[0].distance = {
          ...sortedTariffs[0]?.distance,
          min: 0,
        };
      }

      resolve(sortedTariffs);
    } catch (error) {
      reject(error);
    }
  });
};

export const sortTariffsByDistance2 = async (tariffs = []) => {
  try {
    const sortedTariffs = tariffs.slice().sort(
      (a, b) => Number(a?.distance?.max) - Number(b?.distance?.max)
    );

    for (let index = 0; index < sortedTariffs.length - 1; index++) {
      const currDistance = sortedTariffs[index]?.distance;
      const nextDistance = sortedTariffs[index + 1]?.distance;

      sortedTariffs[index + 1] = {
        ...sortedTariffs[index + 1],
        distance: {
          ...nextDistance,
          min: currDistance?.max || 0,
        },
      };
    }

    if (sortedTariffs.length > 0) {
      sortedTariffs[0] = {
        ...sortedTariffs[0],
        distance: {
          ...sortedTariffs[0]?.distance,
          min: 0,
        },
      };
    }

    return sortedTariffs;
  } catch (error) {
    throw new Error(error);
  }
};
