import "react-phone-number-input/style.css";
import "./index.css";

import React, { memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import ProfileImage from "../../../components/profileImage";
import RiderProfileForm from "../../../components/RiderProfileForm";

function Index() {
  const { profileRider } = useSelector((state) => state.admin);

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1 className="fw-bold">
        My profile
          </h1>
        </Col>
        <Col lg={12}
          className="bg-white py-5 shadow-md rounded-lg">
          <Row className="d-flex justify-content-center align-items-center flex-column">
            <Col lg={6}
              className="mb-3">
              <h1 className="text-center">
                Welcome! 
                {" "}
                <span>
                  {profileRider?.name}
                </span>
              </h1>
            </Col>
            {profileRider && (
              <>
                <ProfileImage user={profileRider} />
                <RiderProfileForm />
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
