import React from "react";
import Geocode from "react-geocode";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";

import { BASENAME } from "./config";
import { persistor, store } from "./config/store";
import MainRoutes from "./routers/MainRoutes";

Geocode.setApiKey("AIzaSyAXY0KXAGloxJe_eB22yrq_USUpT9lXezk");
const { renderRoutes } = MainRoutes;
function App() {
  
  return (
    <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={null}
          persistor={persistor}>
          <Router basename={BASENAME}>
            {renderRoutes()}
          </Router>
        </PersistGate>
      </Provider>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
