import { Modal, TimePicker } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { memo, useState } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

// import { updateSiteSettingsSchedule } from "../../redux/auth/action";
import { updateSiteSettingsSchedule } from "../../redux/auth/action";

const { RangePicker } = TimePicker;

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
function Index({ show, onHide }) {
  const { settings } = useSelector((state) => state.auth);

  const [
    loader, setLoader
  ] = useState(false);

  const stopLoader = () => {
    setLoader(false);
  };
  const dispatch = useDispatch();
  const saveTimming = (chatScheduleTime) => {
    setLoader(true);
    dispatch(
      updateSiteSettingsSchedule("Settings", { chatScheduleTime }, () => {
        stopLoader();
        onHide();
      })
    );
  };

  return (
    <Modal
      title={"Add Schedule"}
      centered
      onCancel={onHide}
      open={show}
      footer={null}
    >
      <Formik
        initialValues={ {
          days: settings?.Settings?.chatScheduleTime?.days || [],
          time: settings?.Settings?.chatScheduleTime?.time || [
            "9:00", "18:00"
          ],
        } }
        //   validationSchema={restValidation.RestForm}
        onSubmit={saveTimming}
      >
        {({
          values,

          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form className="gap-2 flex-col flex"
            onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicService">
              <Form.Label>
Select days
              </Form.Label>
              <Select
                isMulti
                isSearchable={true}
                name="days"
                value={values.days?.map((e) => {
                  return {
                    label: e,
                    value: e,
                  };
                })}
                onChange={(e) => {
                  setFieldValue(
                    "days",
                    e?.map((e) => e.value)
                  );
                }}
                // className=" capitalize "
                placeholder="Select days"
                options={days?.map((e) => {
                  return {
                    label: e,
                    value: e,
                  };
                })}
              />
            </Form.Group>
            <Form.Group controlId="formBasicService">
              <Form.Label>
Time
              </Form.Label>
              <div>
                <RangePicker
                  value={values.time.map((e) => moment(e, "HH:mm"))}
                  format={"HH:mm"}
                  onChange={(e) => {
                    if (!e) {
                      return;
                    }
                    setFieldValue(
                      "time",
                      e.map((e) => moment(e).format("HH:mm"))
                    );
                  }}
                  className="w-full"
                />
              </div>
            </Form.Group>
            <Col lg={12}
              className="text-center mt-4">
              <Button
                type="submit"
                className="rounded rounded-2 fw-bolder text-white"
                variant="warning"
                disabled={loader}
              >
                {loader ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner
                      animation="border"
                      className="mr-2"
                      variant="dark"
                      size="sm"
                    />
                    Saving...
                  </div>
                ) : (
                  "Save"
                )}
              </Button>
            </Col>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default memo(Index);
