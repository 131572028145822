import moment from "moment";
import queryString from "query-string";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";

import {
  getOrderbyRestaurant,
  getOrdersbyRider,
  getRidesbyDriver,
  getRidesbyUsers,
} from "../../redux/admin/action";
import Page404 from "../page404";

import Riders from "./riders";
import Users from "./users";

function Index() {
  const { search } = useLocation();

  const dispatch = useDispatch();
  const { userId } = useParams();
  const [ rides, setOrders ] = useState([]);
  const [loader, setLoader] = useState(false);
  const { to, from, as } = queryString.parse(search);

  const stopLoader = (data) => {
    setLoader(false);
    setOrders(data);
  };

  useEffect(() => {
    if (userId && from && to && as) {
      if (as === "restaurant") {
        setLoader(true);
        dispatch(
          getOrderbyRestaurant(
            userId,
            moment(from).startOf("d").format("x"),
            moment(to).endOf("d").format("x"),
            stopLoader
          )
        );
      } else if (as === "user") {
        setLoader(true);
        dispatch(
          getRidesbyUsers(
            userId,
            moment(from).startOf("d").format("x"),
            moment(to).endOf("d").format("x"),
            stopLoader
          )
        );
      } else {
        setLoader(true);
        dispatch(
          getRidesbyDriver(
            userId,
            moment(from).startOf("d").format("x"),
            moment(to).endOf("d").format("x"),
            stopLoader
          )
        );
      }
    }
  }, [userId, dispatch, from, to, as]);

  if (loader) {
    return <div>
      loading....
    </div>;
  }

  if (rides?.length && as === "rider") {
    return <Riders to={to}
      from={from}
      rides={rides} />;
  }
  if (rides?.length && as === "user") {
    return <Users to={to}
      from={from}
      rides={rides} />;
  }
  return <Page404 />;
}
export default memo(Index);
