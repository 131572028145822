import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Enter role name"),
  specificMain: yup.boolean(),
  chatSupport: yup.boolean(),
  accessTo: yup.array().when("specificMain", {
    is: true,
    then: yup.array().required().min(1, "Please select atleast one tab"),
  }),
  specificRider: yup.boolean(),
  riderTabs: yup.array().when([
    "accessTo", "specificRider", "specificMain"
  ], {
    is: (accessTo, specificRider, specificMain) =>
      (accessTo.includes("riders") || specificMain) && specificRider,
    then: yup.array().required().min(1, "Please select atleast one tab"),
  }),

  specificCustomer: yup.boolean(),
  customerTabs: yup
    .array()
    .when([
      "accessTo", "specificCustomer", "specificMain"
    ], {
      is: (accessTo, specificCustomer, specificMain) =>
        (accessTo.includes("clients") || specificMain) && specificCustomer,
      then: yup.array().required().min(1, "Please select atleast one tab"),
    }),
  specificAdmin: yup.boolean(),
  adminTabs: yup.array().when([
    "accessTo", "specificAdmin", "specificMain"
  ], {
    is: (accessTo, specificAdmin, specificMain) =>
      (accessTo.includes("management") || specificMain) && specificAdmin,
    then: yup.array().required().min(1, "Please select atleast one tab"),
  }),
});

export default schema;
