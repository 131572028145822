import "./index.css";

import {
  DeleteOutlined,
  EditFilled,
  InteractionOutlined,
} from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ActionBtn from "../../../components/actionBtn";
import VehicleModel from "../../../modals/VehicleModel";

import {
  activateVehicleById,
  deActivateVehicleById,
  deleteDriverVehicle,
  getVehicles,
} from "../../../redux/admin/action";
import DriverName from "./DriverName";
import VehicleType from "./VehicleType";

function Index() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { vehicles } = useSelector((state) => state.admin);

  const [vehicle, setVehicle] = useState(null);

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getVehicles(stopLoader));
  }, [dispatch]);

  const addVehicleHandler = () => {
    setVehicle(null);
    setModalShow(true);
  };

  const editHandler = (car) => {
    setVehicle(car);
    setModalShow(true);
  };

  const changeStatusAdmin = (car) => (cb) => {
    if (car?.isActive) {
      dispatch(deActivateVehicleById(car, cb));
    } else {
      dispatch(activateVehicleById(car, cb));
    }
  };

  const deleteAdmin = (car) => (cb) => {
    dispatch(deleteDriverVehicle(car.id, cb));
  };
  
  const columns = [
    {
      title: "Image",
      align: "center",
      dataIndex: "imageUrl",
      key: "imageUrl",
      fixed: "left",
      width: 120,
      render: (imageUrl, record) => (
        <img
          style={ {
            height: "100%",
            maxHeight: 80,
            width: "100%",

            objectPosition: "100% 100%",
          } }
          src={imageUrl}
          alt={record?.name}
        />
      ),
    },
    {
      title: "Name",
      align: "center",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 120,
    },
    {
      title: "Assigned Driver",
      align: "center",
      dataIndex: "driver",
      key: "driver",
      fixed: "left",
      width: 120,
      render: (driver) => <DriverName id={driver} />,
    },
    {
      title: "Vehicle Type",
      align: "center",
      dataIndex: "type",
      key: "type",
      fixed: "left",
      width: 120,
      render: (type) => {
        return <VehicleType id={type} />;
      },
    },
    {
      title: "Vehicle ID",
      align: "center",
      dataIndex: "id",
      key: "id",
      width: 120,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    {
      title: "Registration Mark",
      align: "center",
      dataIndex: "registrationNumber",
      key: "registrationNumber",
      width: 120,
    },
    {
      title: "MOT",
      align: "center",
      dataIndex: "mot",
      key: "mot",
      render: (_, record) => (
        <div>
          {record.mot}
          {" "}
          ({record.motExpiry})
        </div>
      ),
      width: 220,
    },
    
    {
      title: "Actions",
      key: "operation",

      align: "center",
      width: 120,

      render: (_, record) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <EditFilled
              onClick={() => editHandler(record)}
              className="mx-1 my-auto"
              style={ {
                color: "blue",
                cursor: "pointer",
              } }
            />
            <ActionBtn
              confirmMsg={`Want to ${
                record.isActive ? "de-activate" : "activate"
              } type?`}
              action={changeStatusAdmin(record)}
            >

              <Tooltip title={record.isActive ? "activate" : "de-activate"}>
                <InteractionOutlined
                  style={ {
                    color: record.isActive ? "green" : "red",
                    fontSize: 15,
                    margin: "2px",
                  } }
                />
              </Tooltip>
            </ActionBtn>
            <ActionBtn
              action={deleteAdmin(record)}
              confirmMsg={"Want to delete this type?"}
            >

              <Tooltip title={"delete"}>
                <DeleteOutlined
                  style={ { color: "red", fontSize: 15, margin: "2px" } }
                />
              </Tooltip>
            </ActionBtn>

          </div>
        );
      },
    },
  ];

  return (
    <Container fluid>
      <Row className="gap-3">
        <Col lg={12}>
          <h1 className="fw-bold ">
              Cars
          </h1>
        </Col>
        <Col lg={12}
          className="text-end">
          <Button
            type="primary"
            onClick={addVehicleHandler}
            className="fw-bold"
          >
            Add New Car
          </Button>
        </Col>
        <Col lg={12}
          className="shadow rounded-3 bg-white p-3">
          <Table
            bordered
            className="table-curved adminTable"
            columns={columns}
            dataSource={vehicles}
            pagination={false}
            loading={loader}
            scroll={ { y: 600 } }
          />
        </Col>
      </Row>
      {modalShow && (
        <VehicleModel
          vehicle={vehicle}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </Container>
  );
}

export default memo(Index);
