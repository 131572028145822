import {
  Autocomplete,
  GoogleMap,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import bikeRiderMarker from "../../assets/images/bikeRiderMarker.png";
import carRiderMarker from "../../assets/images/carRider.png";
import clientMarker from "../../assets/images/client.png";
import restaurantMarker from "../../assets/images/restaurantMarker.png";
import walkRider from "../../assets/images/walkRider.png";
import { getRidersLive } from "../../redux/admin/action";
import { getAddress } from "../../utils/addressChanger";
import OrderNumber from "./orderNumber";
import NumberOFOrderCompeted from "./riderOrders";

const riderImages = {
  Car: carRiderMarker,
  Scooter: bikeRiderMarker,
  Bike: bikeRiderMarker,
  Walk: walkRider,
};
function Index({ title, heading }) {
  const { liveOrders } = useSelector((state) => state.admin);
  const [
    autocomplet, setautocomplet
  ] = useState(null);
  const dispatch = useDispatch();
  const onLoad = (autocomplete) => {
    setautocomplet(autocomplete);
  };
  const [
    search, setSearch
  ] = useState(null);
  const [
    riders, setRiders
  ] = useState([]);

  useEffect(() => {
    dispatch(getRidersLive((data) => setRiders(data)));
  }, [dispatch]);
  const [
    activeInfo, setActiveInfo
  ] = useState(null);

  return (
    <Row className="bg-white shadow-lg rounded-lg p-3">
      <Col lg={12}
        className=" pb-2   ">
        <div className=" flex justify-between flex-wrap ">
          <div>
            <p>
              {title}
            </p>
            <h1 className="text-sm">
              {heading}
            </h1>
          </div>
        </div>
        <Row className="gap-2 justify-end  ">
          <Col lg={3}>
            <Select
              className="basic-single w-full "
              classNamePrefix="select"
              isSearchable={true}
              onChange={(e) => {
                const selectedRider = riders.find(
                  (rider) => rider.id === e.value
                );

                setSearch({
                  latValue: selectedRider.liveLocation?.latitude,
                  lngValue: selectedRider.liveLocation?.longitude,
                });
              }}
              name="color"
              options={riders?.map((e) => {
                return {
                  label: `${e.name} (${e.email})`,
                  value: e.id,
                };
              })}
            />
          </Col>
          <Col lg={3}>
            <Autocomplete
              options={ { enablePoweredByContainer: false } }
              onLoad={onLoad}
              restrictions={ { country: "GB" } }
              onPlaceChanged={async () => {
                if (autocomplet !== null) {
                  const place = await autocomplet.getPlace();
                  setSearch(getAddress(place));
                }
              }}
            >
              <Form.Control type="text"
                placeholder="Search Address" />
            </Autocomplete>
          </Col>
        </Row>
      </Col>
      <Col lg={12}
        className="dast-bot-info p-0 ">
        <GoogleMap
          mapContainerStyle={ { width: "100%", height: "500px" } }
          center={search
            ? { lat: search?.latValue, lng: search?.lngValue }
            : { lat: 52.205337, lng: 0.121817 }}
          zoom={18}
        >
          {riders.map((rider) => (
            <Marker
              animation="BOUNCE"
              clickable
              icon={ {
                url: riderImages[rider?.vehicle],
                strokeColor: "red",
                fillColor: "red",
                scaledSize: new window.google.maps.Size(30, 30),
              } }
              title={rider?.name}
              key={rider?.id}
              visible={true}
              onClick={() => setActiveInfo(rider.id)}
              position={ {
                lat: rider?.liveLocation?.latitude,
                lng: rider?.liveLocation?.longitude,
              } }
            >
              {activeInfo === rider.id && (
                <InfoWindow
                  onCloseClick={() => {
                    setActiveInfo(null);
                  }}
                  position={ {
                    lat: rider?.liveLocation?.latitude,
                    lng: rider?.liveLocation?.longitude,
                  } }
                >
                  <div className="flex flex-col flex-wrap">
                    <p className="fw-bold m-0">
                      Name: 
                      {" "}
                      <span>
                        {rider?.name}
                      </span>
                    </p>
                    <p className="fw-bold m-0">
                      Status: 
                      {" "}
                      <span>
                        {rider?.isBound ? "Busy" : "Idle"}
                      </span>
                    </p>
                    {rider?.isBound && <OrderNumber id={rider.id} />}
                    <p className="fw-bold m-0">
                      Login:
                      {" "}
                      <span>
                        {moment(rider.liveAt).format("DD MMM YYYY HH MM")}
                      </span>
                    </p>
                    <NumberOFOrderCompeted rider={rider} />
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
          {liveOrders.map((order) => (
            <React.Fragment key={order.id}>
              <Marker
                animation={"BOUNCE"}
                icon={ {
                  url: restaurantMarker,
                  scaledSize: new window.google.maps.Size(30, 30),
                } }
                clickable
                title={order?.restaurant?.restInformation?.name}
                key={order.restaurant.id}
                position={ {
                  lat: order?.restaurant?.restInformation?.location.latValue,

                  lng: order?.restaurant?.restInformation?.location.lngValue,
                } }
              />
              <Marker
                animation="bounce"
                clickable
                key={order.clientInfo.id}
                visible={true}
                title={order.clientInfo.name}
                icon={ {
                  url: clientMarker,
                  scaledSize: new window.google.maps.Size(30, 30),
                } }
                position={ {
                  lat: order.clientInfo.location.latValue,

                  lng: order.clientInfo.location.lngValue,
                } }
              />
            </React.Fragment>
          ))}
        </GoogleMap>
      </Col>
    </Row>
  );
}

export default memo(Index);
