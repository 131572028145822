import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DOMPurify from "dompurify";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Alert, Form, Button } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch } from "react-redux";
import moment from "moment";

import { DatePicker } from "antd";
import {
  getPublicCareersById,
  updatePublicCareersById,
} from "../../redux/admin/action";
import { useParams } from "react-router-dom";

function index() {
  const { id } = useParams();
  const [add, setAdd] = useState(false);
  const [textAreaCount, setTextAreaCount] = useState(0);

  const recalculate = (e) => {
    setTextAreaCount(e.target.value.length);
  };
  const [career, setCareer] = useState({
    title: "",
    desc: EditorState.createEmpty(),
    // link: "",
    noOfPositions: "",
    experience: "",
    qualification: "",
    shortDesc: "",
    lastDate: moment().format("DD MMM YYYY"),
  });
  const [errors, setErrors] = useState([]);
  const changeInput = (e) => {
    const { name, value } = e.target;
    setCareer((p) => ({ ...p, [name]: value }));
  };
  const stopAdding = () => {
    setAdd(false);
  };
  const saveCareer = (e) => {
    e.preventDefault();
    let error = [];
    if (career.title === "") {
      error.push("titleEmpty");
    }
    if (career.desc === "") {
      error.push("descEmpty");
    }
    // if (career.link === "") {
    //   error.push("linkEmpty");
    // }
    if (career.noOfPositions === "") {
      error.push("positionEmpty");
    }
    if (career.experience === "") {
      error.push("experienceEmpty");
    }
    if (career.qualification === "") {
      error.push("qualificationEmpty");
    }

    if (error.length) {
      setErrors(error);
    } else {
      setErrors([]);
      setAdd(true);
      const html = `<div>${draftToHtml(
        convertToRaw(career?.desc?.getCurrentContent())
      )}</div>`;
      setAdd(true);
      dispatch(
        updatePublicCareersById(
          id,
          {
            title: career.title,
            desc: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } }),
            noOfPositions: career.noOfPositions,
            experience: career.experience,
            qualification: career.qualification,
            lastDate: career.lastDate,
            shortDesc: career.shortDesc,
          },
          stopAdding
        )
      );
    }
  };
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const stopLoader = (data) => {
    setLoader(false);

    const contentBlock = htmlToDraft(data.desc);

    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);

    setCareer((p) => {
      return {
        ...p,
        ...data,
        desc: editorState,
      };
    });
  };
  useEffect(() => {
    if (career) {
      setTextAreaCount(career.shortDesc.toString().length);
    }
  }, [career]);

  useEffect(() => {
    if (id) {
      dispatch(getPublicCareersById(id, stopLoader));
    }
  }, [id, dispatch]);
  if (loader) {
    return <div>loading....</div>;
  }
  return (
    <div>
      <h1 className="fw-bold">Edit Career</h1>
      {add && (
        <Alert
          variant="success"
          style={{ height: "3em" }}
          onClose={() => setAdd(false)}
          dismissible
        >
          <p>Career updated!</p>
        </Alert>
      )}
      <Form onSubmit={saveCareer}>
        <Form.Group controlId="formBasicTitle">
          <Form.Label>Title:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            name="title"
            value={career.title}
            onChange={changeInput}
          />
          {errors.includes("titleEmpty") && (
            <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
              Enter title
            </span>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicTitle" className="mt-3">
          <Form.Label>Number of positions:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter number of positions"
            name="noOfPositions"
            onChange={changeInput}
            value={career.noOfPositions}
          />
          {errors.includes("positionEmpty") && (
            <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
              Enter number of positions
            </span>
          )}
        </Form.Group>

        <Form.Group controlId="date" className="mt-3">
          <Form.Label>Last date to apply:</Form.Label>
          <DatePicker
            className="h-12 w-100"
            name="lastDate"
            format={"DD MMM YYYY"}
            value={moment(career?.lastDate, "DD MMM YYYY")}
            // onBlur={handleBlur}
            onChange={(e) => {
              if (!e) {
                return;
              }
              setCareer((p) => ({
                ...p,
                lastDate: moment(e).format("DD MMM YYYY"),
              }));
            }}
          />
        </Form.Group>

        <Form.Group controlId="formBasicTitle" className="mt-3">
          <Form.Label>Experience:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter experience (years)"
            name="experience"
            onChange={changeInput}
            value={career.experience}
          />
          {errors.includes("experienceEmpty") && (
            <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
              Enter experience
            </span>
          )}
        </Form.Group>

        <Form.Group controlId="formBasicTitle" className="mt-3">
          <Form.Label>Qualification:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter qualification"
            name="qualification"
            onChange={changeInput}
            value={career.qualification}
          />
          {errors.includes("qualificationEmpty") && (
            <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
              Enter qualification
            </span>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicTitle" className="mt-3">
          <Form.Label>Short description:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            // type="text"
            placeholder="Short description"
            name="shortDesc"
            onChange={(e) => {
              changeInput(e);
              recalculate(e);
            }}
            value={career.shortDesc}
          />
          {errors.includes("qualificationEmpty") && (
            <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
              Enter short description
            </span>
          )}
        </Form.Group>
        <p style={{ fontSize: "1rem", float: "right" }}>
          {`Charaters count: ${textAreaCount}`}
        </p>
        <Form.Group className="mt-3" controlId="formBasicDescription">
          <Form.Label>Description:</Form.Label>
          <Editor
            editorState={career.desc}
            name="desc"
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(e) => {
              setCareer((p) => ({ ...p, desc: e }));
            }}
          />
          {errors.includes("descEmpty") && (
            <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
              Enter description
            </span>
          )}
        </Form.Group>
        <Button
          disabled={add}
          variant="primary"
          className="mt-3 btn-sm fw-bolder text-white btn-warning"
          type="submit"
        >
          {add ? "Updating..." : "Update"}
        </Button>
      </Form>
    </div>
  );
}

export default index;
