import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";

import {
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Dropdown, Layout } from "antd";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";

import CitySidebar from "../../components/citySidebar";

const { Header, Content } = Layout;

function Index({ children }) {
  const [
    collapsed, setCollapsed
  ] = useState(false);
  const onToggle = () => {
    setCollapsed(!collapsed);
  };

  const { user } = useSelector((state) => state.auth);

  const { photoURL } = user;

  const menu = (
    <div className="bg-white shadow-md w-60 p-2">
      <p className="text-center text-sm font-bold border-b m-0">
        {user.name}
      </p>
      <div className="flex flex-col custom-menu border-b ">
        <div className="flex items-center custom-menu-item px-2 my-1">
          <MailOutlined className="text-md " />
          <button href="/#"
            className="text-sm text-start p-1 font-bold ">
            {user.email}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <Layout
      className="site-layout-background shadow"
      style={ { minHeight: "100vh" } }
    >
      <CitySidebar collapsed={collapsed} />
      <Layout>
        <Header
          className=" shadow-md flex flex-row items-center justify-between px-4"
          style={ { padding: 0 } }
        >
          <div>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "text-lg text-white",
                onClick: onToggle,
              }
            )}
          </div>
          <Dropdown
            overlay={menu}
            className="w-12 cursor-pointer  h-12  shadow-md blue-icon rounded-full flex items-center justify-center text-4xl font-bold text-white"
            trigger={["click"]}
          >
            <div>
              {photoURL ? (
                <img
                  src={photoURL}
                  style={ { width: "100%", height: "100%" } }
                  alt="profile"
                  className="rounded-full"
                />
              ) : (
                user?.name?.charAt(0)
              )}
            </div>
          </Dropdown>
        </Header>
        <Content
          className="site-layout-background"
          style={ {
            padding: "24px 16px",
            overflow: "auto",
            minHeight: 280,
          } }
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}
export default memo(Index);
