import "./index.css";

import { Table , Tag , Tooltip } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";

import ActionBtn from "../../../components/actionBtn";
import OrderItem from "../../../modals/OrderItem";
import { getDriverRides , deleteRide } from "../../../redux/rider/action";
import RideStatusTag from "../../../components/RideStatusTag/RideStatusTag";

function Index() {
  const { rides } = useSelector((state) => state.rider);

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [finalRides, setFinalRides] = useState([]);


  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getDriverRides(stopLoader));

  }, [dispatch]);

  const deleteRideRecord = (ride) => (cb) => {
    dispatch(deleteRide(ride.id, cb));
  };

  const [items, setItems] = useState([]);

  // console.log("Rides ===>", rides);

  // const columns = [
  //   {
  //     title: "Ride Status",
  //     align: "center",
  //     dataIndex: "status",
  //     key: "status",
  //     className: "capitalize",
  //     width: 150,
  //     render: (status) => {
  //       if (status === "completed") {
  //         return (
  //           <Tag color="green"
  //             className="rounded-pill capitalize">
  //             {status.charAt(0).toUpperCase() + status.slice(1)}
  //           </Tag>
  //         );
  //       } else if (status === "cancelled") {
  //         return (
  //           <Tag color="red"
  //             className="rounded-pill capitalize">
  //             {status.charAt(0).toUpperCase() + status.slice(1)}
  //           </Tag>
  //         );
  //       } else if (status === "pending") {
  //         return (
  //           <Tag color="yellow"
  //             className="rounded-pill capitalize">
  //             {status.charAt(0).toUpperCase() + status.slice(1)}
  //           </Tag>
  //         );
  //       } else if (status === "driverAssigned") {
  //         return (
  //           <Tag color="gold"
  //             className="rounded-pill capitalize">
  //             {status.charAt(0).toUpperCase() + status.slice(1)}
  //           </Tag>
  //         );
  //       } else {
  //         return <p>
  //           {status}
  //         </p>;
  //       }
  //     },
  //   },
  //   {
  //     title: "Passenger Name",
  //     align: "center",
  //     dataIndex: "passenger",
  //     key: "passenger",
  //     width: 220,
  //     render: (_, record) => record?.passenger?.name || "Not Provided",
  //   },
  //   {
  //     title: "Fare",
  //     align: "center",
  //     dataIndex: "cost",
  //     key: "cost",
  //     width: 120,
  //     render: (_, record) => (
  //       <>
  //         {" "}
  //         &pound;
  //         {" "}
  //         {record.estimatedCost || record.cost
  //           ? record.estimatedCost
  //             ? record.estimatedCost
  //             : record.cost
  //           : 0}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Ride",
  //     align: "center",
  //     dataIndex: "ride",
  //     key: "ride",
  //     width: 120,
  //     render: (record) => (
  //       <Button
  //         variant="warning"
  //         onClick={() => {
  //           setItems(record);
  //           setModalShow(true);
  //           console.log("button item===>", record);
  //         }}
  //         className="btn-sm fw-bolder text-white"
  //       >
  //         View
  //       </Button>
  //     ),
  //   },
  // ];
  
  const columns = [
    {
      title: "Ride Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      className: "capitalize",
      width: 150,
      render: (status) => <RideStatusTag status={status} />
    },
    // {
    //   title: "Reason",
    //   align: "center",
    //   dataIndex: "reason",
    //   key: "reason",
    //   width: 120,
    //   render: (_, record) =>
    //     record?.status === "cancelled" ? record?.reason : "-",
    // },
    {
      title: "Ride #",
      align: "center",
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 100,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    // {
    //   title: "Driver",
    //   align: "center",
    //   dataIndex: "drivers",
    //   key: "drivers",
    //   width: 220,
    //   render: (drivers) => {
    //     return Array.isArray(drivers) ? ( drivers.map((driver) => (<div>{driver.label}</div>)) ) : ("Not Provided");
    //   },
    // },
    {
      title: "Ride Date",
      align: "center",
      dataIndex: "when",
      key: "when",
      className: "capitalize",
      width: 100,
      render: (_, record) => record.date,
    },
    // {
    //   title: "Pickup time",
    //   align: "center",
    //   dataIndex: "when",
    //   key: "when",
    //   className: "capitalize",
    //   width: 150,
    //   render: (_, record) => record.time,
    // },
    {
      title: "Pickup location",
      align: "center",
      dataIndex: "origin",
      key: "origin",
      className: "capitalize",
      width: 150,
      render: (origin) => origin?.address,
    },
    {
      title: "Additional Stops",
      align: "center",
      dataIndex: "stops",
      key: "stops",
      className: "capitalize",
      width: 150,
      render: (_, record) => (
        <>
          {record?.stops.length === 0 
            ? ( "-" ) 
            : (
              <>
                {
                  record?.stops.map((stop) => (
                    <div>{stop.address}</div>
                  ))
                }
              </>
              ) 
          }
        </>
      ),
    },
    {
      title: "Destination",
      align: "center",
      dataIndex: "destination",
      key: "destination",
      className: "capitalize",
      width: 150,
      render: (destination) => destination?.address,
    },
    {
      title: "Passenger",
      align: "center",
      dataIndex: "name",
      key: "name",
      width: 100,
      render: (_, record) => record?.passenger?.name || "Not Provided",
    },
    // {
    //   title: "Passenger Email",
    //   align: "center",
    //   dataIndex: "email",
    //   key: "email",
    //   width: 220,
    //   render: (_, record) => ( record?.passenger?.email || "Not Provided" ),
    // },
    // {
    //   title: "Passenger Phone #",
    //   align: "center",
    //   dataIndex: "phoneNo",
    //   key: "phoenNo",
    //   width: 220,
    //   render: (_, record) => ( record?.passenger?.phoneNumber || "Not Provided" ),
    // },
    // {
    //   title: "No of Bags",
    //   align: "center",
    //   dataIndex: "noOfBags",
    //   key: "noOfBags",
    //   width: 220,
    //   render: (noOfBags) => {
    //     return noOfBags;
    //   },
    // },
    // {
    //   title: "No of Luggages",
    //   align: "center",
    //   dataIndex: "noOfLuggage",
    //   key: "noOfLuggage",
    //   width: 220,
    //   render: (noOfLuggage) => {
    //     return noOfLuggage;
    //   },
    // },
    // {
    //   title: "No of Passengers",
    //   align: "center",
    //   dataIndex: "noOfPass",
    //   key: "noOfPass",
    //   width: 220,
    //   render: (noOfPass) => {
    //     return noOfPass;
    //   },
    // },
    {
      title: "Fare",
      align: "center",
      dataIndex: "cost",
      key: "cost",
      width: 120,
      render: (_, record) => (
        <>
          &pound;
          {record.cost || 0}
        </>
      ),
    },
    {
          title: "Action",
          align: "center",
          dataIndex: "ride",
          key: "ride",
          width: 80,
          render: (_, record) => (
            <Button
              variant="warning"
              onClick={() => {
                setItems(record);
                setModalShow(true);
                // console.log("button item===>", record);
              }}
              className="btn-sm fw-bolder text-white"
            >
              View
            </Button>
          ),
    },
    // {
    //   title: "Actions",
    //   key: "operation",

    //   align: "center",
    //   width: 150,
    //   render: (_, record) => {
    //     return (
    //       <div className="d-flex flex-col align-items-center justify-content-center">
    //         {/* <Edit onClick={() => editHandler(record)} /> */}
    //         <ActionBtn
    //           action={deleteRideRecord(record)}
    //           confirmMsg={"Do you want to delete this ride?"}
             
    //         >
    //           <Tooltip title={"delete"}>
    //             <DeleteOutlined
    //               style={ { color: "red", fontSize: 15, margin: "2px" } }
    //             />
    //           </Tooltip>
    //         </ActionBtn>
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1 className="fw-bold">
            Driver Rides History
          </h1>
        </Col>
        <Table
          bordered
          loading={loader}
          className="table-curved riderTable"
          columns={columns}
          dataSource={rides}
          pagination={false}
          scroll={rides.length > 7 && { y: 400 }}
        />
      </Row>
      {modalShow && (
        <OrderItem
          items={items}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </Container>
  );
}

export default memo(Index);
