import { useParams } from "react-router-dom";
import { getBlogById, updateBlog } from "../../redux/admin/action";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DOMPurify from "dompurify";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Button as AntdButton, Image, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { storage1 } from "../../config/firebase";

const index = () => {
  const { id } = useParams();
  const [add, setAdd] = useState(false);
  const [textAreaCount, setTextAreaCount] = useState(0);

  const recalculate = (e) => {
    setTextAreaCount(e.target.value.length);
  };
  const [blog, setBlog] = useState({
    title: "",
    shortDesc: "",
    image: "",
    featured: false,
    desc: EditorState.createEmpty(),
  });
  const [errors, setErrors] = useState([]);
  const changeInput = (e) => {
    const { name, value } = e.target;
    setBlog((p) => ({ ...p, [name]: value }));
  };
  const stopAdding = () => {
    setAdd(false);
  };
  const updateHandler = (e) => {
    e.preventDefault();
    let error = [];
    if (blog.title === "") {
      error.push("titleEmpty");
    }
    if (blog.desc === "") {
      error.push("descEmpty");
    }

    if (error.length) {
      setErrors(error);
    } else {
      setErrors([]);
      // setAdd(true);
      const html = `<div>${draftToHtml(
        convertToRaw(blog?.desc?.getCurrentContent())
      )}</div>`;
      setAdd(true);
      let slug = blog.title
        .replace(/ /g, "-")
        .replace(/\?/g, "")
        .replace(/,/g, "")
        .replace(/"/g, "")
        .replace(/'/g, "")
        .toLowerCase();
      dispatch(
        updateBlog(
          id,
          {
            title: blog.title,
            slug,
            shortDesc: blog.shortDesc,
            featured: blog.featured,
            image: blog.image,
            desc: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } }),
            updateDate: moment().format("DD MMM YYYY"),
          },
          stopAdding
        )
      );
    }
  };
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const stopLoader = (data) => {
    setLoader(false);

    const contentBlock = htmlToDraft(data.desc);

    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);

    setBlog((p) => {
      return {
        ...p,
        ...data,
        desc: editorState,
      };
    });
  };
  useEffect(() => {
    if (blog) {
      setTextAreaCount(blog.shortDesc.toString().length);
    }
  }, [blog]);
  useEffect(() => {
    if (id) {
      setLoader(true);
      dispatch(getBlogById(id, stopLoader));
    }
  }, [id, dispatch]);
  if (loader) {
    return <div>loading....</div>;
  }
  return (
    <div>
      <h1 className="fw-bold">Edit Blog</h1>
      {add && (
        <Alert
          variant="success"
          style={{ height: "3em" }}
          onClose={() => setAdd(false)}
          dismissible
        >
          <p>Blog updated!</p>
        </Alert>
      )}
      <Form onSubmit={updateHandler}>
        <Form.Group controlId="formBasicTitle" className="mt-3">
          <Form.Label>Title:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            name="title"
            value={blog.title}
            onChange={changeInput}
          />
          {errors.includes("titleEmpty") && (
            <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
              Enter title
            </span>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicTitle" className="mt-3">
          <Form.Label>Short description:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            // type="text"
            placeholder="Short description"
            name="shortDesc"
            onChange={(e) => {
              changeInput(e);
              recalculate(e);
            }}
            value={blog.shortDesc}
          />
        </Form.Group>
        <p style={{ fontSize: "1rem", float: "right" }}>
          {`Charaters count: ${textAreaCount}`}
        </p>
        <Form.Group className="mt-3" controlId="formBasicDescription">
          <Form.Label>Blog:</Form.Label>
          <Editor
            editorState={blog.desc}
            name="desc"
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(e) => {
              setBlog((p) => ({ ...p, desc: e }));
            }}
          />
          {errors.includes("descEmpty") && (
            <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
              Enter blog description
            </span>
          )}
        </Form.Group>
        <Image width={200} src={blog?.image} className="mt-3" />
        <Form.Group className="mt-3" controlId="formBasicTitle">
          {/* <Form.Label>Add featured image:</Form.Label> */}
          <Upload
            className="ml-3"
            customRequest={(e) => {
              const cvRef = ref(
                storage1,
                `blog_images/${e.file.name + e.file.uid}`
              );
              uploadBytes(cvRef, e.file).then((snapshot) => {
                e.onSuccess("ok");
                getDownloadURL(snapshot.ref).then((url) => {
                  setBlog({ ...blog, image: url });
                });
              });
            }}
            onRemove={(e) => {
              const cvRef = ref(storage1, `blog_images/${e.name + e.uid}`);
              getDownloadURL(cvRef).then((url) => {
                setBlog({ ...blog, image: "" });
              });
              deleteObject(cvRef).then(() => {
                return;
              });
            }}
          >
            <AntdButton icon={<UploadOutlined />}>
              {blog?.image === ""
                ? "Upload featured image"
                : "Update featured image"}
            </AntdButton>
          </Upload>
          {errors.includes("imgEmpty") && (
            <span className="w-100 text-danger" style={{ fontSize: "12px" }}>
              Upload featured image
            </span>
          )}
        </Form.Group>
        <Form.Check
          type="checkbox"
          id="checkbox"
          label="Featured post"
          className="mt-3"
          defaultChecked={blog?.featured}
          onChange={(e) => {
            setBlog({ ...blog, featured: e.target.checked });
          }}
        />
        <Button
          disabled={loader}
          variant="primary"
          className="mt-3 btn-sm fw-bolder text-white btn-warning"
          type="submit"
        >
          {loader ? "Updating..." : "Update"}
        </Button>
      </Form>
    </div>
  );
};

export default index;
