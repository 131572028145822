import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ImageDrop from "../../../components/Imagedrop";
import { updateSiteSettings } from "../../../redux/auth/action";

function Index() {
  const { settings } = useSelector((state) => state.auth);

  const [file, setfile] = useState({
    sitelogo: "",
    favIcon: "",
    careerBanner: "",
  });

  useEffect(() => {
    setfile((p) => {
      return {
        ...p,
        ...settings?.Images,
      };
    });
  }, [settings]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };
  const saveImages = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(updateSiteSettings("Images", file, stopLoader));
  };
  return (
    <Container fluid>
      <Row className="gap-2">
        <Col lg={12}>
          <h1 className="fw-bold">Images</h1>
        </Col>
        <Col lg={12} className="bg-white p-4 rounded-lg shadow-lg  ">
          <form onSubmit={saveImages}>
            <Row className="d-flex align-items-center justify-content-center">
              <Col
                lg={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <ImageDrop
                  changeAble={true}
                  file={file.sitelogo}
                  onSuccess={(image) =>
                    setfile((p) => {
                      return {
                        ...p,
                        sitelogo: image,
                      };
                    })
                  }
                  title={"Site logo:"}
                  subtitle={"175,35px"}
                  col={12}
                />
              </Col>
              <Col
                lg={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <ImageDrop
                  changeAble={true}
                  file={file.favIcon}
                  onSuccess={(image) =>
                    setfile((p) => {
                      return {
                        ...p,
                        favIcon: image,
                      };
                    })
                  }
                  title={"Favicon:"}
                  subtitle={"256,256px"}
                />
              </Col>
              <Col
                lg={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <ImageDrop
                  changeAble={true}
                  file={file.careerBanner}
                  onSuccess={(image) =>
                    setfile((p) => {
                      return {
                        ...p,
                        careerBanner: image,
                      };
                    })
                  }
                  title={"Career:"}
                  subtitle={"700,400px"}
                />
              </Col>
              <Col lg={12} className="text-center">
                <Button
                  variant="warning"
                  className="mt-3 fw-bolder text-white z-10  relative"
                  disabled={loader}
                  type="submit"
                >
                  {loader ? "Saving..." : "Save"}
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
