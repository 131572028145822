import { Button, Empty, Select, Spin } from "antd";
import React, { Fragment, memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

import AdminDocumentModal from "../../../modals/adminDocumentModal";
import AdminEmergencyModal from "../../../modals/adminEmergencyModal";
import AdminOtherDocuments from "../../../modals/adminOtherDocuments";
import AdminPersonalInfoModal from "../../../modals/adminPersonalInfoModal";
import { getAdminDetailInfo } from "../../../redux/admin/action";
import Documents from "./document";

const { Option } = Select;

function Modal(props) {
  const { type } = props;
  switch (type?.id) {
  case "documents":
    return <AdminDocumentModal {...props} />;
  case "emergency":
    return <AdminEmergencyModal {...props} />;

  case "personal":
    return <AdminPersonalInfoModal {...props} />;
  case "others":
    return <AdminOtherDocuments {...props} />;
  default:
    return <Fragment />;
  }
}
function Index() {
  const dispatch = useDispatch();
  const [
    info, setInfo
  ] = useState([]);
  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = (data) => {
    setLoader(false);
    if (data) {
      setInfo(data);
    }
  };
  const updateHandler = (data) => {
    let updateData = info.map((item) => {
      if (item.id === data.id) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setInfo(updateData);
  };
  const addDocumentHandler = (data) => {
    let newDocs = info.concat([data]);
    setInfo(newDocs);
  };
  const [
    show, setShow
  ] = useState(false);
  const handleClose = () => setShow(false);

  const [
    docType, setDocType
  ] = useState(null);
  useEffect(() => {
    setLoader(true);
    dispatch(getAdminDetailInfo(stopLoader));
  }, [dispatch]);

  const deleteDocument = (data) => {
    const newDocument = info.filter((e) => e.id !== data.id);
    setInfo(newDocument);
  };

  const addDocument = () => {
    if (!docType) {
      swal("Please select document type");
      return;
    }
    if (info.find((e) => e.id === docType)) {
      swal("Document already added");
      return;
    }
    setShow(true);
  };

  return (
    <Container fluid>
      <Spin spinning={loader}>
        <Row>
          <Col lg={12}>
            <h1 className="fw-bold">
Documents
            </h1>
          </Col>
          <Col lg={12}
            className="flex justify-end flex-wrap gap-2">
            <Select
              showSearch
              value={docType}
              onChange={(e) => setDocType(e)}
              style={ { width: 200, marginRight: 4 } }
              placeholder="Select document type"
              optionFilterProp="children"
            >
              {[
                "documents", "emergency", "personal", "others"
              ].map(
                (e, index) => (
                  <Option className=" capitalize"
                    key={index}
                    value={e}>
                    {e}
                  </Option>
                )
              )}
            </Select>
            <Button type="primary"
              onClick={addDocument}
              className="fw-bold">
              Add Document
            </Button>
          </Col>
          {info?.length > 0 ? (
            <div>
              {info.map((inf, index) => (
                <Documents
                  onDelete={deleteDocument}
                  onUpdate={updateHandler}
                  key={index}
                  info={inf}
                />
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </Row>
        {show && (
          <Modal
            as="NEW"
            person="admin"
            onAdd={addDocumentHandler}
            type={ { id: docType } }
            show={show}
            onHide={handleClose}
          />
        )}
      </Spin>
    </Container>
  );
}
export default memo(Index);
