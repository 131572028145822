import { Alert, Snackbar } from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import { Checkbox } from "antd";
import { Formik } from "formik";
import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import swal from "sweetalert";

import { getCitys, updateAdminProfile } from "../../redux/admin/action";
import { getAddress } from "../../utils/addressChanger";
import updateAdminFormValidation from "../../validations/updateAdminForm";

function Index() {
  const [
    updated, setUpdated
  ] = useState(false);
  const [
    autocomplet, setautocomplet
  ] = useState("");
  const onLoad = (autocomplete) => {
    setautocomplet(autocomplete);
  };
  const [
    loader, setLoader
  ] = useState(false);

  const dispatch = useDispatch();
  const { profileAdmin, roles } = useSelector((state) => state.admin);

  const updateProfile = (e) => {
    setLoader(true);
    const data = {
      ...e,
      name: e.firstName + " " + e.lastName,
      slug: `${e.firstName + " " + e.lastName}`
        ?.replace(" ", "-")
        ?.toLowerCase(),
    };
    delete data.firstName;
    delete data.lastName;

    dispatch(updateAdminProfile(data, stoploader));
  };
  const stoploader = (error) => {
    setLoader(false);
    if (error) {
      swal(error);
      return;
    }
    setUpdated(true);
  };
  const closeSnackBar = () => {
    setUpdated(false);
  };
  const [
    flag, setFlag
  ] = useState(false);
  const [
    cities, setCities
  ] = useState([]);
  const stopFlag = (data) => {
    setFlag(false);
    setCities(data);
  };

  useEffect(() => {
    dispatch(getCitys(stopFlag));
  }, [dispatch]);

  return (
    <Col lg={6}
      className="py-4">
      <Snackbar open={updated}
        autoHideDuration={2000}
        onClose={closeSnackBar}>
        <Alert
          variant="filled"
          onClose={closeSnackBar}
          severity="success"
          sx={ { width: "100%" } }
        >
          Profile Updated!
        </Alert>
      </Snackbar>
      <Formik
        initialValues={ {
          firstName: profileAdmin?.name?.split(" ")[0],
          lastName: profileAdmin?.name?.split(" ")[1],
          phone: profileAdmin?.phone,
          email: profileAdmin?.email,
          location: profileAdmin?.location,
          fullAddress: profileAdmin?.fullAddress,
          role: profileAdmin?.role,
          totalLeaves: profileAdmin?.totalLeaves,
          specificCities: profileAdmin?.specificCities,
          citys: profileAdmin?.citys,
          totalPublicHolidays: profileAdmin?.totalPublicHolidays,
        } }
        validationSchema={updateAdminFormValidation}
        onSubmit={updateProfile}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form
            onSubmit={handleSubmit}
            className=" profile-from d-flex justify-content-center flex-column align-items-center"
          >
            <div className="d-flex flex-row gap-2 w-100 mb-3">
              <InputGroup className=" w-50">
                <FormControl
                  placeholder="first name"
                  aria-label="firstName"
                  aria-describedby="basic-addon1"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="firstName"
                  className="h-12"
                />
                {touched.firstName && errors.firstName && (
                  <span className="w-100 text-danger  text-sm">
                    {errors.firstName}
                  </span>
                )}
              </InputGroup>
              <InputGroup className=" w-50">
                <FormControl
                  placeholder="Last Name"
                  aria-label="Username"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="lastName"
                  className="h-12"
                  aria-describedby="basic-addon1"
                />
                {touched.lastName && errors.lastName && (
                  <span className="w-100 text-danger  text-sm ">
                    {errors.lastName}
                  </span>
                )}
              </InputGroup>
            </div>
            <InputGroup>
              <FormControl
                placeholder="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                className="h-12"
              />
              {touched.email && errors.email && (
                <span className="w-100 text-danger  text-sm ">
                  {errors.email}
                </span>
              )}
            </InputGroup>
            <InputGroup className="h-12">
              <Select
                onChange={(e) => {
                  setFieldValue("role", e.value);
                }}
                name="colors"
                value={roles
                  .map((b) => {
                    return {
                      label: b.name,
                      value: b.id,
                    };
                  })
                  ?.find((a) => a.value === values.role)}
                options={roles?.map((e) => {
                  return {
                    label: e.name,
                    value: e.id,
                  };
                })}
                className="basic-multi-select w-full my-3 border-0 h-12"
                classNamePrefix="select"
              />
            </InputGroup>
            {touched.role && errors.role && (
              <span className="w-100 text-danger  text-sm ">
                {errors.role}
              </span>
            )}
            <FormControl
              placeholder="Annual Leaves"
              value={values?.totalLeaves}
              onChange={handleChange}
              onBlur={handleBlur}
              name="totalLeaves"
              type="number"
              className="my-3 h-12 "
            />
            {touched.totalLeaves && errors.totalLeaves && (
              <span className="w-100 text-danger  text-sm">
                {errors.totalLeaves}
              </span>
            )}
            <FormControl
              placeholder="Public Holidays"
              value={values?.totalPublicHolidays}
              onChange={handleChange}
              onBlur={handleBlur}
              name="totalPublicHolidays"
              type="number"
              className=" h-12 "
            />
            {touched.totalPublicHolidays && errors.totalPublicHolidays && (
              <span className="w-100 text-danger  text-sm">
                {errors.totalPublicHolidays}
              </span>
            )}
            <div className="w-full">
              <Checkbox
                className="mx-2"
                checked={values.specificCities}
                onChange={(e) => {
                  setFieldValue("specificCities", e.target.checked);
                }}
              >
                Specific Cities
              </Checkbox>
            </div>
            {values.specificCities && (
              <div className=" w-full ">
                <Select
                  onChange={(e) => {
                    let cities = e.map((e) => e.value);

                    setFieldValue("citys", cities);
                  }}
                  name="citys"
                  value={values.citys.map((e) => {
                    return {
                      label: cities.find((a) => a?.name === e)?.name,
                      value: e,
                    };
                  })}
                  options={cities.map((e) => {
                    return {
                      label: e.name,
                      value: e.name,
                    };
                  })}
                  isMulti
                  isLoading={flag}
                  className="basic-multi-select w-full  border-0"
                  classNamePrefix="select"
                />
                {errors.citys && touched.citys && (
                  <span
                    className="w-60 text-danger text-start  text-sm"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.citys}
                  </span>
                )}
              </div>
            )}
            <PhoneInput
              defaultCountry="GB"
              name="phone"
              value={values?.phone}
              className="w-full my-2"
              onChange={(phone) => setFieldValue("phone", phone)}
            />
            {touched.phone && errors.phone && (
              <span className="w-100 text-danger  text-sm ">
                {errors.phone}
              </span>
            )}
            <InputGroup className=" w-100 mb-2"
              style={ { height: "3em" } }>
              <Autocomplete
                className="w-full "
                onLoad={onLoad}
                onPlaceChanged={async () => {
                  if (autocomplet !== null) {
                    const place = await autocomplet.getPlace();
                    const address = await getAddress(place);
                    setFieldValue("location", address);
                  }
                }}
              >
                <FormControl
                  style={ { height: "3em" } }
                  placeholder="Enter Address"
                  aria-label="address"
                  aria-describedby="basic-addon1"
                  value={values?.location?.address}
                  onChange={(e) => {
                    setFieldValue("location", e.target.value);
                  }}
                  name="address"
                />
              </Autocomplete>
            </InputGroup>
            {touched.location && errors.location && (
              <span className="w-100 text-danger  text-sm">
                {errors.location}
              </span>
            )}
            <InputGroup style={ { height: "3em" } }>
              <FormControl
                placeholder="Enter Full Address"
                aria-label="Username"
                value={values?.fullAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                name="fullAddress"
                aria-describedby="basic-addon1"
              />
              {touched.fullAddress && errors.fullAddress && (
                <span className="w-100 text-danger text-sm ">
                  {errors.fullAddress}
                </span>
              )}
            </InputGroup>
            <Button
              type="submit"
              className="rounded fw-bolder text-white rounded-2 mt-2"
              variant="warning"
              disabled={loader}
            >
              {loader ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="mr-2"
                    variant="dark"
                    size="sm"
                  />
                  Saving...
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Col>
  );
}
export default memo(Index);
