import { Formik } from "formik";
import React, { memo,useState  } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  addDriverCarType,
  updateDriverCarType,
  uploadFiles,
} from "../../redux/admin/action";
import vehicleTypeValidation from "../../validations/vehicleType";

function Index({ show, onHide, type }) {
  const dispatch = useDispatch();

  const addCarTypeHandler = (values, helper) => {
    dispatch(
      addDriverCarType(values, () => {
        helper.setSubmitting(false);
        onHide();
      })
    );
  };
  const updateCarTypeHandler = (values, helper) => {
    dispatch(
      updateDriverCarType(values, type?.id, () => {
        helper.setSubmitting(false);
        onHide();
      })
    );
  };
  const [
    loader, setLoader
  ] = useState(false);

  const submitHandle = (values, helper) => {
    if (!type) {
      addCarTypeHandler(values, helper);
    } else {
      updateCarTypeHandler(values, helper);
    }
  };
  return (
    <Modal show={show}
      onHide={onHide}
      centered>
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="capitalize fs-6"
        >
          Add new car type
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={ {
            name: type?.name || "",
            description: type?.description || "",
            imageUrl: type?.imageUrl || "",
            passengers: type?.passengers || 1,
            luggage: type?.luggage || 0,
            handLuggage: type?.handLuggage || 0,
            wheelChairs: type?.wheelChairs || 0,
            boosterSeats: type?.boosterSeats || 0,
            childSeats: type?.boosterSeats || 0,
            infantSeats: type?.infantSeats || 0,
          } }
          validationSchema={vehicleTypeValidation}
          onSubmit={submitHandle}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Form className=" mx-auto"
              onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Label>
Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.name}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicemail">
                <Form.Label>
Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.description && touched.description && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.description}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicemail">
                <Form.Label>
Image
                </Form.Label>
                <Form.Control
                  type="file"
                  multiple={false}
                  placeholder="Enter image"
                  name="imageUrl"
                  disabled={loader}
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    setLoader(true);
                    dispatch(
                      uploadFiles(e.target.files, (img) => {
                        setFieldValue("imageUrl", img[0]);
                        setLoader(false);
                      })
                    );
                  }}
                />
                {errors.imageUrl && touched.imageUrl && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.imageUrl}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicemail">
                <Form.Label>
Passengers
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Passengers"
                  name="passengers"
                  value={values.passengers}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.passengers && touched.passengers && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.passengers}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formluggage">
                <Form.Label>
Luggage
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Luggage"
                  name="luggage"
                  value={values.luggage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.luggage && touched.luggage && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.luggage}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formluggage">
                <Form.Label>
Hand luggage
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter hand luggage"
                  name="handLuggage"
                  value={values.handLuggage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.handLuggage && touched.handLuggage && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.handLuggage}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formluggage">
                <Form.Label>
Wheel chairs
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter wheel chairs"
                  name="wheelChairs"
                  value={values.wheelChairs}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.wheelChairs && touched.wheelChairs && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.wheelChairs}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formluggage">
                <Form.Label>
Booster seats
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter booster seats"
                  name="boosterSeats"
                  value={values.boosterSeats}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.boosterSeats && touched.boosterSeats && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.boosterSeats}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formluggage">
                <Form.Label>
Child seats
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter child seats"
                  name="childSeats"
                  value={values.childSeats}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.childSeats && touched.childSeats && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.childSeats}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formluggage">
                <Form.Label>
Infant seats
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter infant seats"
                  name="infantSeats"
                  value={values.infantSeats}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.infantSeats && touched.infantSeats && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.infantSeats}
                  </span>
                )}
              </Form.Group>
              <Button
                disabled={isSubmitting || loader}
                variant="warning"
                className="mt-3 fw-bolder text-white "
                type="submit"
              >
                {isSubmitting ? "Adding..." : "Add"}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
