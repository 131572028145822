/* eslint-disable func-names */
import * as yup from "yup";

import { RESTAURANT_COLLECTION } from "../config";
import { firestore } from "../config/firebase";

yup.addMethod(yup.string, "emailisAlready", function (errorMessage) {
  return this.test("email-is-user", errorMessage, async function (value) {
    const { path, createError } = this;

    if (!value) return;

    const userRef = firestore()
      .collection(RESTAURANT_COLLECTION)
      .where("email", "==", value);
    const doc = await userRef.get();
    if (!doc.empty) {
      return createError({ path, message: errorMessage });
    } else {
      return value;
    }
  });
});

const schema = yup.object().shape({
  title: yup.string().required("Enter Title"),
  orderNumber: yup.string().notRequired(),
});

export default schema;
