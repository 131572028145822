import { Button, Empty, Select, Spin , Tooltip } from "antd";
import React, { Fragment, memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch , useSelector } from "react-redux";
import swal from "sweetalert";

import DrivingLicenceModal from "../../../modals/DrivingLicenceModal";
import IdentityRecordModal from "../../../modals/IdentityRecordModal";
import VehicalDocumentModal from "../../../modals/VehicalDocumentModal";
import ActionBtn from "../../../components/actionBtn";
import { getRiderDetailInfo , approvedRiderDocument, disapprovedRiderDocument,} from "../../../redux/admin/action";
import Documents from "./document";

const { Option } = Select;

function Modal(props) {
  const { type } = props;
  switch (type?.id) {
  case "identity":
    return <IdentityRecordModal {...props} />;
  case "driving_License":
    return <DrivingLicenceModal {...props} />;
  case "vehicle_Document":
    return <VehicalDocumentModal {...props} />;

  default:
    return <Fragment />;
  }
}
function Index() {

  const dispatch = useDispatch();
  const { profileRider , riders } = useSelector((state) => state.admin);
  // console.log("rider asasayaa ==> ", riders);

  const [info, setInfo] = useState([]);
  const [loader, setLoader] = useState(false);

  const stopLoader = (data) => {
    setLoader(false);
    if (data) {
      // setInfo(data);
      data?.map((e) => {
        if (e?.additionalDocuments) {
          let infor = e.additionalDocuments;
          let id = e.id;
          let finalInfo = { ...infor, id };
          setInfo((arr) => [
            ...arr, finalInfo
          ]);
        } else {
          setInfo((arr) => [
            ...arr, e
          ]);
        }
        return null;
      });
    }
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getRiderDetailInfo(stopLoader));
  }, [dispatch]);
  
  const updateHandler = (data) => {
    let updateData = info.map((item) => {
      if (item.id === data.id) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setInfo(updateData);
  };
  const addDocumentHandler = (data) => {
    let newDocs = info.concat([data]);
    setInfo(newDocs);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [docType, setDocType] = useState(null);


  const deleteDocument = (data) => {
    const newDocument = info.filter((e) => e.id !== data.id);
    setInfo(newDocument);
  };

  const addDocument = () => {
    if (!docType) {
      swal("Please select document type");
      return;
    }
    if (
      info?.find(
        (e) =>
          e?.name === docType ||
          info?.find((e) => e?.additionalDocuments?.name === docType)
      )
    ) {
      swal("Document already added");
      return;
    }
    setShow(true);
  };

  const isProved = profileRider?.isProved;

  const [activeloader, setActiveLoader] = useState(false);
  // setIsApproved(isProved ? isProved : false);
  // console.log("Driver proved state ===> ", profileRider?.isProved);

  const stopActiveLoader = () => {
    setActiveLoader(false);
  }
  
  const handleToggleApproval = (isProved) => (cb) => {
    setActiveLoader(true);
    if (!isProved) {
      const confirmed = window.confirm('Are you sure you want to approve the documents?');
      if(confirmed){
        // console.log('Approving Documents');
        dispatch(approvedRiderDocument(profileRider, stopActiveLoader));
      }
    }else if (isProved){
      const confirmed = window.confirm('Are you sure you want to disapprove the documents?');
      if(confirmed){
        // console.log('Disapproving Documents');
        dispatch(disapprovedRiderDocument(profileRider, stopActiveLoader));
      }
    }
  };



  return (
    <Container fluid>
      <Spin spinning={loader}>
        <Row>
          <Col lg={12}>
            <h1 className="fw-bold">
              Document 
            </h1>
          </Col>
          <Col lg={12} className="flex justify-end flex-wrap gap-2">
            {/* Documents approve or disapprove action button */}
            <Tooltip title={isProved ? "Disapprove driver documents?" : "Approve driver documents?"}>
              <Button
                type={isProved ? 'danger' : 'secondary'}
                className="fw-bold"
                value={isProved ? 'disapprove' : 'approve'}
                onClick={handleToggleApproval(isProved)}
              >
                {activeloader ? 
                  <>
                  {isProved ? 'Disapproving Documents...' : 'Approving Documents...'}
                  </> : 
                  <>
                  {isProved ? 'Disapprove Documents' : 'Approve Documents'}
                  </>
                }
              </Button>
            </Tooltip>
            

            <Select
              showSearch
              value={docType}
              className="text-capitalize"
              onChange={(e) => setDocType(e)}
              style={ { width: 200, marginRight: 4 } }
              placeholder="Select document type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())}
            >
              {[
                { label: "Identity", value: "identity" },
                { label: "Driving licence", value: "driving_License" },
                { label: "Vehicle document", value: "vehicle_Document" },
              ].map((e, index) => (
                <Option className=" capitalize"
                  key={index}
                  value={e.value}>
                  {e.label}
                </Option>
              ))}
            </Select>
            <Button type="primary"
              onClick={addDocument}
              disabled={isProved ? true : false}
              className="fw-bold">
              Add Document
            </Button>
          </Col>
          {info?.length > 0 ? (
            <div>
              {/* {info?.additionalDocuments ? info?.add} */}
              {info.map((inf, index) => (
                <Documents
                  onDelete={deleteDocument}
                  onUpdate={updateHandler}
                  key={index}
                  // info={inf?.additionalDocuments ? inf?.additionalDocuments : inf}
                  info={inf}
                />
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </Row>
        {show && (
          <Modal
            as="NEW"
            onAdd={addDocumentHandler}
            type={ { id: docType } }
            show={show}
            onHide={handleClose}
          />
        )}
      </Spin>
    </Container>
  );
}
export default memo(Index);
