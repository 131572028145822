import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import dotenv from "dotenv";
import firebase from "firebase/compat/app";
import * as geofirex from "geofirex";
import { getStorage } from "firebase/storage";

dotenv.config();
const firebaseConfig = {
  apiKey: "AIzaSyAylOpU63I4uAB_Rp2VdREW307jD2SqijE",
  authDomain: "navigo-taxi.firebaseapp.com",
  databaseURL: "https://navigo-taxi-default-rtdb.firebaseio.com",
  projectId: "navigo-taxi",
  storageBucket: "navigo-taxi.appspot.com",
  messagingSenderId: "864608395746",
  appId: "1:864608395746:web:166903d4f39cbed2b859bb",
  measurementId: "G-FEL33FYZRS",
};
const app = firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore;
const database = firebase.database;
const auth = firebase.auth;
const storage = firebase.storage;
const storage1 = getStorage(app);
const geo = geofirex.init(firebase);
export default firebase;

export { app, auth, database, firebase, firestore, geo,storage, storage1 };
