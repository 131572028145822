import { Formik } from "formik";
import React, { memo, useEffect , useMemo,useState  } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  addDriverVehicle,
  getRiders,
  getVehicleTypes,
  updateDriverVehicle,
  uploadFiles,
} from "../../redux/admin/action";

import addVehicleValidation from "../../validations/addVehicles";

function Index({ show, onHide, vehicle }) {

  const dispatch = useDispatch();
  const { vehicleTypes, riders, vehicles } = useSelector((state) => state.admin);
  const allData =  useSelector((state) => state.admin);

  const riderOptions = useMemo(() => {
    return riders
      // .filter((rider) => !rider.vehicleStatus) // Filter riders where vehicleStatus is false
      .map((rider) => {
        const isRiderInVehicle = vehicles.some((vehicle) => vehicle?.driver === rider.id);
        // Only include riders who are not assigned to any vehicle
        if (!isRiderInVehicle) {
          return {
            label: rider.name,
            value: rider.id,
          };
        }
  
        return null; // Exclude riders assigned to a vehicle
      })
      .filter(Boolean); // Remove any null entries from the map result
  }, [riders, vehicles]);
  

  const vehicleTypesOptions = useMemo(() => {
    return vehicleTypes?.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
  }, [vehicleTypes]);

  const addCarHandler = (values, helper) => {
    dispatch(
      addDriverVehicle(values, () => {
        helper.setSubmitting(false);
        onHide();
      })
    );
  };
  const updateCarHandler = (values, helper) => {
    dispatch(
      updateDriverVehicle(values, vehicle?.driver , vehicle?.id, () => {
        helper.setSubmitting(false);
        onHide();
      })
    );
  };
  
  const [loader, setLoader] = useState(false);

  const submitHandle = (values, helper) => {
    if (!vehicle) {
      addCarHandler(values, helper);
    } else {
      updateCarHandler(values, helper);
    }
  };
  
  const [fetching, setFetching] = useState(false);
  const [fetchingRiders, setFetchingRiders] = useState(false);

  useEffect(() => {
    setFetching(true);
    dispatch(getRiders(() => setFetchingRiders(false)));
    dispatch(getVehicleTypes(() => setFetching(false)));
  }, [dispatch]);



  return (
    <Modal show={show}
      onHide={onHide}
      size="lg"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"
          className=" fs-6">
            {vehicle ? "Update Car Details" : "Add new car"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={ {
            name: vehicle?.name || "",
            type: vehicle?.type || "",
            imageUrl: vehicle?.imageUrl || "",
            registrationNumber: vehicle?.registrationNumber || "",
            mot: vehicle?.mot || "",
            motExpiry: vehicle?.motExpiry || "",
            make: vehicle?.make || "",
            model: vehicle?.model || "",
            color: vehicle?.color || "",
            bodyType: vehicle?.bodyType || "",
            passengers: vehicle?.passengers || 1,
            keeperName: vehicle?.keeperName || "",
            keeperAddress: vehicle?.keeperAddress || "",
            note: vehicle?.note || "",
            isActive: vehicle?.isActive || false,
            driver: vehicle?.driver || "",
          } }
          validationSchema={addVehicleValidation}
          onSubmit={submitHandle}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Form
              className=" mx-auto d-flex flex-column gap-2 "
              onSubmit={handleSubmit}
            >
              <div className="d-flex gap-3 ">
                <Form.Group className="w-50"
                  controlId="formBasicName">
                  <Form.Label>
                  Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.name}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formBasicemail">
                  <Form.Label>
Type
                  </Form.Label>
                  <Select
                    placeholder="Select type"
                    isLoading={fetching}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={(e) => {
                      setFieldValue("type", e.value);
                    }}
                    isSearchable={true}
                    name="type"
                    value={vehicleTypesOptions?.find(
                      (e) => e?.value === values.type
                    )}
                    options={vehicleTypesOptions}
                  />
                  {errors.type && touched.type && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.type}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="d-flex gap-3 ">
                <Form.Group className="w-50"
                  controlId="formBasicemail">
                  <Form.Label>
Image
                  </Form.Label>
                  <Form.Control
                    type="file"
                    multiple={false}
                    placeholder="Enter image"
                    name="imageUrl"
                    disabled={loader}
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      setLoader(true);
                      dispatch(
                        uploadFiles(e.target.files, (img) => {
                          setFieldValue("imageUrl", img[0]);
                          setLoader(false);
                        })
                      );
                    }}
                  />
                  {errors.imageUrl && touched.imageUrl && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.imageUrl}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formBasicemail">
                  <Form.Label>
Registration number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter registration number"
                    name="registrationNumber"
                    value={values.registrationNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.registrationNumber && touched.registrationNumber && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.registrationNumber}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="d-flex gap-3 ">
                <Form.Group className="w-50"
                  controlId="formluggage">
                  <Form.Label>
MOT
                  </Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter MOT"
                    name="mot"
                    value={values.mot}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.mot && touched.mot && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.mot}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formluggage">
                  <Form.Label>
MOT Expiry
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter MOT Expiry"
                    name="motExpiry"
                    value={values.motExpiry}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.motExpiry && touched.motExpiry && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.motExpiry}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="d-flex gap-3 ">
                <Form.Group className="w-50"
                  controlId="formluggage">
                  <Form.Label>
Make
                  </Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter make"
                    name="make"
                    value={values.make}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.make && touched.make && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.make}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formluggage">
                  <Form.Label>
Model
                  </Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter model"
                    name="model"
                    value={values.model}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.model && touched.model && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.model}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="d-flex gap-3 ">
                <Form.Group className="w-50"
                  controlId="formluggage">
                  <Form.Label>
Color
                  </Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter color"
                    name="color"
                    value={values.color}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.color && touched.color && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.color}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formluggage">
                  <Form.Label>
Body type
                  </Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter body type"
                    name="bodyType"
                    value={values.bodyType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.bodyType && touched.bodyType && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.bodyType}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="d-flex gap-3 ">
                <Form.Group className="w-50"
                  controlId="formluggage">
                  <Form.Label>
Passengers
                  </Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter passengers"
                    name="passengers"
                    value={values.passengers}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.passengers && touched.passengers && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.passengers}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formluggage">
                  <Form.Label>
Status
                  </Form.Label>
                  <Select
                    onChange={(e) => {
                      setFieldValue("isActive", e.value);
                    }}
                    value={values.isActive
                      ? {
                        label: "active",
                        value: true,
                      }
                      : { label: "Inactive", value: false }}
                    options={[
                      {
                        label: "Active",
                        value: true,
                      },
                      {
                        label: "Inactive",
                        value: false,
                      },
                    ]}
                  />
                  {errors.isActive && touched.isActive && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.isActive}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="d-flex gap-3 ">
                <Form.Group className="w-50"
                  controlId="formluggage">
                  <Form.Label>
Keeper name
                  </Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter keeper name"
                    name="keeperName"
                    value={values.keeperName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.keeperName && touched.keeperName && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.keeperName}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formluggage">
                  <Form.Label>
Keeper address
                  </Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Enter keeper's address"
                    name="keeperAddress"
                    value={values.keeperAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.keeperAddress && touched.keeperAddress && (
                    <span
                      className="w-100 text-danger text-start"
                      style={ { fontSize: "12px" } }
                    >
                      {errors.keeperAddress}
                    </span>
                  )}
                </Form.Group>
              </div>
              <Form.Group className="w-50"
                controlId="formluggage">
                <Form.Label>
Driver
                </Form.Label>
                <Select
                  placeholder="Select driver"
                  isLoading={fetchingRiders}
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setFieldValue("driver", e.value);
                  }}
                  isSearchable={true}
                  name="driver"
                  value={riderOptions.find((e) => e.value === values.driver)}
                  options={riderOptions}
                />
                {errors.driver && touched.driver && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.driver}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formluggage">
                <Form.Label>
Note
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter note"
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.note && touched.note && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.note}
                  </span>
                )}
              </Form.Group>
              <div className="d-flex">
                <Button
                  disabled={isSubmitting || loader}
                  variant="warning"
                  className="mt-3 fw-bolder text-white "
                  type="submit"
                >
                  {isSubmitting ? "Adding..." : "Add"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
