import * as yup from "yup";

const schema = yup.object().shape({
  type: yup.string().required("Select type"),
  title: yup.string().required("Enter title"),
  reason: yup.string().required("Enter reason"),
  day: yup.array().required().min(2, "Please select day`s"),
});

export default schema;
