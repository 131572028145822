import {DeleteOutlined,InteractionOutlined,LoginOutlined,PlusOutlined,} from "@ant-design/icons";
import {Badge,Button,DatePicker,Input,Radio,Space,Table,Tooltip,} from "antd";
import moment from "moment";
import React, { memo, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import ActionBtn from "../../components/actionBtn";
import Dropdown from "../../components/dropdown";
import FormModal from "../../modals/AddUserModal";
import ViewScheduleModel from "../../modals/ViewScheduleModel";
import {
  activateRiderById,
  deActivateRiderById,
  deleteRiderById,
  getRidersByFilters,
  getRiders,
} from "../../redux/admin/action";

const { RangePicker } = DatePicker;
const { Search } = Input;

const renderStatus = (value) => {
  return (
    <Space>
      {value ? (
        <Badge count="Online"
          style={ { backgroundColor: "#52c41a" } } />
      ) : (
        <Badge count="Offline" />
      )}
    </Space>
  );
};

function Index() {
  const { user } = useSelector((state) => state.auth);
  const { roles } = useSelector((state) => state.admin);

  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [viewSchedule, setViewSchedule] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  const showScheduleHandler = (admin) => {
    setSelectedAdmin(admin);
    setViewSchedule(true);
  };

  const deleteAdmin = (admin) => (cb) => {
    dispatch(deleteRiderById(admin, cb));
  };

  const changeStatusAdmin = (admin) => (cb) => {
    if (!admin.isProved) {
      dispatch(activateRiderById(admin, cb));
      return;
    }
    dispatch(deActivateRiderById(admin, cb));
  };
  const role = roles.find((e) => e.id === user.role);

  const columns = [
    {
      title: "Name",
      align: "center",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 120,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record.id).toLowerCase().includes(value.toLowerCase()) ||
          String(record.isLive).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Driver ID",
      align: "center",
      dataIndex: "driverId",
      key: "driverId",
      fixed: "left",
      width: 100,
      render: (_, record) => record.driverId || "-",
    },
    {
      title: "Email",
      align: "center",
      dataIndex: "email",
      key: "email",

      width: 120,
    },
    {
      title: "Schedule",
      align: "center",
      dataIndex: "schedule",
      key: "schedule",
      width: 200,
      render: (_, record) => {
        let day = record?.scheduleTime?.monday
          ? record?.scheduleTime?.monday
          : "";
        let day1 = record?.scheduleTime?.tuesday
          ? record?.scheduleTime?.tuesday
          : "";
        let day2 = record?.scheduleTime?.wednesday
          ? record?.scheduleTime?.wednesday
          : "";
        let day3 = record?.scheduleTime?.thursday
          ? record?.scheduleTime?.thursday
          : "";
        let day4 = record?.scheduleTime?.friday
          ? record?.scheduleTime?.friday
          : "";
        let day5 = record?.scheduleTime?.saturday
          ? record?.scheduleTime?.saturday
          : "";
        let day6 = record?.scheduleTime?.sunday
          ? record?.scheduleTime?.sunday
          : "";
        let days = [];
        if (day !== "") days.push({ day: day, d: "Monday" });
        if (day1 !== "") days.push({ day: day1, d: "Tuesday" });
        if (day2 !== "") days.push({ day: day2, d: "Wednesday" });
        if (day3 !== "") days.push({ day: day3, d: "Thursday" });
        if (day4 !== "") days.push({ day: day4, d: "Friday" });
        if (day5 !== "") days.push({ day: day5, d: "Saturday" });
        if (day6 !== "") days.push({ day: day6, d: "Sunday" });
        return days?.length > 0 ? (
          days.map((e) => {
            return (
              <>
                {" "}
                {e.d + "=> " + e.day[0] + "-" + e.day[1]} 
                {" "}
                <br />
              </>
            );
          })
        ) : (
          <p>
            N/A
          </p>
        );
      },
    },
    {
      title: "Creation Date",
      align: "center",
      dataIndex: "createdAt",
      width: 120,
      key: "createdAt",
      render: (e) => `${moment(Number(e)).format("DD-MMM-YY/hh:mm A")}`,
    },

    {
      title: "Online Status",
      align: "center",
      dataIndex: "isLive",
      key: "isLive",
      render: renderStatus,
      width: 120,
    },

    {
      title: "Actions",
      key: "operation",

      align: "center",
      width: 120,

      render: (_, record) => {
        return (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Tooltip title={"View schedule"}>
              <button
                onClick={() => showScheduleHandler(record)}
                className="mt-1"
              >
                <FaEye className="text-gray-500" />
              </button>
            </Tooltip>
            {user.role === "ROOT" || !role.specificMain ? (
              <Tooltip title={"profile"}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/rider/${record.id}`}
                >
                  <LoginOutlined
                    style={ { color: "blue", fontSize: 15, margin: "2px" } }
                  />
                </a>
              </Tooltip>
            ) : (
              roles?.find((e) => e.id === user.role)?.riderTabs?.length > 0 && (
                <Tooltip title={"profile"}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/rider/${record.id}`}
                  >
                    <LoginOutlined
                      style={ { color: "blue", fontSize: 15, margin: "2px" } }
                    />
                  </a>
                </Tooltip>
              )
            )}
            <ActionBtn
              confirmMsg={`Want to ${
                !record.isProved ? "activate" : "de-activate"
              } this driver?`}
              action={changeStatusAdmin(record)}
            >
              <Tooltip title={record.isProved ? "de-activate" : "activate"}>
                <InteractionOutlined
                  style={ {
                    color: record.isProved ? "green" : "red",
                    fontSize: 15,
                    margin: "2px",
                  } }
                />
              </Tooltip>
            </ActionBtn>
            <ActionBtn
              action={deleteAdmin(record)}
              confirmMsg={"Want to delete this driver?"}
             
            >
              <Tooltip title={"delete"}>
                <DeleteOutlined
                  style={ { color: "red", fontSize: 15, margin: "2px" } }
                />
              </Tooltip>
            </ActionBtn>

          </div>
        );
      },
    },
  ];

  const [showNewAdmin, setShowNewAdmin] = useState(false);

  const { riders } = useSelector((state) => state.admin);
  // const r = useSelector((state) => state.admin);
  // console.log('f', r)
  // console.log("Riders Check: ", riders);

  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [date, setDate] = useState([]);

  
  const setDateHandle = (e) => {
    if (!e) return;
    setDate(e);
    if(date){
      setStartDate(moment(date[0]).startOf("d").format("x"));
      setEndDate(moment(date[1]).endOf("d").format("x"));
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
  }, []);
  
  useEffect(() => {
    // console.log("Check Date", date);
    // if(date){
    //   // setStartDate(moment(date[0]).startOf("d").format("x"));
    //   // setEndDate(moment(date[1]).endOf("d").format("x"));
    // }
   
    dispatch(
      getRidersByFilters(
        {
          start: startDate,
          end: endDate,
        },
        onlineStatus,
        status,
        stopLoader
      )
    );
  }, [
    dispatch, status, onlineStatus, date, riders
  ]);

  

  // useEffect(() => {
  //   dispatch(getRiders(stopLoader))
  // }, [dispatch])

  const stopLoader = () => {
    setLoader(false);
  };
  const [searchValue] = useState("");

  const adminData = useMemo(() => {
    
    // console.log("++ riders calls..", riders);

    if (searchValue) {
      return riders.filter(
        (admin) =>
          admin?.driverId?.includes(searchValue) ||
          admin?.email?.includes(searchValue)
      );
    } else {
      riders.sort((a, b) => {
        // Convert Unix timestamps to Date objects
        const dateA = new Date(moment(Number(a.createdAt)).format("DD-MMM-YY"));
        const dateB = new Date(moment(Number(b.createdAt)).format("DD-MMM-YY"));
        // debugger
        // Compare and return the result for sorting
        return dateB - dateA;
      });
      return riders;
    }
  }, [
    searchValue, riders
  ]);

  return (
    <>
      <Container fluid
        className=" w-100 pb-5">
        <Row>
          <Col lg={12}
            className=" pl-0 ">
            <h2 className="fw-bold">
              Drivers
            </h2>
          </Col>
          <Col lg={12}
            className="flex justify-end p-0">
            <Button
              type="primary"
              className="  fw-bold  m-1"
              onClick={() => {
                setShowNewAdmin(true);
              }}
            >
              <PlusOutlined className="m-auto" />
              Add Driver
            </Button>
          </Col>
          <Col lg={12}>
            <Row className="flex-grow-1 gap-3">
              <Col className="p-0 flex items-center gap-2">
                <h6 className="font-bold">
                  Status:
                  {" "}
                </h6>
                <Dropdown
                  type={status && status !== "all"
                    ? `Status- ${status}`
                    : "All Drivers"}
                >
                  <div className="bg-white w-56 shadow-lg p-2 ">
                    <Radio.Group
                      buttonStyle="solid"
                      value={status ? status : "ALL"}
                      onChange={(e) => {
                        if (e.target.value === "ALL") {
                          setStatus(null);
                          return;
                        }
                        setStatus(e.target.value);
                      }}
                    >
                      <Space direction="vertical">
                        <Radio
                          className=" text-base fw-bold capitalize"
                          value={"ALL"}
                        >
                          All
                        </Radio>
                        {[
                          "active", "inactive"
                        ].map((value, index) => (
                          <Radio
                            className=" text-base fw-bold capitalize"
                            key={index}
                            value={value}
                          >
                            {value}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </div>
                </Dropdown>
              </Col>
              <Col className="p-0 flex items-center gap-2">
                <h6 className="font-bold">
                    Online:
                </h6>
                <Dropdown
                  className="text-prop"
                  type={onlineStatus && onlineStatus !== "all"
                    ? `Status- ${onlineStatus}`
                    : "All Drivers"}
                >
                  <div className="bg-white w-56 shadow-lg p-2 ">
                    <Radio.Group
                      buttonStyle="solid"
                      value={onlineStatus ? onlineStatus : "ALL"}
                      onChange={(e) => {
                        if (e.target.value === "ALL") {
                          setOnlineStatus(null);
                          return;
                        }
                        setOnlineStatus(e.target.value);
                      }}
                    >
                      <Space direction="vertical">
                        <Radio
                          className=" text-base fw-bold capitalize"
                          value={"ALL"}
                        >
                          All
                        </Radio>
                        {[
                          "online", "offline"
                        ].map((value, index) => (
                          <Radio
                            className=" text-base fw-bold capitalize"
                            key={index}
                            value={value}
                          >
                            {value}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </div>
                </Dropdown>
              </Col>
              <Col className="p-0 flex items-end">
                <RangePicker
                  className="w-full my-auto "
                  format="DD MMM YYYY"
                  value={date}
                  onChange={setDateHandle}
                  
                />
              </Col>
              <Col className="p-0">
                <Search
                  placeholder="Search driver..."
                  allowClear
                  // size="large"
                  onSearch={(value) => setSearchedText(value)}
                  onChange={(e) => setSearchedText(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12}
            className="shadow rounded-3 bg-white p-3 mt-3">
            <Table
              bordered
              className="table-curved adminTable"
              columns={columns}
              dataSource={adminData}
              pagination={false}
              loading={loader}
              scroll={ { y: 500 } }
            />
          </Col>
        </Row>
        {showNewAdmin && (
          <FormModal
            as="driver"
            show={showNewAdmin}
            onHide={() => setShowNewAdmin(false)}
          />
        )}
        {viewSchedule && (
          <ViewScheduleModel
            person={selectedAdmin}
            show={viewSchedule}
            onHide={() => {
              setSelectedAdmin(null);
              setViewSchedule(false);
            }}
          />
        )}
      </Container>
    </>
  );
}

export default memo(Index);
