import React, { memo } from "react";
import { ukCurrancy } from "../../../utils/convertions";
import { Tag } from "antd";


function Index({ item }) {

  // console.log("my ride item ====>", item);
  
  const variantNames =
    item?.varients?.map((e) => `${e?.variant.optionName} ${e.option?.name}`) ||
    [];

  const optionsNames =
    item?.extras?.map(
      (e) => `${e?.extra?.map((a) => `${a?.name} ${e?.category?.name}`)}`
    ) || [];

  const setStatusTag = (status) => {
    if (status === "completed") {
      return (
        <Tag color="green"
          className="rounded-pill capitalize">
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Tag>
      );
    } else if (status === "cancelled") {
      return (
        <Tag color="red"
          className="rounded-pill capitalize">
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Tag>
      );
    } else if (status === "pending") {
      return (
        <Tag color="yellow"
          className="rounded-pill capitalize">
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Tag>
      );
    } else if (status === "driverAssigned") {
      return (
        <Tag color="gold"
          className="rounded-pill capitalize">
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Tag>
      );
    } else {
      return <p>
        {status}
      </p>;
    }
  };

  return (
    <>
    {/* {console.log("my ride item ====>", item)} */}
      {/* <tr className="font-weight-bold" style={ { fontSize: "1.2em" } }> */}
        <tr>
          <th colSpan={2}>Ride Status</th>
          <td className="capitalize">{setStatusTag(item?.status)}</td>
        </tr>
        <tr>
          <th colSpan={2}>Date</th>
          <td>{item?.date}</td>
        </tr>
        <tr>
          <th colSpan={2}>Time</th>
          <td>{item?.time}</td>
        </tr>
        <tr>
          <th rowSpan={3}>Passenger Details</th>
          <th>Name</th>
          <td>{item?.passenger?.name}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{item?.passenger?.email || "Not Provided"}</td>
        </tr>
        <tr>
          <th>Phone Number</th>
          <td>{item?.passenger?.phoneNumber || "Not Provided"}</td>
        </tr>
        <tr>
          <th rowSpan={3}>Ride Details</th>
          <th>Pickup Location</th>
          <td>{item?.origin?.address}</td>
        </tr>       
        <tr>
          <th>Additional Stops</th>
          {item?.stops.length === 0 
            ? (<td>{"-"}</td>) : (<td>{item?.stops.map((stop) => ( stop.address ))}</td>)}
          
        </tr>
        <tr>
          <th>Dropoff Location</th>
          <td>{item?.destination?.address}</td>
        </tr>
        <tr>
          <th colSpan={2}>Estimated Distance</th>
          <td>{item?.estimatedDistance} miles</td>
        </tr>
        <tr>
          <th rowSpan={4}>Other Details</th>
          <th>No of Bags</th>
          <td>{item?.noOfBags || 0}</td>
        </tr>
        <tr>
          <th >No of Luggages</th>
          <td>{item?.noOfLuggage || 0}</td>
        </tr>
        <tr>
          <th >No of Passengers</th>
          <td>{item?.noOfPass || 0}</td>
        </tr>
        <tr>
          <th >Note For Driver</th>
          <td>{item.noteForDriver ? item?.noteForDriver : "-"}</td>
        </tr>
        <tr>
          <th colSpan={2}>Fare</th>
          <td>{ukCurrancy.format(item?.cost || 0)}</td>
        </tr>
        <tr>
          <th colSpan={2}>Paid Status</th>
          <td>
            <strong style={{ 
                color:"white",
                padding:"4px 20px 4px 20px",
                borderRadius: "20px",
                backgroundColor: `${item.paid ? "green": "red" }`, 
              }}>
              {item?.paid ? "Paid" : "Not Paid"}
            </strong>
          </td>
        </tr>
        {/* <td>{item?.date}</td>
        <td>{item?.time}</td>
        <td>{item?.origin?.address}</td>
        <td>
          {item?.stops.length === 0 
            ? ( "-" ) : ( item?.stops.map((stop) => ( stop.address )) 
          )} 
        </td>
        <td>{item?.destination?.address}</td>
        <td>{ukCurrancy.format(item?.cost || 0 )}</td>
        <td className="capitalize" style={{fontWeight: "bold"}}>{item?.status}</td> */}
        {/* <td className=" capitalize">
          {optionsNames.join(", ") || "No extras found"}
        </td> */}
      {/* </tr> */}
    </>
  );
}

export default memo(Index);
