/* eslint-disable no-case-declarations */
import * as constants from "./constants";

let initState = {
  orders: [],
  rides: [],
  payments: [],
  schedules: [],
  notifications: [],
  hasUnreadNotifications: "",
};
function riderReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
    case constants.GET_ORDERS:
      return {
        ...state,
        orders: payload,
      };

    case constants.GET_RIDES:
      return {
        ...state,
        rides: payload,
      };
      case constants.DELETE_RIDE:
        const deletedRide = state.rides.filter((e) => e.id !== payload.id);
        return {
          ...state,
          rides: deletedRide,
        };
        
    case constants.GET_PAYMENTS:
      return {
        ...state,
        payments: payload,
      };
    case constants.GET_NOTIFICATIONS:
      let hasUnread = payload.some((not) => not.isRead === false);
      return {
        ...state,
        notifications: payload,
        hasUnreadNotifications: hasUnread,
      };
    case constants.GET_READ_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };
    case constants.GET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };
    case constants.MARK_ALL_AS_READ_NOTIFICATIONS:
      let hasUnread1 = payload.some((not) => not.isRead === false);
      return {
        ...state,
        notifications: payload,
        hasUnreadNotifications: hasUnread1,
      };

    case constants.ADD_SCHEDULE:
      let newSchedule = [...state.schedules, payload];
      return {
        ...state,
        schedules: newSchedule,
      };
    case constants.REMOVE_SCHEDULE:
      let newSchedules = state.schedules.filter(
        (schedule) => schedule.id !== payload.id
      );
      return {
        ...state,
        schedules: newSchedules,
      };
    case constants.UPDATE_SCHEDULE:
      let updatedSchedules = state.schedules.map((schedule) => {
        if (schedule.id === payload.id) {
          return {
            ...schedule,
            ...payload,
          };
        }
        return schedule;
      });
      return {
        ...state,
        schedules: updatedSchedules,
      };
    case constants.UPDATE_NOTIFICATIONS:
      let updatedNotifications = state.notifications.map((notification) => {
        if (notification.id === payload.id) {
          return {
            ...notification,
            ...payload,
          };
        }
        return notification;
      });
      let hasUnread2 = updatedNotifications.some((not) => not.isRead === false);
      return {
        ...state,
        notifications: updatedNotifications,
        hasUnreadNotifications: hasUnread2,
      };
    case constants.REMOVE_PAYMENT:
      const newPayments = state.payments.filter((pay) => pay.id !== payload.id);
      return {
        ...state,
        payments: newPayments,
      };
    case constants.ADD_PAYMENT:
      const newpayments = state.payments.map((e) => e);
      newpayments.push(payload);
      return {
        ...state,
        payments: newpayments,
      };
    case constants.UPDATE_PAYMENTS:
      const updatedpayments = state.payments.map((e) => {
        if (e.id === payload.id) {
          return payload;
        }
        return e;
      });

      return {
        ...state,
        payments: updatedpayments,
      };
    default:
      return state;
  }
}

export default riderReducer;
