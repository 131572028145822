import "./index.css";

import {
  DeleteOutlined,
  InteractionOutlined,
  LoginOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Badge, Button, Input, Space, Table, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ActionBtn from "../../components/actionBtn";
import FormModal from "../../modals/AddUserModal";
import {
  activateUserById,
  deActivateUserById,
  deleteUserById,
  getUsers,
} from "../../redux/admin/action";

const renderStatus = (value) => {
  return (
    <Space>
      {value ? (
        <Badge count="active"
          style={ { backgroundColor: "#52c41a" } } />
      ) : (
        <Badge count="inactive" />
      )}
    </Space>
  );
};

function Index() {
  const { user } = useSelector((state) => state.auth);
  const { roles } = useSelector((state) => state.admin);
  const deleteAdmin = (admin) => (cb) => {
    dispatch(deleteUserById(admin, cb));
  };
  const role = roles.find((e) => e.id === user.role);

  const changeStatusAdmin = (admin) => (cb) => {
    if (!admin.isProved) {
      dispatch(activateUserById(admin, cb));
      return;
    }
    dispatch(deActivateUserById(admin, cb));
  };

  const columns = [  
    {
      title: "Name",
      align: "center",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 120,
    },
    {
      title: "Rider ID",
      align: "center",
      dataIndex: "driverId",
      key: "driverId",
      fixed: "left",
      width: 120,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    {
      title: "Email",
      align: "center",
      dataIndex: "email",
      key: "email",

      width: 120,
    },
    {
      title: "Creation Date",
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      // render: (e) => `${moment(Number(e)).format("DD-MMM-YY/hh:mm A")}`,
      render: (createdAt) => {
        const date = new Date(parseInt(createdAt)); // Convert Firebase timestamp to JavaScript Date object
        return moment(date).format("DD-MMM-YY/hh:mm A");
      },
    },

    {
      title: "Active",
      align: "center",
      dataIndex: "isProved",
      key: "isProved",
      render: renderStatus,
      width: 120,
    },

    {
      title: "Actions",
      key: "operation",

      align: "center",
      width: 120,

      render: (_, record) => {
        if (record.Role === "Owner") {
          return null;
        }
        return (
          <div className="d-flex align-items-center justify-content-center">
            {user.role === "ROOT" || !role.specificMain ? (
              <Tooltip title="profile">
                <a target="_blank"
                  rel="noreferrer"
                  href={`/user/${record.id}`}>
                  <LoginOutlined
                    style={ { color: "blue", fontSize: 15, margin: "2px" } }
                  />
                </a>
              </Tooltip>
            ) : (
              roles?.find((e) => e.id === user.role)?.customerTabs?.length >
                0 && (
                <Tooltip title="profile">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/user/${record.id}`}
                  >
                    <LoginOutlined
                      style={ { color: "blue", fontSize: 15, margin: "2px" } }
                    />
                  </a>
                </Tooltip>
              )
            )}
            <ActionBtn
              confirmMsg={`Want to ${
                record.isProved ? "de-activate" : "activate"
              } rider?`}
              action={changeStatusAdmin(record)}
            >
              <Tooltip title={record.isProved ? "de-activate" : "activate"}>
                <InteractionOutlined
                  style={ {
                    color: record.isProved ? "green" : "red",
                    fontSize: 15,
                    margin: "2px",
                  } }
                />
              </Tooltip>
            </ActionBtn>
            <ActionBtn
              action={deleteAdmin(record)}
              confirmMsg={"Want to delete this rider?"}
              
            >
              <Tooltip title={"delete"}>
                <DeleteOutlined
                  style={ { color: "red", fontSize: 15, margin: "2px" } }
                />
              </Tooltip>
            </ActionBtn>
          </div>
        );
      },
    },
  ];

  const [
    showNewAdmin, setShowNewAdmin
  ] = useState(false);

  const { users } = useSelector((state) => state.admin);

  const [
    filteradmins, setfilteradmins
  ] = useState([]);

  const [
    loader, setLoader
  ] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoader(true);
    dispatch(getUsers(stopLoader));
  }, [dispatch]);
  const stopLoader = () => {
    setLoader(false);
  };

  const searchTable = (e) => {
    if (e.target.value) {
      let filter = users.filter(
        (admin) =>
          admin?.name?.includes(e.target.value) ||
          admin?.email?.includes(e.target.value)
      );
      setfilteradmins(filter);
    } else {
      setfilteradmins([]);
    }
  };

  const adminData = filteradmins.length > 0 ? filteradmins : users;

  return (
    <>
      <Container fluid
        className=" w-100  pb-5">
        <Row>
          <Col lg={12}
            className=" pl-0 ">
            <h1 className="fw-bold">
            Riders
            </h1>
          </Col>
          <Col lg={12}
            className="d-flex justify-content-end ">
            <Row>
              <Col lg={6}
                className=" flex justify-end p-0">
                <Button
                  type="primary"
                  className=" d-flex fw-bold algin-items-center m-1"
                  onClick={() => {
                    setShowNewAdmin(true);
                  }}
                >
                  <PlusOutlined className="m-auto" />
                  Add Rider
                </Button>
              </Col>
              <Col className="p-0">
                <Input
                  onChange={searchTable}
                  className="m-1"
                  placeholder="Name/Email"
                  prefix={<SearchOutlined />}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12}
            className="shadow rounded-3 bg-white p-3">
            <Table
              bordered
              className="table-curved adminTable"
              columns={columns}
              dataSource={adminData}
              pagination={false}
              loading={loader}
              scroll={ { y: 500 } }
            />
          </Col>
        </Row>
        {showNewAdmin && (
          <FormModal
            as="rider"
            show={showNewAdmin}
            onHide={() => setShowNewAdmin(false)}
          />
        )}
      </Container>
    </>
  );
}

export default memo(Index);
