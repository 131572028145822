import React, { memo, useEffect, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import { addAdminPayment, updateAdminPayment } from "../../redux/admin/action";

function Index({ payment, show, onHide }) {
  const { payments } = useSelector((state) => state.rider);

  const [
    updated, setupdated
  ] = useState(false);

  const [
    errors, seterrors
  ] = useState([]);
  const [
    newpayment, setnewpayment
  ] = useState({
    name: "",
    bankName: "",
    number: "",
    code: [],
    country: "",
    IBAN: "",
  });
  const [
    saving, setSaving
  ] = useState(false);
  const inputchange = (e) => {
    const { name, value } = e.target;
    seterrors([]);
    setnewpayment((p) => ({ ...p, [name]: value }));
  };
  useEffect(() => {
    if (payment) {
      setnewpayment(payment);
    }
    return () =>
      setnewpayment({
        name: "",
        bankName: "",
        number: "",
        code: "",
        country: "",
        IBAN: "",
      });
  }, [payment]);

  const dispatch = useDispatch();

  const stopSaving = () => {
    setSaving(false);
  };
  const addpayment = async (e) => {
    e.preventDefault();
    let error = [];
    setupdated(false);
    if (newpayment.name === "") {
      error.push({ nameempty: true });
    }
    if (newpayment.bankName === "") {
      error.push({ bnameempty: true });
    }
    if (newpayment.country === "") {
      error.push({ countryempty: true });
    }
    if (newpayment.country === "PAK" && newpayment.IBAN === "") {
      error.push({ IBANempty: true });
    }

    if (newpayment.number === "") {
      error.push({ numberempty: true });
    } else if (newpayment.number.length < 8) {
      error.push({ eightnumberempty: true });
    } else {
      let exist = payments?.filter(
        (pay) => pay.accountNumber === newpayment.number
      );
      if (exist?.length) {
        error.push({ numberalready: true });
      }
    }
    if (newpayment.country === "UK" && newpayment.code.length < 3) {
      error.push({ eightcodeempty: true });
    }
    if (error.length) {
      seterrors(error);
    } else {
      seterrors([]);
      if (payments?.length === 1 && !payment) {
        seterrors([{ limitreached: true }]);
      } else {
        seterrors([]);
        swal({
          title: "Are you sure?",
          text: "your payment information is correct!",
          icon: "warning",
          buttons: true,
        }).then((e) => {
          if (e) {
            setSaving(true);

            if (payment) {
              dispatch(updateAdminPayment(newpayment, payment.id, stopSaving));

              return;
            }

            dispatch(addAdminPayment(newpayment, stopSaving));
          }
        });
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {payment ? "Update Payment" : "Add payment"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updated && (
          <Alert
            variant={"success"}
            onClose={() => setupdated(false)}
            dismissible
          >
            Payment Added successfully!
          </Alert>
        )}
        {errors &&
          errors.map(({ limitreached }, index) => {
            if (limitreached) {
              return (
                <Alert key={index}
                  variant="danger">
                  Dear! you max limit reached
                </Alert>
              );
            }
            return null;
          })}
        <Form className=" w-100"
          onSubmit={addpayment}>
          <Form.Group className="my-2">
            <Form.Label>
Account holder name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              value={newpayment.name}
              name="name"
              onChange={inputchange}
            />
          </Form.Group>
          {errors &&
            errors.map(({ nameempty }, index) => {
              if (nameempty) {
                return (
                  <span
                    key={index}
                    className="w-100 text-danger"
                    style={ { fontSize: "12px" } }
                  >
                    Enter account holder name
                  </span>
                );
              }
              return null;
            })}
          <Form.Group className="my-2">
            <Form.Label>
Bank name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Bank"
              value={newpayment.bankName}
              name="bankName"
              onChange={inputchange}
            />
          </Form.Group>
          {errors &&
            errors.map(({ bnameempty }, index) => {
              if (bnameempty) {
                return (
                  <span
                    key={index}
                    className="w-100 text-danger"
                    style={ { fontSize: "12px" } }
                  >
                    Enter bank name
                  </span>
                );
              }
              return null;
            })}
          <Form.Group className="my-2">
            <Form.Label>
Account number
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Number"
              value={newpayment.number}
              name="number"
              onChange={inputchange}
            />
          </Form.Group>
          {errors &&
            errors.map(({ numberempty }, index) => {
              if (numberempty) {
                return (
                  <span
                    key={index}
                    className="w-100 text-danger"
                    style={ { fontSize: "12px" } }
                  >
                    Enter account number
                  </span>
                );
              }
              return null;
            })}
          {errors &&
            errors.map(({ numberalready }, index) => {
              if (numberalready) {
                return (
                  <span
                    key={index}
                    className="w-100 text-danger"
                    style={ { fontSize: "12px" } }
                  >
                    Account number already added
                  </span>
                );
              }
              return null;
            })}
          {errors &&
            errors.map(({ eightnumberempty }, index) => {
              if (eightnumberempty) {
                return (
                  <span
                    key={index}
                    className="w-100 text-danger"
                    style={ { fontSize: "12px" } }
                  >
                    Enter 8-digits account number
                  </span>
                );
              }
              return null;
            })}
          <Form.Group className="my-2">
            <Form.Label>
Country
            </Form.Label>
            <Form.Select
              value={newpayment.country}
              onChange={(event) => {
                seterrors([]);
                setnewpayment({ ...newpayment, country: event.target.value });
              }}
            >
              <option value="">
Select country
              </option>
              <option value="PAK">
Pakistan
              </option>
              <option value="UK">
Uk
              </option>
            </Form.Select>
          </Form.Group>
          {errors &&
            errors.map(({ countryempty }, index) => {
              if (countryempty) {
                return (
                  <span
                    key={index}
                    className="w-100 text-danger"
                    style={ { fontSize: "12px" } }
                  >
                    Select country
                  </span>
                );
              }
              return null;
            })}
          {newpayment.country === "PAK" && (
            <Form.Group className="my-2">
              <Form.Label>
IBAN Number
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="IBAN"
                value={newpayment.IBAN}
                name="IBAN"
                onChange={inputchange}
              />
            </Form.Group>
          )}
          {errors &&
            errors.map(({ IBANempty }, index) => {
              if (IBANempty) {
                return (
                  <span
                    key={index}
                    className="w-100 text-danger"
                    style={ { fontSize: "12px" } }
                  >
                    Enter IBAN number
                  </span>
                );
              }
              return null;
            })}
          {newpayment.country === "UK" && (
            <Form.Group className="my-2">
              <Form.Label>
Sort code
              </Form.Label>
              <div className="flex gap-2 items-center">
                <Form.Control
                  type="text"
                  placeholder="00 "
                  value={newpayment.code[0]}
                  name="code"
                  onChange={(event) => {
                    seterrors([]);
                    setnewpayment({
                      ...newpayment,
                      code: [
                        event.target.value,
                        newpayment.code[1],
                        newpayment.code[2],
                      ],
                    });
                  }}
                />
                <span>
-
                </span>
                <Form.Control
                  type="text"
                  placeholder="00 "
                  value={newpayment.code[1]}
                  name="code"
                  onChange={(event) => {
                    seterrors([]);
                    setnewpayment({
                      ...newpayment,
                      code: [
                        newpayment.code[0],
                        event.target.value,
                        newpayment.code[2],
                      ],
                    });
                  }}
                />
                <span>
-
                </span>
                <Form.Control
                  type="text"
                  placeholder="00 "
                  value={newpayment.code[2]}
                  name="code"
                  onChange={(event) => {
                    seterrors([]);
                    setnewpayment({
                      ...newpayment,
                      code: [
                        newpayment.code[0],
                        newpayment.code[1],
                        event.target.value,
                      ],
                    });
                  }}
                />
              </div>
            </Form.Group>
          )}
          {errors &&
            errors.map(({ eightcodeempty }, index) => {
              if (eightcodeempty) {
                return (
                  <span
                    key={index}
                    className="w-100 text-danger"
                    style={ { fontSize: "12px" } }
                  >
                    Enter 6-digits sort code
                  </span>
                );
              }
              return null;
            })}
          <div className="text-center mt-4">
            <Button
              disabled={saving}
              type="submit"
              className="rounded rounded-2 fw-bolder text-white"
              variant="warning"
            >
              {saving ? "waiting..." : "Save"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className=" fw-bolder text-white btn-sm"
          variant="warning"
          onClick={onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(Index);
