import { Circle, Polygon, Rectangle } from "@react-google-maps/api"
import React, { useCallback, useEffect, useState } from "react"

function GoogleZones({ zoneType, zone, onUpdate, zoneColor, drawingMode }) {
  const [
    initialValues,setInitialValues
  ]= useState(null)
  const onLoadHandler = (values)=>{
    setInitialValues(values)
  }
  const [
    working,setWorking
  ]=useState(true)
  useEffect(()=>{
    if (!working && initialValues && drawingMode === "edit"){
      onUpdate(initialValues)
      
    }
    return ()=>{
      setWorking(true)
    }
  }, [
    drawingMode, initialValues, onUpdate, working
  ])
  const onUpdateHandler=useCallback(()=>{
    setTimeout(()=>setWorking(false),2000)

  },[])
 

  
  switch (zoneType) {
  case "polygon":
    return (
      <Polygon 
        path={zone}
        onLoad={onLoadHandler}
        onMouseUp={onUpdateHandler}
        options={ {
          fillColor: `rgb(${zoneColor?.rgb?.join(",")})` || "red",
          fillOpacity: 0.3,
          strokeColor: `rgb(${zoneColor?.rgb?.join(",")})` || "red",
          strokeOpacity: 1,
          strokeWeight: 2,
          clickable: true,
          draggable: true,
          editable: true,
          geodesic: false,
          zIndex: 1
        } }  />
    )
  case "circle":
    return (
      <Circle
        center={zone?.center}
        radius={zone?.radius}
        onLoad={onLoadHandler}
        onCenterChanged={onUpdateHandler}
        onRadiusChanged={onUpdateHandler}

        options={ {
          fillColor: `rgb(${zoneColor?.rgb?.join(",")})` || "red",
          fillOpacity: 0.3,
          strokeColor: `rgb(${zoneColor?.rgb?.join(",")})` || "red",
          strokeOpacity: 1,
          strokeWeight: 2,
          clickable: true,
          draggable: true,
          editable: true,
          geodesic: false,
          zIndex: 1
        } } />
    )
  case "rectangle":
    return(
      <Rectangle
        onLoad={onLoadHandler}
        onBoundsChanged={onUpdateHandler}
        bounds={zone}
        options={ {
          fillColor: `rgb(${zoneColor?.rgb?.join(",")})` || "red",
          fillOpacity: 0.3,
          strokeColor: `rgb(${zoneColor?.rgb?.join(",")})` || "red",
          strokeOpacity: 1,
          strokeWeight: 2,
          clickable: true,
          draggable: true,
          editable: true,
          geodesic: false,
          zIndex: 1
        } }
      />

    )
  default:
    return null
  }

}

export default GoogleZones