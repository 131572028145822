import React, { memo, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { getLiveOrderByRider } from "../../redux/admin/action";

function Index({ id }) {
  const dispatch = useDispatch();
  const [
    orders, setOrders
  ] = useState([]);
  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = (data) => {
    setLoader(false);
    if (data) {
      setOrders(data);
    }
  };
  useEffect(() => {
    if (id) {
      setLoader(true);
      dispatch(getLiveOrderByRider(id, stopLoader));
    }
  }, [
    id, dispatch
  ]);

  if (loader) return <Spinner animation="grow"
    size="sm" />;
  return (
    <div className="fw-bold flex flex-wrap">
      Order Number&apos;s:
      {" "}
      {orders.map((order) => (
        <a
          key={order?.orderNumber}
          target="_blank"
          rel="noreferrer"
          className="px-2"
          href={`/order-detail/${order?.orderNumber}`}
        >
          {order.orderNumber}
        </a>
      ))}
    </div>
  );
}
export default memo(Index);
