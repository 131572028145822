import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";

import LogoImg from "../../../assets/images/logo.png";
import { ukCurrancy } from "../../../utils/convertions";
import Ordertr from "./Ordertr";
import styles from "./styles";

export default function Index({ rides, invoiceNumber }) {

  // console.log("Inside invoice doc", rides);
  const completedOrders = rides?.filter((e) => e.paid === true);
  const date = moment(new Date()).format("DD-MMM-YYYY");

  // console.log("all rides", completedOrders);
  
  const total = completedOrders?.reduce((a, b) => {

    const cost = b.cost || 0;
    const discount = b.discount || 0;
    const tot = parseFloat(cost) - parseFloat(discount);

    // const riderFee = b.riderdetail.riderfee || 0;
    // const ridertip = parseFloat(b.ridertip || 0);
    // const deductions = b?.deduction?.from === "rider" ? b?.deduction?.fee : 0;
    // const deliveryFee = b.deliveryfree || 0;
    // const tot = parseFloat(riderFee) - parseFloat(deductions) + parseFloat(deliveryFee) + ridertip;

    return a + tot;

  }, 0);

  return (
    <Document>
    {/* {console.log("Ride Detail ====> ", rides[0])} */}
      <Page style={styles.page}>
        <View style={styles.pageTopHeadingView}>
          <Text style={styles.pageTopHeadingViewHeading}>
            Statement
          </Text>
        </View>

        {/* Navigo Info */}
        <View style={styles.topDescripView}>
          <View style={styles.siteView}>
            <View>
              <Image src={LogoImg}style={styles.siteLogo}alt="logo" />
              <Text style={ { width: 130, textAlign: "left", marginTop: 10 , fontSize: "16px" } }>Navigo Taxis</Text>
              <Text style={ { width: 200, flexWrap: "wrap" } }>Exeter Business Park, Emperor Way,</Text>
              <Text style={ { width: 150, marginBottom: 5, flexWrap: "wrap" } }>Exeter EX1 3QS.</Text>
            </View>
          </View>
          <View style={styles.invoiceView}>
            <View>
              <Text>&nbsp;</Text>
              <Text style={ { marginTop: 10 } }>Statement Date: {date}</Text>
              <Text>Invoice #: {invoiceNumber}</Text>
            </View>
          </View>
          {/* <View style={styles.invoiceView}>
            <View style={styles.siteDesView}>
              <Text>Statement Date: {date}</Text>
            </View>
            <View style={styles.siteDesView}>
              <Text>Invoice #: {invoiceNumber}</Text>
            </View>
          </View> */}
        </View>

        {/* Bill Heading*/}
        <View style={styles.billTo}>
          <Text style={ { textAlign: "left", paddingLeft: 20 , fontWeight: 600 } }>BILLED TO:</Text>
        </View>

        {/* Rider Info */}       
        <View style={styles.driveInfo}>
          <View>
            <Text>Name: {rides[0]?.riderdetail?.name}</Text>
            <Text>Address: {rides[0]?.riderdetail?.address?.address || "-"}</Text>
          </View>
          <View>
            <Text>Email: {rides[0]?.riderdetail?.email || "-"}</Text>
            <Text>Phone #: {rides[0]?.riderdetail?.phoneNumber || "-"}</Text>
          </View>
        </View>

        <View style={styles.statmentBox}>
          <Text style={styles.paymentSummaryText}>Payment Summary</Text>
        </View>
        {/* Rides Table */}
        <View style={styles.statmentTable}>
          <View style={styles.TableLableRow}>
            <Text style={styles.TableLable}>S.No</Text>
            <Text style={styles.TableLable}>Ride #</Text>
            <Text style={styles.TableLable}>Date</Text>
            <Text style={styles.TableLable}>Time</Text>
            <Text style={styles.TableLable}>Pick up location</Text>
            <Text style={styles.TableLable}>Drop off location</Text>
            <Text style={styles.TableLable}>Additional stops</Text>
            <Text style={styles.TableLable}>Driver Name</Text>
            <Text style={styles.TableLable}>Fare</Text>
          </View>
          {completedOrders.map((ride, index) => (
            <Ordertr 
              ride={ride}
              key={index}
              index={index}
            />
          ))}
        </View>

        {/* Total */}
        <View style={styles.statmentBox}>
          <View style={styles.statmentTotalView}>
            <Text style={styles.statmentTotalViewlabel}>Statement Total</Text>
            <Text style={styles.statmentTotalViewText}>{ukCurrancy.format(total)}</Text>
          </View>
          {/* <Text style={styles.paymentSummaryText}>Payment Summary</Text> */}
        </View>

        {/* Thank you line */}
        <View style={ { padding: 20, position: "absolute", bottom: 0 } }>
          <Text>
            Thank you for using NavigoTaxis. Please check and reconcile your statement. If you have any queries
            please contact us on partners.support@navigotaxis.com
          </Text>
          
        </View>
      </Page>

      {/* <Page>
        <View>
          <Text style={styles.itemSummmaryText}>Items summary</Text>
        </View>
        <View style={styles.statmentTable}>
          <View style={styles.TableLableRow}>
            <Text style={styles.TableLable}>S.No</Text>
            <Text style={styles.TableLable}>Ride #</Text>
            <Text style={styles.TableLable}>Date</Text>
            <Text style={styles.TableLable}>Time</Text>
            <Text style={styles.TableLable}>Pick up location</Text>
            <Text style={styles.TableLable}>Drop off location</Text>
            <Text style={styles.TableLable}>Additional stops</Text>
            <Text style={styles.TableLable}>Customer Name</Text>
            <Text style={styles.TableLable}>Fare</Text>
          </View>
          {rides.map((ride, index) => (
            <Ordertr showDed
              ride={ride}
              key={index} />
          ))}
        </View>
      </Page> */}
    </Document>
  );
}
