import "./index.css";

import {
  DeleteOutlined,
  EditFilled,
  InteractionOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Badge, Button, Input, Space, Table, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ActionBtn from "../../../components/actionBtn";
import VehicleTypeModel from "../../../modals/VehicleTypeModel";
import {
  activateVehicleTypeById,
  deActivateVehicleTypeById,
  deleteVehicleTypeById,
  getAllVehicleTypes,
} from "../../../redux/admin/action";

function CapacityItem({ label, count }) {
  return <li
    style={ {
      color: "gray",
      textAlign: "start",
      display: "flex",
      gap: "5px",
    } }
  >
    <p
      style={ {
        width: "95%",
        margin: 0,
      } }
    >
      {label}
:
    </p>
    <span className="text-dark">
      {count}
    </span>
  </li>
}
const renderStatus = (value) => {
  return (
    <Space>
      {value ? (
        <Badge count="Active"
          style={ { backgroundColor: "#52c41a" } } />
      ) : (
        <Badge count="Inactive" />
      )}
    </Space>
  );
};

function Index() {
  const deleteAdmin = (admin) => (cb) => {
    dispatch(deleteVehicleTypeById(admin, cb));
  };

  const changeStatusAdmin = (admin) => (cb) => {
    if (!admin.isActive) {
      dispatch(activateVehicleTypeById(admin, cb));
      return;
    }
    dispatch(deActivateVehicleTypeById(admin, cb));
  };
  const [
    selectedType, setSelectedType
  ] = useState(null);
  const editHandler = (e) => {
    setSelectedType(e);
    setShowTypeModel(true);
  };

  const columns = [
    {
      title: "Image",
      align: "center",
      dataIndex: "imageUrl",
      key: "imageUrl",
      fixed: "left",
      width: 120,
      render: (imageUrl, record) => (
        <img
          style={ {
            height: "100%",
            maxHeight: 80,
            width: "100%",

            objectPosition: "100% 100%",
          } }
          src={imageUrl}
          alt={record?.name}
        />
      ),
    },
    {
      title: "Name",
      align: "center",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 120,
    },
    {
      title: "Description",
      align: "center",
      dataIndex: "description",
      key: "description",
      fixed: "left",
      width: 120,
    },
    {
      title: "Capacity",
      align: "center",
      dataIndex: "capacity",
      key: "capacity",
      fixed: "left",
      width: 120,
      render: (_, record) => {
        return (
          <ul className="p-0 d-flex flex-column gap-1">
            <CapacityItem label="Passengers"
              count={record.passengers} />
            <CapacityItem label="Luggage"
              count={record.luggage} />
            <CapacityItem label="Hand luggage"
              count={record.handLuggage} />
            <CapacityItem label="Booster seats"
              count={record.boosterSeats} />
            <CapacityItem label="Child seats"
              count={record.childSeats} />
            <CapacityItem label="Infant seats"
              count={record.infantSeats} />
          </ul>
        );
      },
    },
    {
      title: "Creation Date",
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,

      render: (e) => moment(e).format("DD-MMM-YY/hh:mm A"),
    },

    {
      title: "Status",
      align: "center",
      dataIndex: "isActive",
      key: "isActive",
      render: renderStatus,
      width: 120,
    },

    {
      title: "Actions",
      key: "operation",

      align: "center",
      width: 120,

      render: (_, record) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <EditFilled
              onClick={() => editHandler(record)}
              className="mx-1 my-auto"
              style={ {
                color: "blue",
                cursor: "pointer",
              } }
            />
            <ActionBtn
              confirmMsg={`Want to ${
                record.isProved ? "de-activate" : "activate"
              } type?`}
              action={changeStatusAdmin(record)}
            >
              <Tooltip title={record.isActive ? "de-activate" : "activate"}>
                <InteractionOutlined
                  style={ {
                    color: record.isActive ? "green" : "red",
                    fontSize: 15,
                    margin: "2px",
                  } }
                />
              </Tooltip>
            </ActionBtn>
            <ActionBtn
              action={deleteAdmin(record)}
              confirmMsg={"Want to delete this type?"}
            >
              <Tooltip title={"delete"}>
                <DeleteOutlined
                  style={ { color: "red", fontSize: 15, margin: "2px" } }
                />
              </Tooltip>
            </ActionBtn>
          </div>
        );
      },
    },
  ];

  const { vehicleTypes } = useSelector((state) => state.admin);

  const [
    filteradmins, setfilteradmins
  ] = useState([]);

  const [
    loader, setLoader
  ] = useState(false);
  const [
    showTypeModel, setShowTypeModel
  ] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoader(true);
    dispatch(getAllVehicleTypes(stopLoader));
  }, [dispatch]);
  const stopLoader = () => {
    setLoader(false);
  };

  const searchTable = (e) => {
    if (e.target.value) {
      let filter = vehicleTypes.filter((admin) =>
        admin?.name?.includes(e.target.value)
      );
      setfilteradmins(filter);
    } else {
      setfilteradmins([]);
    }
  };

  const adminData = filteradmins.length > 0 ? filteradmins : vehicleTypes;

  return (
    <>
      <Container fluid
        className=" w-100  pb-5">
        <Row>
          <Col lg={12}
            className=" pl-0 ">
            <h1 className="fw-bold">
              Type of Vehicles 
            </h1>
          </Col>
          <Col lg={12}
            className="d-flex justify-content-end ">
            <Row>
              <Col lg={6}
                className=" flex justify-end p-0">
                <Button
                  type="primary"
                  className=" d-flex fw-bold algin-items-center m-1"
                  onClick={() => {
                    setSelectedType(null);
                    setShowTypeModel(true);
                  }}
                >
                  <PlusOutlined className="m-auto" />
                  Add Type
                </Button>
              </Col>
              <Col className="p-0">
                <Input
                  onChange={searchTable}
                  className="m-1"
                  placeholder="Name"
                  prefix={<SearchOutlined />}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12}
            className="shadow rounded-3 bg-white p-3">
            <Table
              bordered
              className="table-curved adminTable"
              columns={columns}
              dataSource={adminData}
              pagination={false}
              loading={loader}
              scroll={ { y: 500 } }
            />
          </Col>
        </Row>
        {showTypeModel && (
          <VehicleTypeModel
            type={selectedType}
            show={showTypeModel}
            onHide={() => setShowTypeModel(false)}
          />
        )}
      </Container>
    </>
  );
}

export default memo(Index);
