import "./index.css";

import { Button, Empty } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import PaymentInfoCard from "../../../../components/PaymentInfoCard";

// import RiderPaymentModal from "../../../../modals/riderPaymentModal";
import { getRiderPayments } from "../../../../redux/rider/action";

import { 
  getAllDriverRides,
  getDriverInvoices,
} from "../../../../redux/admin/action";

// import PaymentRow from "../paymentRow";

function Index() {
  const [show, setShow] = useState(false);
  const [grid, setGrid] = useState(12);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { profileRider , driverInvoices } = useSelector((state) => state.admin);
  // const { payments } = useSelector((state) => state.rider);

  const [ myRides, setMyRides ] = useState([]);
  const [ myInvoices, setMyInvoices ] = useState([]);
  // const [finalRides, setFinalRides] = useState([]);

  const [totalRides, setTotalRides] = useState(0);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [dueInvoices, setDueInvoices] = useState(0);

  const [workingHours, setWorkingHours] = useState(0);
  const [cancelledRides, setCancelledRides] = useState(0);
  const [completedRides, setCompletedRides] = useState(0);

  // console.log("profileRide" , profileRider);

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    dispatch(getAllDriverRides( profileRider?.id , stopLoader))
    // dispatch(getRiderPayments(stopLoader));
  }, [dispatch, profileRider]);


  const stopLoader = (data) => {
    setLoader(false);
    setMyRides(data);
  };

  const stopAdder = (data) => {
    setMyInvoices(data);
  }

  useEffect(() => {
    dispatch(getDriverInvoices(stopAdder));
  }, []);

  useEffect(() => { 
    if (myRides) {
      // Total Rides
      const total_rides = myRides?.length;
      setTotalRides(total_rides);
      // console.log("total", totalRides);

      // Total Invoices
      const total_invoices = driverInvoices?.length;
      setTotalInvoices(total_invoices);
      // console.log("Total Invoices: ", totalInvoices);

      // Due Invoices
      const due_invoices = driverInvoices?.length ? driverInvoices.filter(x=>x.paidStatus === false).length : 0;
      setDueInvoices(due_invoices);
      // console.log("Due Invoices: ", dueInvoices);

      // Cancelled Rides
      const cancelled_rides = myRides?.filter((ride) => ride.status === "cancelled");
      setCancelledRides(cancelled_rides.length);
      // console.log("Cancelled Rides: ", cancelledRides);

      // Compeleted Rides
      const completed_rides = myRides?.filter((ride) => ride.status === "completed");
      setCompletedRides(completed_rides.length);
      // console.log("Compeleted Rides: ", completedRides);
    }
  }, [myRides]);
  

  if (loader) {
    <div>
      loading...
    </div>;
  }
  return (
    <Container fluid>
      <Row className="gap-3">
        <Col lg={12}>
          <h2 className="fw-bold">
            Payment Summary
          </h2>
        </Col>
      </Row>
      <div className="grid gap-x-8 gap-y-4 grid-cols-3">
          <PaymentInfoCard
            lg={grid}
            borderClass="admin_mainColor"
            // action={getLatestPendingOrders}
            title={"Rides"}
            renderData={totalRides}
            // renderData={(data) => {
            //   return <div>
            //     {data || "Empty"}
            //   </div>;
            // }}
          />
          <PaymentInfoCard
            lg={grid}
            borderClass="admin_mainColor"
            // action={getLatestPendingOrders}
            title={"Total Invoices"}
            renderData={totalInvoices}
            // renderData={(data) => {
            //   return <div>
            //     {data || "Empty"}
            //   </div>;
            // }}
          />
          <PaymentInfoCard
            lg={grid}
            borderClass="admin_mainColor"
            // action={getLatestPendingOrders}
            title={"Due Invoices"}
            renderData={dueInvoices}
            // renderData={(data) => {
            //   return <div>
            //     {data || "Empty"}
            //   </div>;
            // }}
          />
          <PaymentInfoCard
            lg={grid}
            borderClass="admin_mainColor"
            // action={getLatestPendingOrders}
            title={"Working Hours"}
            renderData={workingHours}
            // renderData={(data) => {
            //   return <div>
            //     {data || "Empty"}
            //   </div>;
            // }}
          />
          <PaymentInfoCard
            lg={grid}
            borderClass="admin_mainColor"
            // action={getLatestPendingOrders}
            title={"Cancelled Rides"}
            renderData={cancelledRides}
            // renderData={(data) => {
            //   return <div>
            //     {data || "Empty"}
            //   </div>;
            // }}
          />
          <PaymentInfoCard
            lg={grid}
            borderClass="admin_mainColor"
            // action={getLatestPendingOrders}
            title={"Completed Trips"}
            renderData={completedRides}
            // renderData={(data) => {
            //   return <div>
            //     {data || "Empty"}
            //   </div>;
            // }}
          />
        </div>
    </Container>
  );
}

export default memo(Index);
