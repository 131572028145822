/* eslint-disable func-names */
import * as yup from "yup";

import AuthApi from "../redux/auth/authApi";
import { validateemail } from "../utils/gerenatePassword";

const authApi = new AuthApi();
yup.addMethod(yup.string, "emailisAlready", function (errorMessage) {
  return this.test("email-is-user", errorMessage, async function (value) {
    const { path, createError } = this;

    if (!value || !validateemail(value)) return;

    const { data } = await authApi.emailIsAdmin({ email: value });

    if (data?.code === "auth/user-not-found" || !data?.isAdmin) {
      return createError({ path, message: errorMessage });
    } else {
      return value;
    }
  });
});
const schema = yup.object().shape({
  pass: yup
    .string()
    .required("Enter your Password")
    .min(6, "Enter atleast 6 digits password"),
  email: yup
    .string()
    .required("Enter your email")
    .email("Enter valid email address")
    .emailisAlready("email not register as admin"),
});

export default schema;
