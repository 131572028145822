import "./index.css";

import React, { memo } from "react";

function Index({ data = [], renderItem, columns = [] }) {
  return (
    <div className="navigo-table-container">
      <table className="navigo-table">
        <thead>
          <tr>
            {columns?.map((column, index) => {
              return <th key={index}>
                {column.label}
              </th>;
            })}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data?.map((row, index) => {
            return renderItem({ row, columns, index });
          })}
        </tbody>
      </table>
    </div>
  );
}

export default memo(Index);
