import { Button, DatePicker, Input, Modal, Select } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { memo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  saveLeaveToFirestore,
  updateLeaveToFirestore,
} from "../../redux/admin/action";
import addLeaveValidation from "../../validations/addLeave";

const { Option } = Select;
const { RangePicker } = DatePicker;
const leaveTypes = [
  {
    label: "Authorized Leave",
    value: "EAL",
  },
  {
    label: "Unauthorized Leave",
    value: "EUAL",
  },
];
const leaveReasons = [
  {
    label: "Annual Leave",
    value: "AL",
  },
  {
    label: "Sick Leave",
    value: "SL",
  },
  {
    label: "Compassionate Leave",
    value: "CL",
  },
  {
    label: "Training Leave",
    value: "TL",
  },
  {
    label: "Public Holiday",
    value: "PH",
  },
];
function Index({ show, onHide, prevLeave }) {
  const { leaves, profileAdmin } = useSelector((state) => state.admin);

  const [
    loader, setLoader
  ] = useState(false);
  const [
    error, setErrors
  ] = useState({});
  const stopLoader = () => {
    setLoader(false);
    onHide?.();
  };
  const dispatch = useDispatch();

  const saveLeave = (values) => {
    setErrors({});
    let totalAnualLeavesLeft =
      leaves
        .filter((e) => e?.reason?.includes("AL"))
        ?.reduce((a, c) => {
          const days =
            moment(Number(c?.day[1]))
              .startOf("d")
              .diff(moment(Number(c?.day[0])).startOf("d"), "d") + 1;

          return a + days;
        }, 0) +
      (moment(values?.day[1])
        .startOf("d")
        .diff(moment(values?.day[0]).startOf("d"), "d") +
        1);
    let totalPublicHoliLeavesLeft =
      leaves
        .filter((e) => e?.reason?.includes("PH"))
        ?.reduce((a, c) => {
          const days =
            moment(Number(c?.day[1]))
              .startOf("d")
              .diff(moment(Number(c?.day[0])).startOf("d"), "d") + 1;

          return a + days;
        }, 0) +
      (moment(values?.day[1])
        .startOf("d")
        .diff(moment(values?.day[0]).startOf("d"), "d") +
        1);
    if (prevLeave) {
      totalAnualLeavesLeft =
        totalAnualLeavesLeft -
        (moment(Number(prevLeave?.day[1]))
          .startOf("d")
          .diff(moment(Number(prevLeave?.day[0])).startOf("d"), "d") +
          1);
    }
    if (prevLeave) {
      totalPublicHoliLeavesLeft =
        totalPublicHoliLeavesLeft -
        (moment(Number(prevLeave?.day[1]))
          .startOf("d")
          .diff(moment(Number(prevLeave?.day[0])).startOf("d"), "d") +
          1);
    }
    if (
      values.reason === "AL" &&
      profileAdmin?.totalLeaves < totalAnualLeavesLeft
    ) {
      setErrors({
        ...error,
        day: "You have reached your maximum Annual leave limit",
      });
      return;
    }
    if (
      values.reason === "PH" &&
      profileAdmin?.totalPublicHolidays < totalPublicHoliLeavesLeft
    ) {
      setErrors({
        ...error,
        day: "You have reached your maximum Public Holiday limit",
      });
      return;
    }
    if (moment(values.day[0]).year() !== moment().year()) {
      setErrors({
        ...error,
        day: "You can only apply for current year leaves",
      });
      return;
    }

    setLoader(true);
    if (prevLeave) {
      dispatch(
        updateLeaveToFirestore(
          {
            ...values,
            day: values?.day?.map((e) => moment(e).format("x")),
            start: moment(values.day[0]).format("x"),
            end: moment(values.day[1]).format("x"),
          },
          prevLeave.id,
          stopLoader
        )
      );
      return;
    }
    dispatch(
      saveLeaveToFirestore(
        {
          ...values,
          day: values?.day?.map((e) => moment(e).format("x")),
          start: moment(values.day[0]).format("x"),
          end: moment(values.day[1]).format("x"),
        },
        stopLoader
      )
    );
  };

  return (
    <Modal
      title={"Add Leave"}
      centered
      onCancel={onHide}
      open={show}
      footer={null}
    >
      <Formik
        initialValues={ {
          type: prevLeave?.type || "",
          title: prevLeave?.title || "",
          reason: prevLeave?.reason || "",
          day: prevLeave?.day.map((e) => moment(Number(e))) || [
            moment(),
            moment(),
          ],
        } }
        validationSchema={addLeaveValidation}
        onSubmit={saveLeave}
      >
        {({
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="gap-1">
              <Col>
                <h3 className="capitalize">
Title
                  {" "}
                </h3>
                <Input
                  value={values.title}
                  name="title"
                  placeholder="Enter title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${
                    errors.title && touched.title && "border-red-500"
                  }`}
                />
                {errors.title && touched.title && (
                  <span className="text-red-500 text-sm">
                    {errors.title}
                  </span>
                )}
              </Col>
              <Col md={6}>
                <h3 className="capitalize  ">
Type
                  {" "}
                </h3>
                <Select
                  showSearch
                  style={ { width: "100%" } }
                  placeholder="Select a option and change input text above"
                  onChange={(value) => {
                    setFieldValue("type", value);
                  }}
                  onBlur={handleBlur}
                  allowClear
                  value={values.type}
                  name="type"
                >
                  {leaveTypes.map((e, index) => (
                    <Option key={index}
                      value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
                {errors.type && touched.type && (
                  <span className="text-red-500 text-sm">
                    {errors.type}
                  </span>
                )}
              </Col>
              <Col md={12}>
                <h3 className="capitalize  ">
days
                  {" "}
                </h3>
                <RangePicker
                  value={values.day}
                  name="day"
                  onBlur={handleBlur}
                  format="DD/MMM/YYYY"
                  onChange={(e) => {
                    setFieldValue("day", e);
                  }}
                  className="w-full"
                />
                {errors.day && touched.day && (
                  <span className="text-red-500 text-sm">
                    {errors.day}
                  </span>
                )}
                {error.day && (
                  <span className="text-red-500 text-sm">
                    {error.day}
                  </span>
                )}
              </Col>
              <Col md={12}>
                <h3 className="capitalize  ">
Reason
                  {" "}
                </h3>
                <Select
                  showSearch
                  style={ { width: "100%" } }
                  placeholder="Select a option and change input text above"
                  onChange={(value) => {
                    setFieldValue("reason", value);
                  }}
                  onBlur={handleBlur}
                  allowClear
                  value={values.reason}
                  name="reason"
                >
                  {leaveReasons.map((e, index) => (
                    <Option key={index}
                      value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
                {errors.reason && touched.reason && (
                  <span className="text-red-500 text-sm">
                    {errors.reason}
                  </span>
                )}
              </Col>
            </Row>
            <Col lg={12}
              className="text-center mt-4">
              <Button
                loading={loader}
                disabled={loader}
                type="primary"
                htmlType="submit"
              >
                Add leave
              </Button>
            </Col>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default memo(Index);
