import { v4 as uuidv4 } from "uuid";

import { showErrorToast } from "../../components/toaster";
import { addCreateHistory, addUpdateHistory } from "../../utils/convertions";
import * as constants from "./constants";
import RiderApi from "./riderApi";

export default function toTimestamp(strDate) {
  var datum = Date.parse(strDate);
  return datum;
}

const riderApi = new RiderApi();
export const getUserDetailById = (id, cb) => async () => {
  try {
    const res = await riderApi.getUserDetailById(id);

    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

// Get Driver Rides History
export const getDriverRides = (cb) => async (dispatch, getState) => {
  const user = getState().admin.profileRider;

  // console.log("User   =>> ", user);
  try {
    const rides = await riderApi.getDriverRides(user?.id, user.name);
    // console.log("api rides", rides);

    dispatch({ type: constants.GET_RIDES, payload: rides });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

// Delete Ride Record
export const deleteRide = (id, cb) => async (dispatch) => {
  try {
    const data = await riderApi.deleteRide(id);
    dispatch({
      type: constants.DELETE_RIDE,
      payload: data,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    // cb?.(null);
  }
};



export const getRiderOrders = (cb) => async (dispatch, getState) => {
  const user = getState().admin.profileRider;
  try {
    const orders = await riderApi.getRiderOrders(user?.id);
    dispatch({ type: constants.GET_ORDERS, payload: orders });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const getEmergencyData = (cb) => async (dispatch, getState) => {
  const user = getState().admin.profileRider;
  try {
    const details = await riderApi.getEmergencyData(user?.id);

    cb?.(details);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const AddRiderSchedule = (data, cb) => async (dispatch, getState) => {
  const user = getState().admin.profileRider;
  const admin = getState().auth.user;
  const id = uuidv4();
  try {
    const details = await riderApi.AddRiderSchedule(
      addCreateHistory(data, admin),
      user?.id,
      id
    );
    dispatch({ type: constants.ADD_SCHEDULE, payload: details });
    cb?.(details);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const getRiderPayments = (cb) => async (dispatch, getState) => {
  const user = getState().admin.profileRider;

  try {
    const details = await riderApi.getRiderPayments(user?.id);

    dispatch({ type: constants.GET_PAYMENTS, payload: details });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const getRiderSchedules = (cb) => async (dispatch, getState) => {
  const user = getState().admin.profileRider;

  try {
    const details = await riderApi.getRiderSchedules(user?.id);

    dispatch({ type: constants.GET_SCHEDULES, payload: details });

    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const updateRiderDetail =
  (by, data, cb) => async (dispatch, getState) => {
    // console.log("by", by);
    // console.log("data", data);
    const id = getState().admin.profileRider.id;
    const user = getState().auth.user;
    try {
      const res = await riderApi.updateRiderDetail(
        id,
        by,
        addUpdateHistory(data, user)
      );

      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };
export const removeRiderDetail =
  (by, data, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileRider.id;

    try {
      const res = await riderApi.removeRiderDetail(id, by, data);

      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };
export const removeRiderPayment = (payId, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileRider.id;

  try {
    const res = await riderApi.removeRiderPayment(id, payId);
    dispatch({ type: constants.REMOVE_PAYMENT, payload: res });
    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const removeRiderSchedule =
  (schId, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileRider.id;

    try {
      const res = await riderApi.removeRiderSchedule(id, schId);
      dispatch({ type: constants.REMOVE_SCHEDULE, payload: res });
      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };

export const updateRiderSchedule =
  (data, schId, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileRider.id;
    const user = getState().auth.user;

    try {
      const res = await riderApi.updateRiderSchedule(
        id,
        schId,
        addUpdateHistory(data, user)
      );

      dispatch({ type: constants.UPDATE_SCHEDULE, payload: res });
      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };
export const addRiderDetail =
  (name, data, cb) => async (dispatch, getState) => {
    // console.log("data in action rider",data);
    const id = getState().admin.profileRider.id;
    const user = getState().auth.user;
    try {
      const res = await riderApi.addRiderDetail(
        id,
        uuidv4(),
        addCreateHistory({ ...data, name }, user)
      );

      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };
export const getRiderDetails = (cb) => async () => {
  try {
    const res = await riderApi.getRiderDetails();
    cb(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getAllNotifications = (cb) => async (dispatch) => {
  try {
    const res = await riderApi.getAllNotifications();
    dispatch({ type: constants.GET_NOTIFICATIONS, payload: res });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getReadNotifications = (cb) => async (dispatch) => {
  try {
    const res = await riderApi.getReadNotifications();
    dispatch({ type: constants.GET_READ_NOTIFICATIONS, payload: res });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getUnReadNotifications = (cb) => async (dispatch) => {
  try {
    const res = await riderApi.getUnReadNotifications();
    dispatch({ type: constants.GET_UNREAD_NOTIFICATIONS, payload: res });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const saveNotifications = (notifications, cb) => async (dispatch) => {
  // console.log("notifications in action", notifications);

  try {
    const res = await riderApi.saveNotifications(notifications);
    dispatch({ type: constants.GET_NOTIFICATIONS, payload: res });
    cb?.()
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.()
  }
};
export const updateNotification = (data, cb) => async (dispatch) => {

  try {
    const res = await riderApi.updateNotification(data);
    dispatch({ type: constants.UPDATE_NOTIFICATIONS, payload: res });
    cb?.()
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.()
  }
};
export const markAllAsRead = (cb) => async (dispatch) => {

  try {
    const res = await riderApi.markAllAsRead();
    dispatch({ type: constants.MARK_ALL_AS_READ_NOTIFICATIONS, payload: res });
    cb?.()
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.()
  }
};

export const addRiderPayment = (data, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileRider.id;
  const user = getState().auth.user;
  try {
    const res = await riderApi.addRiderPayment(
      id,
      uuidv4(),
      addCreateHistory(data, user)
    );
    dispatch({ type: constants.ADD_PAYMENT, payload: res });
    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const updateRiderPayment =
  (data, payId, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileRider.id;
    const user = getState().auth.user;
    try {
      const res = await riderApi.updateRiderPayment(
        id,
        payId,
        addUpdateHistory(data, user)
      );
      dispatch({ type: constants.UPDATE_PAYMENTS, payload: res });
      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };
export const getUserInformation = (id, by, cb) => async () => {
  try {
    const res = await riderApi.getUserInformation(id, by);

    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const addNoteForRider = (data, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileRider.id;
  const user = getState().auth.user;

  try {
    const info = await riderApi.addNoteForRider(
      addCreateHistory(data, user),
      id,
      uuidv4()
    );

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getRiderNotes = (cb) => async (dispatch, getState) => {
  const id = getState()?.admin?.profileRider?.id;

  try {
    const info = await riderApi.getRiderNotes(id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const riderSupport = (data, cb) => async () => {
  try {
    const res = await riderApi.riderSupport(data);

    cb?.(res.data);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const uploadFiles = (acceptedFiles, cb) => async () => {
  try {
    const files = await riderApi.uploadImage(acceptedFiles);

    cb?.(files);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const saveInvoice = (blob, data, cb) => async (dispatch, getState) => {
  const { id, name } = getState().auth.user;
  try {
    const file = await riderApi.uploadBlob(blob);

    const dataObject = {
      ...data,
      createdBy: { id, name },
      createdAt: toTimestamp(new Date()),
      url: file,
    };
    const res = await riderApi.saveInvoice(dataObject, uuidv4());

    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
