import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DOMPurify from "dompurify";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Form, Row, Table, Button } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Popconfirm, Spin } from "antd";
import moment from "moment";
import { FaEdit, FaTrash } from "react-icons/fa";

import {
  deletePublicCareer,
  getPublicCareers,
  setPublicCareers,
  updatePublicCareersById,
} from "../../../redux/admin/action";
import EditCareer from "./EditCareer";
import ActionBtn from "../../../components/actionBtn";
import { InteractionOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Index() {
  const [modalShow, setModalShow] = useState(false);
  const [careerId, setCareerId] = useState(null);
  const { careers } = useSelector((state) => state.admin);
  const [textAreaCount, setTextAreaCount] = useState(0);

  const recalculate = (e) => {
    setTextAreaCount(e.target.value.length);
  };
  const [career, setCareer] = useState({
    title: "",
    desc: EditorState.createEmpty(),
    // link: "",
    noOfPositions: "",
    experience: "",
    qualification: "",
    shortDesc: "",
    lastDate: moment().format("DD MMM YYYY"),
  });
  const [errors, setErrors] = useState([]);

  const [deleted, setDeleted] = useState(false);

  // const [careers, setCareers] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };
  const changeStatusCareer = (doc) => (cb) => {
    setLoader(true);
    if (!doc.active) {
      doc.active = true;
      dispatch(updatePublicCareersById(doc.id, doc, stopLoader));

      return;
    }
    doc.active = false;
    dispatch(updatePublicCareersById(doc.id, doc, stopLoader));
    // setLoader(false);
  };
  const changeInput = (e) => {
    var { name, value } = e.target;
    setCareer((p) => ({ ...p, [name]: value }));
  };
  const saveCareer = (e) => {
    e.preventDefault();
    let error = [];
    if (career.title === "") {
      error.push("titleEmpty");
    }
    if (career.desc === "") {
      error.push("descEmpty");
    }
    if (career.noOfPositions === "") {
      error.push("positionEmpty");
    }
    if (career.experience === "") {
      error.push("experienceEmpty");
    }
    if (career.qualification === "") {
      error.push("qualificationEmpty");
    }

    if (error.length) {
      setErrors(error);
    } else {
      setErrors([]);
      setLoader(true);
      const html = `<div>${draftToHtml(
        convertToRaw(career?.desc?.getCurrentContent())
      )}</div>`;

      dispatch(
        setPublicCareers(
          {
            title: career.title,
            desc: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } }),
            noOfPositions: career.noOfPositions,
            experience: career.experience,
            qualification: career.qualification,
            shortDesc: career.shortDesc,
            lastDate: career.lastDate,
            active: false,
          },
          stopLoader
        )
      );
    }
  };
  useEffect(() => {
    dispatch(getPublicCareers());
  }, [dispatch]);
  const stopDelete = () => {
    setDeleted(false);
  };
  const deletCareer = (e, id) => {
    e.preventDefault();
    setDeleted(true);
    if (window.confirm("Want to delete this job?")) {
      dispatch(deletePublicCareer(id, stopDelete));
    }
    setDeleted(false);
  };

  return (
    <Container fluid>
      <Row className="gap-3">
        <Col lg={12}>
          <h1 className="fw-bold">Career</h1>
        </Col>
        <Col lg={6} className=" p-4 shadow-lg rounded-lg bg-white">
          <Form onSubmit={saveCareer}>
            <Form.Group controlId="formBasicTitle">
              <Form.Label>Title:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                name="title"
                value={career.title}
                onChange={changeInput}
              />
              {errors.includes("titleEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={{ fontSize: "12px" }}
                >
                  Enter title
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicTitle" className="mt-3">
              <Form.Label>Number of positions:</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter number of positions"
                name="noOfPositions"
                onChange={changeInput}
                value={career.noOfPositions}
              />
              {errors.includes("positionEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={{ fontSize: "12px" }}
                >
                  Enter number of positions
                </span>
              )}
            </Form.Group>

            <Form.Group controlId="date" className="mt-3">
              <Form.Label>Last date to apply:</Form.Label>
              <DatePicker
                className="h-12 w-100"
                name="lastDate"
                format={"DD MMM YYYY"}
                value={moment(career?.lastDate, "DD MMM YYYY")}
                onChange={(e) => {
                  if (!e) {
                    return;
                  }
                  setCareer((p) => ({
                    ...p,
                    lastDate: moment(e).format("DD MMM YYYY"),
                  }));
                }}
              />
            </Form.Group>

            <Form.Group controlId="formBasicTitle" className="mt-3">
              <Form.Label>Experience:</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter experience (years)"
                name="experience"
                onChange={changeInput}
                value={career.experience}
              />
              {errors.includes("experienceEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={{ fontSize: "12px" }}
                >
                  Enter experience
                </span>
              )}
            </Form.Group>

            <Form.Group controlId="formBasicTitle" className="mt-3">
              <Form.Label>Qualification:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter qualification"
                name="qualification"
                onChange={changeInput}
                value={career.qualification}
              />
              {errors.includes("qualificationEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={{ fontSize: "12px" }}
                >
                  Enter qualification
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicTitle" className="mt-3">
              <Form.Label>Short description:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                // type="text"
                placeholder="Short description"
                name="shortDesc"
                onChange={(e) => {
                  changeInput(e);
                  recalculate(e);
                }}
                value={career.shortDesc}
              />
              {errors.includes("qualificationEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={{ fontSize: "12px" }}
                >
                  Enter short description
                </span>
              )}
            </Form.Group>
            <p style={{fontSize: "1rem", float: "right"}}> {`Charaters count: ${textAreaCount}`} </p>

            <Form.Group className="mt-3" controlId="formBasicDescription">
              <Form.Label>Job details:</Form.Label>
              <Editor
                editorState={career.desc}
                name="desc"
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName border"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  setCareer((p) => ({ ...p, desc: e }));
                }}
              />
              {errors.includes("descEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={{ fontSize: "12px" }}
                >
                  Enter job details
                </span>
              )}
            </Form.Group>
            <Button
              disabled={loader}
              variant="primary"
              className="mt-3 btn-sm fw-bolder text-white btn-warning"
              type="submit"
            >
              {loader ? "Posting job..." : "Post Job"}
            </Button>
          </Form>
        </Col>
        <Col className="p-4 rounded-lg shadow-lg bg-white">
          {!careers && <p>Loading...</p>}
          {careers && (
            <Table bordered hover className="text-center">
              <thead style={{ background: "#6ABABD", color: "white" }}>
                <tr>
                  <th>Job title</th>
                  <th>Last date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {careers.map((doc, index) => (
                  <tr key={index}>
                    <td>{doc?.title}</td>
                    <td>{doc?.lastDate}</td>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <button className="btn-sm fw-bolder text-white">
                        <Link to={`/career/edit/${doc?.id}`}>
                          <FaEdit style={{ color: "blue" }} />
                        </Link>
                      </button>
                      <button
                        disabled={deleted}
                        className="btn-sm fw-bolder"
                        onClick={(e) => deletCareer(e, doc.id)}
                      >
                        <FaTrash style={{ color: "red" }} />
                      </button>
                      <Spin
                        size="small"
                        spinning={loader}
                        style={{ width: "fit-content" }}
                        key={doc.id}
                      >
                        <Popconfirm title={`Want to ${
                          doc.active ? "de-activate" : "activate"
                        } job?`} onConfirm={changeStatusCareer(doc)}>
                          <InteractionOutlined
                            style={{
                              color: doc.active ? "red" : "green",
                              fontSize: 15,
                              // margin: 5,
                            }}
                            title={
                              doc.active ? "de-activate job" : "activate job"
                            }
                          />
                        </Popconfirm>
                      </Spin>
                      {/* <button
                        disabled={deleted}
                        className="btn-sm fw-bolder"
                        onClick={(e) => {
                          handleStatus(doc);
                        }}
                      ></button> */}
                      {/* <ActionBtn
                        type={doc.active ? "De-activate" : "Activate"}
                        confirmMsg={`Want to ${
                          doc.active ? "de-activate" : "activate"
                        } job?`}
                        action={changeStatusCareer(doc)}
                      >
                        <InteractionOutlined
                          style={{
                            color: doc.active ? "red" : "green",
                            fontSize: 15,
                            // margin: 5,
                          }}
                          title={
                            doc.active ? "de-activate job" : "activate job"
                          }
                        />
                      </ActionBtn> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
