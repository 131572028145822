import "./index.css";

import { Button, Calendar, Spin } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import AddAdminAgenda from "../../../modals/addAdminAgenda";
import UpdateAdminSchedule from "../../../modals/updateAdminSchedule";
import { getAdminShifts } from "../../../redux/admin/action";

function Index() {
  const { shifts, profileAdmin } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);

  useEffect(() => {
    setLoader(true);
    dispatch(getAdminShifts(stopLoader));
  }, [dispatch]);

  const stopLoader = () => {
    setLoader(false);
  };

  const [
    selectedDate, setSelectedDate
  ] = useState(moment());

  const [
    show, setShow
  ] = useState(false);
  const [
    selectedShift, setSelectedShift
  ] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  const [
    showSchedule, setShowSchedule
  ] = useState(false);

  const handleCloseSchedule = () => setShowSchedule(false);
  const handleShowSchedule = () => setShowSchedule(true);

  return (
    <Container fluid>
      <Row className="gap-3">
        <Col lg={12}>
          <h1 className="fw-bold">
Schedules
          </h1>
        </Col>
        <Col lg={12}
          className="flex justify-end">
          <Button
            type="primary"
            className="fw-bold"
            onClick={handleShowSchedule}
          >
            Add Schedule
          </Button>
        </Col>
        <Spin spinning={loader}>
          <Col lg={12}>
            <Row className="   bg-white rounded-lg shadow-md p-0 ">
              <Col className="rider_mainColor rounded-t-lg"
                lg={12}>
                <h1 className="text-lg fw-bold text-white p-2">
                  Schedules (
                  {moment(selectedDate?.currentDate).format("MMM YYYY")}
)
                </h1>
              </Col>
              <Calendar
                value={selectedDate?.currentDate}
                disabledDate={(current) => {
                  return (
                    moment().add(-1, "days") >= current ||
                    moment().add(1, "month") <= current
                  );
                }}
                dateCellRender={(value) => {
                  const todayShifts = shifts.filter((e) =>
                    moment(Number(e?.date)).isSame(moment(value), "day")
                  );
                  if (moment().isAfter(moment(value), "day")) {
                    return (
                      <div className="p-2">
                        <div className="p-2 flex items-start flex-col">
                          {todayShifts.map((e,index) => (
                            <button key={index}
                              className="text-xs hover:text-yellow-400 my-1 border-b-2 border-yellow-400">
                              <span className="fw-bold">
                                {e?.name}
                              </span>
                            </button>
                          ))}
                        </div>
                      </div>
                    );
                  }
                  const todaySchedules =
                    profileAdmin?.scheduleTime?.[
                      moment(value).format("dddd").toLowerCase()
                    ];

                  return (
                    <div className="p-2 relative">
                      <FaPlusCircle
                        onClick={() => {
                          setSelectedDate(value);
                          setSelectedShift(null);

                          handleShow();
                        }}
                        className="text-xl z-50 absolute top-0 right-0 text-yellow-400"
                      />
                      <div className="p-2 flex items-start flex-col">
                        {todayShifts?.map((e,index) => (
                          <button
                            key={index}
                            onClick={() => {
                              setSelectedShift(e);
                              setSelectedDate(value);

                              handleShow();
                            }}
                            className={`text-xs hover:text-yellow-400 my-1 border-b-2 ${
                              e?.offDay ? "border-red-400" : "border-yellow-400"
                            }`}
                          >
                            <span className="fw-bold">
                              {e?.name || moment(value).format("dddd")}
                            </span>
                          </button>
                        ))}
                        {todayShifts?.length < 2 &&
                          todaySchedules?.map((e, index) => {
                            if (todayShifts.find((x) => x.dayId === e.id)) {
                              return null;
                            }
                            return (
                              <button
                                key={index}
                                onClick={() => {
                                  setSelectedShift(e);
                                  setSelectedDate(value);

                                  handleShow();
                                }}
                                className={`text-xs hover:text-yellow-400 my-1 border-b-2 ${
                                  e?.offDay
                                    ? "border-red-400"
                                    : "border-yellow-400"
                                }`}
                              >
                                <span className="fw-bold">
                                  {e?.name || moment(value).format("dddd")}
                                </span>
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  );
                }}
              />
              ,
            </Row>
          </Col>
        </Spin>
      </Row>
      {show && (
        <UpdateAdminSchedule
          selectedDate={selectedDate}
          selectedShift={selectedShift}
          show={show}
          onHide={handleClose}
        />
      )}
      {showSchedule && (
        <AddAdminAgenda show={showSchedule}
          onHide={handleCloseSchedule} />
      )}
    </Container>
  );
}

export default memo(Index);
