import "./index.css";

import {
  DeleteOutlined,
  InteractionOutlined,
  LoginOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Badge, Button, Input, Space, Table, Tooltip } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ActionBtn from "../../components/actionBtn";
import AddNewCity from "../../modals/AddNewCity";
import {
  getCitys,
  removeCity,
  updateCityStatus,
} from "../../redux/admin/action";
import { capitalizeFirstLetter } from "../../utils/convertions";

const renderStatus = (value) => {
  return (
    <Space>
      {value ? (
        <Badge count="Active" style={{ backgroundColor: "#52c41a" }} />
      ) : (
        <Badge count="Inactive" />
      )}
    </Space>
  );
};

function Index() {
  const { cities } = useSelector((state) => state.admin);

  const [cityFilterList, setCityFilterList] = useState([]);

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    setLoader(true);
    dispatch(getCitys(stopLoader));
    return () => {
      setLoader(false);
    };
  }, [dispatch]);
  const deleteCity = (admin) => (cb) => {
    dispatch(removeCity(admin.id, cb));
  };
  const updateCityStatusHandler = (admin) => (cb) => {
    dispatch(updateCityStatus({ isActive: !admin.isActive }, admin.id, cb));
  };
  const filterCity = (e) => {
    let filterlis = cities.filter((list) =>
      list.name.includes(capitalizeFirstLetter(e.target.value))
    );
    setCityFilterList(filterlis);
  };
  const finalCities = cityFilterList.length ? cityFilterList : cities;
  const columns = [
    {
      title: "City ID",
      align: "center",
      dataIndex: "id",
      key: "id",
      width: 120,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    {
      title: "Name",
      align: "center",
      dataIndex: "name",
      key: "name",
      className: "capitalize",
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "isActive",
      key: "isActive",
      className: "capitalize",
      render: renderStatus,
    },

    {
      title: "Actions",
      key: "operation",

      align: "center",

      render: (_, record) => {
        if (record.Role === "Owner") {
          return null;
        }
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Tooltip title="profile">
              <a
                target="_blank"
                rel="noreferrer"
                href={`/cities/${record.slug}`}
              >
                <LoginOutlined
                  style={{ color: "blue", fontSize: 15, margin: "2px" }}
                />
              </a>
            </Tooltip>
            <ActionBtn
              action={updateCityStatusHandler(record)}
              confirmMsg={`Want to ${
                record.isActive ? "de-activate" : "activate"
              } city?`}
            >
              <Tooltip title={record.isActive ? "de-activate" : "activate"}>
                <InteractionOutlined
                  style={{
                    color: record.isActive ? "green" : "red",
                    fontSize: 15,
                    margin: "2px",
                  }}
                />
              </Tooltip>
            </ActionBtn>
            <ActionBtn
              action={deleteCity(record)}
              confirmMsg={"Want to delete this city?"}
            >
              <Tooltip title={"delete"}>
                <DeleteOutlined
                  style={{ color: "red", fontSize: 15, margin: "2px" }}
                />
              </Tooltip>
            </ActionBtn>
          </div>
        );
      },
    },
  ];
  const [showAddCity, setShowAddCity] = useState(false);
  return (
    <Container fluid>
      <Row className=" gap-3">
        <Col lg={12}>
          <h2 className="fw-bold">Cities</h2>
        </Col>
        <Col lg={12} className="d-flex justify-content-end ">
          <Row>
            <Col lg={6} className=" flex justify-end p-0">
              <Button
                type="primary"
                onClick={() => setShowAddCity(true)}
                className=" d-flex fw-bold algin-items-center m-1"
              >
                <PlusOutlined className="m-auto" />
                Add City
              </Button>
            </Col>
            <Col className="p-0">
              <Input
                onChange={filterCity}
                className="m-1"
                placeholder="Name/Email"
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={12} className="bg-white rounded-lg p-3  ">
          <Table
            bordered
            className="table-curved adminTable"
            columns={columns}
            dataSource={finalCities}
            pagination={false}
            loading={loader}
            scroll={{ y: 500 }}
          />
        </Col>
      </Row>
      {showAddCity && (
        <AddNewCity show={showAddCity} onHide={() => setShowAddCity(false)} />
      )}
    </Container>
  );
}

export default memo(Index);
