import { ErrorMessage } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { getVehiclesByType } from "../../redux/admin/action";

function DriversSelection({ onDriverChange, vehicleType, drivers }) {

  const { riders } = useSelector((state) => state.admin);
  const [loader, setLoader] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  const dispatch = useDispatch();
   
  // const stopLoader = (data) => {
  //   setLoader(false);
  //   if (data) {
  //     setVehicles(data);
  //   }
  // };
  

  useEffect(() => {

    let isMounted = true; // Flag to track if the component is mounted

    const stopLoader = (data) => {
      if (isMounted) {
        setLoader(false);
        if (data) {
          setVehicles(data);
        }
      }
    };
    
    if (vehicleType) {
      setLoader(true);
      dispatch(getVehiclesByType(vehicleType, stopLoader));
    }

    return () => {
      isMounted = false; // Clean up by setting the flag to false when unmounting
    };

  }, [vehicleType, dispatch]);

  const driverOptions = useMemo(() => {
    let filteredDrivers = [];

    vehicles.forEach((vehicle) => {
      if (vehicle?.driver) {
        const findedDriver = riders?.find(
          (rider) => rider?.id === vehicle?.driver
        );
        // console.log("Finded data", findedDriver);
        if (findedDriver && findedDriver.isLive === true && findedDriver.rideStatus === false) {
          // if (findedDriver) {
          filteredDrivers.push({
            label: findedDriver?.name,
            value: findedDriver?.id,
            email: findedDriver?.email || '',
            phoneNumber: findedDriver?.phoneNumber || '',
          });
        }
      }
    });

    // console.log("drivers data", riders);

    // console.log("Driver with vehicle ", filteredDrivers);

    return filteredDrivers;
  }, [riders, vehicles]);

  return (
    <Form.Group controlId="driver">
      <Form.Label>
      Assign Driver
      </Form.Label>
      <Select
        isMulti
        className="w-100 h-12"
        styles={ {
          control: (styles) => ({
            ...styles,
            height: "3rem",
          }),
        } }
        placeholder="Select drivers"
        isSearchable={true}
        name="drivers"
        isLoading={loader}
        value={drivers}
        onChange={onDriverChange}
        options={driverOptions}
      />
    </Form.Group>
  );
}

export default DriversSelection;
