import React from "react";
import { Col } from "react-bootstrap";

import { getCartTotal, ukCurrancy } from "../../utils/convertions";

function Singleitem({ item }) {
  const total = getCartTotal(item);

  const allExrtas =
    item?.extras
      ?.map((e) => e.extra)
      ?.flat(1)
      ?.filter((e) => e) || [];
  const allVariants = item?.varients?.map((e) => e.variant) || [];

  const allSubs = allVariants
    .concat(allExrtas)
    .map((e) => e?.name || e?.optionName);

  return (
    <div
      className={"d-flex justify-content-between align-items-center  col-12 mt-4  "}
    >
      <div>
        {item?.qty}
        {" "}
X
      </div>
      <Col lg={7}>
        <h1 className="m-0 text-base fw-bold capitalize ">
          {item?.name}
        </h1>
        {item?.extras?.length > 0 && (
          <ul className="p-0 m-0 d-flex flex-column">
            {allSubs?.join(", ")}
          </ul>
        )}
      </Col>
      <Col lg={2}
        className="text-right text-base">
        {ukCurrancy.format(total)}
      </Col>
    </div>
  );
}

export default Singleitem;
