import React, { memo, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import ImageDrop from "../../components/Imagedrop";
import SnackBarCustom from "../../components/SnackBarCustom";
import { addRiderDetail, updateRiderDetail } from "../../redux/rider/action";

function Index({ as, info, show, onHide, onAdd, onUpdate }) {
  const [
    submitloading, setsubmitloading
  ] = useState(false);
  const [
    errors, seterrors
  ] = useState([]);
  const [
    file, setfile
  ] = useState({
    addressPic: "",
  });
  const stopLoader = (data) => {
    if (data) {
      setAdder(true);
      if (as === "NEW") {
        onAdd(data);
      } else {
        onUpdate(data);
      }
    }
    setsubmitloading(false);
    onHide?.();
  };
  const dispatch = useDispatch();

  const [
    adder, setAdder
  ] = useState(false);
  useEffect(() => {
    if (info) {
      setfile({
        addressPic: info?.addressImg,
      });
    }
  }, [info]);
  const saveindatabase = (e) => {
    e.preventDefault();
    let error = [];
    if (file.addressPic === "") {
      error.push({ AddressPicempty: true });
    }

    if (file.profilePic === "") {
      error.push({ profilePicempty: true });
    }
    if (error.length < 1) {
      setsubmitloading(true);

      if (as === "NEW") {
        dispatch(
          addRiderDetail(
            "personal",
            {
              addressImg: file.addressPic,
            },
            stopLoader
          )
        );
      }
      dispatch(
        updateRiderDetail(
          "personal",
          {
            addressImg: file.addressPic,
          },
          stopLoader
        )
      );
    } else {
      seterrors(error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addModel"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Personal Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          method="post"
          onSubmit={(e) => {
            saveindatabase(e);
          }}
        >
          <Col lg={12}
            className="text-center ">
            <Row className="d-flex justify-content-center flex-column align-items-center">
              <ImageDrop
                file={file.addressPic}
                onSuccess={(img) => {
                  setfile({ ...file, addressPic: img });
                }}
                names="addressPic"
                title={"Upload Proof of your address"}
                subtitle={"Bank statement(No Monzio) / Drivers Licence / Utility bill / Council tax of your current address"}
              />
            </Row>
            {errors &&
              errors.map(({ AddressPicempty }, index) => {
                if (AddressPicempty) {
                  return (
                    <span
                      key={index}
                      className="w-50 ml-5 text-danger d-block "
                      style={ { fontSize: "15px" } }
                    >
                      *required
                    </span>
                  );
                }
                return null;
              })}
          </Col>
          <Col lg={12}>
            <Button
              variant="warning"
              className="m-2 fw-bolder text-white"
              disabled={submitloading ? true : false}
              type="submit"
            >
              {submitloading ? "Saving.." : "Save"}
            </Button>
          </Col>
        </form>
      </Modal.Body>
      <SnackBarCustom
        msg={"Item added"}
        open={adder}
        onClose={() => setAdder(false)}
      />
    </Modal>
  );
}

export default memo(Index);
