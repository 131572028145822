import { Badge, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getAdminShiftsByDateAndId } from "../../redux/admin/action";

function Index({ user }) {
  const [
    isLogin, setIsLogin
  ] = useState(false);
  const dispatch = useDispatch();
  const stopLoader = (shifts) => {
    const activeShift = shifts.find((shift) => shift.active);

    if (activeShift) {
      setIsLogin(true);
    }
  };
  useEffect(() => {
    if (user) {
      dispatch(
        getAdminShiftsByDateAndId(
          {
            start: moment().startOf("day").format("x"),
            end: moment().endOf("day").format("x"),
          },
          user.id,
          stopLoader
        )
      );
    }
  }, [
    dispatch, user
  ]);
  return (
    <Space>
      {isLogin ? (
        <Badge count="Online"
          style={ { backgroundColor: "#52c41a" } } />
      ) : (
        <Badge count="Offline" />
      )}
    </Space>
  );
}

export default Index;
