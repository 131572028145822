/* eslint-disable no-async-promise-executor */
import { notification } from "antd";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
//useless page
import {
  ADMIN_COLLECTION,
  ADMINROLE_COLLECTION,
  BASES_COLLECTION,
  CAREER_COLLECTION,
  CATEGORYS_COLLECTION,
  CHAT_COLLECTION,
  CITIES_COLLECTION,
  firebase_bucket,
  HOLIDAY_SURCHARGE,
  INVOICE_COLLECTION,
  ORDER_COLLECTION,
  PRICING_COLLECTION,
  RESTAURANT_COLLECTION,
  RIDER_COLLECTION,
  RIDES_COLLECTION,
  TARRIF_COLLECTION,
  BLOG_COLLECTION,
  TEAM_COLLECTION,
  USER_COLLECTION,
  VEHICLES__COLLECTION,
  VEHICLES_TYPES_COLLECTION,
  VOUCHER_COLLECTION,
  PAYMENT_COLLECTION,
} from "../../config";
import { INSTANCE } from "../../config/axiosInstance";
import { database, firestore, storage } from "../../config/firebase";
import { useRef } from "react";

export default class AdminApi {

  async getLiveOrders() {
    const orderRef = await database().ref(ORDER_COLLECTION);
    return orderRef;
  }

  async getRestaurantBySlug(slug) {
    const restRef = firestore()
      .collection(RESTAURANT_COLLECTION)
      .where("slug", "==", slug);
    const doc = await restRef.get();
    return doc.empty ? null : { ...doc.docs[0].data(), id: doc.docs[0].id };
  }
  async updateRiderFirstore(data, id) {
    const userRef = firestore().collection(RIDER_COLLECTION).doc(id);
    const res = await userRef.update(data);
    return res ? false : { ...data, id };
  }
  async getRiderBySlug(slug) {
    const restRef = firestore()
      .collection(RIDER_COLLECTION)
      .where("slug", "==", slug);
    const doc = await restRef.get();
    return doc.empty ? null : { ...doc.docs[0].data(), id: doc.docs[0].id };
  }

  // Get Driver by Id
  async getRiderById(id) {
    const restRef = firestore().collection(RIDER_COLLECTION).doc(id);
    const doc = await restRef.get();
    return doc.exists ? { ...doc.data(), id: doc.id } : null;
  }

  async getAdminBySlug(slug) {
    const restRef = firestore().collection(ADMIN_COLLECTION).doc(slug);
    const doc = await restRef.get();
    // return doc.empty ? null : { ...doc.docs[0].data(), id: doc.docs[0].id };
    return doc.exists ? { ...doc.data(), id: doc.id } : null;
  }

  async removeAdminPayment(id, payId) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("payments")
      .doc(payId);

    const res = await riderRef.delete();

    return res ? false : { id: payId };
  }
  async updateAdminPayment(id, paymentId, data) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("payments")
      .doc(paymentId);

    const res = await riderRef.update(data);

    return res ? false : { ...data, id: paymentId };
  }

  async addAdminPayment(id, paymentId, data) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("payments")
      .doc(paymentId);

    const res = await riderRef.set(data);

    return res ? false : { ...data, id: paymentId };
  }

  async getAdminPayments(id) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("payments");

    const doc = await riderRef.get();

    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getUserBySlug(slug) {
    const restRef = firestore()
      .collection(USER_COLLECTION)
      .where("slug", "==", slug);
    const doc = await restRef.get();
    return doc.empty ? null : { ...doc.docs[0].data(), id: doc.docs[0].id };
  }
  async getOrderById(id) {
    const orderRef = firestore().collection(ORDER_COLLECTION).doc(id);

    const res = await orderRef.get();
    return res.exists ? { ...res.data(), id: res.id } : null;
  }
  async addNewCategory(data, id) {
    const userRef = firestore().collection(CATEGORYS_COLLECTION).doc(id);

    const res = await userRef.set(data);
    return res
      ? false
      : {
          ...data,
          id,
        };
  }
  async updateCateById(data, id) {
    const userRef = firestore().collection(CATEGORYS_COLLECTION).doc(id);

    const res = await userRef.update(data);
    return res ? false : { ...data, id };
  }
  async getSiteTeam() {
    const userRef = firestore().collection("Team");

    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async addNewRestType(data, id) {
    const userRef = firestore().collection("RestaurantTypes").doc(id);

    const res = await userRef.set(data);
    return res ? false : { ...data, id };
  }
  async removeRestaurantType(id) {
    const userRef = firestore().collection("RestaurantTypes").doc(id);

    const res = await userRef.delete();
    return res ? false : { id };
  }
  async getAllRestaurantTypes() {
    const userRef = firestore().collection("RestaurantTypes");

    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async uploadImage(acceptedFiles) {
    return await new Promise(async (r, eror) => {
      var array = [];
      for (var i = 0; i < Array.from(acceptedFiles).length; i++) {
        try {
          let bucketName = firebase_bucket;
          var filename = Array.from(acceptedFiles)[i].name.split(".");
          var ext = filename[filename.length - 1];
          let storageRef = storage().ref(`${bucketName}/${Date.now()}.${ext}`);
          let upload = await storageRef.put(Array.from(acceptedFiles)[i]);
          const downloadURL = await upload.ref.getDownloadURL();
          array.push(downloadURL);
        } catch (error) {
          eror(error);
        }
      }
      r(array);
    });
  }

  async removeCategoryById(id) {
    const restRef = firestore().collection(CATEGORYS_COLLECTION).doc(id);
    const res = await restRef.delete();
    return res ? false : true;
  }
  async getAllCategorys() {
    const userRef = firestore().collection(CATEGORYS_COLLECTION);

    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async cancelPayment(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/squareup/cancelPayment",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }

  // Update Rides 
  async updateRideFirestore(data, id) {

    const restRef = firestore().collection(RIDES_COLLECTION).doc(id);
    const res = await restRef.update(data);
    return res ? false : true;
  }

  async updateOrderFirestore(data, id) {
    const restRef = firestore().collection(ORDER_COLLECTION).doc(id);
    const res = await restRef.update(data);
    return res ? false : true;
  }

  async setPublicCareers(data) {
    // console.log("data in api", data);
    const restRef = firestore().collection(CAREER_COLLECTION).doc();
    const res = await restRef.set(data);
    return res ? false : true;
  }
  async deletePublicCareer(id) {
    // console.log("delete id in api", id);
    const restRef = firestore().collection(CAREER_COLLECTION).doc(id);
    const res = await restRef.delete();
    return res ? false : { id };
  }
  async getPublicCareersById(id) {
    const restRef = firestore().collection(CAREER_COLLECTION).doc(id);
    const res = await restRef.get();
    return res.exists ? { ...res.data(), id: res.id } : null;
  }
  async updatePublicCareersById(id, data) {
    const restRef = firestore().collection(CAREER_COLLECTION).doc(id);
    const res = await restRef.set({ ...data }, { merge: true });
    return res ? false : true;
  }
  async getPublicCareers() {
    const restRef = firestore().collection(CAREER_COLLECTION);
    const res = await restRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async setSiteTeam(Team) {
    const restRef = firestore().collection(TEAM_COLLECTION).doc();
    const res = await restRef.set(Team);
    return res ? false : true;
  }
  async deleteTeamMember(id) {
    const restRef = firestore().collection(TEAM_COLLECTION).doc(id);
    const res = await restRef.delete();
    return res ? false : true;
  }
  async updateTeamMember(data, id) {
    delete data.id;
    const restRef = firestore().collection(TEAM_COLLECTION).doc(id);
    const res = await restRef.set(data, { merge: true });
    return res ? false : { ...data, id };
  }
  async getPublicCareers() {
    const restRef = firestore().collection(CAREER_COLLECTION);
    const res = await restRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  async activateRestById(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "admin/activateRestaurant",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  // Activate Rider by Id
  async activateUserById(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "admin/activateUser",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  // Activate Driver by Id
  async activateRiderById(data) {
    // console.log("data check: ", data);
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "admin/createDriver",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  // Activate Admin by Id
  async activateAdminById(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "admin/activateAdmin",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async checkUseremail(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "user/checkemail",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }

  async sendWellcomeRideremail(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/welcome/rider",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }

  // Welcome Email to Rider (user)
  async sendWellcomeUseremail(data) {
    return new Promise((resolve, reject) => {
      // console.log(data,"sendWelcome");
      INSTANCE({
        method: "POST",
        url: "/welcome/user",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async sendWellcomeAdminemail(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "welcome/admin",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async deActivateUserById(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "admin/deActivateUser",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async deActivateAdminById(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "admin/deActivateAdmin",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async deActivateRestById(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "admin/deActivateRestaurant",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async deActivateRiderById(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "admin/deActivateRider",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async deleteUserById(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "DELETE",
        url: "admin/user",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async deleteAdminById(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "DELETE",
        url: "admin/admin",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }

  async deleteRiderById(data) {
    return new Promise((resolve, reject) => {
      // console.log("data",data);
      INSTANCE({
        method: "DELETE",
        url: "admin/rider",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async removeRestaurantById(id) {
    const restRef = firestore().collection(RESTAURANT_COLLECTION).doc(id);

    const res = await restRef.delete();
    return res ? false : { id };
  }
  async addDriverCarType(data, id) {
    const restRef = firestore().collection(VEHICLES_TYPES_COLLECTION).doc(id);

    const res = await restRef.set(data);
    return res ? false : { ...data, id };
  }
  async updateDriverCarType(data, id) {
    const restRef = firestore().collection(VEHICLES_TYPES_COLLECTION).doc(id);

    const res = await restRef.update(data);
    return res ? false : { ...data, id };
  }
  async updateDriverCar(data, id) {
    const restRef = firestore().collection(VEHICLES__COLLECTION).doc(id);

    const res = await restRef.update(data);
    return res ? false : { ...data, id };
  }
  async removeRiderById(id) {
    // console.log("remove rider by id",id);
    const restRef = firestore().collection(RIDER_COLLECTION).doc(id);

    const res = await restRef.delete();
    return res ? false : { id };
  }
  async removeUserById(id) {
    // console.log("removing user",id)
    const restRef = firestore().collection(USER_COLLECTION).doc(id);

    const res = await restRef.delete();
    return res ? false : { id };
  }

  async removeVehicleTypeById(id) {
    const restRef = firestore().collection(VEHICLES_TYPES_COLLECTION).doc(id);

    const res = await restRef.delete();
    return res ? false : { id };
  }
  async removeOrderDatabase(id) {
    const restRef = database().ref(ORDER_COLLECTION).child(id);

    const res = await restRef.remove();
    return res ? false : true;
  }
  async getUnboundRiders() {
    const restRef = await database()
      .ref("riders")
      .orderByChild("isBound")
      .equalTo(false);

    return restRef;
  }
  async updateOrderDatabase(data, id) {
    const orderRef = database().ref(ORDER_COLLECTION).child(id);

    const res = await orderRef.update(data);
    return res ? false : true;
  }
  async riderUpdateDatabase(data, id) {
    const orderRef = database().ref(RIDER_COLLECTION).child(id);

    const res = await orderRef.update(data);
    return res ? false : true;
  }

  async orderHistory() {
    const orderRef = firestore()
      .collection(ORDER_COLLECTION)
      .where("status", "!=", "pending");

    const res = await orderRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  // Get Ride History By Dates
  async rideHistoryByDate(start, end, city) {

    let rideRef;

    if (city !== "ALL") {
      rideRef = firestore()
        .collection(RIDES_COLLECTION)
        .where("city", "==", city)
        .where("createdAt", ">=", start)
        .where("createdAt", "<=", end)
        .orderBy("createdAt", "desc");

    } else {
      rideRef = firestore()
        .collection(RIDES_COLLECTION)
        .where("createdAt", ">=", start)
        .where("createdAt", "<=", end)
        .orderBy("createdAt", "desc");
    }

    const res = await rideRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }


  async orderHistoryByDate(start, end, city) {
    let orderRef;

    if (city !== "ALL") {
      orderRef = firestore()
        .collection(ORDER_COLLECTION)

        .where("city", "==", city)

        .where("createdAt", ">=", start)

        .where("createdAt", "<=", end)
        .orderBy("createdAt", "desc");
    } else {
      orderRef = firestore()
        .collection(ORDER_COLLECTION)
        .where("createdAt", ">=", start)

        .where("createdAt", "<=", end)
        .orderBy("createdAt", "desc");
    }

    const res = await orderRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }



  async getAdmins() {
    const adminRef = firestore().collection(ADMIN_COLLECTION);

    const doc = await adminRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getAllAdminShifts(start, end, id) {
    const adminRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("shifts")
      .where("date", ">=", Number(start))
      .where("date", "<=", Number(end));

    const doc = await adminRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getRiders() {
    const adminRef = firestore().collection(RIDER_COLLECTION);

    const doc = await adminRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getRidersByStatus(start, end, onlineStatus, status) {

    let adminRef;
    // console.log("start", start);
    // console.log("end", end);
    // console.log("onlineStatus", onlineStatus);
    // console.log("status", status);
    adminRef = firestore().collection(RIDER_COLLECTION);

    if(start && end){
      adminRef = adminRef.where("createdAt", ">=", start).where("createdAt", "<=", end);
    }

    if (onlineStatus) {
      adminRef =  adminRef.where("isLive", "==", onlineStatus === "online" ? true : false)
    }

    if (status) {
      adminRef = adminRef.where("isProved", "==", status === "active" ? true : false)       
    }
    if (onlineStatus && status) {
      adminRef = adminRef.where("isProved", "==", status === "active" ? true : false)
      .where("isLive", "==", onlineStatus === "online" ? true : false)
    }

    const doc = await adminRef.get();

    // console.log("doc my", doc.docs);

    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  async addAdminRole(data, id) {
    const adminRef = firestore().collection(ADMINROLE_COLLECTION).doc(id);

    const res = await adminRef.set(data);
    return res ? false : { ...data, id };
  }
  async updateMemberemail(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/admin/updateemail",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async updateAdminRoleAuth(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/admin/update-role",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async updateAdminRole(data, id) {
    const adminRef = firestore().collection(ADMINROLE_COLLECTION).doc(id);

    const res = await adminRef.update(data);
    return res ? false : { ...data, id };
  }
  async getSiteRoles() {
    const adminRef = firestore().collection(ADMINROLE_COLLECTION);

    const res = await adminRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  //Update admin 
  async updateAdminMember(data, id) {

    // console.log("admin upd-", data,id);

    const adminRef = firestore().collection(ADMIN_COLLECTION).doc(id);

    const res = await adminRef.update(data);

    return res ? false : { ...data, id };
  }

  async deleteAdminRole(id) {
    const adminRef = firestore().collection(ADMINROLE_COLLECTION).doc(id);

    const res = await adminRef.delete();
    return res ? false : { id };
  }
  async deleteAdmin(id) {
    const adminRef = firestore().collection(ADMIN_COLLECTION).doc(id);

    const res = await adminRef.delete();
    return res ? false : { id };
  }
  async uploadBlob(blob) {
    return await new Promise(async (r, eror) => {
      try {
        let bucketName = firebase_bucket;
        var filename = "adhakjd.pdf";
        var ext = filename[filename.length - 1];
        let storageRef = storage().ref(`${bucketName}/${Date.now()}.${ext}`);
        let upload = await storageRef.put(blob);
        const downloadURL = await upload.ref.getDownloadURL();
        r(downloadURL);
      } catch (error) {
        eror(error);
      }
    });
  }
  // Save Inovice
  async saveInvoice(data, id) {
    const invoiceRef = firestore()
      .collection(INVOICE_COLLECTION)
      .where("from", "==", data.from)
      .where("to", "==", data.to);
      
    const invoices = await invoiceRef.get();

    // console.log("Checking Invoices ==> ", invoices);
    if (invoices.empty) {
      const riderRef = firestore().collection(INVOICE_COLLECTION).doc(id);
      const res = await riderRef.set(data);

      return res ? false : true;
    } else {
      const isExist = invoices.docs.find(
        (e) => e.data().userId === data.userId
      );

      if (isExist) {
        return false;
      }
      const riderRef = firestore().collection(INVOICE_COLLECTION).doc(id);
      const res = await riderRef.set(data);

      return res ? false : true;
    }
  }
  
  async getLatestPendingOrders() {
    const invoiceRef = firestore()
      .collection(ORDER_COLLECTION)
      .where("createdAt", ">=", moment().startOf("d").format("x"))
      .where("status", "==", "pending");
    const invoices = await invoiceRef.get();

    return invoices.empty
      ? []
      : invoices.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getNumberOfRegisterUsers() {
    const invoiceRef = firestore()
      .collection(USER_COLLECTION)
      .where("createdAt", ">=", moment().startOf("d").format("x"));

    const invoices = await invoiceRef.get();

    return invoices.empty
      ? []
      : invoices.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  async getRidersLive() {
    const invoiceRef = database().ref(RIDER_COLLECTION);
    return invoiceRef;
  }

  // Get Online drivers
  async getDriversOnline() {

    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .where("isLive", "==", true);

      const doc = await userRef.get();
      
      return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  async getLiveOrderByRider(id) {
    const riderRef = firestore()
      .collection(ORDER_COLLECTION)
      .where("riderdetail.id", "==", id)
      .where("status", "!=", "completed");
    const res = await riderRef.get();
    return res?.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getUsers() {
    const userRef = firestore().collection(USER_COLLECTION);
    ///no spec for dat, yhi hai shyd
    const doc = await userRef.get();

    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getVehicleTypes() {
    const userRef = firestore()
      .collection(VEHICLES_TYPES_COLLECTION)
      .where("isActive", "==", true);

    const doc = await userRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getAllVehicleTypes() {
    const userRef = firestore().collection(VEHICLES_TYPES_COLLECTION);

    const doc = await userRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async savePricings(data, id) {
    const userRef = firestore().collection(PRICING_COLLECTION).doc(id);
    const holidayRef = firestore().collection(HOLIDAY_SURCHARGE).doc(uuidv4());

    let cityIds = data.city;
    if (data.action === "multiply") {
      const tarrifData = [];
      for (let i = 0; i < cityIds.length; i++) {
        await firestore()
          .collection(TARRIF_COLLECTION)
          .where("city", "array-contains", cityIds[i])
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              tarrifData.push(doc.data());
            });
          });
      }

      tarrifData.map(async (tData) => {
        let newMpv = tData.mpv * data.factorValue;
        let newStandard = tData.standard * data.factorValue;
        let newExecutive = tData.executive * data.factorValue;
        let newMultiseater = tData.multiseater * data.factorValue;
        let amount = newMpv + newStandard + newExecutive + newMultiseater;

        let holidayData = {
          mpv: newMpv,
          standard: newStandard,
          multiseater: newMultiseater,
          executive: newExecutive,
          city: tData.city,
          factorValue: data.factorValue,
          amount: amount,
          date: data.fromTo,
        };
        await holidayRef.set(holidayData, { merge: true });
      });
    } else {
      const tarrifData = [];
      for (let i = 0; i < cityIds.length; i++) {
        await firestore()
          .collection(TARRIF_COLLECTION)
          .where("city", "==", cityIds[i])
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              tarrifData.push(doc.data());
            });
          });
      }
      tarrifData.map(async (tData) => {
        let newMpv = parseInt(tData.mpv) + parseInt(data.factorValue);
        let newStandard = parseInt(tData.standard) + parseInt(data.factorValue);
        let newExecutive =
          parseInt(tData.executive) + parseInt(data.factorValue);
        let newMultiseater =
          parseInt(tData.multiseater) + parseInt(data.factorValue);
        let amount =
          parseInt(newMpv) +
          parseInt(newStandard) +
          parseInt(newExecutive) +
          parseInt(newMultiseater);

        let holidayData = {
          mpv: newMpv,
          standard: newStandard,
          multiseater: newMultiseater,
          executive: newExecutive,
          city: tData.city,
          factorValue: data.factorValue,
          amount: amount,
          date: data.fromTo,
        };

        await holidayRef.set(holidayData, { merge: true });
      });
    }
    const doc = await userRef.set(data, { merge: true });
    return doc ? false : { ...data, id };
  }
  async addNewCityTariff(data, cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Tariffs")
      .doc(id);
    const res = await userRef.set(data);
    return res ? false : { ...data, id };
  }
  async updateCityTariffById(data, cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Tariffs")
      .doc(id);
    const res = await userRef.update(data);
    return res ? false : { ...data, id };
  }
  async updateCityShiftsById(data, cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Shifts")
      .doc(id);
    const res = await userRef.update(data);
    return res ? false : { ...data, id };
  }
  async updateCityWeekDaysSurchargeById(data, cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("WeekDaysSurcharges")
      .doc(id);
    const res = await userRef.update(data);
    return res ? false : { ...data, id };
  }
  async updateCityHolidaySurchargesById(data, cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("HolidaySurcharges")
      .doc(id);
    const res = await userRef.update(data);
    return res ? false : { ...data, id };
  }
  async addCityNewShift(data, cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Shifts")
      .doc(id);
    const res = await userRef.set(data);
    return res ? false : { ...data, id };
  }
  async addCityHolidaySurcharges(data, cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("HolidaySurcharges")
      .doc(id);
    const res = await userRef.set(data);
    return res ? false : { ...data, id };
  }
  async addCityWeekDaySurcharge(data, cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("WeekDaysSurcharges")
      .doc(id);
    const res = await userRef.set(data);
    return res ? false : { ...data, id };
  }
  async addNewCityBase(data, cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Bases")
      .doc(id);
    const res = await userRef.set(data);
    return res ? false : { ...data, id };
  }
  async addCityZones(data, cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Zones")
      .doc(id);
    const res = await userRef.set(data);
    return res ? false : { ...data, id };
  }

  async removeTariffById(cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Tariffs")
      .doc(id);
    const res = await userRef.delete();
    return res ? false : { id };
  }
  async removeShiftById(cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Shifts")
      .doc(id);
    const res = await userRef.delete();
    return res ? false : { id };
  }
  async removeCityHolidaySurchargesById(cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("HolidaySurcharges")
      .doc(id);
    const res = await userRef.delete();
    return res ? false : { id };
  }
  async removeCityWeekDaySurchargeById(cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("WeekDaysSurcharges")
      .doc(id);
    const res = await userRef.delete();
    return res ? false : { id };
  }
  async getTariffsByCityId(cityId) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Tariffs");

    const { empty, docs } = await userRef.get();
    return empty
      ? []
      : docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
  }
  async getCityShifts(cityId) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Shifts");

    const { empty, docs } = await userRef.get();
    return empty
      ? []
      : docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
  }
  async getCitySubCollection(data, cityId, subCollection, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection(subCollection)
      .doc(id);

    const res = await userRef.update(data);
    return res ? false : { ...data, id };
  }
  
  async getCityHolidaySurcharges(cityId) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("HolidaySurcharges");

    const { empty, docs } = await userRef.get();
    return empty
      ? []
      : docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
  }
  async getCityWeekDaysSurcharges(cityId) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("WeekDaysSurcharges");

    const { empty, docs } = await userRef.get();
    return empty
      ? []
      : docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
  }
  async getCityBases(cityId) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Bases");

    const { empty, docs } = await userRef.get();
    return empty
      ? []
      : docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
  }
  async getCityZones(cityId) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Zones");

    const { empty, docs } = await userRef.get();
    return empty
      ? []
      : docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
  }
  async getPricings() {
    const userRef = firestore().collection(PRICING_COLLECTION);

    const doc = await userRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async deletePricings(docId) {
    const userRef = firestore().collection(PRICING_COLLECTION).doc(docId);

    const doc = await userRef.delete();
    return doc ? false : { id: docId };
  }

  async getVehicles() {
    const userRef = firestore().collection(VEHICLES__COLLECTION);

    const doc = await userRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getVehiclesByType(type) {
    const userRef = firestore()
      .collection(VEHICLES__COLLECTION)
      .where("type", "==", type);

    const doc = await userRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async updateDriverVehicle(data, docId) {
    const riderRef = firestore().collection(VEHICLES__COLLECTION).doc(docId);
    const res = await riderRef.update(data);

    return res ? false : { ...data, id: docId };
  }

  // update (rider) drivers vehicle type
  async updateDriverVehicleType(data, docId){
    const riderRef = firestore().collection(RIDER_COLLECTION).doc(docId);
    // console.log(riderRef);
    const res = await riderRef.update(data);

    return res ? false : { ...data, id: docId };
  }


  async deleteDriverVehicle(docId) {
    const riderRef = firestore()
      .collection(VEHICLES__COLLECTION)

      .doc(docId);
    const res = await riderRef.delete();

    return res ? false : { id: docId };
  }
  async addDriverCar(data, docId) {
    const riderRef = firestore().collection(VEHICLES__COLLECTION).doc(docId);
    const res = await riderRef.set(data);

    return res ? false : { ...data, id: docId };
  }
  async getVehicleTypeById(id) {
    const userRef = firestore().collection(VEHICLES_TYPES_COLLECTION).doc(id);

    const doc = await userRef.get();
    return doc.exists ? { ...doc.data(), id } : null;
  }
  async saveBaseOffice(data, id) {
    const userRef = firestore().collection(BASES_COLLECTION).doc(id);

    const doc = await userRef.set(data);
    return doc ? false : { ...data, id };
  }
  async deleteBaseOffice(cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Bases")
      .doc(id);

    const doc = await userRef.delete();
    return doc ? false : { id };
  }
  async deleteZoneOffice(cityId, id) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Zones")
      .doc(id);

    const doc = await userRef.delete();
    return doc ? false : { id };
  }

  async getBaseOffices() {
    const userRef = firestore().collection(BASES_COLLECTION);

    const doc = await userRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getActiveBaseOffices() {
    const userRef = firestore()
      .collection(BASES_COLLECTION)
      .where("isActive", "==", true);

    const doc = await userRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  async getDriverBetweenDates(dates) {
    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .where("date", ">=", dates[0])
      .where("date", "<=", dates[1]);

    const doc = await userRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }


  async getRidesBetweenDates(dates) {
    const userRef = firestore()
      .collection(RIDES_COLLECTION)
      .where("date", ">=", dates[0])
      .where("date", "<=", dates[1]);

    const doc = await userRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async updateBaseOffice(data, id, cityId) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Bases")
      .doc(id);

    const doc = await userRef.update(data);
    return doc ? false : { ...data, id };
  }
  async updateZoneOffice(data, id, cityId) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .doc(cityId)
      .collection("Zones")
      .doc(id);

    const doc = await userRef.update(data);
    return doc ? false : { ...data, id };
  }

  async getRestaurants() {
    const userRef = firestore().collection(RESTAURANT_COLLECTION);

    const doc = await userRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async addUserFirestore(data, id) {
    // console.log("Add Rider to  ===> ");

    const userRef = firestore().collection(USER_COLLECTION).doc(id);

    const res = await userRef.set(data);

    return res ? false : { ...data, id };
  }
  async addRestFirestore(data, id) {
    const userRef = firestore().collection(RESTAURANT_COLLECTION).doc(id);

    const res = await userRef.set(data);

    return res ? false : true;
  }
  
  async addAdminFirestore(data, id) {
    const userRef = firestore().collection(ADMIN_COLLECTION).doc(id);

    const res = await userRef.set(data);

    return res ? false : { ...data, id };
  }

  async addRiderFirestore(data, id) {
    // console.log("Add Driver to  ===> ");
    const userRef = firestore().collection(RIDER_COLLECTION).doc(id);

    const res = await userRef.set(data);

    return res ? false : { ...data, id };
  }
  // add Test for CF driver
  async addRiderTestforCf(eventData) {

  // console.log("Test for CF Driver ===> ", eventData);
  const apiUrl = "https://us-central1-navigo-taxi.cloudfunctions.net/driversApi/onDriverSignup";

    const requestBody = JSON.stringify(eventData);
    
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
        Token: ""
      },
      body: requestBody, 
    };

    const data = await fetch(apiUrl, requestOptions);
    
    let res;
    if(data.status == 200){
      res = eventData;
    }else{
      return false;
    }
    // .then(response => {
    //   if (!response.ok) {
    //     throw new Error('nope, faulty');
    //   }
    //   return response; 
    // }).then(data => {
    //   // console.log('Response data:', data);
    // }).catch(error => {
    //   console.error('Error:', error);
    // });

    // console.log('my data:', data);

    // const res = data;

    // return res ? false : { ...data };
    return res;
  }

  async addNoteForUser(data, id) {
    const userRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("Notes")
      .doc();

    const res = await userRef.set(data);

    return res ? false : true;
  }
  async addNoteForRider(data, id) {
    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("Notes")
      .doc();

    const res = await userRef.set(data);

    return res ? false : true;
  }
  async addNoteForRestaurant(data, id, noteId) {
    const userRef = firestore()
      .collection(RESTAURANT_COLLECTION)
      .doc(id)
      .collection("Notes")
      .doc(noteId);

    const res = await userRef.set(data);

    return res ? false : { ...data, id: noteId };
  }
  async deleteUserNote(id, noteId) {
    const userRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("Notes")
      .doc(noteId);

    const res = await userRef.delete();

    return res ? false : true;
  }
  async deleteRiderNote(id, noteId) {
    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("Notes")
      .doc(noteId);

    const res = await userRef.delete();

    return res ? false : true;
  }
  async deleteRestaurantNote(id, noteId) {
    const userRef = firestore()
      .collection(RESTAURANT_COLLECTION)
      .doc(id)
      .collection("Notes")
      .doc(noteId);

    const res = await userRef.delete();

    return res ? false : true;
  }
  async AddAdminSchedule(data, userId, id) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("shifts")
      .doc(id);
    const doc = await riderRef.set(data);

    return doc ? false : { ...data, id };
  }
  async AddAdminShift(data, userId, id) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("shifts")
      .doc(id);
    const doc = await riderRef.set(data);

    return doc ? false : { ...data, id };
  }
  async removeAdminShift(id, userId) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("shifts")
      .doc(id);
    const doc = await riderRef.delete();

    return doc ? false : { id };
  }
  async updateAdminShift(data, userId, id) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("shifts")
      .doc(id);
    const doc = await riderRef.set(data, { merge: true });

    return doc ? false : { ...data, id };
  }
  async addNoteForAdmin(data, id, noteId) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("Notes")
      .doc(noteId);

    const res = await userRef.set(data);

    return res ? false : { ...data, id: noteId };
  }
  async deleteAdminNote(id, noteId) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("Notes")
      .doc(noteId);

    const res = await userRef.delete();

    return res ? false : true;
  }
  async updateUserFirestore(data, id) {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);

    const res = await userRef.update(data);

    return res ? false : { ...data, id };
  }
  async updateUserPassword(data, id) {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);

    const res = await userRef.set({ password: data });

    return res ? false : { ...data, id };
  }
  async updateUserPhone(data, id) {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);

    const res = await userRef.set({ phone: data });

    return res ? false : { ...data, id };
  }
  async updateUserPayment(data, id) {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);

    const res = await userRef.set(data, { merge: true });

    return res ? false : { ...data, id };
  }
  async updateUserAddress(data, id) {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);

    const res = await userRef.set({ fullAddress: data });

    return res ? false : { ...data, id };
  }
  async getAdminById(id) {
    const userRef = firestore().collection(ADMIN_COLLECTION).doc(id);

    const res = await userRef.get();

    return res.exists ? { ...res.data(), id: res.id } : null;
  }
  async updateRestaurantFirestore(data, id) {
    const userRef = firestore().collection(RESTAURANT_COLLECTION).doc(id);

    const res = await userRef.update(data);

    return res ? false : { ...data, id };
  }
  async updateRiderFirestore(data, id) {
    // console.log("rider upd-",data,id);
    const userRef = firestore().collection(RIDER_COLLECTION).doc(id);

    const res = await userRef.update(data);
    // console.log("rider updateddd-",res);

    return res ? false : { ...data, id };
  }
  async updateRide(data, id) {

    // TODO: No need of this
    // if (data?.driver?.length === 0) {
    //   data.status = "pending";
    // } else {
    //   // data.status = "driverAssigned";
    //   data.status = "driverAssigned";
    // }
    const userRef = firestore().collection(RIDES_COLLECTION).doc(id);

    const res = await userRef.update(data);

    return res ? false : { ...data, id };
  }
  async completeRide(data, id) {
    const userRef = firestore().collection(RIDES_COLLECTION).doc(id);

    const res = await userRef.update(data);

    return res ? false : { ...data, id };
  }
  async cancelRide(data, id) {
    const userRef = firestore().collection(RIDES_COLLECTION).doc(id);

    const res = await userRef.update(data);

    return res ? false : { ...data, id };
  }
  async getAllVouchers() {
    const userRef = firestore().collection(VOUCHER_COLLECTION);

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async updateVoucher(data, id) {
    const userRef = firestore().collection(VOUCHER_COLLECTION).doc(id);

    const res = await userRef.update(data);

    return res ? false : { ...data, id };
  }
  async deleteVoucher(id) {
    const userRef = firestore().collection(VOUCHER_COLLECTION).doc(id);

    const res = await userRef.delete();

    return res ? false : { id };
  }
  async getUserById(id) {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);

    const res = await userRef.get();

    return res.exists ? { ...res.data(), id: res.id } : null;
  }
  async addVoucher(data, id) {
    const userRef = firestore().collection(VOUCHER_COLLECTION).doc(id);

    const res = await userRef.set(data);

    return res ? false : { ...data, id };
  }
  async getAllRestaurants() {
    const userRef = firestore().collection(RESTAURANT_COLLECTION);

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  // Get all drivers
  async getAllRiders() {
    const userRef = firestore().collection(RIDER_COLLECTION);

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  // get all users
  async getAllUsers() {
    const userRef = firestore().collection(USER_COLLECTION);

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  async getOrdersbyRestaurant(id, from, to) {
    const userRef = firestore()
      .collection(ORDER_COLLECTION)
      .where("restaurant.id", "==", id)

      .where("createdAt", ">=", from)
      .where("createdAt", "<=", to)

      .orderBy("createdAt", "desc");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getPaidOrderByTime(from, to) {
    const userRef = firestore()
      .collection(ORDER_COLLECTION)
      .where("paid", "==", true)

      .where("createdAt", ">=", from)
      .where("createdAt", "<=", to)

      .orderBy("createdAt", "desc");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  // get paid rides by time
  async getPaidRidesByTime(from, to) {
    const userRef = firestore()
      .collection(RIDES_COLLECTION)
      .where("paid", "==", true)
      .where("createdAt", ">=", from)
      .where("createdAt", "<=", to)

      .orderBy("createdAt", "desc");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

    // Get All Driver Rides
    async getAllDriverRides(id, riderName) {
      const userRef = firestore()
        .collection(RIDES_COLLECTION)
        .where("drivers", "array-contains" , { value: id , label: riderName })
        .orderBy("createdAt", "desc");
  
      const res = await userRef.get();
  
      // console.log("ini mini ==> ", res);
  
      return res.empty
        ? []
        : res.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
    }

  // Get Rides by Driver 
  async getRidesbyDriver(id, riderName , from, to) {

    const userRef = firestore()
      .collection(RIDES_COLLECTION)
      // .where("drivers", "array-contains" , { value: id , label: riderName })
      .where("driverId" , "==" , id)
      .where("createdAt", ">=", from)
      .where("createdAt", "<=", to)
      .orderBy("createdAt", "desc");

    const res = await userRef.get();

    // console.log("ini mini ==> ", res);

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

    // Get Rides by User 
    async getRidesbyUser(id, userName , from, to) {
      const userRef = firestore()
        .collection(RIDES_COLLECTION)
        .where("passenger.id", "==", id)
        .where("createdAt", ">=", from)
        .where("createdAt", "<=", to)
        .orderBy("createdAt", "desc");
  
      const res = await userRef.get();
  
      // console.log("ini mini ==> ", res);
  
      return res.empty
        ? []
        : res.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
    }

  async getOrdersbyRider(id, from, to) {
    const userRef = firestore()
      .collection(ORDER_COLLECTION)
      .where("riderdetail.id", "==", id)

      .where("createdAt", ">=", from)
      .where("createdAt", "<=", to)

      .orderBy("createdAt", "desc");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }


  async getUpdatedOrdersbyRider(id, from, to, data) {
    const userRef = firestore()
      .collection(ORDER_COLLECTION)
      .where("riderdetail.id", "==", id)

      .where("createdAt", ">=", String(from))
      .where("createdAt", "<=", String(to))

      .orderBy("createdAt", "desc");

    const res = await userRef.get();
    const res2 = await Promise.all(
      res.docs.map(async (e) => {
        return await e.ref.update(data);
      })
    );
    return res2.every((e) => e === true) ? true : false;
  }

  async getUserNotes(id) {
    const userRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("Notes");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getRiderNotes(id) {
    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("Notes");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async saveLeaveToFirestore(data, id, userId) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("Leaves")
      .doc(id);

    const res = await userRef.set(data);

    return res ? false : { ...data, id };
  }
  async updateLeaveToFirestore(data, id, userId) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("Leaves")
      .doc(id);

    const res = await userRef.update(data);

    return res ? false : { ...data, id };
  }
  async deleteLeaveToFirestore(id, userId) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("Leaves")
      .doc(id);

    const res = await userRef.delete();

    return res ? false : { id };
  }
  async getRestaurantsNotes(id) {
    const userRef = firestore()
      .collection(RESTAURANT_COLLECTION)
      .doc(id)
      .collection("Notes");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  async getDriverSchedulesByDate(start, end, id) {
    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("schedules")
      .where("date", ">=", start)
      .where("date", "<=", end);

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getCitys() {
    const userRef = firestore().collection(CITIES_COLLECTION);

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getCity(id) {
    const userRef = firestore().collection(CITIES_COLLECTION).doc(id);

    const res = await userRef.get();

    return res.exists ? { ...res.data(), id: res.id } : null;
  }
  async getCityBySlug(slug) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .where("slug", "==", slug);

    const { empty, docs } = await userRef.get();
    if (empty) {
      return null;
    }
    const cities = docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });

    return cities[0];
  }
  async getCityBySlugAndSubCollections(slug) {
    const userRef = firestore()
      .collection(CITIES_COLLECTION)
      .where("slug", "==", slug)
      .limit(1);

    const { empty, docs } = await userRef.get();
    if (empty) {
      return null;
    }
    const cities = docs.map(async (doc) => {
      const { empty: tariffEmpty, docs: tariffDocs } = await doc.ref
        .collection("Tariffs")
        .get();
      const { empty: weekDaysSurchargesEmpty, docs: weekDaysSurchargesDocs } =
        await doc.ref.collection("WeekDaysSurcharges").get();

      const { empty: shiftEmpty, docs: shiftDocs } = await doc.ref
        .collection("Shifts")
        .get();
      const { empty: holidaySurchargesEmpty, docs: holidaySurchargesDocs } =
        await doc.ref.collection("HolidaySurcharges").get();

      const { empty: baseEmpty, docs: baseDocs } = await doc.ref
        .collection("Bases")
        .get();

      return {
        ...doc.data(),
        id: doc.id,
        tariffs: tariffEmpty
          ? []
          : tariffDocs.map((tariff) => {
              return {
                ...tariff.data(),
                id: tariff.id,
              };
            }),
        bases: baseEmpty
          ? []
          : baseDocs.map((base) => {
              return {
                ...base.data(),
                id: base.id,
              };
            }),
        weekDaysSurcharges: weekDaysSurchargesEmpty
          ? []
          : weekDaysSurchargesDocs.map((weekDaysSurcharge) => {
              return {
                ...weekDaysSurcharge.data(),
                id: weekDaysSurcharge.id,
              };
            }),
        shifts: shiftEmpty
          ? []
          : shiftDocs.map((shift) => {
              return {
                ...shift.data(),
                id: shift.id,
              };
            }),
        holidaySurcharges: holidaySurchargesEmpty
          ? []
          : holidaySurchargesDocs.map((holidaySurcharge) => {
              return {
                ...holidaySurcharge.data(),
                id: holidaySurcharge.id,
              };
            }),
      };
    });
    const results = await Promise.all(cities);
    return results[0];
  }
  async saveCity(data, id) {
    const userRef = firestore().collection(CITIES_COLLECTION).doc(id);

    const res = await userRef.set(data);

    return res ? false : { ...data, id };
  }
  async removeCity(id) {
    const userRef = firestore().collection(CITIES_COLLECTION).doc(id);

    const res = await userRef.delete();

    return res ? false : { id };
  }
  async updateCity(data, id) {
    const userRef = firestore().collection(CITIES_COLLECTION).doc(id);

    const res = await userRef.update(data);

    return res ? false : { ...data, id };
  }

  // Additional Documents Driver
  async getRiderDetailInfo(id) {
    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("additionalDocuments");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }


  // Get ALl invoices
  async getAllInvoices() {
    const userRef = firestore().collection(INVOICE_COLLECTION);
    const res = await userRef.get();
  
    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }

  async getInvoicesbyId(id , as) {
    const userRef = firestore()
      .collection(INVOICE_COLLECTION)
      .where("userId","==", id)
      .where("as","==", as)
      .orderBy("createdAt", "desc");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }



  // Earnings Driver by Id
  async getRiderEarnings(id) {
    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("earnings");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }


  async getRestDetailInfo(id) {
    const userRef = firestore()
      .collection(RESTAURANT_COLLECTION)
      .doc(id)
      .collection("Detailinfo");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getAdminDetailInfo(id) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("Detailinfo");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async deleteRiderDocument(id, docId) {
    const userRef = firestore()
      .collection(RIDER_COLLECTION)
      .doc(id)
      .collection("additionalDocuments")
      .doc(docId);

    const res = await userRef.delete();

    return res ? false : { id: docId };
  }
  async updateAdminSchedule(id, schId, data) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("schedules")
      .doc(schId);

    const res = await riderRef.update(data);

    return res ? false : { ...data, id: schId };
  }
  async getAdminNotes(id) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("Notes");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getAdminLeaves(start, end, id) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("Leaves")
      .where("createdAt", ">=", start)
      .where("createdAt", "<=", end);

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getAdminSchedules(id) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("schedules");

    const doc = await riderRef.get();

    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getAdminShifts(id) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("shifts");

    const doc = await riderRef.get();

    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getAdminShiftsByDate(date, userId) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("shifts")
      .where("date", ">=", moment(date).startOf("day").format("x"))
      .where("date", "<=", moment(date).endOf("day").format("x"))
      .orderBy("date", "asc");

    const res = await userRef.get();

    return res.empty
      ? []
      : await Promise.all(
          res.docs.map(async (e) => {
            const data = e.data();
            const first = moment(Number(data?.lockInTime))
              .startOf("d")
              .format("x");
            const last = moment(Number(data?.lockInTime))
              .endOf("d")
              .format("x");

            const absence = await this.getAdminAbsencesByDate(
              first,
              last,
              userId
            );
            if (absence?.length) {
              return {
                ...data,
                day: moment(date).format("dddd"),

                id: e.id,
                absence,
                absencePercentage: 0,
              };
            }
            const scheduleTime =
              moment(data?.time?.[1], "HH:mm").diff(
                moment(data?.time?.[0], "HH:mm"),
                "hours"
              ) + 1;
            let workingTime;

            if (data.lockOutTime) {
              workingTime = (
                (moment(Number(data.lockOutTime)).diff(
                  moment(Number(data.lockInTime)),
                  "minutes"
                ) +
                  1) /
                60
              ).toFixed(1);
            } else {
              workingTime = (
                (moment().diff(moment(Number(data.lockInTime)), "minutes") +
                  1) /
                60
              ).toFixed(1);
            }
            const workingTimeNumber = parseFloat(workingTime);
            if (workingTimeNumber >= scheduleTime) {
              return {
                ...data,
                day: moment(date).format("dddd"),

                id: e.id,
                absence,

                absencePercentage: 100,
              };
            }

            return {
              ...data,
              day: moment(date).format("dddd"),

              id: e.id,

              absence,
              absencePercentage: (workingTimeNumber / scheduleTime) * 100,
            };
          })
        );
  }

  async getAdminShiftsByDateandId(start, end, userId) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("shifts")
      .where("date", ">=", start)
      .where("date", "<=", end)
      .orderBy("date", "asc");

    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getAdminAbsencesByDate(start, end, userId) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("Leaves")
      .where("start", ">=", start)
      .orderBy("start", "asc");
    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs
          .map((e) => {
            if (e.data().end <= end) {
              return {
                ...e.data(),
                id: e.id,
              };
            }
            return null;
          })
          .filter((e) => e);
  }
  async updateAdminDetail(id, by, data) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("Detailinfo")
      .doc(by);

    const res = await riderRef.update(data);

    return res ? false : { ...data, id: by };
  }
  async updateRestaurantDetail(id, by, data) {
    const riderRef = firestore()
      .collection(RESTAURANT_COLLECTION)
      .doc(id)
      .collection("Detailinfo")
      .doc(by);

    const res = await riderRef.update(data);

    return res ? false : { ...data, id: by };
  }
  async addAdminDetail(id, by, data) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("Detailinfo")
      .doc(by);

    const res = await riderRef.set(data);

    return res ? false : { ...data, id: by };
  }
  async addRestaurantDetail(id, by, data) {
    const riderRef = firestore()
      .collection(RESTAURANT_COLLECTION)
      .doc(id)
      .collection("Detailinfo")
      .doc(by);

    const res = await riderRef.set(data);

    return res ? false : { ...data, id: by };
  }
  async deleteAdminDocument(id, docId) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("Detailinfo")
      .doc(docId);

    const res = await userRef.delete();

    return res ? false : { id: docId };
  }
  async deleteRestaurantDocument(id, docId) {
    const userRef = firestore()
      .collection(RESTAURANT_COLLECTION)
      .doc(id)
      .collection("Detailinfo")
      .doc(docId);

    const res = await userRef.delete();

    return res ? false : { id: docId };
  }

  async getOrderbyOrderNumber(number) {
    const userRef = firestore()
      .collection(ORDER_COLLECTION)
      .where("orderNumber", "==", number);

    const res = await userRef.get();

    return res.empty ? null : { ...res.docs[0].data(), id: res.docs[0].id };
  }
  async getLiveDetailById(id) {
    const restRef = database().ref(ORDER_COLLECTION).child(id);
    return restRef;
  }
  async getRiderLiveDetail(id) {
    const restRef = database().ref(RIDER_COLLECTION).child(id);
    return restRef;
  }
  async getAllChatSessions() {
    const restRef = database().ref(CHAT_COLLECTION);
    return restRef;
  }
  async getAllChatSessionsFirebaseByDateAndAdminPlat(
    start,
    end,
    admin,
    platform
  ) {
    let restRef = firestore()
      .collection(CHAT_COLLECTION)
      .where("createdAt", ">=", Number(start))
      .where("createdAt", "<=", Number(end));

    if (admin) {
      restRef = firestore()
        .collection(CHAT_COLLECTION)
        .where("createdAt", ">=", Number(start))
        .where("createdAt", "<=", Number(end))
        .where("startBy", "==", admin);
    }
    if (platform) {
      restRef = firestore()
        .collection(CHAT_COLLECTION)
        .where("createdAt", ">=", Number(start))
        .where("createdAt", "<=", Number(end))
        .where("platform", "==", platform);
    }
    if (platform && admin) {
      restRef = firestore()
        .collection(CHAT_COLLECTION)
        .where("createdAt", ">=", Number(start))
        .where("createdAt", "<=", Number(end))
        .where("platform", "==", platform)
        .where("startBy", "==", admin);
    }
    const res = await restRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getAllAdminsByRole(role) {
    const riderRef = firestore()
      .collection(ADMIN_COLLECTION)
      .where("role", "==", role);

    const doc = await riderRef.get();

    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async getAdminRolesBychatSupport() {
    const riderRef = firestore()
      .collection(ADMINROLE_COLLECTION)
      .where("chatSupport", "==", true);

    const doc = await riderRef.get();

    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async updateChatSessionById(data, id) {
    const restRef = database().ref(CHAT_COLLECTION).child(id);
    const res = await restRef.update(data);
    return res ? null : { ...data, id: id };
  }
  async getSessionsById(id) {
    const restRef = firestore().collection(CHAT_COLLECTION).doc(id);
    const res = await restRef.get();
    return res.exists ? { ...res.data(), id: res.id } : null;
  }
  async getLiveSessionsById(id) {
    const restRef = database().ref(CHAT_COLLECTION).child(id);
    return restRef;
  }
  async removeSessionDatabase(id) {
    const restRef = database().ref(CHAT_COLLECTION).child(id);
    const res = await restRef.remove();
    return res ? null : { id };
  }
  async saveSessionfirebase(data, id) {
    const restRef = firestore().collection(CHAT_COLLECTION).doc(id);
    const res = await restRef.set(data);
    return res ? null : { ...data, id };
  }
  async addTarrif(data, docId) {
    const tarrifExist = [];
    await firestore()
      .collection(TARRIF_COLLECTION)
      .where("name", "==", data.name)
      .where("shift", "==", data.shift)
      // .where("city", "array-contains", data.city[0], data.city[1])
      .where("startDistance", "==", data.startDistance)
      .where("endDistance", "==", data.endDistance)
      .where("day", "array-contains-any", data.day)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          tarrifExist.push(doc.data());
        });
      });
    if (tarrifExist.length >= 1) {
      notification.error({
        message: `This distance already exist. New distance should start from: ${data.endDistance}`,
        placement: "top",
      });
      return `This distance already exists. New distance should start distance from: ${data.endDistance}`;
    } else {
      const cities = [];
      await firestore()
        .collection(CITIES_COLLECTION)
        .where("name", "==", data?.city[1])
        .where("shifts", "array-contains", data?.shift)
        // .where("shift", "", data.shift)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            cities.push(doc.data());
          });
        });

      if (data?.shift === "Day") {
        data.time = cities[0]?.time;
      } else {
        data.time = cities[0]?.time1;
      }

      // if (cities[0].shift1 === "Night") {
      //   data.time = cities[0].time1;
      // } else {
      //   data.time = cities[1].time1;
      // }
      const riderRef = firestore().collection(TARRIF_COLLECTION).doc(docId);
      const res = await riderRef.set(data);

      return res ? false : { ...data, id: docId };
    }
  }
  async getTarrif() {
    const adminRef = firestore()
      .collection(TARRIF_COLLECTION)
      .orderBy("name", "asc");

    const doc = await adminRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  async updateTarrif(data, docId) {
    const riderRef = firestore().collection(TARRIF_COLLECTION).doc(docId);
    const res = await riderRef.update(data);

    return res ? false : { ...data, id: docId };
  }

  async deleteTarrif(docId) {
    const riderRef = firestore().collection(TARRIF_COLLECTION).doc(docId);
    const res = await riderRef.delete();

    return res ? false : { id: docId };
  }

  async addRidePayment(data, docId){

    // console.log("in payment", data);

    const paymentRef = firestore().collection(PAYMENT_COLLECTION).doc(docId);
    const res = await paymentRef.set(data);

    return res ? false : { ...data, id: docId };
    
  }

  // Add Ride 
  async addRide(data, docId) {
    
    if (data?.drivers?.length === 0) {
      data.status = "pending";
    } else {
      // data.status = "driverAssigned";
      data.status = "requesting";
    }
    const riderRef = firestore().collection(RIDES_COLLECTION).doc(docId);

    const res = await riderRef.set(data);

    return res ? false : { ...data, id: docId };
  }


  async getHolidaySurcharge() {
    const adminRef = firestore().collection(HOLIDAY_SURCHARGE);

    const doc = await adminRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

    // get and sync all rides
    async getSyncRides() {

      const adminRef = firestore().collection(RIDES_COLLECTION).orderBy("date", "asc");

      return adminRef;

      // const handleSnapshot = (querySnapshot) => {
      //   const rides = [];
      //   querySnapshot.forEach((doc) => {
      //     rides.push({
      //       ...doc.data(),
      //       id: doc.id,
      //     });
      //   });
    
      //   dispatch({
      //     type: constants.GET_RIDE,
      //     payload: rides,
      //   });
  
      //   cb?.();
      // };
  
      // const handleError = (error) => {
      //   showErrorToast(error.message || error?.response?.message);
      //   cb?.(null);
      // };
    
      // adminRef.onSnapshot(handleSnapshot, handleError);
  }

  // get all rides
  async getRides() {
    const adminRef = firestore()
      .collection(RIDES_COLLECTION)
      .orderBy("date", "asc");

    const doc = await adminRef.get();
    
    return doc.empty
      ? []
      : doc.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }

  // get all payments
  async getPayments() {
      const adminRef = firestore().collection(PAYMENT_COLLECTION).orderBy("date", "asc");
  
      const doc = await adminRef.get();

      return doc.empty ? [] : doc.docs.map((e) => { 
        return {
              ...e.data(),
              id: e.id,
            };
        });
  }

  async deleteRide(docId) {
    const riderRef = firestore().collection(RIDES_COLLECTION).doc(docId);
    const res = await riderRef.delete();

    return res ? false : { id: docId };
  }

  async calculateCost(data) {
    // const costRef = firestore().collection(RIDES_COLLECTION).doc(docId);

    var myDate = new Date(data.date);
    var day = myDate.getDay();
    var finalDay = "";
    if (day === 0) {
      finalDay = "Sunday";
    } else if (day === 1) {
      finalDay = "Monday";
    } else if (day === 2) {
      finalDay = "Tuesday";
    } else if (day === 3) {
      finalDay = "Wednesday";
    } else if (day === 4) {
      finalDay = "Thursday";
    } else if (day === 5) {
      finalDay = "Friday";
    } else {
      finalDay = "Saturday";
    }
    let tarrifData = [];
    await firestore()
      .collection(TARRIF_COLLECTION)
      .where("day", "array-contains", finalDay)
      .where("startDistance", "<", data.estimatedDistance)
      .where("endDistance", ">", data.estimatedDistance)
      .where("time[0]", "<", data.time)
      .where("time[1]", ">", data.time)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          tarrifData.push(doc.data());
        });
      });

    // const res = await costRef.set(data);

    // return res ? false : { ...data, id: docId };
  }
  async saveBlog(data) {
    // console.log("data in api", data);
    const restRef = firestore().collection(BLOG_COLLECTION).doc();
    if (data?.featured) {
      const ref = firestore().collection(BLOG_COLLECTION);
      const getRes = await ref.get();
      getRes.docs.map(async (e) => {
        // console.log("data in api res", e.data());
        if (e.data().featured) {
          const ref1 = firestore().collection(BLOG_COLLECTION).doc(e.id);
          await ref1.set({ featured: false }, { merge: true });
        }
      });
    }
    const res = await restRef.set(data);
    return res ? false : true;
  }
  async getBlogs() {
    const restRef = firestore().collection(BLOG_COLLECTION);
    const res = await restRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
  }
  async deleteBlog(id) {
    // console.log("delete id in api", id);
    const restRef = firestore().collection(BLOG_COLLECTION).doc(id);
    const res = await restRef.delete();
    return res ? false : { id };
  }
  async updateBlog(id, data) {
    const restRef = firestore().collection(BLOG_COLLECTION).doc(id);
    if (data?.featured) {
      const ref = firestore().collection(BLOG_COLLECTION);
      const getRes = await ref.get();
      getRes.docs.map(async (e) => {
        if (e.data().featured) {
          const ref1 = firestore().collection(BLOG_COLLECTION).doc(e.id);
          await ref1.set({ featured: false }, { merge: true });
        }
      });
    }
    const res = await restRef.set({ ...data }, { merge: true });
    return res ? false : true;
  }
  async getBlogById(id) {
    const restRef = firestore().collection(BLOG_COLLECTION).doc(id);
    const res = await restRef.get();
    return res.exists ? { ...res.data(), id: res.id } : null;
  }
}
