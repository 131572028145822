import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getOrdersbyRider } from "../../redux/admin/action";

function Index({ rider }) {
  const [
    orders, setOrders
  ] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!rider) return;
    dispatch(
      getOrdersbyRider(
        rider?.id,
        moment().startOf("d").format("x"),
        moment().endOf("d").format("x"),
        (data) => {
          setOrders(data);
        }
      )
    );
  }, [
    rider, dispatch
  ]);
  return (
    <p className="fw-bold">
      Orders completed: 
      {" "}
      <span>
        {orders?.length}
      </span>
    </p>
  );
}

export default memo(Index);
