import { DrawingManager,GoogleMap } from "@react-google-maps/api"
import React from "react"

import { getRandomInRange } from "../../../utils/getDistance";
import GoogleZones from "./GoogleZones"

let lat = getRandomInRange(-180, 180, 3)
let lng = getRandomInRange(-180, 180, 3)
function Index({
  drawingMode,
  zoneType,
  position,
  onPolygonComplete,
  onCircleComplete,
  onRectangleComplete,
  onZoneUpdate,
  zone,
  zoneColor
}) {
  return (
    <GoogleMap
      mapContainerStyle={ {
        width: "100%",
        height: "500px",
        marginTop: "16px",
      } }
      center={ {
        lat: position?.latValue || lat,
        lng: position?.lngValue || lng,
      } }
      zoom={9}
    >
      {zone && (

        <GoogleZones
          drawingMode={drawingMode}
          zoneType={zoneType}
          zoneColor={zoneColor}
          zone={zone}
          position={position}
          onUpdate={onZoneUpdate}/>
      )}
      <DrawingManager
        drawingMode={drawingMode === "draw" ? zoneType:null}
        onOverlayComplete={({overlay})=>{
          overlay.setVisible(false)
        }}
        options={ {
          drawingControl:false,
          polygonOptions:{
            editable:false,
            visible: drawingMode === "draw"
          },
          circleOptions:{
            editable:false,
            visible: drawingMode==="draw"
          },
          rectangleOptions:{
            editable: false,
            visible: drawingMode === "draw"
          }
        } }
        onCircleComplete={onCircleComplete}
        onPolygonComplete={onPolygonComplete}
        onRectangleComplete={onRectangleComplete}  
      />
 
    
    </GoogleMap>
  )
}

export default Index