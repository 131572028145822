import { Spin } from "antd";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";

import { getOrderHistoryByDate , getRideHistoryByDate } from "../../../redux/admin/action";
import {
  filterLineCart,
  getDateRange,
  getLablesByCategory,
} from "../../../utils/dateRanges";

function Index({ date, title, type, Chart, city }) {

  const dispatch = useDispatch();
  const [currentLoader, setCurrentLoader] = useState(false);

  const [previousLoader, setPreviousLoader] = useState(false);

  const [dataa, setData] = useState(getLablesByCategory(type));

  const stopPrevoius = useCallback((data) => {
      setPreviousLoader(false);

      // console.log("Check stopPrevoius data", data);
      // console.log("Check stopPrevoius type", type);

      const value = filterLineCart(type, data);
      // console.log("Check stopPrevoius value", value);


      const dataObject = {
        label: "Prevoius",
        data: value?.map((e) => e.rides),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        hoverOffset: 4,
      };

      setData((p) => {
        return {
          ...p,
          datasets: [
            ...p.datasets, { ...dataObject }
          ],
        };
      });
    },
    [type]
  );
  const stopCurrent = useCallback((data) => {

      setCurrentLoader(false);

      // console.log("Check stopCurrent data", data);
      // console.log("Check stopCurrent type", type);

      const value = filterLineCart(type, data);
      // console.log("Check stopCurrent value", value);


      const dataObject = {
        label: "Current",
        data: value?.map((e) => e.rides),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        hoverOffset: 4,
      };

      setData((p) => {
        return {
          ...p,
          datasets: [
            ...p.datasets, { ...dataObject }
          ],
        };
      });
    },
    [type]
  );
  const [range, setRange] = useState(null);

  useEffect(() => {
    const data = getDateRange(type, date);

    // console.log("My rides data ==>", data);

    setRange(data);

    if (data?.prevoius && data.current) {
      setPreviousLoader(true);
      setCurrentLoader(true);
      dispatch(getRideHistoryByDate(data.prevoius, city, stopPrevoius));
      dispatch(getRideHistoryByDate(data.current, city, stopCurrent));
    }

    return () => setData(getLablesByCategory(type));

  }, [
    type, date, dispatch, stopPrevoius, stopCurrent, city
  ]);

  return (
    <Col lg={12}
      className=" shadow-lg bg-white p-0 rounded-lg ">
      <h3 className="fw-bold text-md admin_mainColor text-white p-3 capitalize rounded-t-lg">
        {title}
      </h3>
      <div className="w-full flex  justify-end  px-4">
        <div className="flex justify-start flex-col">
          <div className=" flex justify-start text-sm">
            <span className="fw-bold mx-2 flex items-center ">
              <FaCircle
                color="rgba(255, 99, 132, 0.5)"
                style={ { fontSize: 10, margin: 3 } }
              />
              Previous:
            </span>
            {moment(Number(range?.prevoius?.start)).format("DD-MMM-YYYY")}
            {" "}
              -
            {" "}
            {moment(Number(range?.prevoius?.end)).format("DD-MMM-YYYY")}
          </div>
          <div className=" flex justify-start text-sm">
            <span className="fw-bold mx-2 flex items-center ">
              <FaCircle
                color="rgba(53, 162, 235, 0.5)"
                style={ { fontSize: 10, margin: 3 } }
              />
              Current:
            </span>
            {moment(Number(range?.current?.start)).format("DD-MMM-YYYY")}
            {" "}
              -
            {" "}
            {moment(Number(range?.current?.end)).format("DD-MMM-YYYY")}
          </div>
        </div>
      </div>
      <Spin className="h-full"
        spinning={currentLoader || previousLoader}>
        <div className="h-80 p-3">
          <Chart
            data={dataa}
            options={ {
              responsive: true,
              maintainAspectRatio: false,
            } }
          />
        </div>
      </Spin>
    </Col>
  );
}
export default memo(Index);
