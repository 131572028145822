import "./index.css";

import { SearchOutlined } from "@ant-design/icons";
import { Input, Table } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PaymentInfoCard from "../../../components/PaymentInfoCard";

// import { getInvoicesByRiderId } from "../../../redux/auth/action";
import { getRiderEarnings , getDriverInvoices} from "../../../redux/admin/action";

function Index() {
  const [grid, setGrid] = useState(12);

  // Setting All Invoice Table
  const columns = [
    {
      title: "Invoice #",
      align: "center",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      fixed: "left",
    },
    {
      title: "From",
      align: "center",
      dataIndex: "from",
      key: "from",
      fixed: "left",
      render: (from) => {
        const date = new Date(parseInt(from)); // Convert Firebase timestamp to JavaScript Date object
        return moment(date).format("DD-MMM-YY/hh:mm A");
      },
    },

    {
      title: "To",
      align: "center",
      dataIndex: "to",
      key: "to",
      fixed: "left",
      render: (to) => {
        const date = new Date(parseInt(to)); // Convert Firebase timestamp to JavaScript Date object
        return moment(date).format("DD-MMM-YY/hh:mm A");
      },
    },
    {
      title: "Amount",
      align: "center",
      dataIndex: "amount",
      key: "amount",
      fixed: "left",
      render: (_, record) => (
        <>
          {" "}
          &pound;
          {record.amount || 0}
        </>
      ),
    },
    // {
    //   title: "Payement Mode",
    //   align: "center",
    //   dataIndex: "paymentMode",
    //   key: "paymentMode",
    //   fixed: "left",
    // },
    {
      title: "Status",
      align: "center",
      dataIndex: "paidStatus",
      key: "paidStatus",
      fixed: "left",
      render: (_, record) => (
        <>
          <span 
            style={{
              backgroundColor: record.paidStatus ? "green" : "red",
              color: "white",
              padding: "5px 15px 5px 15px",
              borderRadius: "5px",
            }}>
              {record.paidStatus ? "Paid" : "Pending"}
            </span>
        </>
      ),
    },
    {
      title: "Download",
      align: "center",
      dataIndex: "url",
      key: "url",
      fixed: "left",
      render: (_, record) => (
        <>
        { record.paidStatus 
          ? <>
            <a
              className="btn  btn-sm btn-warning fw-bold text-white "
              href={record.url}
              target="_blank"
              rel="noreferrer"
            >
              View
            </a>
          </> : <>
            {"Not Available"}
          </>
        }
        </>
      ),
    },
  ];

  const { driverInvoices } = useSelector((state) => state.admin);

  const [filterInvoices, setFilterInvoices] = useState([]);

  const [loader, setLoader] = useState(false);
  const stopLoader = () => {setLoader(false);};
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(getDriverInvoices(stopLoader));
  }, [dispatch]);

  const searchtable = (e) => {
    let filterbyorder = driverInvoices.filter((earning) =>
      earning.invoiceNumber.includes(e.target.value)
    );
    setFilterInvoices(filterbyorder);
  };
  const finalInvoices = filterInvoices?.length ? filterInvoices : driverInvoices;

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1 className="fw-bold">
        Earnings
          </h1>
        </Col>
        <div className="grid gap-x-8 gap-y-4 grid-cols-3">
          <PaymentInfoCard
            lg={grid}
            title={"Total Invoices"}
            // renderData={2}
            renderData={ driverInvoices?.length ?  driverInvoices?.length : 0 }
          />
          <PaymentInfoCard
            lg={grid}
            title={"Paid Invoices"}
            // renderData={2}
            renderData={  driverInvoices?.length ? driverInvoices.filter(x=>x.paidStatus === true).length : 0 }
          />
          <PaymentInfoCard
            lg={grid}
            // action={getLatestPendingOrders}
            title={"Pending Invoices"}
            // renderData={2}
            renderData={ driverInvoices?.length ? driverInvoices.filter(x=>x.paidStatus === false).length : 0 }
          />
         <br/>
         </div> 
        <Col lg={12}
          className="flex justify-end ">
          <Input
            onChange={searchtable}
            className="m-1"
            style={ { width: "20%" } }
            placeholder="invoice number"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Table
          bordered
          className="table-curved riderTable"
          columns={columns}
          dataSource={finalInvoices}
          pagination={false}
          loading={loader}
          scroll={driverInvoices?.length > 7 && { y: 400 }}
        />
      </Row>
    </Container>
  );
}

export default memo(Index);
