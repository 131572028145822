import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { getVehicleTypes } from "../../redux/admin/action";

function Index({ value, onChange , rideData }) {
  const [loader, setLoader] = useState(false);
  const { vehicleTypes } = useSelector((state) => state.admin);
  const [initialValue, setInitialValue] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(getVehicleTypes(() => setLoader(false)));
  }, [dispatch]);

  
  const vehicleTypeOptions = useMemo(() => {
    return vehicleTypes.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
  }, [vehicleTypes]);

  // console.log("Inside vehicle type", initialValue);
  // console.log("Vehicle type ====> ", rideData);

  useEffect(() => {
    if (value !== initialValue?.value) {
      onChange?.(initialValue?.value);
    }
  }, [initialValue, value, onChange]);

  useEffect(() => {
    if (value) {
      setInitialValue(vehicleTypeOptions?.find((e) => e.value === value));
    }
  }, [value, vehicleTypeOptions]);

  useEffect(() => {
    if (rideData && rideData.vehicleType) {
      setInitialValue(vehicleTypeOptions?.find((e) => e.value === rideData?.vehicleType));
    }
  }, [rideData, vehicleTypeOptions]);


  return (
    <Select
      isLoading={loader}
      className="w-100 h-12"
      value={initialValue}
      styles={ {
        control: (styles) => ({
          ...styles,
          height: "3rem",
        }),
      } }
      onChange={setInitialValue}
      options={vehicleTypeOptions}
      placeholder="Choose type"
      isSearchable={true}
      name="vehicleType"
      type="text"
    />
  );
}

export default memo(Index);
