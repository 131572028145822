import { Alert, Snackbar } from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import { Formik } from "formik";
import React, { memo, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Spinner,
} from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import { updateUserProfile } from "../../../redux/admin/action";
import { getAddress } from "../../../utils/addressChanger";
import updateUserFormValidation from "../../../validations/updateUserForm";

function Index() {
  const [
    updated, setUpdated
  ] = useState(false);
  const [
    autocomplet, setautocomplet
  ] = useState("");
  const onLoad = (autocomplete) => {
    setautocomplet(autocomplete);
  };
  const [
    loader, setLoader
  ] = useState(false);

  const dispatch = useDispatch();
  const { profileUser } = useSelector((state) => state.admin);
  const updateProfile = (e) => {
    setLoader(true);
    const data = {
      ...e,
      name: e.firstName + " " + e.lastName,
      slug: `${e.firstName + " " + e.lastName}`
        ?.replace(" ", "-")
        ?.toLowerCase(),
    };
    delete data.firstName;
    delete data.lastName;

    dispatch(updateUserProfile(data, stoploader));
  };
  const stoploader = (error) => {
    setLoader(false);
    if (error) {
      swal(error);
      return;
    }
    setUpdated(true);
  };
  const closeSnackBar = () => {
    setUpdated(false);
  };
  return (
    <Col lg={6}
      className="p-4">
      <Snackbar open={updated}
        autoHideDuration={2000}
        onClose={closeSnackBar}>
        <Alert
          variant="filled"
          onClose={closeSnackBar}
          severity="success"
          sx={ { width: "100%" } }
        >
          Profile Updated!
        </Alert>
      </Snackbar>
      <Formik
        initialValues={ {
          firstName: profileUser?.name?.split(" ")[0],
          lastName: profileUser?.name?.split(" ")[1],
          phone: profileUser?.phone,
          email: profileUser?.email,
          location: profileUser?.location,
          fullAddress: profileUser?.fullAddress,
        } }
        validationSchema={updateUserFormValidation}
        onSubmit={updateProfile}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form
            onSubmit={handleSubmit}
            className=" profile-from d-flex gap-2 justify-content-center flex-column align-items-center"
          >
            <div className="d-flex flex-row w-100 gap-2 ">
              <FormGroup className="w-50">
                <FormControl
                  placeholder="First Name"
                  aria-label="firstName"
                  aria-describedby="basic-addon1"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="firstName"
                  className="h-12 "
                />
                {touched.firstName && errors.firstName && (
                  <span className="w-100 text-danger ">
                    {errors.firstName}
                  </span>
                )}
              </FormGroup>
              <FormGroup className="w-50">
                <FormControl
                  placeholder="Last Name"
                  aria-label="Username"
                  className="h-12"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="lastName"
                  aria-describedby="basic-addon1"
                />
                {touched.lastName && errors.lastName && (
                  <span className="w-100 text-danger ">
                    {errors.lastName}
                  </span>
                )}
              </FormGroup>
            </div>
            <FormGroup className=" w-full">
              <FormControl
                placeholder="Email"
                value={values.email}
                className="h-12"
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
              />
              {touched.email && errors.email && (
                <span className="w-100 text-danger ">
                  {errors.email}
                </span>
              )}
            </FormGroup>
            <FormGroup className=" w-full">
              <PhoneInput
                defaultCountry="GB"
                name="phone"
                value={values?.phone}
                placeholder=" Phone"
                className="w-full h-12"
                onChange={(phone) => setFieldValue("phone", phone)}
              />
              {touched.phone && errors.phone && (
                <span className="w-100 text-danger ">
                  {errors.phone}
                </span>
              )}
            </FormGroup>
            <FormGroup className=" w-full">
              <Autocomplete
                className="w-full "
                onLoad={onLoad}
                restrictions={ { country: "GB" } }
                onPlaceChanged={async () => {
                  if (autocomplet !== null) {
                    const place = await autocomplet.getPlace();
                    const address = await getAddress(place);
                    setFieldValue("location", address);
                  }
                }}
              >
                <FormControl
                  placeholder="Enter Address"
                  aria-label="address"
                  className="h-12"
                  aria-describedby="basic-addon1"
                  value={values?.location?.address}
                  name="address"
                />
              </Autocomplete>
              {touched.location && errors.location && (
                <span className="w-100 text-danger ">
                  {errors.location}
                </span>
              )}
            </FormGroup>
            <FormGroup className=" w-full">
              <FormControl
                placeholder="Enter Full Address"
                aria-label="Username"
                value={values?.fullAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                name="fullAddress"
                className="h-12"
                aria-describedby="basic-addon1"
              />
              {touched.fullAddress && errors.fullAddress && (
                <span className="w-100 text-danger ">
                  {errors.fullAddress}
                </span>
              )}
            </FormGroup>
            <Button
              type="submit"
              className="rounded fw-bolder text-white rounded-2 mt-2"
              variant="warning"
              disabled={loader}
            >
              {loader ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="mr-2"
                    variant="dark"
                    size="sm"
                  />
                  Saving...
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Col>
  );
}

export default memo(Index);