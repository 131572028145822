import "./order.css";

import { Input, Table, Tag } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import OrderItem from "../../../modals/OrderItem";
import { getUserRides} from "../../../redux/user/action";
import { getAllVehicleTypes } from "../../../redux/admin/action";
import RideStatusTag from "../../../components/RideStatusTag/RideStatusTag";

const { Search } = Input;
function Index() {

  const { vehicleTypes  } = useSelector((state) => state.admin);
  const { rides } = useSelector((state) => state.user);

  const [loader, setLoader] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  const [finalRides, setFinalRides] = useState([]);

  // console.log("IUnside User Rideds ====>");

  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(getUserRides(stopLoader));
  }, [dispatch]);

    // Get all vehicle types
    useEffect(() => {
      dispatch(getAllVehicleTypes(stopLoader));
    }, [dispatch]);
  

    useEffect(() => {
      if (rides) {
        // const filteredRides = rides?.filter((ride) => {
        //   return ride.status === 'completed' || ride.status === 'cancelled';
        // });
    
        const updateVehicleTypeName = () => {
          const updatedRides = rides.map((ride) => {
            const vehicleTypeId = ride.vehicleType;
            const vehicleType = vehicleTypes.find((type) => type.id === vehicleTypeId);
            const vehicleTypeName = vehicleType ? vehicleType?.name : ride?.vehicleType || 'Unknown';
    
            return { ...ride, vehicleType: vehicleTypeName };
          });
    
          let finalSortedRides = updatedRides.sort((a, b) => {
            if (a.date === b.date) {
              return a.time > b.time ? -1 : 1;
            } else if (b.date > a.date) {
              return 1;
            } else {
              return -1;
            }
          });
    
          setFinalRides(finalSortedRides);
        };
    
        updateVehicleTypeName();
      }
    }, [rides, vehicleTypes]);


  const stopLoader = () => {setLoader(false);};

  const [modalShow, setModalShow] = useState(false);
  const [items] = useState(null);

  // console.log(" All user rides ==> " , rides);

  const columns = [
    {
      title: "Ride Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      className: "capitalize",
      width: 150,
      render: (status) => <RideStatusTag status={status} />
    },
    {
      title: "Ride #",
      align: "center",
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 120,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    {
      title: "Ride Date",
      align: "center",
      dataIndex: "when",
      key: "when",
      className: "capitalize",
      width: 150,
      render: (_, record) => record.date,
    },
    {
      title: "Pickup time",
      align: "center",
      dataIndex: "when",
      key: "when",
      className: "capitalize",
      width: 150,
      render: (_, record) => record.time,
    },
    // {
    //   title: "Rider",
    //   align: "center",
    //   dataIndex: "name",
    //   key: "name",
    //   width: 220,
    //   filteredValue: [searchedText],
    //   onFilter: (value, record) => {
    //     return (
    //       String(record.name).toLowerCase().includes(value.toLowerCase()) ||
    //       String(record.status).toLowerCase().includes(value.toLowerCase()) ||
    //       String(record.vehicleType)
    //         .toLowerCase()
    //         .includes(value.toLowerCase()) ||
    //       String(record.origin?.address)
    //         .toLowerCase()
    //         .includes(value.toLowerCase()) ||
    //       String(record.destination?.address)
    //         .toLowerCase()
    //         .includes(value.toLowerCase()) ||
    //       String(record.phone).toLowerCase().includes(value.toLowerCase()) ||
    //       String(record.orderNumber)
    //         .toLowerCase()
    //         .includes(value.toLowerCase()) ||
    //       String(record.id.toString().split("-")[0])
    //         .toLowerCase()
    //         .includes(value.toLowerCase())
    //     );
    //   },
    //   render: (name) => {
    //     return `${name}`;
    //   },
    // },
    {
      title: "Pickup location",
      align: "center",
      dataIndex: "origin",
      key: "origin",
      className: "capitalize",
      width: 120,
      render: (origin) => origin?.address,
    },
    {
      title: "Additional Stops",
      align: "center",
      dataIndex: "stops",
      key: "stops",
      className: "capitalize",
      width: 120,
      render: (_, record) => (
        <>
          {record?.stops.length === 0 
            ? ( "-" ) 
            : (
              <>
                {
                  record?.stops.map((stop) => (
                    <div>{stop.address}</div>
                  ))
                }
              </>
              ) 
          }
        </>
      ),
    },
    {
      title: "Destination",
      align: "center",
      dataIndex: "destination",
      key: "destination",
      className: "capitalize",
      width: 120,
      render: (destination) => destination?.address,
    },
    {
      title: "Driver",
      align: "center",
      dataIndex: "drivers",
      key: "drivers",
      width: 220,
      render: (drivers) => {
        return Array.isArray(drivers) ? ( drivers.map((driver) => (<div>{driver.label}</div>)) ) : ("Not Provided");
      },
    },
    {
      title: "Type of vehicle",
      align: "center",
      dataIndex: "vehicleType",
      key: "vehicleType",
      width: 120,
      className: "capitalize",
    },
    {
      title: "No of Bags",
      align: "center",
      dataIndex: "noOfBags",
      key: "noOfBags",
      width: 220,
      render: (noOfBags) => {
        return noOfBags;
      },
    },
    {
      title: "No of Luggages",
      align: "center",
      dataIndex: "noOfLuggage",
      key: "noOfLuggage",
      width: 220,
      render: (noOfLuggage) => {
        return noOfLuggage;
      },
    },
    {
      title: "No of Passengers",
      align: "center",
      dataIndex: "noOfPass",
      key: "noOfPass",
      width: 220,
      render: (noOfPass) => {
        return noOfPass;
      },
    },
    {
      title: "Paid Status",
      align: "center",
      dataIndex: "paid",
      key: "paid",
      width: 120,
      className: "capitalize",
      render: (e) => (e ? "Paid" : "Pending"),
    },
    {
      title: "Fare",
      align: "center",
      dataIndex: "cost",
      key: "cost",
      width: 120,
      render: (_, record) => (
        <>
          &pound;
          {record.cost ||  0}
        </>
      ),
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h2 className="fw-bold">
            User Rides History
          </h2>
        </Col>
        <Col lg={12}
          className="gap-3">
          <Search
            placeholder="Search rides..."
            allowClear
            size="large"
            onSearch={(value) => setSearchedText(value)}
            onChange={(e) => setSearchedText(e.target.value)}
            style={ {
              width: 300,
              marginBottom: "10px",
              float: "right",
            } }
          />
          <Table
            bordered
            className="table-curved riderTable"
            columns={columns}
            dataSource={finalRides}
            pagination={false}
            loading={loader}
            scroll={ { x: 900, y: 400 } }
          />
        </Col>
      </Row>
      {modalShow && (
        <OrderItem
          items={items}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </Container>
  );
}

export default memo(Index);
