import { Empty, Spin, Tooltip } from "antd";
import { Chat } from "boomaeats-chat";
import moment from "moment";
import queryString from "query-string";
import React, { memo, useEffect, useMemo } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";

import {
  getLiveSessionsById,
  getSessionsById,
  saveSession,
  updateChatSessionById,
} from "../../redux/admin/action";
import AuthApi from "../../redux/auth/authApi";


function Index() {
  const { id } = useParams();
  const location = useLocation();

  const query = queryString.parse(location.search);

  const sessionType = query?.sessionType;
  const [
    selectedSession, setSelectedSession
  ] = React.useState(null);

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const chats = useMemo(() => {
    return selectedSession?.chats;
  }, [selectedSession]);
  const users = useMemo(() => {
    return selectedSession?.users;
  }, [selectedSession]);
  const onTypeHandle = (typing) => {
    const newSession = {
      ...selectedSession,
      chats: selectedSession.chats.map((chat) => {
        if (chat.senderId !== user.id) {
          return {
            ...chat,
            status: "SEEN",
          };
        }
        return chat;
      }),
      users: selectedSession.users.map((e) => {
        if (e.id === user.id) {
          return {
            ...e,
            typing,
          };
        }
        return e;
      }),
    };

    dispatch(
      updateChatSessionById(newSession, selectedSession.id, (data) => {
        setSelectedSession({
          ...selectedSession,
          ...data,
        });
      })
    );
  };

  const onSendHandle = async (e) => {
    if (selectedSession?.startBy && !users?.find((e) => e.id === user.id)) {
      swal(
        "",
        "You can`t message this session is already assign to someone other",
        "error"
      );
      return;
    }

    const files = e?.chat?.files?.map((e) => e.file);
    const authApi = new AuthApi();
    let imageUrls = [];
    if (!selectedSession?.startAt) {
      if (window.confirm("Are you sure you want to start this session?")) {
        imageUrls = await authApi.uploadFile(files);
        const newSession = {
          startAt: moment().format("x"),
          startBy: user.id,
          users: [
            ...selectedSession.users,
            {
              id: user.id,
              name: user.name,
              image: user.photoURL || "",
              number: user.phone || 1234,
              email: user.email,
              typing: false,
              role: user.role,
            },
          ],
          chats: [
            ...selectedSession.chats, { ...e.chat, files: imageUrls }
          ],
        };
        dispatch(
          updateChatSessionById(newSession, selectedSession.id, (data) => {
            setSelectedSession({
              ...selectedSession,
              ...data,
            });
          })
        );
      }

      return;
    }
    imageUrls = await authApi.uploadFile(files);

    dispatch(
      updateChatSessionById(
        {
          chats: [
            ...selectedSession.chats, { ...e.chat, files: imageUrls }
          ],
          users: selectedSession.users.map((e) => {
            if (e.id === user.id) {
              return { ...e, typing: false };
            }
            return e;
          }),
        },
        selectedSession.id,
        (data) => {
          setSelectedSession({
            ...selectedSession,
            ...data,
          });
        }
      )
    );
  };
  const [
    loader, setLoader
  ] = React.useState(true);
  const stopLoader = (data) => {
    setLoader(false);
    if (data) {
      setSelectedSession(data);
    }
  };

  useEffect(() => {
    if (id) {
      setLoader(true);
      if (sessionType === "previous") {
        dispatch(getSessionsById(id, stopLoader));
        return;
      }
      dispatch(getLiveSessionsById(id, stopLoader));
    }
  }, [
    id, dispatch, sessionType
  ]);
  const closeSession = async () => {
    const value = await swal("Do you want to end this Session?", {
      buttons: {
        cancel: true,
        confirm: true,
      },
      icon: "info",
    });
    if (!value) {
      return;
    }
    setLoader(true);
    dispatch(
      updateChatSessionById(
        {
          endAt: moment().format("x"),
          endBy: user.id,
        },
        selectedSession.id,
        (data) => {
          dispatch(
            saveSession(
              {
                ...selectedSession,
                ...data,
              },
              selectedSession.id,
              (res) => {
                setSelectedSession(res);
                setLoader(false);
              }
            )
          );
        }
      )
    );
  };
  const sender = users?.find((e) => e.id === selectedSession?.createdBy);

  return (
    <Spin spinning={loader}>
      <div className=" h-screen ">
        <div className="flex p-2 shadow-lg border-b fixed w-full bg-white  ">
          <h1 className="text-sm text-center flex-grow m-0 fw-bold capitalize  ">
            {sessionType}
            {" "}
Support Session (
            {sender?.name}
            {" "}
-
            {" "}
            {selectedSession?.platform}
)
          </h1>
          {selectedSession &&
            !selectedSession?.endAt &&
            users?.find((e) => e.id === selectedSession?.startBy) && (
            <div className="  cursor-pointer text-red-600">
              <Tooltip placement="topRight"
                title={"End & Save Session"}>
                <FaSignOutAlt onClick={closeSession} />
              </Tooltip>
            </div>
          )}
        </div>
        {selectedSession ? (
          <Chat
            containerStyle={ {
              background: "white",
              paddingTop: 30,
            } }
            chats={chats}
            users={users}
            session={selectedSession}
            sender={users?.find((e) => e.id === selectedSession?.startBy)?.id ||
              user.id}
            soundUrl={"https://firebasestorage.googleapis.com/v0/b/boomaeats-d8c74.appspot.com/o/audio%2Fmixkit-positive-notification-951.wav?alt=media&token=3bd4a9a7-24fa-4159-9e5f-581c23af2225"}
            onTyping={onTypeHandle}
            onSend={onSendHandle}
          />
        ) : (
          <Empty description="No chat found" />
        )}
      </div>
    </Spin>
  );
}

export default memo(Index);
