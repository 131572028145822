import React, { memo } from "react";
import { FaPaperclip } from "react-icons/fa";

function Index({ name, onChange, multiple }) {
  return (
    <div className={"navigo-file-box btn  py-0 mx-1 btn-outline-secondary"}>
      <input
        name={name}
        type="file"
        onChange={onChange}
        hidden
        multiple={multiple}
        id={name}
      />
      <label
        style={ {
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        } }
        className="flex "
        htmlFor={name}
      >
        <FaPaperclip />
        <span className="mx-1">
Attach file&apos;s
        </span>
      </label>
    </div>
  );
}

export default memo(Index);
