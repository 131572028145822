import "./index.css";

import { Button, Calendar, Spin } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import AddRiderAgenda from "../../../modals/addRiderAgenda";
import UpdateRiderSchedule from "../../../modals/updateRiderSchedule";
import { getRiderSchedules } from "../../../redux/rider/action";

function Index() {
  const { schedules } = useSelector((state) => state.rider);
  const { profileRider } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);

  useEffect(() => {
    setLoader(true);
    dispatch(getRiderSchedules(stopLoader));
  }, [dispatch]);

  const stopLoader = () => {
    setLoader(false);
  };

  const [
    selectedDate, setSelectedDate
  ] = useState(moment());
  const [
    show, setShow
  ] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  const [
    showSchedule, setShowSchedule
  ] = useState(false);

  const handleCloseSchedule = () => setShowSchedule(false);
  const handleShowSchedule = () => setShowSchedule(true);

  return (
    <Container fluid>
      <Row className="gap-3">
        <Col lg={12}>
          <h1 className="fw-bold">
Schedules
          </h1>
        </Col>
        <Col lg={12}
          className="flex justify-end">
          <Button
            type="primary"
            className="fw-bold"
            onClick={handleShowSchedule}
          >
            Add Schedule
          </Button>
        </Col>
        <Spin spinning={loader}>
          <Col lg={12}>
            <Row className="   bg-white rounded-lg shadow-md p-0 ">
              <Col className="rider_mainColor rounded-t-lg"
                lg={12}>
                <h1 className="text-lg fw-bold text-white p-2">
                  Schedules (
                  {moment(selectedDate?.currentDate).format("MMM YYYY")}
)
                </h1>
              </Col>
              <Calendar
                value={selectedDate?.currentDate}
                onSelect={(value) => {
                  setSelectedDate({ currentDate: value });
                  if (moment(value).isBefore(moment(), "day")) return;
                  if (moment(value).isSame(moment(), "month")) {
                    handleShow();
                    let agendaTime =
                      schedules?.find((e) =>
                        moment(Number(e?.date)).isSame(moment(value), "day")
                      )?.time ||
                      profileRider?.scheduleTime?.[
                        moment(value).format("dddd").toLowerCase()
                      ];

                    setSelectedDate({
                      currentDate: value,
                      time: agendaTime,
                      isWorking: schedules?.find((e) =>
                        moment(Number(e?.date)).isSame(moment(value), "day")
                      )?.isWorking,
                    });
                  }
                }}
                disabledDate={(current) => {
                  return (
                    moment().add(-1, "days") >= current ||
                    moment().add(1, "month") <= current
                  );
                }}
                dateCellRender={(value) => {
                  let schedule = schedules.find((e) =>
                    moment(Number(e.date)).isSame(moment(value), "day")
                  );

                  if (moment(value).isBefore(moment(), "month")) {
                    return null;
                  }

                  if (schedule) {
                    return (
                      <div className="p-2">
                        {schedule?.time?.map((e, index) => {
                          let text = index === 0 ? "Start" : "End";

                          return (
                            <p
                              key={index}
                              className={`text-sm m-0 ${
                                !schedule?.isWorking && "text-red-500"
                              } ${
                                moment(value).isBefore(
                                  moment(selectedDate?.currentDate),
                                  "month"
                                )
                                  ? " font-thin"
                                  : "font-bold"
                              }`}
                            >
                              {text}
                              {" "}
:
                              {e}
                            </p>
                          );
                        })}
                      </div>
                    );
                  }

                  if (
                    profileRider?.scheduleTime?.[
                      moment(value).format("dddd").toLowerCase()
                    ]
                  ) {
                    return (
                      <div className="p-2">
                        {profileRider?.scheduleTime?.[
                          moment(value).format("dddd").toLowerCase()
                        ]?.map((e, index) => {
                          let text = index === 0 ? "Start" : "End";
                          return (
                            <p
                              key={index}
                              className={`text-sm m-0 ${
                                moment(value).isBefore(
                                  moment(selectedDate?.currentDate),
                                  "month"
                                )
                                  ? " font-thin"
                                  : "font-bold"
                              }`}
                            >
                              {text}
                              {" "}
:
                              {e}
                            </p>
                          );
                        })}
                      </div>
                    );
                  }
                }}
              />
              ,
            </Row>
          </Col>
        </Spin>
      </Row>
      {show && (
        <UpdateRiderSchedule
          selectedDate={selectedDate}
          show={show}
          onHide={handleClose}
        />
      )}
      {showSchedule && (
        <AddRiderAgenda show={showSchedule}
          onHide={handleCloseSchedule} />
      )}
    </Container>
  );
}

export default memo(Index);
