import "./index.css";
import { Autocomplete } from "@react-google-maps/api";
import _ from "lodash";
import React, { memo, useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { FiMapPin } from "react-icons/fi";
import { getAddress } from "../../utils/addressChanger";
import MapPopup from "./MapPopup";

function Index({
  value,
  onPlaceChange,
  onChange,
  onBlur,
  placeholder,
  name,
  className,
  restrictions,
  rideData, // New prop for rideData
}) {

  // console.log("GoogleAutoComplete value ====> ", value)
  const [initialValue, setInitialValue] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [autoComplete, setAutoComplete] = useState(null);

  const onLoad = (value) => {
    setAutoComplete(value);
  };

  const onPlaceChangeHandler = () => {
    if (autoComplete) {
      const place = autoComplete.getPlace();
      const address = getAddress(place);
      setInitialValue(address);
      onPlaceChange(address);
    }
  };
  
  // useEffect(() => {
  //   if (!_.isEmpty(value)) {
  //     setInitialValue(value);
  //   }
  // }, [value]);

  // useEffect(() => {
  //   if (rideData) {
  //     setInitialValue(rideData); // Use rideData when available
  //   }
  // }, [rideData]);

  useEffect(() => {
    if (rideData && rideData.origin) {
      setInitialValue(rideData.origin);
    } else if (!value?.address) {
      setInitialValue(null);
    }
  }, [value, rideData]);
  

  const onDragEndHandler = (value) => {
    setInitialValue(value);
    onPlaceChange(value);
  };

  return (
    <div className={`${className} navigo-autocomplete`}>
      <Autocomplete
        onLoad={onLoad}
        restrictions={restrictions}
        onPlaceChanged={onPlaceChangeHandler}
      >
        <InputGroup>
          <FormControl
            placeholder={placeholder}
            name={name}
            value={value?.address !== "" ? value?.address : ""}
            className="h-12"
            onChange={onChange}
            onBlur={onBlur}
          />
          <InputGroup.Text>
            <button type="button"
              onClick={() => setShowMap(!showMap)}>
              <FiMapPin />
            </button>
          </InputGroup.Text>
        </InputGroup>
      </Autocomplete>
      {showMap && (
        <MapPopup value={initialValue} onPlaceChange={onDragEndHandler} />
      )}
    </div>
  );
}

export default memo(Index);
