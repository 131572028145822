import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, List, Radio, Space, Spin } from "antd";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import ChatItems from "../../components/chatSupport/ChatItems";
import DropDown from "../../components/dropdown";
import AddChatSchedule from "../../modals/addchatschedule";
import {
  getAdminRolesBychatSupport,
  getAllAdminsByRoles,
  getAllChatSessionsFirebaseByDateAndAdminPlat,
} from "../../redux/admin/action";

const platforms = [
  "Drivers", "Riders"
];
function Index() {
  const [
    sessions, setSessions
  ] = React.useState([]);
  const [
    filterdSessions, setFilteredSessions
  ] = React.useState([]);

  const [
    loader, setLoader
  ] = React.useState(false);

  const stopLoader = (data) => {
    setLoader(false);
    if (data?.length) {
      setSessions(data);
    } else {
      setSessions([]);
    }
  };
  const dispatch = useDispatch();
  const [
    date, setDate
  ] = React.useState([
    moment(new Date()).startOf("d"),
    moment(new Date()).endOf("d"),
  ]);
  const [
    platform, setPlatform
  ] = React.useState(null);
  const [
    admin, setAdmin
  ] = React.useState(null);
  const [
    admins, setAdmins
  ] = React.useState([]);
  const getAllAdminsByRoleHandler = useCallback(
    (data) => {
      dispatch(
        getAllAdminsByRoles(data, (res) => {
          setAdmins(res);
        })
      );
    },
    [dispatch]
  );
  useEffect(() => {
    dispatch(getAdminRolesBychatSupport(getAllAdminsByRoleHandler));
  }, [
    dispatch, getAllAdminsByRoleHandler
  ]);

  useEffect(() => {
    setLoader(true);
    dispatch(
      getAllChatSessionsFirebaseByDateAndAdminPlat(
        {
          start: moment(date[0]).startOf("d").format("x"),
          end: moment(date[1]).endOf("d").format("x"),
        },
        admin,
        platform,
        stopLoader
      )
    );
  }, [
    dispatch, date, admin, platform
  ]);

  const { RangePicker } = DatePicker;
  const setDateHandle = (e) => {
    if (!e) return;
    setDate(e);
  };
  const searchtable = (e) => {
    if (e.target.value) {
      let filterbyorder = sessions.filter((session) => {
        const users = session?.users?.map((e) => e.name);

        return users?.includes(e.target.value);
      });

      setFilteredSessions(filterbyorder);
    } else {
      setFilteredSessions([]);
    }
  };
  const [
    showSchedule, setShowSchedule
  ] = useState(false);
  const finalOrder = filterdSessions?.length > 0 ? filterdSessions : sessions;
  const handleCloseSchedule = () => setShowSchedule(false);
  const handleShowSchedule = () => setShowSchedule(true);
  return (
    <Container fluid
      className=" w-100  pb-5">
      <Row>
        <Col lg={12}
          className=" pl-0 ">
          <h2 className="fw-bold">
            Chat History
          </h2>
        </Col>
        <Col lg={12}
          className="flex justify-end p-0">
          <Button
            type="primary"
            className="fw-bold"
            onClick={handleShowSchedule}
          >
            Add Schedule
          </Button>
        </Col>
        <Col lg={12}
          className="flex justify-end my-2 ">
          <Row className="gap-2 w-75 ">
            <Col lg={3}
              className="p-0 ">
              <DropDown
                type={admin
                  ? `Admin- ${admins.find((e) => e.id === admin)?.name}`
                  : "All Admins"}
              >
                <div className="bg-white w-56 shadow-lg p-2 ">
                  <Radio.Group
                    buttonStyle="solid"
                    value={admin ? admin : "ALL"}
                    onChange={(e) => {
                      if (e.target.value === "ALL") {
                        setAdmin(null);
                        return;
                      }
                      setAdmin(e.target.value);
                    }}
                  >
                    <Space direction="vertical">
                      <Radio
                        className=" text-base fw-bold capitalize"
                        value={"ALL"}
                      >
                        All
                      </Radio>
                      {admins?.map((value, index) => (
                        <Radio
                          className=" text-base fw-bold "
                          key={index}
                          value={value?.id}
                        >
                          {value?.name}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </div>
              </DropDown>
            </Col>
            <Col lg={3}
              className="p-0 ">
              <DropDown
                type={platform && platform !== "ALL"
                  ? `Platform- ${platform}`
                  : "All Platforms"}
              >
                <div className="bg-white w-56 shadow-lg p-2 ">
                  <Radio.Group
                    buttonStyle="solid"
                    value={platform ? platform : "ALL"}
                    onChange={(e) => {
                      if (e.target.value === "ALL") {
                        setPlatform(null);
                        return;
                      }
                      setPlatform(e.target.value);
                    }}
                  >
                    <Space direction="vertical">
                      <Radio
                        className=" text-base fw-bold capitalize"
                        value={"ALL"}
                      >
                        All
                      </Radio>
                      {platforms.map((value, index) => (
                        <Radio
                          className=" text-base fw-bold capitalize"
                          key={index}
                          value={value}
                        >
                          {value}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </div>
              </DropDown>
            </Col>
            <Col lg={4}
              className="p-0">
              <RangePicker
                className="w-full"
                format="DD MMM YYYY"
                value={date}
                onChange={setDateHandle}
              />
            </Col>
            <Col className="p-0">
              <Input
                onChange={searchtable}
                placeholder="User name"
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={12}
          className="p-0 ">
          <Spin spinning={loader}>
            <List
              dataSource={finalOrder}
              grid={ { gutter: 16, column: 2 } }
              renderItem={(item) => (
                <ChatItems chatType="previous"
                  showRead={false}
                  item={item} />
              )}
            ></List>
          </Spin>
        </Col>
      </Row>
      {showSchedule && (
        <AddChatSchedule show={showSchedule}
          onHide={handleCloseSchedule} />
      )}
    </Container>
  );
}

export default memo(Index);
