import { DatePicker } from "antd";
import moment from "moment";
import React, { memo, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";

import { getPaidOrderByTime , getPaidRidesByTime } from "../../../../redux/admin/action";
import Tr from "./tr";

function Index() {
  const [invoiceData, setinvoiceData] = useState({
    from: moment().format("DD-MMM-YYYY"),
    to: moment().format("DD-MMM-YYYY"),
  });

  const [data, setdata] = useState([]);
  const [errors, seterrors] = useState([]);

  var headers = [
    { label: "Ride date", key: "rideDate" },
    { label: "Ride #", key: "rideNumber" },
    { label: "Driver", key: "driver" },
    { label: "Passenger", key: "passenger" },
    { label: "Completed At", key: "completedAt" },
    { label: "Fare", key: "fare" },
    { label: "Platform fee", key: "Platformfee" },
    { label: "Total Paid", key: "total" },
    { label: "Driver Percentage", key: "driverPercentage" },
    { label: "Driver payment", key: "driverPayment" },
    { label: "Navigo payment", key: "navigoPayment" },
    { label: "Status", key: "rideStatus" },
    // { label: "Vehicle Type", key: "vehicleType" },

    // { label: "Restaurant`s", key: "Restaurant" },
    // { label: "Rider`s", key: "Rider" },
    // { label: "Rider tip", key: "Ridertip" },
    // { label: "Client`s", key: "Client" },
    // { label: "Voucher", key: "Voucher" },

    // { label: "Deductions", key: "Deductions" },
    // { label: "Delivery fee", key: "Deliveryfee" },
    // { label: "Service fee", key: "Servicefee" },
    // { label: "Platform fee", key: "Platformfee" },
    // { label: "Order fee", key: "Orderfee" },
    // { label: "Smallorder fee", key: "Smallorder" },
    // { label: "Total paid", key: "Totalpaid" },
    // { label: "Income", key: "Income" },
    // { label: "Completed at", key: "CompletedAt" },
  ];

  const inputChange = (name) => (value) => {
    if (!value) {
      return;
    }
    setinvoiceData((p) => ({ ...p, [name]: value }));
  };
  const [loader, setLoader] = useState(false);

  const stopLoader = (data) => {
    // console.log("Check ride data", data);
    setLoader(false);
    if (data?.length) {
      setdata(
        data?.map((e) => {
          const rideNumber = e?.id.toString().split("-")[0];
          const rideDate = e?.date;
          const driverName = e?.drivers.length === 0  ? ("-") : ( e.drivers.map((driver) => (driver.label)));
          const passengerName = e?.passenger?.name;
          const completedAt = moment(e?.updatedAt).format("DD-MMM-YY/hh:mm");
          const fare = (parseFloat(e?.cost || 0).toFixed(2));
          const platformfee = (parseFloat(e?.platformFee || 0).toFixed(2));
          const totalpaid = (parseFloat(e?.riderTake || 0).toFixed(2));
          const driverPercentage = (parseFloat(e?.driverPercentage || 0).toFixed(2));
          const driverPayment = (parseFloat(e?.driverTake || 0).toFixed(2));

          const subtotal = (parseFloat(fare) - parseFloat(driverPayment));
          const fromRider = (parseFloat(totalpaid) - parseFloat(fare));

          const navigoPayment = (parseFloat(subtotal) + parseFloat(fromRider)).toFixed(2);

          const rideStatus = e?.status;

          // const subTotal = parseFloat(e?.subtotal) || 0.0;
          // const additionalfee = parseFloat(e?.additionalfee) || 0.0;
          // const ownRiderdeliveryfree =
          //   e?.restaurant?.deliverby?.rider === "ownriders" &&
          //   e?.type === "delivery"
          //     ? parseFloat(e?.deliveryfree)
          //     : 0.0;
          // const boomaRiderdeliveryfree =
          //   e?.restaurant?.deliverby?.rider === "boomariders" &&
          //   e?.type === "delivery"
          //     ? parseFloat(e?.riderdetail?.riderfee) || 0
          //     : 0.0;

          // const restDeduction =
          //   e?.deduction?.from === "restaurant"
          //     ? parseFloat(e?.deduction?.fee)
          //     : 0.0;
          // const riderDeduction =
          //   e?.deduction?.from === "rider"
          //     ? parseFloat(e?.deduction?.fee)
          //     : 0.0;
          // const riderTip = parseFloat(e?.ridertip).toFixed(2) || 0.0;

          // const restPay =
          //   subTotal -
          //   platfee +
          //   additionalfee +
          //   ownRiderdeliveryfree -
          //   restDeduction;

          // const riderPay =
          //   e?.restaurant?.deliverby?.rider === "boomariders" &&
          //   e?.type === "delivery"
          //     ? boomaRiderdeliveryfree + riderTip - riderDeduction
          //     : 0.0;
          // const totalIncome = e.total - restPay - riderPay;

          return {
            rideDate: rideDate,
            rideNumber: rideNumber,
            driver: driverName,
            passenger: passengerName,
            completedAt: completedAt,
            fare: fare,
            platformfee: platformfee,
            total: totalpaid,
            driverPercentage: driverPercentage,
            driverPayment: driverPayment,
            navigoPayment: navigoPayment,
            rideStatus: rideStatus,

            // Restaurant: e?.restaurant?.restInformation?.name,
            // Rider: e?.riderdetail?.name || "Own rider",
            // Ridertip: riderTip,
            // Client: e?.clientInfo?.name,
            // Voucher: parseFloat(e?.voucher || 0.0).toFixed(2),
            // Deductions: e?.deduction
            //   ? ` ${e?.deduction?.from}(${parseFloat(e?.deduction?.fee).toFixed(
            //     2
            //   )})`
            //   : "No Deductions",
            // Deliveryfee:
            //   e?.type === "delivery"
            //     ? parseFloat(e?.deliveryfree).toFixed(2)
            //     : 0.0,
            // Servicefee: parseFloat(e?.service).toFixed(2),
            // Platformfee: parseFloat(e?.platformfee).toFixed(2),
            // Orderfee: parseFloat(e?.subtotal).toFixed(2),
            // Smallorder: e?.smallorder
            //   ? parseFloat(e?.smallorder).toFixed(2)
            //   : 0.0,
            // type: e?.type,
            // Totalpaid: parseFloat(e?.total).toFixed(2),
            // RestaurantPayment: parseFloat(restPay).toFixed(2),
            // RiderPayment: riderPay,
            // Income: parseFloat(totalIncome).toFixed(2),
            // CompletedAt: `${moment(
            //   e?.riderDeliveredTime || e?.completedAt
            // ).format("DD-MMM-YY/hh:mm")}`,
          };
        })
      );
    }
  };
  const dispatch = useDispatch();
  const gerenateInvoice = (e) => {
    e.preventDefault();
    let error = [];

    if (invoiceData.from === "") {
      error.push("fromEmpty");
    }
    if (invoiceData.to === "") {
      error.push("toEmpty");
    } else if (
      invoiceData.from !== "" &&
      moment(invoiceData.to).isBefore(moment(invoiceData.from))
    ) {
      error.push("tomustless");
    }

    if (error.length) {
      seterrors(error);
    } else {
      seterrors([]);
      dispatch(
        getPaidRidesByTime(
          moment(invoiceData.from).startOf("day").format("x"),
          moment(invoiceData.to).endOf("day").format("x"),
          stopLoader
        )
      );
    }
  };
  return (
    <Container fluid
      style={ { minHeight: "300px" } }>
      <Row>
        <Col lg={12}
          className="bg-white shadow-lg p-3">
          <Row>
            <Col lg={12}>
              <Form
                className="d-flex flex-wrap gap-2 justify-content-start align-items-center"
                onSubmit={gerenateInvoice}
              >
                <Form.Group
                  className=" d-flex flex-column align-items-start justify-content-center"
                  controlId="formBasicFrom"
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <Form.Label className="w-12">
                      From:
                    </Form.Label>
                    <DatePicker
                      name="from"
                      format="DD-MMM-YYYY"
                      value={moment(invoiceData.from)}
                      onChange={inputChange("from")}
                    />
                  </div>
                  {errors.includes("fromEmpty") && (
                    <span
                      className="w-100 text-center text-danger"
                      style={ { fontSize: "12px" } }
                    >
                      Choose from date
                    </span>
                  )}
                </Form.Group>
                <Form.Group
                  className=" d-flex flex-column align-items-start justify-content-center"
                  controlId="formBasicto"
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <Form.Label className="w-6">
                    To:
                    </Form.Label>
                    <DatePicker
                      name="to"
                      format="DD-MMM-YYYY"
                      value={moment(invoiceData.to)}
                      onChange={inputChange("to")}
                    />
                  </div>
                  {errors.includes("toEmpty") && (
                    <span
                      className="w-100 text-center text-danger"
                      style={ { fontSize: "12px" } }
                    >
                      Choose to date
                    </span>
                  )}
                  {errors.includes("tomustless") && (
                    <span
                      className="w-100 text-center text-danger"
                      style={ { fontSize: "12px" } }
                    >
                      Date must be greater then from date
                    </span>
                  )}
                </Form.Group>
                <Button
                  disabled={loader}
                  variant="warning"
                  size="sm"
                  className="fw-bolder text-white  ms-3"
                  type="submit"
                >
                  {loader ? "Waiting..." : "Generate"}
                </Button>
              </Form>
            </Col>
            {data?.length > 0 && (
              <Col lg={12}
                className="mt-5">
                <Col
                  lg={12}
                  className="d-flex justify-content-end align-items-center mb-2"
                >
                  <CSVLink
                    filename={`Navigo(${invoiceData.from}/${invoiceData.to}).csv`}
                    className=" btn font-weight-bold text-white btn-lg btn-warning ml-2"
                    data={data}
                    headers={headers}
                  >
                    Import to csv
                  </CSVLink>
                </Col>
                <Table responsive
                  bordered>
                  <thead>
                    <tr>
                      <th>Ride Date</th>
                      <th>Ride #</th>
                      <th>Driver</th>
                      <th>Passenger</th>
                      <th>CompletedAt</th>
                      <th>Ride Fare</th>
                      <th>Platform fee (%)</th>
                      <th>Total Paid</th>
                      <th>Driver Percentage (%)</th>
                      <th>Driver Payment</th>
                      <th>Navigo Payment</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((ride, index) => (
                      <Tr ride={ride}
                        key={index} />
                    ))}
                  </tbody>
                </Table>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
export default memo(Index);
