import { Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";

import { ukCurrancy } from "../../../utils/convertions";
import styles from "./styles";

function Ordertr({ ride, showDed , index}) {

  // const rideDate = moment(Number(ride?.createdAt)).format("DD-MMM-YY/hh:mm A");

  const rideDate = ride?.date;
  const rideTime = ride?.time;
  const rideId = ride?.id.toString().split("-")[0];

  const ridePickUp = ride?.origin?.address;
  const rideDropOff = ride?.destination?.address;

  const rideStops = ride?.stops.length === 0 
    ? ( "-" ) 
    : ( 
        ride?.stops.map((stop) => (
          stop.address + ", "
        ))
     );

  const customerName = ride?.passenger?.name;
  const fare = ride?.cost;

  // const deductions = ride?.deduction?.from === "rider" ? ride?.deduction?.fee : 0;
  // const riderFee = parseFloat(ride.riderdetail.riderfee || 0);
  // const ridertip = parseFloat(ride.ridertip || 0);
  // const deliveryFee = ride?.deliveryfree || 0;

  return (
    <View style={styles.TableLableRow}>
      <Text style={styles.TableDataText}>{index + 1}</Text>
      <Text style={styles.TableDataText}>{rideId}</Text>
      <Text style={styles.TableDataText}>{rideDate}</Text>
      <Text style={styles.TableDataText}>{rideTime}</Text>

      <Text style={styles.TableDataText}>{ridePickUp}</Text>
      <Text style={styles.TableDataText}>{rideDropOff}</Text>
      <Text style={styles.TableDataText}>{rideStops}</Text>

      <Text style={styles.TableDataText}>{customerName}</Text>
      <Text style={styles.TableDataText}>{ukCurrancy.format(fare)}</Text>

      {/* <Text style={styles.TableDataText}>{ukCurrancy.format(deliveryFee)}</Text>
      <Text style={styles.TableDataText}>{ukCurrancy.format(ridertip)}</Text>
      <Text style={styles.TableDataText}>{ride?.deduction?.from === "rider" ? (<>{ukCurrancy.format(fare)}</>) : ("0.00")}</Text> */}
      {showDed && (
        <Text style={styles.TableDataText}>
          {ride?.deduction?.from === "rider"
            ? ride?.deduction?.reason
            : "No deductions"}
        </Text>
      )}
      {/* <Text style={styles.TableDataText}>{ukCurrancy.format(ridertip + deliveryFee - deductions + riderFee)}</Text> */}
    </View>
  );
}

export default Ordertr;
