import {
  FaBook,
  FaCalendarCheck,
  FaCreditCard,
  //   FaFileInvoice,
  FaIdCard,
  FaPowerOff,
  FaUserTimes,
  //   FaShoppingBasket,
} from "react-icons/fa";

const RiderLinks = [
  {
    id: 2,
    url: "/",
    name: "profile",
    text: "Profile",
    icon: FaIdCard,
  },

  {
    id: 4,
    url: "/payment",
    name: "payment",
    text: "Payment",
    icon: FaCreditCard,
  },
  {
    id: 4,
    url: "/schedule",
    name: "schedule",
    text: "Schedule",
    icon: FaUserTimes,
  },
  {
    id: 3,
    url: "/shifts",
    name: "shifts",
    text: "Shifts",
    icon: FaCalendarCheck,
  },
  {
    id: 1,
    url: "/documents",
    name: "documents",
    text: "Documents",
    icon: FaBook,
  },
  {
    id: 5,
    url: "/absence",
    name: "absence",
    text: "Absence",
    icon: FaPowerOff,
  },

  {
    id: 1,
    url: "/notes",
    name: "notes",
    text: "Notes",
    icon: FaBook,
  },
];

export default RiderLinks;
