import { Autocomplete } from "@react-google-maps/api";
import { notification } from "antd";
import { ErrorMessage, Formik } from "formik";
import React, { memo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { saveCity } from "../../redux/admin/action";
import { getArea, getCity } from "../../utils/addressChanger";
import { createSlug } from "../../utils/convertions";
import addCityValidation from "../../validations/addCity";

function Index({ show, onHide }) {
  const [autoComplete, setAutoComplete] = useState(null);
  const [cityLongitude, setLongitude] = useState(null); //City Longitude
  const [cityLatitude, setLatitude] = useState(null); //City Latitude

  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const stopAdding = (actions) => () => {
    actions.setSubmitting(false);
    actions.resetForm();

    onHide();
    notification.success({
      message: "City successfully added!",
      placement: "top",
    });
  };
  const dispatch = useDispatch();

  const addCityHandler = (values, actions) => {

    // console.log("Cities values", values);

    dispatch(
      saveCity(
        { ...values, isActive: false, lat: cityLatitude , lng: cityLongitude, slug: createSlug(values.name) },
        stopAdding(actions)
      )
    );
  };

  return (
    <Modal show={show}
      onHide={onHide}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Add city
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={ {
            name: "",
            delay: "",
            message: "",
            VAT: "",
            platformFee: {
              type: "value",
              value: ""
            },
            driverFee: {
              type: "value",
              value: ""
            },
          } }
          validationSchema={addCityValidation}
          onSubmit={addCityHandler}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,

            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="d-flex gap-3">
                <Form.Group className="w-50"
                  controlId="formBasiccity">
                  <Form.Label>
                    City:
                  </Form.Label>
                  <Autocomplete
                    restrictions={ { country: "GB" } }
                    onLoad={onLoad}
                    onPlaceChanged={() => {
                      if (autoComplete !== null) {
                        var city = getCity(autoComplete.getPlace().address_components) || getArea(autoComplete.getPlace().address_components);
                        setLatitude(autoComplete.getPlace().geometry.location.lat().toString() || '');
                        setLongitude(autoComplete.getPlace().geometry.location.lng().toString() || '');
                        setFieldValue("name", city);
                      }
                    }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter city"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Autocomplete>
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="VAT">
                  <Form.Label>
VAT: (Value added tax)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter value added tax (%)"
                    name="VAT"
                    value={values.VAT}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="VAT"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </div>
              <div className="d-flex gap-3">
                <Form.Group className="w-50"
                  controlId="formBasicDelay">
                  <div className="flex items-center my-2 justify-between gap-2 ">
                    <Form.Label className="m-0">
Platform fee:
                    </Form.Label>
                    <Form.Select
                      name="platformFeeType"
                      className="w-50 py-0"
                      value={values?.platformFee?.type}
                      onChange={(e) => {
                        setFieldValue("platformFee.type", e.target.value);
                      }}
                    >

                      <option value="value">
                        Value
                      </option>
                      <option value="percentage" >
Percentage
                      </option>
                    </Form.Select>
                  </div>
                  <Form.Control
                    type="number"
                    placeholder="Enter platform fee"
                    name="platformFee"
                    value={values?.platformFee?.value}
                    onChange={(e) => {
                      setFieldValue("platformFee.value", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="platformFee.value"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group className="w-50"
                  controlId="formBasicDelay">
                  <div className="flex items-center my-2 justify-between gap-2 ">
                    <Form.Label className="m-0">
Driver fee:
                    </Form.Label>
                    <Form.Select
                      name="driverFeeType"
                      className="w-50 py-0"
                      value={values?.driverFee?.type}
                      onChange={(e) => {
                        setFieldValue("driverFee.type", e.target.value);
                      }}
                    >

                      <option value="value">
                        Value
                      </option>
                      <option value="percentage" >
Percentage
                      </option>
                    </Form.Select>
                  </div>
                  <Form.Control
                    type="number"
                    placeholder="Enter percentage"
                    name="driverPercentage"
                    value={values?.driverFee?.value}
                    onChange={(e) => {
                      setFieldValue("driverFee.value", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="driverFee.value"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </div>
              <Form.Group controlId="formBasicDelay">
                <Form.Label>
Delay: (type like 1:30)
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="1:30"
                  name="delay"
                  value={values.delay}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="delay"
                  component="div"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group controlId="formBasicNotification">
                <Form.Label>
Notification message:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="text-danger"
                />
              </Form.Group>
              <Button
                disabled={isSubmitting}
                variant="warning"
                className="mt-3 btn-sm fw-bolder text-white"
                type="submit"
              >
                {isSubmitting ? "Saving.." : "Save"}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
