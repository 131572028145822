import moment from "moment";

export const filterCompletedOrder = (rides) => {
  // console.log("Pie Rides", rides);
  const completedRides = rides?.filter((e) => e.status === "completed");
  const allRides = rides?.length;
  const assignedRides = rides?.filter((e) => e.status === "requesting");

  return {
    labels: [
      // "Assigned", 
      "Completed" , 
      "Total Rides"
    ],

    datasets: [{
      label: "Rides",
      data: [
        // assignedRides?.length ,
        completedRides?.length , 
        allRides,
        // allRides.length > completedRides?.length ? (assignedRides?.length - completedRides?.length) : 0,
      ],
      pointerStyle: "circle",
      backgroundColor: [ 
        // "rgb(255, 99, 132)",
        "rgb(0, 128, 0)" , 
        "rgb(54, 162, 235)",
      ],
      hoverOffset: 6,
    },],
  };
};

export const filterEarnOrder = (orders) => {
  const completedOrders = orders?.filter((e) => e.paid === true);

  const compeletedEarnOrder = completedOrders?.map((e) => {
    const platfee = (parseFloat(e?.subtotal) * parseFloat(e?.platformfee)) / 100;
    // const deductions = e?.deduction?.from === "restaurant" ? parseFloat(e?.deduction?.fee) : 0;
    // const deliveryFee = e?.riderdetail?.type === "Own Rider" ? parseFloat(e.deliveryfree) : 0;

    return {
      subTotal: parseFloat(e?.subtotal),
      platfee: (parseFloat(e?.subtotal) * parseFloat(e?.platformfee)) / 100,
      deductions,
      deliveryFee,
      discount: e?.discount?.Discountfee || 0,
      total: parseFloat(e?.total),
      earn: parseFloat(e?.subtotal) - platfee - deductions + deliveryFee,
    };
  });

  const allEarnOrder = orders?.map((e) => {
    const platfee = (parseFloat(e?.subtotal) * parseFloat(e?.platformfee)) / 100;
    // const deductions = e?.deduction?.from === "restaurant" ? parseFloat(e?.deduction?.fee) : 0;
    // const deliveryFee = e?.riderdetail?.type === "Own Rider" ? parseFloat(e?.deliveryfree) : 0;

    return {
      subTotal: parseFloat(e?.subtotal),
      platfee: (parseFloat(e?.subtotal) * parseFloat(e?.platformfee)) / 100,
      deductions,
      deliveryFee,
      discount: e?.discount?.Discountfee || 0,
      total: parseFloat(e?.total),
      earn: parseFloat(e?.subtotal) - platfee - deductions + deliveryFee,
    };
  });

  return {
    completedOrders: compeletedEarnOrder,
    data: {
      labels: [
        "Earn", "Total"
      ],
      datasets: [{
        label: "Earning",

        data: [
          compeletedEarnOrder?.reduce((a, c) => a + c.earn, 0),
          allEarnOrder?.reduce((a, c) => a + c.earn, 0),
        ],

        backgroundColor: [
          "rgb(255, 99, 132)", "rgb(54, 162, 235)"
        ],
        hoverOffset: 4,
      },],
    },
  };
};

export const filterCompanyEarnOrder = (orders) => {
  // console.log("Completed Rides", orders);

  const completedOrders = orders?.filter((e) => e.paid === true);

  const compeletedEarnOrder = completedOrders?.map((e) => {
    // const platfee = (parseFloat(e?.cost) * parseFloat(e?.platformfee || 0)) / 100;
    // const subTotal = parseFloat(e?.cost) || 0.0;
    // const additionalfee = parseFloat(e?.additionalfee) || 0.0;
    // const ownRiderdeliveryfree = e?.restaurant?.deliverby?.rider === "ownriders" && e?.type === "delivery"? parseFloat(e?.deliveryfree) : 0.0;
    // const boomaRiderdeliveryfree =e?.restaurant?.deliverby?.rider === "boomariders" && e?.type === "delivery"? parseFloat(e?.deliveryfree) || 0 : 0.0;

    // const restDeduction = e?.deduction?.from === "restaurant" ? parseFloat(e?.deduction?.fee) : 0.0;
    // const riderDeduction = e?.deduction?.from === "rider" ? parseFloat(e?.deduction?.fee) : 0.0;
    // const riderTip = parseFloat(e?.ridertip) || 0.0;

    // const restPay = subTotal - platfee + additionalfee + ownRiderdeliveryfree - restDeduction;
    // const restPay = subTotal - platfee;
    // const riderFee = parseFloat(e.riderdetail.riderfee || 0);
    // const riderPay = e?.restaurant?.deliverby?.rider === "boomariders" && e?.type === "delivery" ? boomaRiderdeliveryfree + parseFloat(riderTip) - riderDeduction + riderFee : 0.0;
    // const riderPay = 0.0;

    // const totalIncome = e.total - restPay - riderPay;

    return {
      cost: parseFloat(e?.cost) || 0,
      discount:  parseFloat(e?.discount) || 0,
      driverTake:  parseFloat(e?.driverTake) || 0,
      riderTake:  parseFloat(e?.riderTake) || 0,
      driverPercentage: Number(e?.driverPercentage) || 0,
      platformFee: Number(e?.platformFee) || 0,
      // restPay: restPay,
      // discount: e?.discount || 0,
      // total: parseFloat(e?.total || 0),
      // earn: parseFloat(totalIncome),
    };
  });

  // console.log("Completed Orders ====> ", compeletedEarnOrder);

  const allEarnOrder = orders?.map((e) => {
    // const deductions = e?.deduction?.from === "restaurant" ? parseFloat(e?.deduction?.fee) : 0;

    // const discount = parseFloat(e?.discount) || 0;

    // const platformfee = parseFloat(e?.platformfee) || 0;
    // const driverPercentage = parseFloat(e?.driverPercentage) || 0;
    // const driverTake = parseFloat(e?.driverTake) || 0;
    // const riderTake = parseFloat(e?.riderTake) || 0;

    // const deliveryFee = e?.riderdetail?.type === "Own Rider" && e?.type === "delivery" ? parseFloat(e?.deliveryfree) : 0;

    return {
      cost: parseFloat(e?.cost) || 0,
      discount:  parseFloat(e?.discount) || 0,
      driverTake:  parseFloat(e?.driverTake) || 0,
      riderTake:  parseFloat(e?.riderTake) || 0,
      driverPercentage: Number(e?.driverPercentage) || 0,
      platformFee: Number(e?.platformFee) || 0,

      // subTotal: parseFloat(e?.cost),
      // platfee: (parseFloat(e?.subtotal) * parseFloat(e?.platformfee)) / 100,
      // platformfee,
      // driverPercentage,
      // driverTake,
      // riderTake,
      // discount,
      // total: parseFloat(e?.total || 0),
      // earn: parseFloat(e?.total || 0),
    };
  });

  return {
    completedOrders: completedOrders,
    data: {
      labels: [
        "Earn", "Total"
      ],
      datasets: [{
        label: "Earning",

        data: [
          compeletedEarnOrder?.reduce((a, c) => a + c.cost, 0) - compeletedEarnOrder?.reduce((a, c) => a + c.driverTake, 0),
          allEarnOrder?.reduce((a, c) => a + c.cost, 0),
          // 1 , 3
        ],

        backgroundColor: [
          "rgb(255, 99, 132)", "rgb(54, 162, 235)"
        ],
        hoverOffset: 4,
      },],
    },
  };
};

export const getFilterbyDays = (type) => {
  switch (type) {
  case "Monthly":
    return [
      moment(new Date()).startOf("month").startOf("day"),
      moment(new Date()).endOf("month").endOf("day"),
    ];
  case "Weekly":
    return [
      moment(new Date()).startOf("week").startOf("day"),
      moment(new Date()).endOf("week").endOf("day"),
    ];

  case "Yearly":
    return [
      moment(new Date()).startOf("year").startOf("day"),
      moment(new Date()).endOf("year").endOf("day"),
    ];

  default:
    return [
      moment(new Date()).startOf("d"), moment(new Date()).endOf("d")
    ];
  }
};
