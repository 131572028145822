export function gerenatePassWord(len) {
  let ans = "";

  const arr = "123456780abcdefghijklmnopqrstuvwxyz!@#$%^&*()_";
  for (var i = len; i > 0; i--) {
    ans += arr[Math.floor(Math.random() * arr.length)];
  }
  return ans;
}

export function validateemail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}
export function makeId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
