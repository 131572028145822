import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { updateSiteSettings } from "../../../redux/auth/action";

function Index() {
  const { settings } = useSelector((state) => state.auth);
  const [
    smtp, setsmtp
  ] = useState({
    host: "",
    port: "",
    activated: {
      user: "",
      pass: "",
    },
    senderemail: {
      user: "",
      pass: "",
    },
    riderSupport: "",

    userSupport: "",
  });
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };
  const savesmtp = (e) => {
    e.preventDefault();

    setLoader(false);
    dispatch(updateSiteSettings("smtp", smtp, stopLoader));
  };
  const inputChange = (e) => {
    const { name, value } = e.target;
    setsmtp((p) => ({ ...p, [name]: value }));
  };
  const inputChangeact = (e) => {
    const { name, value } = e.target;
    setsmtp((p) => ({
      ...p,
      activated: {
        ...p.activated,
        [name]: value,
      },
    }));
  };

  const inputChangeridersup = (e) => {
    const { name, value } = e.target;
    setsmtp((p) => ({
      ...p,
      senderemail: {
        ...p.ridersupport,
        [name]: value,
      },
    }));
  };
  useEffect(() => {
    setsmtp((p) => {
      return {
        ...p,
        ...settings?.smtp,
      };
    });
  }, [settings]);

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1 className="fw-bold">
Site SMTP
          </h1>
        </Col>
        <Col lg={12}
          className="bg-white p-4 shadow-lg rounded-lg  ">
          <Form onSubmit={savesmtp}>
            <Form.Group controlId="formBasicHost">
              <Form.Label>
Host:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter host"
                name="host"
                value={smtp?.host}
                onChange={inputChange}
              />
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicnumber">
              <Form.Label>
Port:
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Number"
                name="port"
                value={smtp?.port}
                onChange={inputChange}
              />
            </Form.Group>
            <h1 className="mt-3 text-base fw-bold">
Emails:
            </h1>
            <p className="mt-3 mb-1 font-weight-bold">
Activation:
            </p>
            <Form.Group controlId="formBasicActivationUsername">
              <Form.Label>
User Name:
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="User name"
                name="user"
                value={smtp?.activated?.user}
                onChange={inputChangeact}
              />
            </Form.Group>
            <Form.Group
              className="mt-3"
              controlId="formBasicActivationPassword"
            >
              <Form.Label>
Password:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="User name"
                name="pass"
                value={smtp?.activated?.pass}
                onChange={inputChangeact}
              />
            </Form.Group>
            <p className="mt-3 mb-1 font-weight-bold">
Sender email:
            </p>
            <Form.Group controlId="formBasicRiderUsername">
              <Form.Label>
User name:
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="User name"
                name="user"
                value={smtp?.senderemail?.user}
                onChange={inputChangeridersup}
              />
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicRiderPassword">
              <Form.Label>
Password:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Password"
                name="pass"
                value={smtp?.senderemail?.pass}
                onChange={inputChangeridersup}
              />
            </Form.Group>
            <p className="mt-3 mb-1 font-weight-bold">
Other emails:
            </p>
            <Form.Group controlId="formBasicRider">
              <Form.Label>
Driver support:
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                name="riderSupport"
                value={smtp?.riderSupport}
                onChange={inputChange}
              />
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicRestaurant">
              <Form.Label>
Rider support:
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                name="userSupport"
                value={smtp?.userSupport}
                onChange={inputChange}
              />
            </Form.Group>
            <Button
              disabled={loader}
              className="btn-warning btn-sm fw-bolder text-white mt-3 z-10 relative"
              variant="primary"
              type="submit"
            >
              {loader ? "saving..." : "Submit"}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
