import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getVehicleTypeById } from "../../../redux/admin/action";

function Index({ id }) {
  const [
    type, setType
  ] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(
        getVehicleTypeById(id, (data) => {
          setType(data);
        })
      );
    }
  }, [
    dispatch, id
  ]);
  return <div>
    {type?.name}
  </div>;
}

export default memo(Index);
