export const GET_ORDERS = "GET_ORDERS";

export const GET_RIDES = "GET_RIDES";
export const DELETE_RIDE = "DELETE_RIDE";


export const GET_PAYMENTS = "GET_PAYMENTS";
export const UPDATE_PAYMENTS = "UPDATE_PAYMENTS";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const REMOVE_PAYMENT = "REMOVE_PAYMENT";

export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const GET_SCHEDULES = "GET_SCHEDULES";
export const REMOVE_SCHEDULE = "REMOVE_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_READ_NOTIFICATIONS = "GET_READ_NOTIFICATIONS";
export const GET_UNREAD_NOTIFICATIONS = "GET_UNREAD_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const MARK_ALL_AS_READ_NOTIFICATIONS = "MARK_ALL_AS_READ_NOTIFICATIONS";