import React, { memo } from "react";

function Index({ info }) {
  return (
    <div>
      <p>
        First Name: 
        {" "}
        <span>
          {info?.firstName}
        </span>
      </p>
      <p>
        Last name: 
        {" "}
        <span>
          {info?.lastName}
        </span>
      </p>
      <p>
        Phone number: 
        {" "}
        <span>
          {info?.phoneNumber}
        </span>
      </p>
      <p>
        Relationship: 
        {" "}
        <span>
          {info?.relationship}
        </span>
      </p>
      <p>
        Address: 
        {" "}
        <span>
          {info?.location?.address}
        </span>
      </p>
    </div>
  );
}

export default memo(Index);
