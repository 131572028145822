import "./index.css";

import {
  DeleteOutlined,
  EditOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import { Badge, Button, Popconfirm, Space, Spin, Table, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import VoucherModal from "../../modals/VoucherModal";
import {
  activateUserVoucher,
  deActivateUserVoucher,
  deleteUserVoucher,
  getAllVouchers,
} from "../../redux/admin/action";
import Users from "./Users";

const renderStatus = (value) => {
  return (
    <Space>
      {value ? (
        <Badge count="Active"
          style={ { backgroundColor: "#52c41a" } } />
      ) : (
        <Badge count="Inactive" />
      )}
    </Space>
  );
};
function Index() {
  const [
    id, setId
  ] = useState(null);
  const [
    flag, setFlag
  ] = useState(false);
  const [
    selectedVoucher, setSelectedVoucher
  ] = useState(null);
  const columns = [
    {
      title: "Name",
      align: "center",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 120,
    },
    {
      title: "Code",
      align: "center",
      dataIndex: "code",
      key: "code",
      width: 120,
    },

    {
      title: "Created at",
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,

      render: (e) => moment(Number(e)).format("DD-MMM-YY/hh:mm A"),
    },
    {
      title: "Limit",
      align: "center",
      dataIndex: "limit",
      key: "limit",
      width: 120,
    },

    {
      title: "Purpose",
      align: "center",
      dataIndex: "for",
      width: 120,

      key: "for",
      render: (value, record) =>
        value === "all" ? "All" : <Users users={record.users} />,
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "active",
      key: "active",
      width: 120,

      render: renderStatus,
    },
    {
      title: "Action",
      align: "center",
      width: 120,

      render: (_, record) => {
        return (
          <div className="d-flex align-items-center justify-content-center z-50">
            <Tooltip title={"Edit"}>
              <EditOutlined
                onClick={() => {
                  setSelectedVoucher(record);
                  setShow(true);
                }}
                style={ {
                  color: "green",
                  fontSize: 15,
                  margin: "2px",
                } }
              />
            </Tooltip>
            <Spin spinning={record.id === id && flag ? true : false}>
              <Tooltip title={record.active ? "de-activate" : "activate"}>
                <Popconfirm
                  title={`Want to ${
                    record.active ? "de-activate" : "activate"
                  }?`}
                  onConfirm={() => {
                    if (record.active) {
                      deactivateVoucher(record.id);
                    } else {
                      activateVoucher(record.id);
                    }
                  }}
                >
                  <InteractionOutlined
                    style={ {
                      color: record.active ? "green" : "red",
                      fontSize: 15,
                      margin: "2px",
                    } }
                  />
                </Popconfirm>
              </Tooltip>
            </Spin>
            <Spin spinning={record.id === id && deleting ? true : false}>
              <Tooltip title={"delete"}>
                <Popconfirm
                  title={"Want to delete voucher?"}
                  onConfirm={() => {
                    deleteVoucher(record.id);
                  }}
                >
                  <DeleteOutlined
                    style={ { color: "red", fontSize: 15, margin: "2px" } }
                  />
                </Popconfirm>
              </Tooltip>
            </Spin>
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const activateVoucher = (value) => {
    setId(value);
    setFlag(true);
    dispatch(activateUserVoucher(value, stopFlag));
  };
  const stopFlag = () => {
    setFlag(false);
  };
  const stopDeleting = () => {
    setDeleting(false);
  };
  const [
    deleting, setDeleting
  ] = useState(false);
  const deactivateVoucher = (value) => {
    setId(value);
    setFlag(true);
    dispatch(deActivateUserVoucher(value, stopFlag));
  };
  const deleteVoucher = (value) => {
    setId(value);
    setDeleting(true);
    dispatch(deleteUserVoucher(value, stopDeleting));
  };
  const { vouchers } = useSelector((state) => state.admin);

  const [
    loader, setLoader
  ] = useState(false);
  const [
    show, setShow
  ] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    setLoader(true);
    dispatch(getAllVouchers(stopLoader));
  }, [dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h2 className="fw-bold">
Vouchers
          </h2>
        </Col>
        <Col lg={12}
          className="flex justify-end p-1">
          <Button
            onClick={() => {
              setSelectedVoucher(null);
              setShow(true);
            }}
            className="fw-bold"
            type="primary"
          >
            Add Voucher
          </Button>
        </Col>
        <Col lg={12}
          className="shadow rounded-3 bg-white p-3">
          <Table
            bordered
            className="table-curved adminTable cursor-pointer"
            columns={columns}
            dataSource={vouchers}
            pagination={false}
            loading={loader}
            scroll={ { y: 600 } }
          />
        </Col>
      </Row>
      {show && (
        <VoucherModal
          prevVoucher={selectedVoucher}
          show={show}
          onHide={() => setShow(false)}
        />
      )}
    </Container>
  );
}

export default memo(Index);
