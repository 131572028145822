import { Button, Modal, TimePicker } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  AddRiderSchedule,
  updateRiderSchedule,
} from "../../redux/rider/action";

const { RangePicker } = TimePicker;
function Index({ selectedDate, show, onHide }) {
  const [
    errors, setErrors
  ] = useState([]);

  const { time, currentDate } = selectedDate;
  const { schedules } = useSelector((state) => state.rider);

  let [
    schedule, setSchedule
  ] = useState({
    time: [
      moment(), null
    ],
  });

  useEffect(() => {
    if (time) {
      setSchedule({
        time: time.map((e) => moment(e, "HH:mm")),
      });
    }
  }, [time]);
  const [
    saving, setSaving
  ] = useState(false);
  const dispatch = useDispatch();

  const WorkingHandle = () => {
    let id = schedules.find((e) =>
      moment(Number(e.date)).isSame(moment(currentDate), "day")
    )?.id;
    setFlag(true);
    if (id) {
      dispatch(
        updateRiderSchedule(
          { isWorking: !selectedDate?.isWorking },
          id,
          stopFlag
        )
      );
      return;
    }
    schedule = {
      time: schedule.time.map((t) => moment(t).format("HH:mm")),
    };
    dispatch(
      AddRiderSchedule(
        {
          ...schedule,
          date: moment(currentDate).format("x"),
          isWorking: false,
        },
        stopFlag
      )
    );
  };

  const [
    flag, setFlag
  ] = useState(false);
  const stopFlag = () => {
    setFlag(false);
    onHide();
  };
  const addSchedule = async (e) => {
    e.preventDefault();
    let error = [];

    if (!schedule.time || !schedule.time[0] || !schedule.time[1]) {
      error.push({ timeEmpty: true });
    }

    if (error.length) {
      setErrors(error);
      return;
    }
    setErrors([]);
    setSaving(true);
    schedule = {
      time: schedule.time.map((t) => moment(t).format("HH:mm")),
    };

    if (
      schedules.find((e) =>
        moment(Number(e.date)).isSame(moment(currentDate), "day")
      )
    ) {
      let id = schedules.find((e) =>
        moment(Number(e.date)).isSame(moment(currentDate), "day")
      ).id;

      dispatch(updateRiderSchedule(schedule, id, stopAdder));
      return;
    }
    dispatch(
      AddRiderSchedule(
        { ...schedule, date: moment(currentDate).format("x"), isWorking: true },
        stopAdder
      )
    );
  };
  const stopAdder = () => {
    setSaving(false);
    onHide();
  };
  return (
    <Modal
      title={`Schedule for ${moment(currentDate).format("MMMM DD, YYYY")}`}
      centered
      onCancel={onHide}
      open={show}
      footer={null}
    >
      <Form className="  w-100"
        onSubmit={addSchedule}>
        <Form.Group className="flex items-center"
          controlId="formBasicemail">
          <Form.Label>
Time:
            {" "}
          </Form.Label>
          <div className="m-3 ">
            <RangePicker
              name="time"
              size="middle"
              format="HH:mm"
              value={schedule?.time}
              onChange={(value) => {
                setSchedule({
                  ...schedule,
                  time: value,
                });
              }}
            />
            {errors?.map(({ timeEmpty }, index) => {
              if (timeEmpty) {
                return (
                  <div key={index}
                    className="text-danger text-sm">
                    Select time
                  </div>
                );
              }
              return null;
            })}
          </div>
        </Form.Group>
        <div className="flex justify-end">
          {time && (
            <Button
              onClick={WorkingHandle}
              style={!schedules.find((e) =>
                moment(Number(e.date)).isSame(moment(currentDate), "day")
              )
                ? {}
                : !schedules.find((e) =>
                  moment(Number(e.date)).isSame(moment(currentDate), "day")
                )?.isWorking
                  ? {
                    backgroundColor: "green",
                    borderColor: "green",
                  }
                  : {}}
              type="danger"
              loading={flag}
              disabled={flag}
              className="fw-bold mr-2 text-white"
            >
              {!schedules.find((e) =>
                moment(Number(e.date)).isSame(moment(currentDate), "day")
              )
                ? "Not Working"
                : !schedules.find((e) =>
                  moment(Number(e.date)).isSame(moment(currentDate), "day")
                )?.isWorking
                  ? "Working"
                  : "Not Working"}
            </Button>
          )}
          <Button
            type="primary"
            loading={saving}
            disabled={saving}
            htmlType="submit"
            className="fw-bold text-white"
          >
            {time ? "Update" : "Add"}
            {" "}
schedule
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default memo(Index);
