import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";

import { ukCurrancy } from "../../utils/convertions";
import { Field } from "formik";

function EstimatedCost({
  distance,
  selectedCity,
  vehicleType,
  selectedTime,
  selectedDate,
  onChange,
}) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(null);
  const [ customCost , setCustomCost ] = useState(null);

  const [ driverPercentage , setDriverPercentage ] = useState(0);
  const [ platformFee , setPlatformFee ] = useState(0);
  const [ valueAddedTax , setValueAddedTax ] = useState(0);

  const [ actualCost , setActualCost ] = useState(0);
  const [ roundActualCost , setRoundActualCost ] = useState(0);
  

  const [ riderTake , setRiderTake ] = useState(0);
  const [ driverTake , setDriverTake ] = useState(0);

  const handleCustomCostChange = (e) => {
    // console.log("Ride value cost =====> ", e.target.value);
    setCustomCost('' + e.target.value);
  };

  const roundToNearestTenth = (value) => {

    const formattedValue = parseFloat(value);
    const roundedNumber = Math.round((formattedValue.toFixed(2)) * 10) / 10;
    return parseFloat(roundedNumber.toFixed(2)); // Convert to a number with 2 decimal places
  }

  // console.log("Inside Estimated Cost - distance ====> ", distance);
  // console.log("Inside Estimated Cost - city ====> ", selectedCity);

  useEffect(() => {

    if (distance && selectedCity && vehicleType && selectedTime && selectedDate) {
      // console.log("inside estimated cost");

      setLoader(true);

      setPlatformFee(parseFloat(selectedCity.platformFee.value || 1 )); // platform fees
      setDriverPercentage(parseFloat(selectedCity?.driverFee.value || 1 )); // driver percentage
      setValueAddedTax(parseFloat(selectedCity?.VAT || 0 )); // city vat

      // console.log("Driver Percentage: ===:>", driverPercentage);
      // console.log("Platform Percentage: ===:>", platformFee);

      const selectedTariff = selectedCity?.tariffs?.find((t) => {
        const currMin = Number(t?.distance?.min);
        const currMax = Number(t?.distance?.max);

        // console.log("Tarriff min value: ", currMin);
        // console.log("Tarriff max value: ", currMax);

        const isInRange = currMin <= distance && currMax >= distance;
        // console.log("Tariff Range Value ==> ", isInRange);
        return isInRange;
      });

      // console.log("Selected Tariff  Value ==> ", selectedTariff);
      const tariffValue = parseFloat(selectedTariff?.[vehicleType]); // it return vehicleType multiply factor according to distance range in city -> tariffs
      // console.log("Tariff  Value ==> ", tariffValue);

      // console.log("Shifts ========> ", selectedCity?.shifts);
      const selectedShift = selectedCity?.shifts?.find((s) => {
        const selected = moment(selectedTime, "HH:mm");
        const startTime = moment(s?.startTime, "HH:mm");
        const endTime = moment(s?.endTime, "HH:mm");

        // return selected.isSameOrAfter(startTime) && selected.isSameOrBefore(endTime);
        if (startTime.isBefore(endTime)) {
          return selected.isSameOrAfter(startTime) && selected.isSameOrBefore(endTime);
        } else {
          return selected.isSameOrAfter(startTime) || selected.isSameOrBefore(endTime);
        }

      }); // will return shift time
      // console.log("Selected Shift Value ==> ", selectedShift.id); // 72b1cdf6 -> night , 386d5069 -> day

      // const selectedWeekdaySurchargeValue = parseFloat( 
      //   selectedCity?.weekDaysSurcharges?.[selectedShift?.id]?.selectedDayOfWeek
      // );

      const selectedDayOfWeek =  moment(selectedDate, "DD MMM YYYY").format('dddd').toLowerCase(); // Get the selected day of the week in lowercase (e.g., "monday", "tuesday")
      // console.log("Day =====>",selectedDayOfWeek );
      // console.log("Surcharge =====> ", selectedCity?.weekDaysSurcharges);

      const selectedShiftSurcharge = selectedCity?.weekDaysSurcharges?.find(surcharge => surcharge.shift === selectedShift?.id);
      // console.log("Selected Shift Surcharge =====> ", selectedShiftSurcharge);

      const selectedWeekdaySurchargeValue = selectedShiftSurcharge ? parseFloat(selectedShiftSurcharge[selectedDayOfWeek]) || 0 : 0; // Get the selected Weekday Surcharge Value
      // console.log("Selected Weekday Surcharge Value ==> ", selectedWeekdaySurchargeValue);


      const filteredHolidaySurcharges = selectedCity?.holidaySurcharges?.filter((h) => {
        const from = moment(h?.fromTo[0], "DD MMM YYYY");
        const to = moment(h?.fromTo[1], "DD MMM YYYY");
        const selected = moment(selectedDate, "DD MMM YYYY");
        return (
          h?.shift === selectedShift?.id &&
          selected.isSameOrAfter(from) &&
          selected.isSameOrBefore(to)
        );
      });
      // console.log("Filtered HolidaySurcharge Value ==> ", filteredHolidaySurcharges); // 72b1cdf6 -> night , 386d5069 -> day


      // console.log("Distance Value ==> ", distance);
      // console.log("Tariff Value ==> ", tariffValue); 
      // console.log("Weekday Surcharge Value ==> ", selectedWeekdaySurchargeValue);

      //For default values if not fetching from database
      let subTotalWithHolidaySurcharges = 0;
      if(tariffValue && selectedWeekdaySurchargeValue){
        // console.log("if");
        subTotalWithHolidaySurcharges = distance * tariffValue * selectedWeekdaySurchargeValue;
      }else if(!tariffValue && selectedWeekdaySurchargeValue){
        // console.log("else if 1");
        subTotalWithHolidaySurcharges = distance * 1 * selectedWeekdaySurchargeValue;
      }else if(tariffValue && !selectedWeekdaySurchargeValue){
        // console.log("else if 2");
        subTotalWithHolidaySurcharges = distance * tariffValue * 1;
      }else{
        // console.log("else");
        subTotalWithHolidaySurcharges = distance * 1 * 1;
      }
     

      filteredHolidaySurcharges.forEach((h) => {
        if (h?.action === "add") {
          subTotalWithHolidaySurcharges += parseFloat(h.factorValue ? h?.factorValue : 0);
        } else if (h?.action === "multiply") {
          subTotalWithHolidaySurcharges *= parseFloat(h.factorValue ? h?.factorValue : 1);
        }
      });
      // console.log("With Holdiday Surcharge Cost ==> ", subTotalWithHolidaySurcharges);

      // console.log("Actual Cost ===> ", actualCost);
      // console.log("Custom Cost ===> ", customCost);
      // Setting Cost Values
      setActualCost(data?.subTotalWithHolidaySurcharges);
      
      setRoundActualCost( 
        customCost
        ? roundToNearestTenth(parseFloat(customCost)).toFixed(2)
        : roundToNearestTenth(parseFloat(actualCost)).toFixed(2) 
      ); //rounding actual values

      // console.log("roundActualCost ; ", roundActualCost);
      let dValue = driverPercentage / 100 ;
      let rValue = platformFee / 100 ;

      setDriverTake( 
        customCost
        ? ( parseFloat(customCost) - parseFloat((roundActualCost * dValue)) ).toFixed(2)
        : ( parseFloat(actualCost) - parseFloat((roundActualCost * dValue)) ).toFixed(2)
      ); //driver percentage

      setRiderTake( 
        customCost
        ? ( parseFloat(customCost) + parseFloat((roundActualCost * rValue)) ).toFixed(2)
        : ( parseFloat(actualCost) + parseFloat((roundActualCost * rValue)) ).toFixed(2)
      ); //rider percentage
      
      // console.log("driver take => ", driverTake);
      // console.log("rider take => ", riderTake);

      //  Declaring Payload
      const payload = {
        subtotal: subTotalWithHolidaySurcharges / (1 + valueAddedTax / 100),
        subTotalWithHolidaySurcharges,
        platformFee,
        driverPercentage,
        riderTake,
        driverTake,
        valueAddedTax,
        selectedTariff: {
          id: selectedTariff?.id,
          value: tariffValue,
        },
        selectedShift: {
          id: selectedShift?.id,
          name: selectedShift?.name,
        },
        selectedWeekdaySurcharge: {
          id: selectedShift?.id,
          value: selectedWeekdaySurchargeValue,
        },
        selectedHolidaySurcharges: filteredHolidaySurcharges,
        cost: customCost ? customCost : roundActualCost,
      };
      
      setData(payload);
      onChange?.(payload);
      setLoader(false);
    }
  }, [distance, onChange, selectedCity, selectedDate, selectedTime, vehicleType]);

  return (
    <Row className="mt-3 gap-2">
      <Col lg={12}>
        <h3 className="text-base font-bold">Estimated Cost: </h3>
        {loader ? (
          <span>
            <Spinner size="sm" animation="border" /> Calculating...
          </span>
        ) : (
          ukCurrancy.format(roundActualCost)
        )}
      </Col>
          {/* {console.log("Uncle Ride Cost ==>", ukCurrancy.format(data?.subTotalWithHolidaySurcharges))} */}

      {data && (
        <>
          <Col>
            <Form.Group>
              <Form.Label className="fw-bold">
                Do you want to use the estimated cost for this journey?
              </Form.Label>
              <Form.Check
                inline
                label="Yes"
                name="group1"
                type="radio"
                required
                onChange={() => setCustomCost(null)}
              />
              <Form.Check
                inline
                label="No"
                name="group1"
                type="radio"
                onChange={handleCustomCostChange}
                checked={customCost !== null}
              />
              {customCost !== null && (
                <Field className="h-8 border border-red-100 focus:border-blue-100" type="number" name="customCost" onChange={(e)=>{handleCustomCostChange(e)}} placeholder="0" />
              )}
            </Form.Group>
            <div style={{ paddingBottom: "10px"}}></div>
          </Col>
          <br/>
          <br/>
          <hr/>
          <Row>
            <Col lg={6}>
              <div>
                <h6><b>Driver</b></h6>
                <div>Sub Total: {roundActualCost}</div>
                <div>Driver Fee: {driverPercentage}%</div>
                <div>Total: {driverTake}</div>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <h6><b>Rider</b></h6>
                <div>Sub Total: {roundActualCost}</div>
                <div>Platform Fee: {platformFee}%</div>
                <div>Total: {riderTake}</div>
              </div>
            </Col>
          </Row>
        </>
        
      )}
    </Row>
  );
}
export default EstimatedCost;
