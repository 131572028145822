import { Autocomplete } from "@react-google-maps/api";
import { notification } from "antd";
import { ErrorMessage, Formik } from "formik";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getCitys, updateCity } from "../../../redux/admin/action";
import { getArea, getCity } from "../../../utils/addressChanger";
import updateCityValidation from "../../../validations/updateCity";

function Index() {
  const { slug } = useParams();
  const { cities } = useSelector((state) => state.admin);
  const city = useSelector((state) => state.admin.city?.[slug]);

  const [
    autoComplete, setAutoComplete
  ] = useState(null);
  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete);
  };
  const [
    initialState, setInitialState
  ] = useState({
    name: "",
    delay: "",
    message: "",
    VAT: "",
    platformFee: {
      type:"value",
      value:""
    },
    driverFee: {
      type:"value",
      value:""
    },
  });
  const dispatch = useDispatch();
  const stopAdding = useCallback(
    (actions) => (data) => {
      actions.setSubmitting(false);
      actions.resetForm();
      setInitialState((prev) => {
        return {
          ...prev,
          ...data,
        };
      });
      notification.success({
        message: "City successfully updated!",
        placement: "top",
      });
    },
    []
  );
  const addCityHandler = useCallback(
    (values, actions) => {
      const findedCity = cities.find((city) => city.id === values.id);
      const previousName = findedCity?.name;
      const filterCityByName = cities.filter((city) =>
        city.name?.includes(values.name)
      );
      if (previousName !== values.name && filterCityByName?.length) {
        notification.error({
          message: "City already Added!",
          placement: "top",
        });
        actions.setSubmitting(false);

        return;
      }
      dispatch(updateCity(values, values.id, stopAdding(actions)));
    },
    [
      cities, dispatch, stopAdding
    ]
  );

  useEffect(() => {
    if (city) {
      setInitialState((prev)=>{
        return {
          ...prev,
          ...city
        }
      });
    }
  }, [city]);
  useEffect(() => {
    dispatch(getCitys(() => {}));
  }, [dispatch]);

  return (
    <Container fluid>
      <Row className=" gap-3">
        <Col lg={12}>
          <h2 className="fw-bold">
Basic Information
          </h2>
        </Col>
        <Col lg={12}
          className="p-2 bg-white rounded-lg">
          {initialState?.id ? (
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={updateCityValidation}
              onSubmit={addCityHandler}
            >
              {({
                values,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <div className="d-flex gap-3">
                      <Form.Group className="w-50"
                        controlId="formBasiccity">
                        <Form.Label>
City:
                        </Form.Label>
                        <Autocomplete
                          restrictions={ { country: "GB" } }
                          onLoad={onLoad}
                          onPlaceChanged={() => {
                            if (autoComplete !== null) {
                              var city =
                                getCity(
                                  autoComplete.getPlace().address_components
                                ) ||
                                getArea(
                                  autoComplete.getPlace().address_components
                                );
                              setFieldValue("name", city);
                            }
                          }}
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter city"
                            name="name"
                            value={values.name}
                            onChange={(e) => {
                              setFieldValue("name", e.target.value);
                            }}
                          />
                        </Autocomplete>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                      <Form.Group className="w-50"
                        controlId="formBasicDelay">
                        <Form.Label>
VAT: (Value added tax)
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter value added tax (%)"
                          name="VAT"
                          value={values.VAT}
                          onChange={(e) => {
                            setFieldValue("VAT", e.target.value);
                          }}
                        />
                        <ErrorMessage
                          name="VAT"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                    <div className="d-flex gap-3">
                      <Form.Group className="w-50"
                        controlId="formBasicDelay">
                        <div className="flex items-center my-2 justify-start gap-2 ">
                          <Form.Label className="m-0">
Platform fee:
                          </Form.Label>
                          <Form.Select
                            name="platformFeeType"
                            className="w-25 py-0"
                            value={values?.platformFee?.type}
                            onChange={(e)=>{
                              setFieldValue("platformFee.type", e.target.value);
                            }}
                          >
                            
                            <option value="value">
                              Value
                            </option>
                            <option value="percentage" >
Percentage
                            </option>
                          </Form.Select>
                        </div>
                        <Form.Control
                          type="number"
                          placeholder="Enter platform fee"
                          name="platformFee"
                          value={values?.platformFee?.value}
                          onChange={(e) => {
                            setFieldValue("platformFee.value", e.target.value);
                          }}
                        />
                        <ErrorMessage
                          name="platformFee.value"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                      <Form.Group className="w-50"
                        controlId="formBasicDelay">
                        <div className="flex items-center my-2 justify-start gap-2 ">
                          <Form.Label className="m-0">
Driver fee:
                          </Form.Label>
                          <Form.Select
                            name="driverFeeType"
                            className="w-25 py-0"
                            value={values?.driverFee?.type}
                            onChange={(e) => {
                              setFieldValue("driverFee.type", e.target.value);
                            }}
                          >

                            <option value="value">
                              Value
                            </option>
                            <option value="percentage" >
Percentage
                            </option>
                          </Form.Select>
                        </div>
                        <Form.Control
                          type="number"
                          placeholder="Enter percentage"
                          name="driverPercentage"
                          value={values?.driverFee?.value}
                          onChange={(e) => {
                            setFieldValue("driverFee.value", e.target.value);
                          }}
                        />
                        <ErrorMessage
                          name="driverFee.value"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                    <Form.Group controlId="formBasicDelay">
                      <Form.Label>
Delay: (type like 1:30)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="1:30"
                        name="delay"
                        value={values.delay}
                        onChange={(e) => {
                          setFieldValue("delay", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        name="delay"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicNotification">
                      <Form.Label>
Notification message:
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="message"
                        value={values.message}
                        onChange={(e) => {
                          setFieldValue("message", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>
                  <div className="flex items-center mt-4 justify-center">
                    <Button
                      disabled={isSubmitting}
                      variant="warning"
                      className=" btn-sm  py-2 fw-bolder mx-auto text-white"
                      type="submit"
                    >
                      {isSubmitting ? "Please wait..." : "Update City"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <p className="text-center text-base m-0">
City Not Found!
            </p>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
