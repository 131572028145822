import { MapProvider } from "../../context/mapContext";
import LoginGuard from "../../guard/LoginGuard";
import LoginProtection from "../../guard/LoginProtection";
import UserLoginCheck from "../../guard/UserLoginCheck";
import CityLayout from "../../layouts/cityLayout";
import Dashboardlayout from "../../layouts/dashboardlayout";
import GerenalLayout from "../../layouts/GerenalLayout";
import ProfileLayout from "../../layouts/profileLayout";
import AdminProfile from "../../views/adminProfile";
import Chat from "../../views/chat";
import CityDetail from "../../views/cityDetail";
import ForgetPassword from "../../views/forgetPassword";
import Home from "../../views/home";
import Invoices from "../../views/invoices";
import Login from "../../views/login";
import OrderDetail from "../../views/orderDetail";
import Page404 from "../../views/page404";
import RiderProfile from "../../views/riderProfile";
import UserProfile from "../../views/userProfile";

const routes = [
  {
    path: "/*",
    check: UserLoginCheck,
    protection: LoginProtection,
    layout: Dashboardlayout,
    component: Home,
  },
  {
    path: "/login",

    check: UserLoginCheck,
    protection: LoginGuard,
    layout: GerenalLayout,
    component: Login,
  },
  {
    path: "/forget-password",

    check: UserLoginCheck,
    protection: LoginGuard,
    layout: GerenalLayout,
    component: ForgetPassword,
  },
  {
    path: "/order-detail/:number",
    name: "order-detail",
    check: UserLoginCheck,
    provider: MapProvider,
    component: OrderDetail,
  },

  {
    path: "/rider/:id/*",

    check: UserLoginCheck,
    protection: LoginProtection,
    layout: ProfileLayout,
    component: RiderProfile,
  },
  {
    path: "/chat/:id",

    check: UserLoginCheck,
    protection: LoginProtection,

    component: Chat,
  },
  {
    path: "/admin/:id/*",

    check: UserLoginCheck,
    protection: LoginProtection,
    layout: ProfileLayout,
    component: AdminProfile,
  },
  {
    path: "/user/:id/*",

    check: UserLoginCheck,
    protection: LoginProtection,
    layout: ProfileLayout,
    component: UserProfile,
  },

  {
    exact: true,
    path: "/invoice/:userId/",

    check: UserLoginCheck,

    component: Invoices,
  },
  {
    exact: true,
    path: "/cities/:slug/*",
    layout: CityLayout,

    check: UserLoginCheck,
    component: CityDetail,
  },
  {
    path: "*",
    component: Page404,
  },
];
export default routes;
