import React from "react"
import { Navigate } from "react-router-dom";

import { store } from "../config/store";

export const LimitRoutes = (routes) => {
  const { user } = store.getState().auth;
  const { roles } = store.getState().admin;
  const role = roles.find((e) => e.id === user.role);

  if (!user) {
    return [];
  }
  if (user?.role === "ROOT" || !role?.specificMain) {
    return routes;
  }

  const finalroutes = routes.map((router) => {
    if (
      roles.find((e) => e.id === user.role)?.accessTo?.includes(router.name)
    ) {
      return router;
    }
    return router.name === "redirect" ? router : null;
  });

  return finalroutes
    .filter((e) => e)
    .map((e, _, array) => {
      if (e.name === "redirect") {
        return {
          path: "/",
          name: "redirect",
          component: () => <Navigate to={array[0].path}
            replace={true} />,
        };
      }
      return e;
    });
};
export const profileRoutes = (key, pathName, routes) => {
  const { user } = store.getState().auth;
  const { roles, profileUser, profileAdmin, profileRider, profileRestaurant } =
    store.getState().admin;
  const role = roles.find((e) => e.id === user.role);

  const slug =
    key === "riderTabs"
      ? profileRider.id
      : key === "customerTabs"
        ? profileUser.id
        : key === "restaurantTabs"
          ? profileRestaurant.slug
          : key === "adminTabs" && profileAdmin.slug;

  if (!user) {
    return [];
  }
  let finalRole = true;
  if (key === "riderTabs") {
    finalRole = role?.specificRider;
  } else if (key === "customerTabs") {
    finalRole = role?.specificCustomer;
  } else if (key === "restaurantTabs") {
    finalRole = role?.specificRestaurant;
  } else if (key === "adminTabs") {
    finalRole = role?.specificAdmin;
  }

  if (user?.role === "ROOT" || !finalRole) {
    return routes;
  }

  const finalroutes = routes.map((router) => {
    if (roles.find((e) => e.id === user.role)?.[key]?.includes(router.name)) {
      return router;
    }
    return router.name === "redirect" ? router : null;
  });
  return finalroutes
    .filter((e) => e)
    .map((e, _, array) => {
      if (e.name === "redirect") {
        const path = `/${pathName}/${slug}/${array[0].path}`;
        return {
          ...e,
          component: () => <Navigate to={path}
            replace={true} />,
        };
      }
      return e;
    });
};

export const limitNavigation = (routes) => {
  const { user } = store.getState().auth;
  const { roles } = store.getState().admin;
  const role = roles.find((e) => e.id === user.role);
  if (!user) {
    return [];
  }

  if (user?.role === "ROOT" || !role?.specificMain) {
    return routes;
  }

  const finalroutes = routes.map((router) => {
    if (
      roles.find((e) => e.id === user.role)?.accessTo?.includes(router.name)
    ) {
      return router;
    }
    return null;
  });

  return finalroutes.filter((e) => e);
};

export const profileNavigation = (key, routes) => {
  const { user } = store.getState().auth;
  const { roles } = store.getState().admin;
  const role = roles.find((e) => e.id === user.role);
  if (!user) {
    return [];
  }
  let finalRole = true;
  if (key === "riderTabs") {
    finalRole = role?.specificRider;
  } else if (key === "customerTabs") {
    finalRole = role?.specificCustomer;
  } else if (key === "restaurantTabs") {
    finalRole = role?.specificRestaurant;
  } else if (key === "adminTabs") {
    finalRole = role?.specificAdmin;
  }
  if (user?.role === "ROOT" || !finalRole) {
    return routes;
  }

  const finalroutes = routes.map((router) => {
    if (roles.find((e) => e.id === user.role)?.[key]?.includes(router.name)) {
      return router;
    }
    return null;
  });

  return finalroutes.filter((e) => e);
};
