import { DatePicker, notification, TimePicker } from "antd";
import { ErrorMessage, Formik } from "formik";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { uuid } from "uuidv4";

import GoogleAutoComplete from "../../components/GoogleAutoComplete";

import {
  addRide,
  getCityBySlugAndSubCollections,
  getRiders,
  updateRide,
} from "../../redux/admin/action";

import { createSlug } from "../../utils/convertions";
import addRideValidation from "../../validations/addride";
import BaseSelection from "./BaseSelection";
import Distances from "./Distances";
import DriversSelection from "./DriversSelection";
import EstimatedCost from "./EstimatedCost";
import MapLocation from "./MapLocation";
import PassengerSelection from "./PassengerSelection";
import VehicleSelection from "./VehicleSelection";

function Index({ show, onHide , rideData }) {

  const dispatch = useDispatch();
  const [estimatedDistance, setEstimatedDistance] = useState(null);
  const [selectCity, setSelectedCity] = useState(null);
  const [fetchingCity, setFetchingCity] = useState(null);
  const [costData, setCostData] = useState(null);
  const[valueCost, setValueCost] = useState("");
  const[valuePlatformFee, setPlatformFee] = useState("");
  const[valueDriverPercentage, setDriverPercentage] = useState("");

  
  // const isEditMode = !!rideData; // Check if rideData is provided to edit or no

  // console.log("Edit Ride Data ===> ", rideData);

  useEffect(() => {
    dispatch(getRiders(() => {}));
  }, [dispatch]);

  const getCityBySlugHandler = useCallback((slug) => { 
      //to get city for base selection
      // console.log("SLugggg ==> ", slug);
      setFetchingCity(true);
      dispatch(
        getCityBySlugAndSubCollections(slug, (data) => {
          setSelectedCity(data);
          setFetchingCity(false);
        })
      );
    },[dispatch]);
  

  const stopAdding = useCallback((actions) => () => {
      actions.setSubmitting(false);
      actions.resetForm();
      onHide();
      if(!rideData){
        notification.success({
          message: "Ride successfully added!",
          type: "success",
          placement: "top",
        });
      }else{
        notification.success({
          message: "Ride successfully updated!",
          type: "success",
          placement: "top",
        });
      }
    },[onHide]);

  
    const addRideHandler = (values, helper) => {
      // dispatch(addRide(final, stopAdding(helper))); // add Ride
      dispatch(
        addRide(
          values,
          stopAdding(helper)
        )
      );
    };
    const updateRideHandler = (values, helper) => {
      // dispatch(updateRide(final, rideData.id, stopAdding(helper))); // Update Ride
      dispatch(
        updateRide(
          values, 
          rideData?.id,
          stopAdding(helper)
        )
      );
    };

    // Submit Form
  const submitHandle = (values, helper) => {
    console.log('Done')
    
    // const submitHandle = useCallback((values, helper) => {

      // values.cost=valueCost
      // console.log("===before values are -->", values);
      // console.log("===final value cost -->", values.cost);

      // console.log("platformFee", valuePlatformFee);
      // console.log("driverPercentage", valueDriverPercentage);

      const finalCost = (Number(valueCost).toFixed(2));
      const dPercentValue = valueDriverPercentage / 100 ;
      const rPercentValue = valuePlatformFee / 100 ;

      // console.log("finalCost", finalCost);
      // console.log("dValue", dPercentValue);
      // console.log("rValue", rPercentValue);

      // const mydriverT = parseFloat(finalCost - (finalCost * dValue));
      // const myriderT = parseFloat(finalCost + (finalCost * dValue));
      const dd = (finalCost * dPercentValue);
      const rr = (finalCost * rPercentValue);

      const mydriverTake = parseFloat(finalCost) - parseFloat(dd);
      const myriderTake = parseFloat(finalCost) + parseFloat(rr);

      // console.log("driverT", mydriverTake);
      // console.log("riderT", myriderTake);

      let final = { 
        ...values,
        driverId: values.drivers[0].value,
        city: selectCity.name, 
        cost: (Number(valueCost).toFixed(2)).toString(),
        estimatedDistance: estimatedDistance.toFixed(2),
        platformFee: (Number(valuePlatformFee)).toString(),
        driverPercentage: (Number(valueDriverPercentage)).toString(),
        driverTake: (Number(mydriverTake).toFixed(2)).toString(),
        riderTake: (Number(myriderTake).toFixed(2)).toString(), 
        //TODO: add earning
      };
      
      // console.log("===final is -->", final);
      
      // dispatch(addRide(final, stopAdding(helper)));

      if (!rideData) {
        addRideHandler(final, helper);
      } else {
        updateRideHandler(final, helper);
      }

      // if (rideData) {
      //   dispatch(updateRide(final, rideData.id, stopAdding(helper))); // Update Ride
      // } else {
      //   dispatch(addRide(final, stopAdding(helper))); // add Ride
      // }

    };
    // [dispatch, estimatedDistance, rideData, stopAdding, valueCost]
  // )

  useEffect (() => {
    // console.log("inside effect");
    if(rideData && rideData?.destination){
      getCityBySlugHandler((rideData?.origin.city).toLowerCase());
    }
  } , [rideData]);

  

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      centered
      style={{ position: "absolute" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{rideData ? "Edit Ride" : "Add New Ride"}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={
            rideData
                ? {
                    ...rideData,
                    cost: rideData?.cost.toString(), // Convert cost to string
                  }
              : 
              {
                origin: {
                  address: "",
                },
                destination: {
                  address:  "",
                },
                stops: [], // change
                date: rideData?.date ||  moment().format("DD MMM YYYY"),
                time: rideData?.time || moment().format("HH:mm"),
                vehicleType: rideData?.vehicleType || "", // change
                drivers: [], // change
                discount: rideData?.discount || "",
                noteForDriver: rideData?.noteForDriver || "",
                passenger: {
                  isNew: rideData?.passenger?.isNew || false,
                  name: rideData?.passenger?.name || "",
                  email: rideData?.passenger?.email || "",
                  phoneNumber: rideData?.passenger?.phoneNumber || "",
                },
                noOfPass: rideData?.noOfPass || 0,
                noOfBags: rideData?.noOfLuggage || 0,
                noOfLuggage: rideData?.noOfLuggage || 0,
                selectedBase: rideData?.selectedBase || "", //change
                paid: rideData?.paid || true,
                city: rideData?.city || "", //change
                cost: rideData?.cost.toString() || valueCost,
            }
            // {
            //   origin: {
            //     address: "",
            //   },
            //   destination: {
            //     address: "",
            //   },
            //   stops: [],
            //   date: moment().format("DD MMM YYYY"),
            //   time: moment().format("HH:mm"),
            //   vehicleType: "",
            //   drivers: [],
            //   discount: "",
            //   noteForDriver: "",

            //   passenger: {
            //     isNew: false,
            //     name: "",
            //     email: "",
            //     phoneNumber: "",
            //   },
            //   noOfPass: 0,
            //   noOfBags: 0,
            //   noOfLuggage: 0,
            //   selectedBase: "",
            //   cost: valueCost,
            // }
          }
          validationSchema={addRideValidation}
          onSubmit={submitHandle}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
          {/* {console.log("Print form values ==================>",values)} */}
              <Container fluid>
                <Row>

                {/*  Details */}
                  <Col lg={8}>
                    <Container className="d-flex flex-column gap-2">
                      <Row>
                        <Col lg={12}>
                          <h2 className="text-base font-bold">
                            Journey Details
                          </h2>
                        </Col>
                        <Col lg={6}>
                          <Form.Group controlId="date">
                            <Form.Label>Date</Form.Label>
                            <DatePicker
                              className="h-12 w-100"
                              name="date"
                              format={"DD MMM YYYY"}
                              value={moment(values?.date, "DD MMM YYYY")}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                if (!e) {
                                  return;
                                }
                                setFieldValue(
                                  "date",
                                  moment(e).format("DD MMM YYYY")
                                );
                              }}
                            />
                            <ErrorMessage
                              name="date"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group controlId="time">
                            <Form.Label>Time</Form.Label>
                            <TimePicker
                              className="h-12 w-100"
                              name="time"
                              value={moment(values?.time, "HH:mm")}
                              format={"HH:mm"}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                if (!e) {
                                  return;
                                }
                                setFieldValue(
                                  "time",
                                  moment(e).format("HH:mm")
                                );
                              }}
                            />
                            <ErrorMessage
                              name="time"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <h2 className="text-base font-bold">Location</h2>
                        </Col>
                        <Col lg={12}>
                          <Form.Group controlId="origin">
                            <Form.Label>Pickup Address</Form.Label>
                            <GoogleAutoComplete
                              value={values.origin}
                              name="origin"
                              placeholder="Enter pickup address"
                              restrictions={{ country: "GB" }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onPlaceChange={(value) => {
                                if (value?.city !== values?.origin?.city) {
                                  getCityBySlugHandler(createSlug(value?.city));
                                  setEstimatedDistance(null);
                                  setCostData(null);
                                }
                                setFieldValue("origin", value);
                              }}
                              rideData={rideData ? rideData.origin : null} // Pass rideData.origin if available
                            />
                            <ErrorMessage
                              name="origin.address"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>

                          {/* Additional Stop working */}
                          <Row className="d-flex align-items-center justify-content-center pt-1">

                            <Col lg={12}>
                              <Button
                                onClick={() => {
                                  const newStop = {
                                    id: uuid(),
                                  };
                                  if(values.stops.length < 3){
                                  setFieldValue("stops", [
                                    ...values.stops,
                                    newStop,
                                  ]);
                                }
                                else{
                                  alert("Stops Limit is 3")
                                }
                                }}
                              >
                                Add stop
                              </Button>
                            </Col>
                            <Col>
                              {values?.stops?.map((stop, idx) => {
                                return (
                                  <Form.Group
                                    key={stop?.id}
                                    controlId={stop?.id}
                                  >
                                  <div className="mt-2 flex justify-between">
                                    <Form.Label>Stop -{idx + 1}</Form.Label>
                                    <Button className="rounded-full bg-red-50"
                                      onClick={() => {
                                        // console.log("Remove")
                                        const updatedStops = values.stops.filter((e) => e.id !== stop.id);
                                        setFieldValue("stops", updatedStops);
                                      }}
                                    >   
                                      X
                                    </Button>
                                  </div>
                                    
                                    <GoogleAutoComplete
                                      value={stop}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        const updatedStops = values.stops.map(
                                          (e) => {
                                            if (e?.id === stop.id) {
                                              return {
                                                ...e,
                                                address: value,
                                              };
                                            }
                                            return e;
                                          }
                                        );
                                        setFieldValue("stops", updatedStops);
                                      }}
                                      placeholder="Enter pickup address"
                                      restrictions={{ country: "GB" }}
                                      onPlaceChange={(value) => {
                                        const updatedStops = values.stops.map(
                                          (e) => {
                                            if (e?.id === stop.id) {
                                              return {
                                                ...e,
                                                ...value,
                                              };
                                            }
                                            return e;
                                          }
                                        );
                                        setFieldValue("stops", updatedStops);
                                      }}
                                      rideData={rideData ? rideData.stops[idx] : null} // Pass rideData.stops[idx] if available
                                    />
                                    <ErrorMessage
                                      name="stops"
                                      className="text-danger"
                                      component="div"
                                    />
                                  </Form.Group>
                                );
                              })}
                            </Col>

                          </Row>

                          {/* Destination working */}
                          <Form.Group controlId="destination">
                            <Form.Label>Destination</Form.Label>
                            <GoogleAutoComplete
                              value={values.destination}
                              name="destination"
                              placeholder="Enter destination address"
                              restrictions={{ country: "GB" }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onPlaceChange={(e) => {
                                setFieldValue("destination", e);
                              }}
                              rideData={rideData ? rideData.destination : null} // Pass rideData.destination if available
                            />
                            <ErrorMessage
                              name="destination.address"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          
                          <Form.Group controlId="base">
                            <Form.Label>Base Office</Form.Label>
                            {/* {console.log("Uncle Values ===> ", rideData)} */}
                            {/* {console.log("Uncle selected city ===> ", selectCity)} */}
                            <BaseSelection
                              loader={fetchingCity}
                              bases={selectCity?.bases}
                              value={values.selectedBase}
                              // value={rideData?.selectedBase || values.selectedBase || ""}
                              onChange={(e) => {
                                setFieldValue("selectedBase", e);
                              }}
                              rideData={rideData}
                            />
                            <ErrorMessage
                              name="selectedBase"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <h2 className="text-base font-bold">Ride Details</h2>
                        </Col>
                        <Col lg={6} className="d-flex items-end gap-2">
                          <Form.Group
                            className="flex-grow-1"
                            controlId="noOfPass"
                          >
                            <Form.Label>No of Passengers</Form.Label>
                            <Form.Control
                              className="h-12"
                              placeholder="Enter No of Passengers"
                              type="number"
                              name="noOfPass"
                              value={values.noOfPass}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="noOfPass"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                        {/* {console.log(" Vehicle Type : " , values.vehicleType)} */}
                        <Col lg={6}>
                          <Form.Group controlId="vehicleType">
                            <Form.Label>Vehicle Type</Form.Label>
                            {/* Fix Me: Store name instead of id */}
                            <VehicleSelection
                              rideData={rideData ? rideData : null}
                              value={values.vehicleType}
                              onChange={(e) => {
                                setFieldValue("vehicleType", e);
                              }}
                            />
                            <ErrorMessage
                              name="vehicleType"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                        
                        <Col lg={6}>
                          <Form.Group
                            className="flex-grow-1"
                            controlId="noOfLuggage"
                          >
                            <Form.Label>No of Luggages</Form.Label>
                            <Form.Control
                              className="h-12"
                              placeholder="Enter No of Luggages"
                              type="number"
                              name="noOfLuggage"
                              value={values.noOfLuggage}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="noOfLuggage"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group
                            className="flex-grow-1"
                            controlId="noOfBags"
                          >
                            <Form.Label>No of Bags</Form.Label>
                            <Form.Control
                              className="h-12"
                              placeholder="Enter No of Bags"
                              type="number"
                              name="noOfBags"
                              value={values.noOfBags}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="noOfBags"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <DriversSelection
                            drivers={values.drivers}
                            vehicleType={values.vehicleType}
                            onDriverChange={(value) => {
                              setFieldValue("drivers", value);
                            }}
                          />
                          <ErrorMessage
                              name="drivers"
                              className="text-danger"
                              component="div"
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <h2 className="text-base font-bold">
                          Passenger Details
                          </h2>
                        </Col>
                        <Col lg={6}>
                          <PassengerSelection
                            passenger={values.passenger}
                            onPassengerChange={(value) => {
                              setFieldValue("passenger", value);
                            }}
                            rideData={rideData ? rideData : null}
                          />
                          <ErrorMessage
                              name="passenger?.name"
                              className="text-danger"
                              component="div"
                            />
                        </Col>
                        <Col>
                          <Form.Check
                            onChange={() => {
                              setFieldValue("passenger", {
                                isNew: !values.passenger.isNew,
                                name: "",
                                email: "",
                                phoneNumber: "",
                              });
                            }}
                            inline
                            label="Add new one"
                          />
                        </Col>
                        {values.passenger.isNew && (
                          <Row>
                            <Col lg={6} className="d-flex items-end gap-2">
                              <Form.Group
                                className="flex-grow-1"
                                controlId="name"
                              >
                                <Form.Label>Enter Name</Form.Label>
                                <Form.Control
                                  className="h-12"
                                  placeholder="Name"
                                  name="passenger.name"
                                  type="text"
                                  value={values.passenger.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  name="passenger.name"
                                  className="text-danger"
                                  component="div"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="d-flex items-end gap-2">
                              <Form.Group
                                className="flex-grow-1"
                                controlId="phone"
                              >
                                <Form.Label for="phone">Phone</Form.Label>
                                <PhoneInput
                                  international
                                  defaultCountry="GB"
                                  placeholder="Enter phone number"
                                  value={values.passenger.phoneNumber}
                                  onChange={(value) => {
                                    setFieldValue("passenger", {
                                      ...values.passenger,
                                      phoneNumber: value,
                                    });
                                  }}
                                  className="h-12 rounded-lg border-0 outline-0"
                                />
                                <ErrorMessage
                                  name="passenger.phoneNumber"
                                  className="text-danger"
                                  component="div"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={8} className="d-flex items-end gap-2">
                              <Form.Group
                                className="flex-grow-1"
                                controlId="email"
                              >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  className="h-12"
                                  placeholder="Enter Email"
                                  type="email"
                                  name="passenger.email"
                                  value={values.passenger.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  name="passenger.email"
                                  className="text-danger"
                                  component="div"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        )}
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <h2 className="text-base font-bold">Other Details</h2>
                        </Col>
                        <Col lg={6} className="d-flex items-end gap-2">
                          <Form.Group
                            className="flex-grow-1"
                            controlId="discount"
                          >
                            <Form.Label>Discount</Form.Label>
                            <Form.Control
                              className="h-12"
                              placeholder="Voucher code"
                              name="discount"
                              value={values.discount}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="discount"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                          <Button
                            variant="warning"
                            className=" h-12 fw-bolder text-white"
                          >
                            Apply
                          </Button>
                        </Col>
                        <Col lg={12}>
                          <Form.Group controlId="noteForDriver">
                            <Form.Label>Note for Driver (Optional)</Form.Label>
                            <Form.Control
                              as={"textarea"}
                              rows={3}
                              name="noteForDriver"
                              placeholder="Note for driver"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.noteForDriver}
                            />
                            <ErrorMessage
                              name="noteForDriver"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <Button
                            variant="warning"
                            className="h-12 fw-bolder text-white"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Saving.." : "Save"}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Col>

                  {/* Map */}
                  <Col>
                    <Container fluid>
                      <Row className="gap-3">
                        <Col lg={12}>
                          <MapLocation
                            origin={values.origin}
                            destination={values.destination}
                            stops={values.stops}
                          />
                        </Col>
                      </Row>
                      {/* Api error */}
                      <Row>
                        {values.origin?.latValue &&
                          values.destination?.latValue && (
                            <Distances
                              bases={selectCity?.bases}
                              base={values.selectedBase}
                              origin={values.origin}
                              destination={values.destination}
                              stops={values.stops == null ? 0 : values.stops}
                              onDistanceChange={(distance) =>
                                setEstimatedDistance(distance)
                              }
                            />
                          )}
                        {estimatedDistance &&
                          values.date &&
                          values.vehicleType &&
                          selectCity &&
                          values.time && (
                            <EstimatedCost
                              selectedDate={values.date}
                              selectedTime={values.time}
                              vehicleType={values.vehicleType}
                              selectedCity={selectCity}
                              distance={estimatedDistance}
                              onChange={(value) => {
                                // console.log("hello jee =>", value);
                                if (value.cost !== "") {
                                  // console.log("inside the estimated cost:", value);
                                  setValueCost(value.cost);
                                }
                                if (value.platformFee !== "") {
                                  // console.log("inside the estimated cost:", value);
                                  setPlatformFee(value.platformFee);
                                }
                                if (value.driverPercentage !== "") {
                                  // console.log("inside the estimated cost:", value);
                                  setDriverPercentage(value.driverPercentage);
                                }                              
                                if (value?.subTotalWithHolidaySurcharges !== costData?.subTotalWithHolidaySurcharges) {
                                  setCostData(value);
                                }
                                // console.log("inside the estimated cost before submission:", value.cost);
                              }}
                            />
                          )}
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
