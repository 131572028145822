import { Autocomplete, GoogleMap, Marker } from "@react-google-maps/api";
import { Formik } from "formik";
import React, { memo, useCallback, useState } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import Geocode from "react-geocode";
import { useDispatch } from "react-redux";

import { addCityBase, updateBaseOffice } from "../../redux/admin/action";
import { getAddress } from "../../utils/addressChanger";
import addbaseValidation from "../../validations/Addbase";

function Index({ show, onHide, base, cityId, onChange, onUpdate }) {
  const dispatch = useDispatch();

  const [
    autoComplete, setAutoComplete
  ] = useState("");
  const onLoad = (value) => {
    setAutoComplete(value);
  };
  const stopSaving = useCallback(
    (actions) => (data) => {
      actions.setSubmitting(false);
      onChange(data);
      onHide();
    },
    [
      onHide, onChange
    ]
  );
  const updateHandler = useCallback(
    (actions) => (data) => {
      actions.setSubmitting(false);
      onUpdate(data);
      onHide();
    },
    [
      onHide, onUpdate
    ]
  );
  const addUserNotes = useCallback(
    (values, actions) => {
      if (base) {
        dispatch(
          updateBaseOffice(values, base?.id, cityId, updateHandler(actions))
        );
      } else {
        dispatch(addCityBase(values, cityId, stopSaving(actions)));
      }
    },
    [
      base, cityId, dispatch, stopSaving, updateHandler
    ]
  );
  const onDragEndHandler = (setFieldValue) => (values) => {
    let newLat = values.latLng.lat(),
      newLng = values.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        setFieldValue("location", getAddress(response.results[0]));
      }
    );
  };
  return (
    <Modal show={show}
      onHide={onHide}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
Add new base
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={ {
            name: base?.name || "",

            location: base?.location || {
              latValue: "",
              lngValue: "",
            },
          } }
          validationSchema={addbaseValidation}
          onSubmit={addUserNotes}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasictitle">
                <Form.Control
                  type="text"
                  className="mt-3"
                  placeholder="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
              {errors.name && touched.name && (
                <span
                  className="w-100 text-danger text-start"
                  style={ { fontSize: "12px" } }
                >
                  {errors.name}
                </span>
              )}
              <Form.Group controlId="formBasicdesc">
                <Autocomplete
                  className="w-full mt-3 "
                  onLoad={onLoad}
                  restrictions={ { country: "GB" } }
                  onPlaceChanged={async () => {
                    if (autoComplete !== null) {
                      const place = autoComplete.getPlace();
                      const address = await getAddress(place);
                      setFieldValue("location", address);
                    }
                  }}
                >
                  <FormControl
                    placeholder="Enter address"
                    value={values?.location?.address}
                    className="h-12"
                    name="location"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Autocomplete>
                {errors.location?.latValue && touched?.location?.latValue && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors?.location.latValue}
                  </span>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicdesc">
                <GoogleMap
                  mapContainerStyle={ {
                    width: "100%",
                    height: "500px",
                    marginTop: "16px",
                  } }
                  center={ {
                    lat: values.location?.latValue || 52.205337,
                    lng: values.location?.lngValue || 0.121817,
                  } }
                  zoom={18}
                >
                  <Marker
                    animation={"BOUNCE"}
                    draggable
                    onDragEnd={onDragEndHandler(setFieldValue)}
                    position={ {
                      lat: values.location?.latValue,

                      lng: values.location?.lngValue,
                    } }
                  />
                </GoogleMap>
              </Form.Group>
              <Button
                disabled={isSubmitting}
                variant="warning"
                className="mt-3 fw-bolder text-white"
                type="submit"
              >
                {isSubmitting ? "Adding.." : "Add"}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          className="fw-bolder text-white"
          variant="warning"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(Index);
