/* eslint-disable no-async-promise-executor */
import {
  firebase_bucket,
  INVOICE_COLLECTION,
  ORDER_COLLECTION,
  RIDES_COLLECTION,
  USER_COLLECTION,
  VOUCHER_COLLECTION,
} from "../../config";
import { INSTANCE } from "../../config/axiosInstance";
import { database, firestore, storage } from "../../config/firebase";

export default class UserApi {
  async emailIsUser  (data)  {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/user/emailIsUser",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async addUserInFirestore  (data, id)  {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);
    const res = await userRef.set(data);
    return res ? false : true;
  }
  async getUserByemail  (email)  {
    const userRef = firestore()
      .collection(USER_COLLECTION)
      .where("email", "==", email);
    const doc = await userRef.get();

    const res = doc.empty
      ? []
      : doc.docs.map((e) =>  {
        return {
          ...e.data(),
          id: e.id,
        };
      });
    return res;
  }

  async checkemail  (data)  {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/user/checkemail",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async getInvoicesByUserId  (id)  {
    const userRef = firestore()
      .collection(INVOICE_COLLECTION)
      .where("userId", "==", id)
      .orderBy("createdAt", "desc");
    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }
  async  getUserFirestore  (id)  {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);
    const res = await userRef.get();
    return res.exists ? { ...res.data(), id: res.id } : null;
  }

  async getUserAddresses  (id)  {
    const userRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("addresses");
    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }
  async  removeUserAddress  (id, address)  {
    const userRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("addresses")
      .doc(address.id);
    const res = await userRef.delete();
    return res ? false : { ...address };
  }
  async getVouchersForAll  ()  {
    const userRef = firestore()
      .collection(VOUCHER_COLLECTION)
      .where("for", "==", "all");
    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }
  async getUserNotes  (id)  {
    const userRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("Notes");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) =>  {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }
  async addNoteForUser  (data, id, noteId)  {
    const userRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("Notes")
      .doc(noteId);

    const res = await userRef.set(data);

    return res ? false : { ...data, id: noteId };
  }
  async getUserVouchers  (id)  {
    const userRef = firestore()
      .collection(VOUCHER_COLLECTION)
      .where("users", "array-contains", id);
    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }
  async saveUserAddress  (id, address, addressId)  {
    const userRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("addresses")
      .doc(addressId);
    const res = await userRef.set(address);
    return res ? false : { ...address, id };
  }
  async updateUserAddress  (id, address, addressId)  {
    const userRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("addresses")
      .doc(addressId);
    const res = await userRef.update(address);
    return res ? false : { ...address, id: addressId };
  }

  async setAddressAsPrimary  (id, address)  {
    const addresRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("addresses")
      .where("type", "==", "primary");

    const doc = await addresRef.get();
    if (!doc.empty) {
      doc.docs[0].ref.update({ type: "secondary" });
    }
    const restRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("addresses")
      .doc(address.id);

    const res = await restRef.update({ type: "primary" });

    return res ? false : { ...address, type: "primary" };
  }

  async uploadImage  (acceptedFiles)  {
    return await new Promise(async (r, eror) => {
      var array = [];
      for (var i = 0; i < Array.from(acceptedFiles).length; i++) {
        try {
          let bucketName = firebase_bucket;
          var filename = Array.from(acceptedFiles)[i].name.split(".");
          var ext = filename[filename.length - 1];
          let storageRef = storage().ref(`${bucketName}/${Date.now()}.${ext}`);
          let upload = await storageRef.put(Array.from(acceptedFiles)[i]);
          const downloadURL = await upload.ref.getDownloadURL();
          array.push(downloadURL);
        } catch (error) {
          eror(error);
        }
      }
      r(array);
    });
  }

  async saveImageFirstore  (image, id)  {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);
    const res = await userRef.update({ photoURL: image });
    return res ? false : true;
  }

  async removeImageFirstore  (id)  {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);
    const res = await userRef.update({ photoURL: null });
    return res ? false : true;
  }

  async updateProfile  (data, id)  {
    const userRef = firestore().collection(USER_COLLECTION).doc(id);
    const res = await userRef.update(data);
    return res ? false : true;
  }

  async getUserOrders  (email)  {
    const orderRef = firestore()
      .collection(RIDES_COLLECTION)
      .where("email", "==", email);

    const doc = await orderRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }

  // Get User Rides
  async getUserRides  (email)  {
    const orderRef = firestore()
      .collection(RIDES_COLLECTION)
      .where("passenger.email", "==", email);

    const doc = await orderRef.get();
    return doc.empty
      ? []
      : doc.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }

  async cancelPayment  (data)  {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/squareup/cancelPayment",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }

  async updateOrderFirestore  (data, id)  {
    const restRef = firestore().collection(ORDER_COLLECTION).doc(id);
    const res = await restRef.update(data);
    return res ? false : true;
  }
  async getUserById  (id)  {
    const restRef = firestore().collection(USER_COLLECTION).doc(id);
    const doc = await restRef.get();
    return doc.exists ? { ...doc.data(), id: doc.id } : null;
  }

  async removeOrderDatabase  (id)  {
    const restRef = database().ref(ORDER_COLLECTION).child(id);

    const res = await restRef.remove();
    return res ? false : true;
  }
  async getPaymentDetail  (id)  {
    const paymentRef = firestore()
      .collection(USER_COLLECTION)
      .doc(id)
      .collection("Detailinfo")
      .doc("PaymentInfo");

    const doc = await paymentRef.get();
    return doc.exists ? { ...doc.data(), id: doc.id } : null;
  }
}
