import { Empty, Spin } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllNotifications,
  getNotifications,
  getReadNotifications,
  getRiderDetails,
  getUnReadNotifications,
  markAllAsRead,
  saveNotifications,
  updateNotification,
} from "../../redux/rider/action";

function Index() {
  const dispatch = useDispatch();
  const navData = ["All", "Read", "Unread"];
  const [newNotifications, setNewNotifications] = useState([]);
  const [loader, setLoader] = useState(false);
  const [notificationLoader, setNotificationLoader] = useState(false);
  const { notifications } = useSelector((state) => state.rider);

  const [item, setItem] = useState({ name: "All" });
  const [active, setactive] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const [timeFilter, setTimeFilter] = useState('all');


  const handleClick = (e, i) => {
    setNotificationLoader(true);
    setItem({ name: e.target.textContent });
    setactive(i);
    let type = e.target.textContent;
    if (type === "All") {
      handleAll();
    } else if (type === "Read") {
      handleRead();
    } else {
      handleUnRead();
    }
  };

  useEffect(() => {
    setLoader(true);
    dispatch(
      getRiderDetails((data) => {
        data.map((item) => {
          item?.documents?.map((doc) => {
            //Driving licence documents
            let privateHireExpiry = doc?.privateHire?.privateHireExpiry;
            let privateHireDays = moment(privateHireExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );
            let DVLAPlasticExpiry = doc?.DVLAPlastic?.DVLAPlasticExpiry;
            let DVLAPlasticDays = moment(DVLAPlasticExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );

            let DVLAElectronicCodeExpiry =
              doc?.DVLAElectronicCode?.DVLAElectronicCodeExpiry;
            let DVLAElectronicCodeDays = moment(
              DVLAElectronicCodeExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            //Vehicle documents
            let insuranceSupportingExpiry =
              doc?.insuranceSupporting?.insuranceSupportingExpiry;
            let insuranceSupportingDays = moment(
              insuranceSupportingExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let insuranceCertificateExpiry =
              doc?.insuranceCertificate?.insuranceCertificateExpiry;
            let insuranceCertificateDays = moment(
              insuranceCertificateExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let publicLiabilityInsuranceExpiry =
              doc?.publicLiabilityInsurance?.publicLiabilityInsuranceExpiry;
            let publicLiabilityInsuranceDays = moment(
              publicLiabilityInsuranceExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let phvLicenceExpiry = doc?.phvLicence?.phvLicenceExpiry;
            let phvLicenceDays = moment(phvLicenceExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );

            let motTestCertificateExpiry =
              doc?.motTestCertificate?.motTestCertificateExpiry;
            let motTestCertificateDays = moment(
              motTestCertificateExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let logBookExpiry = doc?.logBook?.logBookExpiry;
            let logBookDays = moment(logBookExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );

            let personalInsuranceNumberExpiry =
              doc?.personalInsuranceNumber?.personalInsuranceNumberExpiry;
            let personalInsuranceNumberDays = moment(
              personalInsuranceNumberExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            //Identity documents
            let bankStatementExpiry = doc?.bankStatement?.bankStatementExpiry;
            let bankStatementDays = moment(
              bankStatementExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let baringServiceExpiry =
              doc?.disclosureBarringService?.baringServiceExpiry;
            let baringServiceDays = moment(
              baringServiceExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let identityExpiry = doc?.proofOfIdentity?.identityExpiry;
            let identityDays = moment(identityExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );

            let profileExpiry = doc?.profilePhoto?.profileExpiry;
            let profileDays = moment(profileExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );

            if (privateHireDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Private hire expires in ${privateHireDays} days`,
              ]);
            }

            if (DVLAPlasticDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s DVLA Plastic expires in ${DVLAPlasticDays} days`,
              ]);
            }

            if (DVLAElectronicCodeDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s DVLA Electronic Code expires in ${DVLAElectronicCodeDays} days`,
              ]);
            }

            if (insuranceSupportingDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Insurance Supporting expires in ${insuranceSupportingDays} days`,
              ]);
            }

            if (insuranceCertificateDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Insurance Certificate expires in ${insuranceCertificateDays} days`,
              ]);
            }

            if (publicLiabilityInsuranceDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Public Liability Insurance expires in ${publicLiabilityInsuranceDays} days`,
              ]);
            }

            if (phvLicenceDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Phv Licence expires in ${phvLicenceDays} days`,
              ]);
            }

            if (motTestCertificateDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s MoT Test Certificate expires in ${motTestCertificateDays} days`,
              ]);
            }

            if (logBookDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Log Book expires in ${logBookDays} days`,
              ]);
            }

            if (personalInsuranceNumberDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Personal Insurance Number expires in ${personalInsuranceNumberDays} days`,
              ]);
            }

            if (bankStatementDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Bank Statement expires in ${bankStatementDays} days`,
              ]);
            }

            if (baringServiceDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Baring Service expires in ${baringServiceDays} days`,
              ]);
            }

            if (identityDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Identity Proof expires in ${identityDays} days`,
              ]);
            }

            if (profileDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Profile Photo expires in ${profileDays} days`,
              ]);
            }
            return null;
          });
          return null;
        });
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (newNotifications.length > 0) {
      dispatch(saveNotifications(newNotifications, () => setLoader(false)));
    } else {
      dispatch(getAllNotifications(setLoader(false)));
    }

  }, [dispatch, newNotifications]);

  const handleRead = () => {
    setNotificationLoader(true);
    dispatch(getReadNotifications(() => setNotificationLoader(false)));
  };
  const handleAll = () => {
    setNotificationLoader(true);
    dispatch(getAllNotifications(() => setNotificationLoader(false)));
  };
  const handleUnRead = () => {
    setNotificationLoader(true);
    dispatch(getUnReadNotifications(() => setNotificationLoader(false)));
  };
  const handleMarkAllAsRead = () => {
    setNotificationLoader(true);
    dispatch(markAllAsRead(() => setNotificationLoader(false)));
  };
  if (loader) {
    return (
      <Spin
        tip="Please Wait..."
        style={{ position: "relative", top: "50%", left: "40%" }}
      />
    );
  }

  const filteredNotifications = notifications.filter((notification) => {
    const notificationTime = moment(notification.time);
    if (timeFilter === 'lastDay') {
      return notificationTime.isAfter(moment().subtract(1, 'day'));
    } else if (timeFilter === 'lastWeek') {
      return notificationTime.isAfter(moment().subtract(1, 'week'));
    } else if (timeFilter === 'lastMonth') {
      return notificationTime.isAfter(moment().subtract(1, 'month'));
    } else {
      return true; // show all notifications
    }
  });


  


  return (
    <Container fluid className=" w-100  pb-5">
      <Row>
        <Col lg={12} className=" pl-0 ">
          <h2 className="fw-bold">Notifications</h2>
        </Col>
        <br />
        <br />
        <div className="flex space-x-3">
          {/* search bar start */}
          <div className="w-full py-2">
          <input
            type="text"
            placeholder="Search notifications..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-4 py-2 border rounded-md w-full"
          />
        </div>
{/* search bar end */}

          {navData.map((item, i) => {
            return (
              <button
                key={i}
                onClick={(e) => handleClick(e, i)}
                className={`${
                  active === i ? "active" : ""
                } text-lg font-semibold`}
              >
                {item}
              </button>
            );
          })}
        </div>

        <Col
          className="py-2 pb-4"
          style={{
            background: "#D9D9D9",
            borderRadius: "20px",
            minHeight: "500px",
          }}
        >
          
          {notificationLoader === true ? (
            <Spin tip="Please Wait..." style={{ margin: "auto", width: "100%", marginTop: "20%"}} />
          ) : (
            <>
              <div className="w-full flex items-end justify-end">
                {notifications.length > 0 && item.name !== "Read" ? (
                  <button
                    className="font-medium text-lg"
                    onClick={handleMarkAllAsRead}
                  >
                    Mark all as read
                  </button>
                ) : (
                  ""
                )}
              </div>
              <Row>
                {notifications.length > 0 &&
                  notifications?.map((notification, index) => {
                    return (
                      <div
                        className="pl-0 w-full"
                        style={{
                          background: `${
                            notification?.isRead ? "#F6F6F6" : "#D0CFCF"
                          }`,
                        }}
                        key={index}
                      >
                        <div
                          className="p-2 flex w-full flex-col space-y-2 cursor-pointer"
                          onClick={() =>
                            dispatch(updateNotification(notification, () => {}))
                          }
                        >
                          <span className="text-black">
                            {notification?.text}
                          </span>
                          <div className="flex space-x-6">
                            <span className="opacity-60">
                              {notification?.time}
                            </span>
                            <span className="opacity-60">
                              {notification?.date}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {notifications.length <= 0 && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: "50vh" }}
                  >
                    <Empty />
                  </div>
                )}
              </Row>

            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
