import "moment-weekday-calc";

import { Badge, DatePicker, Table } from "antd";
import moment from "moment";
import React, { memo, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { getAdminShiftsByDate } from "../../../redux/admin/action";
import { enumerateDaysBetweenDates } from "../../../utils/convertions";

const { RangePicker } = DatePicker;
function Index() {
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);
  const [
    shifts, setShifts
  ] = useState([]);

  const [
    date, setDate
  ] = useState([
    moment(new Date()), moment(new Date())
  ]);
  const stopLoader = (data) => {
    setLoader(false);
    if (data) {
      setShifts(data);
    }
  };

  useEffect(() => {
    setLoader(true);

    const fetchDays = enumerateDaysBetweenDates(date[0], date[1]);

    dispatch(getAdminShiftsByDate(fetchDays, stopLoader));
  }, [
    dispatch, date
  ]);
  const setDateHandle = (e) => {
    if (!e) return;
    setDate(e);
  };

  const completedShift = shifts.filter((shift) => shift?.completed);

  const activeShifts = shifts.filter((shift) => shift?.active);

  const columns = [
    {
      title: "Date",
      align: "center",
      dataIndex: "date",
      key: "date",
      width: 120,
      render: (e) => {
        if (e) {
          return moment(Number(e)).format("DD-MMM-YY");
        }
        return moment().format("DD-MMM-YY");
      },
    },
    {
      title: "Day",
      align: "center",
      dataIndex: "date",
      key: "date",
      className: "capitalize",
      width: 120,
      render: (e) => {
        if (e) {
          return moment(Number(e)).format("dddd");
        }
        return moment().format("dddd");
      },
    },
    {
      title: "Schedule Time",
      align: "center",
      dataIndex: "time",
      key: "time",
      width: 120,
      render: (e, record) => {
        if (!record?.hasSchedule) {
          return "No Schedule";
        }
        if (!record.lockInTime) {
          return (
            <div>
              {record?.shecduleHours?.map((a,index) => {
                return (
                  <div key={index}>
                    {a?.start}
                    {" "}
-
                    {a?.end}
                    {" "}
(
                    {a?.hours}
)
                  </div>
                );
              })}
            </div>
          );
        }
        return `${record?.time?.join(" - ")} (${record?.shecduleHours?.hours})`;
      },
    },
    {
      title: "Working Time",
      align: "center",
      dataIndex: "day",
      key: "day",
      className: "capitalize",
      width: 120,
      render: (_, record) => {
        if (!record?.lockInTime) {
          return "N/A";
        }
        const scheduleTime =
          moment(record?.time?.[1], "HH:mm").diff(
            moment(record?.time?.[0], "HH:mm"),
            "hours"
          ) + 1;
        if (record?.lockOutTime) {
          return `${moment(Number(record.lockInTime)).format(
            "HH:mm"
          )} - ${moment(Number(record.lockOutTime)).format("HH:mm")} (${(
            (moment(Number(record.lockOutTime)).diff(
              moment(Number(record.lockInTime)),
              "minutes"
            ) +
              1) /
            60
          ).toFixed(2)})`;
        }
        const workingTime = (
          (moment().diff(moment(Number(record.lockInTime)), "minutes") + 1) /
          60
        ).toFixed(1);
        const workingTimeNumber = parseFloat(workingTime);
        if (scheduleTime <= workingTimeNumber) {
          return `${moment(Number(record.lockInTime)).format(
            "HH:mm"
          )} - N/A (${scheduleTime})`;
        }
        return `${moment(Number(record.lockInTime)).format(
          "HH:mm"
        )} - N/A (${workingTimeNumber} hours)`;
      },
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      className: "capitalize",
      width: 120,
      render: (e) => {
        let color = "";
        if (e === "Completed") {
          color = "green";
        }
        if (e === "Active") {
          color = "blue";
        }
        if (e === "Inactive") {
          color = "red";
        }

        return <Badge count={e}
          style={ { backgroundColor: color } } />;
      },
    },
    {
      title: "Absence Status",
      align: "center",
      dataIndex: "absence",
      key: "absence",
      className: "capitalize",
      width: 120,
      render: (e) => {
        if (e?.length) {
          return <Badge count="Absent"
            style={ { backgroundColor: "red" } } />;
        }
        return <Badge count="Present"
          style={ { backgroundColor: "#52c41a" } } />;
      },
    },
    {
      title: "Attendance percentage",
      align: "center",
      dataIndex: "absencePercentage",
      key: "absencePercentage",
      className: "capitalize",
      width: 120,
      render: (e) => {
        if (e) {
          return `${e?.toFixed(2)}%`;
        }
        return "0%";
      },
    },
  ];

  const totalScheduleHours = useMemo(() => {
    const filterShifts = shifts.filter((shift) => shift.hasSchedule);
    let shcduleHours = filterShifts.reduce((acc, curr) => {
      if (curr.lockInTime) {
        return acc + curr.shecduleHours.hours;
      }
      return acc + curr.shecduleHours.reduce((a, b) => a + b.hours, 0);
    }, 0);
    return shcduleHours || 0;
  }, [shifts]);
  const totalWorkingHours = useMemo(() => {
    const filterShifts = shifts.filter((shift) => shift.hasSchedule);
    let workingHours = filterShifts.reduce((acc, curr) => {
      const shecduleHours =
        curr.shecduleHours?.hours ||
        curr.shecduleHours?.reduce((a, b) => a + b.hours, 0);

      if (curr.workingHours >= shecduleHours) {
        return acc + shecduleHours;
      }
      return acc + curr.workingHours;
    }, 0);
    return workingHours || 0;
  }, [shifts]);

  return (
    <Container fluid
      className=" w-100  pb-5">
      <Row>
        <Col lg={12}
          className=" pl-0 ">
          <h1 className="fw-bold">
Shifts
          </h1>
        </Col>
        <Col lg={12}
          className=" flex justify-between flex-wrap  p-3">
          <div className="flex flex-wrap ">
            <p className={"text-lg text-green-600 mx-1 px-2 my-0"}>
              Completed:
              <span className="text-black">
                {completedShift.length}
              </span>
            </p>
            <p className={"text-lg text-yellow-400 mx-1 px-2 my-0"}>
              Active: 
              {" "}
              <span className="text-black">
                {activeShifts.length}
              </span>
            </p>
            <p className={"text-lg text-blue-600 mx-1 px-2 my-0"}>
              Total Work Percentage:
              <span className="text-black mx-1">
                {totalScheduleHours > 0 ? (
                  <>
                    {((totalWorkingHours / totalScheduleHours) * 100).toFixed(
                      2
                    )}
                    %
                  </>
                ) : (
                  "0%"
                )}
              </span>
            </p>
            <p className={"text-lg text-blue-600 mx-1 px-2 my-0"}>
              Working Hours:
              <span className="text-black mx-1">
                {totalWorkingHours?.toFixed(2)}
/
                {totalScheduleHours}
              </span>
            </p>
            <p className={"text-lg mx-1 px-2 my-0"}>
              Total : 
              {" "}
              <span>
                {shifts.length}
              </span>
            </p>
          </div>
          <RangePicker
            format="DD MMM YYYY"
            value={date}
            onChange={setDateHandle}
          />
        </Col>
        <Col lg={12}
          className="bg-white p-2 shadow-md">
          <Table
            dataSource={shifts.sort((a, b) => {
              return Number(b.date) - Number(a.date);
            })}
            loading={loader}
            bordered
            className="table-curved adminTable"
            columns={columns}
            pagination={false}
            scroll={ { y: 500 } }
          />
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
