import "./index.css";

import React, { memo } from "react";

function Index({ className, color }) {
  return (
    <svg width={20}
      height={20}
      className={className}>
      <circle cx="7"
        cy="7"
        r="7"
        fill={color} />
      Sorry, your browser does not support inline SVG.
    </svg>
  );
}
export default memo(Index);
