import { Empty, Spin } from "antd";
import moment from "moment";
import React, { memo, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getTodayAdminShifts } from "../../redux/auth/action";
import ShiftCard from "./shiftCard";

function Index() {
  const { shifts, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };

  const todaySchedules = user?.scheduleTime?.[moment().format("dddd").toLowerCase()];
  // console.log("Todays shifts ", todaySchedules);

  const finalTodayShits = useMemo(() => {
    return todaySchedules?.filter(
      (shift) => !shifts?.map((e) => e.dayId).includes(shift.id)
    );
  }, [shifts, todaySchedules]);

  // console.log("final shifts ", finalTodayShits);


  const finalShifts = useMemo(() => {
    return shifts?.filter((shift) => !shift?.offDay);
  }, [shifts]);

  // console.log("my shifts ", finalShifts);


  useEffect(() => {
    setLoader(true);
    dispatch(
      getTodayAdminShifts(
        {
          start: moment().startOf("d").format("x"),
          end: moment().endOf("d").format("x"),
        },
        stopLoader
      )
    );
  }, [dispatch]);

  return (
    <Container fluid className=" w-100  pb-5">
      <Spin spinning={loader}>
        <Row>
          <Col lg={12} className=" pl-0 ">
            <h2 className="fw-bold">Schedules</h2>
          </Col>
          {finalShifts?.length > 0 || finalTodayShits?.length > 0 ? (
            <>
              <Col lg={12}>
                <h1 className="text-base fw-bold my-3">Today Shifts</h1>
              </Col>
              {finalShifts.map((shift, index) => {
                if (shift?.offDay) {
                  return null;
                }
                return (
                  <Col lg={6} className="p-2" key={index}>
                    <ShiftCard key={index} shift={shift} />
                  </Col>
                );
              })}
              {finalTodayShits?.map((shift, index) => {
                return (
                  <Col lg={6} className="p-2" key={index}>
                    <ShiftCard key={index} shift={shift} />
                  </Col>
                );
              })}
            </>
          ) : (
            <Empty description="No Shift Assign yet" />
          )}
        </Row>
      </Spin>
    </Container>
  );
}

export default memo(Index);
