import { Tabs } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import React, { memo } from "react";
import { FaBicycle, FaBuilding, FaCar, FaShopware, FaUser } from "react-icons/fa";

import Company from "./company";
import Rider from "./rider";
import User from "./user";

const { TabPane } = Tabs;
function Index() {
  return (
    <Container fluid>
      <Row className=" flex justify-between gap-4 ">
          <Col lg={12}>
            <h2 className="fw-bold">
              Invoices
            </h2>
          </Col>
      </Row> 
      <Tabs defaultActiveKey="1"
        centered>
        <TabPane
          tab={<span className="flex items-center fw-bold">
            <FaCar className="mr-2" />
              Drivers
          </span>}
          key="1"
        >
          <Rider />
        </TabPane>
        <TabPane
          tab={<span className="flex items-center fw-bold">
            <FaUser className="mr-2" />
              Riders
          </span>}
          key="2"
        >
          <User />
        </TabPane>
        <TabPane
          tab={<span className="flex items-center fw-bold">
            <FaBuilding className="mr-2" />
              Company
          </span>}
          key="3"
        >
          <Company />
        </TabPane>
      </Tabs>
    </Container>
  );
}
export default memo(Index);
