import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DOMPurify from "dompurify";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button as AntdButton } from "antd";
import moment from "moment";
import { storage1 } from "../../config/firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { deleteBlog, getBlogs, saveBlog } from "../../redux/admin/action";
import { FaEdit, FaTrash } from "react-icons/fa";
import toTimestamp from "../../utils/convertions";
import { Link } from "react-router-dom";

const index = () => {
  const [modalShow, setModalShow] = useState(false);
  const [blogId, setBlogId] = useState(null);
  const { blogs } = useSelector((state) => state.admin);
  const [textAreaCount, setTextAreaCount] = useState(0);

  const recalculate = (e) => {
    setTextAreaCount(e.target.value.length);
  };
  const [blog, setBlog] = useState({
    title: "",
    desc: EditorState.createEmpty(),
    image: "",
    shortDesc: "",
    featured: false,
  });
  const [errors, setErrors] = useState([]);

  const [deleted, setDeleted] = useState(false);

  // const [blogs, setBlogs] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const stopLoader = () => {
    setLoader(false);
    setBlog({
      title: "",
      desc: EditorState.createEmpty(),
      image: "",
      shortDesc: "",
      featured: false,
    });
  };
  const changeInput = (e) => {
    var { name, value } = e.target;
    setBlog((p) => ({ ...p, [name]: value }));
  };
  const submitBlog = (e) => {
    e.preventDefault();
    let error = [];
    if (blog.title === "") {
      error.push("titleEmpty");
    }
    if (blog.desc === "") {
      error.push("descEmpty");
    }
    if (blog.image === "") {
      error.push("imgEmpty");
    }

    if (error.length) {
      setErrors(error);
    } else {
      setErrors([]);
      setLoader(true);
      const html = `<div>${draftToHtml(
        convertToRaw(blog?.desc?.getCurrentContent())
      )}</div>`;
      let slug = blog.title
        .replace(/ /g, "-")
        .replace(/\?/g, "")
        .replace(/,/g, "")
        .replace(/"/g, "")
        .replace(/'/g, "")
        .toLowerCase();

      dispatch(
        saveBlog(
          {
            title: blog.title,
            slug,
            desc: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } }),
            image: blog.image,
            shortDesc: blog.shortDesc,
            views: 0,
            featured: blog.featured,
            publishDate: moment().format("DD MMM YYYY"),
            updateDate: moment().format("DD MMM YYYY"),
            createdAt: toTimestamp(new Date()),
          },
          stopLoader
        )
      );
    }
  };

  const stopDelete = () => {
    setDeleted(false);
  };
  const deletBlog = (e, id) => {
    e.preventDefault();
    setDeleted(true);
    if (window.confirm("Want to delete this blog?")) {
      dispatch(deleteBlog(id, stopDelete));
    }
    setDeleted(false);
  };

  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  return (
    <Container fluid>
      <Row className="gap-3">
        <Col lg={12}>
          <h1 className="fw-bold">Blog</h1>
        </Col>
        <Col lg={6} className=" p-4 shadow-lg rounded-lg bg-white">
          <Form onSubmit={submitBlog}>
            <Form.Group controlId="formBasicTitle">
              <Form.Label>Title:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                name="title"
                value={blog.title}
                onChange={changeInput}
              />
              {errors.includes("titleEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={{ fontSize: "12px" }}
                >
                  Enter title
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicTitle" className="mt-3">
              <Form.Label>Short description:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                // type="text"
                placeholder="Short description"
                name="shortDesc"
                onChange={(e) => {
                  changeInput(e);
                  recalculate(e);
                }}
                value={blog.shortDesc}
              />
            </Form.Group>
            <p style={{ fontSize: "1rem", float: "right" }}>
              {" "}
              {`Charaters count: ${textAreaCount}`}{" "}
            </p>
            <Form.Group className="mt-3" controlId="formBasicDescription">
              <Form.Label>Write blog:</Form.Label>
              <Editor
                editorState={blog.desc}
                name="desc"
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName border"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  setBlog((p) => ({ ...p, desc: e }));
                }}
              />
              {errors.includes("descEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={{ fontSize: "12px" }}
                >
                  Enter description
                </span>
              )}
            </Form.Group>

            <Form.Group className="mt-3" controlId="formBasicTitle">
              <Form.Label>Add featured image:</Form.Label>
              <Upload
                className="ml-3"
                customRequest={(e) => {
                  const cvRef = ref(
                    storage1,
                    `blog_images/${e.file.name + e.file.uid}`
                  );
                  uploadBytes(cvRef, e.file).then((snapshot) => {
                    e.onSuccess("ok");
                    getDownloadURL(snapshot.ref).then((url) => {
                      setBlog({ ...blog, image: url });
                    });
                  });
                }}
                onRemove={(e) => {
                  const cvRef = ref(storage1, `blog_images/${e.name + e.uid}`);
                  getDownloadURL(cvRef).then((url) => {
                    setBlog({ ...blog, image: "" });
                  });
                  deleteObject(cvRef).then(() => {
                    return;
                  });
                }}
              >
                <AntdButton icon={<UploadOutlined />}>Click to upload</AntdButton>
              </Upload>
              {errors.includes("imgEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={{ fontSize: "12px" }}
                >
                  Upload featured image
                </span>
              )}
            </Form.Group>
            <Form.Check
              type="checkbox"
              id="checkbox"
              label="Featured post"
              defaultChecked={blog.featured}
              className="mt-3"
              onChange={(e) => {
                setBlog({ ...blog, featured: e.target.checked });
              }}
            />
            <Button
              disabled={loader}
              variant="primary"
              className="mt-3 btn-sm fw-bolder text-white btn-warning"
              type="submit"
            >
              {loader ? "Publishing..." : "Publish"}
            </Button>
          </Form>
        </Col>
        <Col className="p-4 rounded-lg shadow-lg bg-white">
          <Table bordered hover className="text-center">
            <thead style={{ background: "#6ABABD", color: "white" }}>
              <tr>
                <th>Blog title</th>
                <th>Publish on</th>
                <th>Updated on</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((doc, index) => (
                <tr key={index}>
                  <td>{doc?.title}</td>
                  <td>{doc?.publishDate}</td>
                  <td>{doc?.updateDate}</td>
                  <td>
                    <button className="btn-md fw-bolder text-white ml-2 mr-2">
                      <Link to={`/blog/edit/${doc?.id}`}>
                        {" "}
                        <FaEdit style={{ color: "blue" }} />
                      </Link>
                    </button>
                    <button
                      disabled={deleted}
                      className="btn-md fw-bolder"
                      onClick={(e) => deletBlog(e, doc.id)}
                    >
                      <FaTrash style={{ color: "red" }} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default index;
