export const GET_ADMIN_LIVE_ORDERS = "GET_ADMIN_LIVE_ORDERS";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const GET_UNBOUND_RIDERS = "GET_UNBOUND_RIDERS";
export const REMOVE_RIDER = "REMOVE_RIDER";
export const GET_ORDERS_HISTORY = "GET_ORDERS_HISTORY";

export const GET_RIDES_HISTORY = "GET_RIDES_HISTORY";

export const GET_ADMINS = "GET_ADMINS";
export const ADD_ADMIN = "ADD_ADMIN";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const GET_RIDERS = "GET_RIDERS";
export const ADD_RIDER = "ADD_RIDER";
export const UPDATE_RIDER = "UPDATE_RIDER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_RESTAURANT = "UPDATE_RESTAURANT";
export const DELETE_RESTAURANT = "DELETE_RESTAURANT";
export const DELETE_RIDER = "DELETE_RIDER";
export const DELETE_USER = "DELETE_USER";
export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const ADD_RESTAURANT = "ADD_RESTAURANT";
export const GET_RESTAURANTS = "GET_RESTAURANTS";
export const UPDATE_SITE_SETTINGS = "UPDATE_SITE_SETTINGS";
export const ADD_ADMIN_ROLE = "ADD_ADMIN_ROLE";
export const REMOVE_ADMIN_ROLE = "REMOVE_ADMIN_ROLE";
export const UPDATE_ADMIN_ROLE = "UPDATE_ADMIN_ROLE";
export const GET_ADMIN_ROLE = "GET_ADMIN_ROLE";
export const FETCH_PROFILE_RESTAURANT = "FETCH_PROFILE_RESTAURANT";
export const FETCH_PROFILE_RIDER = "FETCH_PROFILE_RIDER";
export const FETCH_PROFILE_USER = "FETCH_PROFILE_USER";
export const FETCH_PROFILE_ADMIN = "FETCH_PROFILE_ADMIN";

export const UPDATE_PROFILE_RESTAURANT = "UPDATE_PROFILE_RESTAURANT";
export const UPDATE_PROFILE_RIDER = "UPDATE_PROFILE_RIDER";
export const UPDATE_PROFILE_USER = "UPDATE_PROFILE_USER";
export const UPDATE_PROFILE_ADMIN = "UPDATE_PROFILE_ADMIN";

export const GET_ALL_VOUCHERS = "GET_ALL_VOUCHERS";
export const ADD_VOUCHER = "ADD_VOUCHER";
export const UPDATE_VOUCHERS = "UPDATE_VOUCHERS";
export const REMOVE_VOUCHER = "REMOVE_VOUCHER";

export const GET_PAYMENTS = "GET_PAYMENTS";
export const REMOVE_PAYMENT = "REMOVE_PAYMENT";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const UPDATE_PAYMENTS = "UPDATE_PAYMENTS";

export const GET_SCHEDULES = "GET_SCHEDULES";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const ADD_LEAVE = "ADD_LEAVE";
export const GET_LEAVES = "GET_LEAVES";
export const UPDATE_LEAVE = "UPDATE_LEAVE";
export const DELETE_LEAVE = "DELETE_LEAVE";
export const ADD_SHIFT = "ADD_SHIFT";
export const GET_SHIFTS = "GET_SHIFTS";
export const UPDATE_SHIFT = "UPDATE_SHIFT";
export const REMOVE_SHIFT = "REMOVE_SHIFT";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";

export const ADD_VEHICLE_TYPE = "ADD_VEHICLE_TYPE";
export const UPDATE_VEHICLE_TYPE = "UPDATEcityUPDATE_PRICINGS_VEHICLE_TYPE";
export const GET_VEHICLE_TYPES = "GET_VEHICLE_TYPES";
export const DELETE_VEHICLE_TYPE = "DELETE_VEHICLE_TYPE";

export const GET_DRIVER_VEHICLES = "GET_DRIVER_VEHICLES";
export const ADD_DRIVER_VEHICLE = "ADD_DRIVER_VEHICLE";
export const UPDATE_DRIVER_VEHICLE = "UPDATE_DRIVER_VEHICLE";
export const DELETE_DRIVER_VEHICLE = "DELETE_DRIVER_VEHICLE";

export const GET_PRICINGS = "GET_PRICINGS";
export const DELETE_PRICINGS = "DELETE_PRICINGS";

export const UPDATE_PRICINGS = "UPDATE_PRICINGS";
export const ADD_PRICINGS = "ADD_PRICINGS";

export const ADD_RIDE = "ADD_RIDE";
export const GET_RIDE = "GET_RIDE";
export const UPDATE_RIDE = "UPDATE_RIDE";
export const DELETE_RIDE = "DELETE_RIDE";
export const CANCEL_RIDE = "CANCEL_RIDE";
export const COMPLETE_RIDE = "COMPLETE_RIDE";

export const ESTIMATED_COST = "ESTIMATED_COST";

export const GET_CITIES = "GET_CITIES";
export const ADD_CITY = "ADD_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const REMOVE_CITY = "REMOVE_CITY";

export const GET_BASES = "GET_BASES";
export const ADD_BASE = "ADD_BASE";
export const UPDATE_BASE = "UPDATE_BASE";
export const REMOVE_BASE = "REMOVE_BASE";

export const ADD_TARRIF = "ADD_TARRIF";
export const GET_TARRIF = "GET_TARRIF";
export const UPDATE_TARRIF = "UPDATE_TARRIF";
export const DELETE_TARRIF = "DELETE_TARRIF";

export const GET_HOLIDAY_SURCHARGE = "GET_HOLIDAY_SURCHARGE";

export const GET_CITY = "GET_CITY";
export const UPDATE_PROFILE_CITY = "UPDATE_PROFILE_CITY";

export const ADD_CAREER = "ADD_CAREER";
export const GET_CAREERS = "GET_CAREERS";
export const UPDATE_CAREER = "UPDATE_CAREER";
export const DELETE_CAREER = "DELETE_CAREER";

export const ADD_BLOG = "ADD_BLOG";
export const GET_BLOGS = "GET_BLOGS";
export const DELETE_BLOG = "DELETE_BLOG";
export const UPDATE_BLOG = "UPDATE_BLOG";

export const APPROVED_DOCUMENTS = "APPROVED_DOCUMENTS";

export const GET_DRIVER_EARNINGS = "GET_DRIVER_EARNINGS";
export const GET_DRIVER_INVOICES = "GET_DRIVER_INVOICES";