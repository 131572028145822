import "./index.css";

import {
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Affix, Badge, Dropdown, Layout, Modal, Tooltip } from "antd";
import React, { memo, useEffect, useState } from "react";
import { FaEllipsisH, FaTimes } from "react-icons/fa";
import { AiOutlineBell } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Pulse from "react-reveal/Pulse";
import RubberBand from "react-reveal/RubberBand";
import moment from "moment"

import ChatSupport from "../../components/chatSupport";
import Sidebar from "../../components/sidebar";
import { getAllChatSessions } from "../../redux/admin/action";
import { Logout } from "../../redux/auth/action";
import { getAllNotifications, getRiderDetails, saveNotifications } from "../../redux/rider/action";
import { Link } from "react-router-dom";

const { Header, Content } = Layout;

function Index({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const onToggle = () => {
    setCollapsed(!collapsed);
  };

  const { roles } = useSelector((state) => state.admin);
  const [newNotifications, setNewNotifications] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { photoURL } = user;
  const [loader, setLoader] = useState(false);
  const [sessions, setSessions] = React.useState([]);
  const { notifications, hasUnreadNotifications } = useSelector((state) => state.rider);

  const stopLoader = () => {
    setLoader(false);
  };
  const dispatch = useDispatch();
  const role = roles.find((e) => e.id === user.role);

  // console.log("Check Role", role);

  function confirm() {
    Modal.confirm({
      title: "Logout",
      icon: <LogoutOutlined />,
      content: "Want to Logout?",
      onOk: () => {
        dispatch(Logout(stopLoader));
      },
      okText: loader ? "Logout" : "Logout Waiting...",
      cancelText: "Cancel",
    });
  }

  useEffect(() => {
    dispatch(
      getAllChatSessions((data) => {
        setSessions(data);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setLoader(true);
    dispatch(
      getRiderDetails((data) => {
        data.map((item) => {
          item?.documents?.map((doc) => {
            //Driving licence documents
            let privateHireExpiry = doc?.privateHire?.privateHireExpiry;
            let privateHireDays = moment(privateHireExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );
            let DVLAPlasticExpiry = doc?.DVLAPlastic?.DVLAPlasticExpiry;
            let DVLAPlasticDays = moment(DVLAPlasticExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );

            let DVLAElectronicCodeExpiry =
              doc?.DVLAElectronicCode?.DVLAElectronicCodeExpiry;
            let DVLAElectronicCodeDays = moment(
              DVLAElectronicCodeExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            //Vehicle documents
            let insuranceSupportingExpiry =
              doc?.insuranceSupporting?.insuranceSupportingExpiry;
            let insuranceSupportingDays = moment(
              insuranceSupportingExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let insuranceCertificateExpiry =
              doc?.insuranceCertificate?.insuranceCertificateExpiry;
            let insuranceCertificateDays = moment(
              insuranceCertificateExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let publicLiabilityInsuranceExpiry =
              doc?.publicLiabilityInsurance?.publicLiabilityInsuranceExpiry;
            let publicLiabilityInsuranceDays = moment(
              publicLiabilityInsuranceExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let phvLicenceExpiry = doc?.phvLicence?.phvLicenceExpiry;
            let phvLicenceDays = moment(phvLicenceExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );

            let motTestCertificateExpiry =
              doc?.motTestCertificate?.motTestCertificateExpiry;
            let motTestCertificateDays = moment(
              motTestCertificateExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let logBookExpiry = doc?.logBook?.logBookExpiry;
            let logBookDays = moment(logBookExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );

            let personalInsuranceNumberExpiry =
              doc?.personalInsuranceNumber?.personalInsuranceNumberExpiry;
            let personalInsuranceNumberDays = moment(
              personalInsuranceNumberExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            //Identity documents
            let bankStatementExpiry = doc?.bankStatement?.bankStatementExpiry;
            let bankStatementDays = moment(
              bankStatementExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let baringServiceExpiry =
              doc?.disclosureBarringService?.baringServiceExpiry;
            let baringServiceDays = moment(
              baringServiceExpiry,
              "DD MMM YYYY"
            ).diff(moment(), "days");

            let identityExpiry = doc?.proofOfIdentity?.identityExpiry;
            let identityDays = moment(identityExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );

            let profileExpiry = doc?.profilePhoto?.profileExpiry;
            let profileDays = moment(profileExpiry, "DD MMM YYYY").diff(
              moment(),
              "days"
            );

            if (privateHireDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Private hire expires in ${privateHireDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Private hire expires in ${privateHireDays} days`
              // );
            }

            if (DVLAPlasticDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s DVLA Plastic expires in ${DVLAPlasticDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s DVLA Plastic expires in ${DVLAPlasticDays} days`
              // );
            }

            if (DVLAElectronicCodeDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s DVLA Electronic Code expires in ${DVLAElectronicCodeDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s DVLA Electronic Code expires in ${DVLAElectronicCodeDays} days`
              // );
            }

            if (insuranceSupportingDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Insurance Supporting expires in ${insuranceSupportingDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Insurance Supporting expires in ${insuranceSupportingDays} days`
              // );
            }

            if (insuranceCertificateDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Insurance Certificate expires in ${insuranceCertificateDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Insurance Certificate expires in ${insuranceCertificateDays} days`
              // );
            }

            if (publicLiabilityInsuranceDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Public Liability Insurance expires in ${publicLiabilityInsuranceDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Public Liability Insurance expires in ${publicLiabilityInsuranceDays} days`
              // );
            }

            if (phvLicenceDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Phv Licence expires in ${phvLicenceDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Phv Licence expires in ${phvLicenceDays} days`
              // );
            }

            if (motTestCertificateDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s MoT Test Certificate expires in ${motTestCertificateDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s MoT Test Certificate expires in ${motTestCertificateDays} days`
              // );
            }

            if (logBookDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Log Book expires in ${logBookDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Log Book expires in ${logBookDays} days`
              // );
            }

            if (personalInsuranceNumberDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Personal Insurance Number expires in ${personalInsuranceNumberDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Personal Insurance Number expires in ${personalInsuranceNumberDays} days`
              // );
            }

            if (bankStatementDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Bank Statement expires in ${bankStatementDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Bank Statement expires in ${bankStatementDays} days`
              // );
            }

            if (baringServiceDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Baring Service expires in ${baringServiceDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Baring Service expires in ${baringServiceDays} days`
              // );
            }

            if (identityDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Identity Proof expires in ${identityDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Identity Proof expires in ${identityDays} days`
              // );
            }

            if (profileDays === 7) {
              setNewNotifications((old) => [
                ...old,
                `${item?.driver.name}'s Profile Photo expires in ${profileDays} days`,
              ]);
              // notifications.push(
              //   `${item?.driver.name}'s Profile Photo expires in ${profileDays} days`
              // );
            }
            return null;
          });
          return null;
        });
      })
    );
  }, [dispatch]);

  
  // useEffect(() => {
  //   if (newNotifications.length > 0) {
  //     dispatch(saveNotifications(newNotifications, () => setLoader(false)));
  //   } else{
  //     dispatch(getAllNotifications(() => setLoader(false)));
  //   }
  //   // setLoader(false);
  // }, [dispatch, newNotifications]);

  const menu = (
    <div className="bg-white shadow-md w-80 p-2">
      <p className="text-center text-sm font-bold border-b m-0">{user.name}</p>
      <div className="flex flex-col custom-menu border-b flex-wrap ">
        <div className="flex items-center custom-menu-item px-2 my-1">
          <MailOutlined className="text-md " />
          <button
            href="/#"
            className="text-sm text-start p-1 font-bold break-words "
          >
            {user.email}
          </button>
        </div>
        {/* <div className="flex items-center px-2 custom-menu-item my-1">
          <ContactsOutlined className="text-md " />
          <button href="/#" className=" text-sm text-start p-1 font-bold ">
            Contact us
          </button>
        </div> */}
      </div>
      <div className="flex items-center justify-center px-2 custom-menu-item my-1">
        <LogoutOutlined className="text-md " />
        <button
          onClick={confirm}
          href="/#"
          className=" text-sm text-start p-1 font-bold "
        >
          Logout
        </button>
      </div>
    </div>
  );

  return (
    <Layout
      className="site-layout-background shadow customlayout"
      style={{ minHeight: "100vh" }}
    >
      <Sidebar collapsed={collapsed} />
      <Layout>
        <Header
          className=" shadow-md flex flex-row items-center justify-between px-4"
          style={{ padding: 0 }}
        >
          <div>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "text-lg text-white",
                onClick: onToggle,
              }
            )}
          </div>
          <div className="flex items-center justify-center space-x-3">
            <div>
              <Link to={"/notifications"}><AiOutlineBell size={30} color="white"/></Link>
              {!hasUnreadNotifications ? "" : 
              <div className="rounded-full w-2 h-2 absolute top-6" style={{right: "88px", background: "#FF0000"}}></div>
              }
            </div>
            <Dropdown
              overlay={menu}
              className="w-12 cursor-pointer  h-12  shadow-md blue-icon rounded-full flex items-center justify-center text-4xl font-bold text-white"
              trigger={["click"]}
            >
              <div>
                {photoURL ? (
                  <img
                    src={photoURL}
                    style={{ width: "100%", height: "100%" }}
                    alt="profile"
                    className="rounded-full"
                  />
                ) : (
                  user?.name?.charAt(0)
                )}
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: "24px 16px",
            overflow: "auto",
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
      {(role?.chatSupport || user?.role === "ROOT") && (
        <>
          <Affix
            offsetBottom={5}
            className=" flex justify-end  fixed bottom-0 right-10    "
          >
            <Badge count={sessions?.filter((e) => !e?.startBy)?.length || 0}>
              {showChat ? (
                <Tooltip title="Close" placement="left">
                  <div className={" chatIconBottom shadow-md    "}>
                    <Pulse>
                      <FaTimes onClick={() => setShowChat(false)} />
                    </Pulse>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Live Chat Support" placement="left">
                  <div className={" chatIconBottom shadow-md    "}>
                    <RubberBand>
                      <FaEllipsisH onClick={() => setShowChat(true)} />
                    </RubberBand>
                  </div>
                </Tooltip>
              )}
            </Badge>
          </Affix>
          {showChat && (
            <Pulse>
              <div
                className=" transition-all shadow-md "
                style={{
                  position: "fixed",
                  backgroundColor: "white",
                  bottom: 80,
                  right: 0,
                  margin: 30,
                  opacity: showChat ? 1 : 0,
                }}
              >
                <ChatSupport sessions={sessions} />
              </div>
            </Pulse>
          )}
        </>
      )}
    </Layout>
  );
}
export default memo(Index);
