import {
  FaBook,
  FaIdCard,
  FaQuestionCircle,
  FaShoppingBasket,
  FaWhmcs,
} from "react-icons/fa";

const UserLinks = [
  {
    id: 1,
    url: "/",
    text: "Profile",
    name: "profile",

    icon: FaIdCard,
  },
  {
    id: 2,
    url: "/orders",
    name: "orders",
    text: "Rides History",
    icon: FaShoppingBasket,
  },
  {
    id: 3,
    url: "/settings",
    text: "Settings",
    name: "settings",

    icon: FaWhmcs,
  },
  {
    id: 4,
    url: "/support",
    text: "Support",
    name: "support",
    icon: FaQuestionCircle,
  },
  {
    id: 1,
    url: "/notes",
    name: "notes",
    text: "Notes",

    icon: FaBook,
  },
];
export default UserLinks;
