/* eslint-disable func-names */
import * as yup from "yup";

import { ADMIN_COLLECTION } from "../config";
import { firestore } from "../config/firebase";
import AdminApi from "../redux/admin/adminApi";
import { createSlug } from "../utils/convertions";

const adminApi = new AdminApi();
yup.addMethod(yup.string, "emailisAlready", function (errorMessage) {
  return this.test("email-is-user", errorMessage, async function (value) {
    const { path, createError } = this;

    if (!value) return;

    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .where("email", "==", value);
    const doc = await userRef.get();
    if (!doc.empty) {
      return createError({ path, message: errorMessage });
    } else {
      return value;
    }
  });
});

yup.addMethod(yup.string, "uniqueName", function (errorMessage) {
  return this.test("email-is-user", errorMessage, async function (value) {
    const { path, createError } = this;
    if (!value) return;
    const data = await adminApi.getAdminBySlug(createSlug(value));
    if (data) {
      return createError({ path, message: errorMessage });
    } else {
      return value;
    }
  });
});

const schema = yup.object().shape({
  name: yup.string().required("Enter name").uniqueName("name is already"),
  role: yup.string().required("Select Role"),
  citys: yup.array().when("specificCities", {
    is: true,
    then: yup.array().required().min(1, "Please select atleast one tab"),
  }),

  specificCities: yup.boolean(),
  email: yup
    .string()
    .required("Enter email")
    .email("Enter valid email address")
    .emailisAlready("email already registered"),
});

export default schema;
