import React from "react";
import { Navigate } from "react-router-dom";

import Notes from "../../views/userProfile/notes";
import Orders from "../../views/userProfile/Orders";
import Profile from "../../views/userProfile/Profile";
import Settings from "../../views/userProfile/settings";
import Support from "../../views/userProfile/support";

const routes = [
  {
    exact: true,
    path: "/",
    name: "profile",
    component: Profile,
  },

  {
    exact: true,
    path: "/support",
    name: "support",
    component: Support,
  },
  {
    exact: true,
    path: "/orders",
    name: "orders",
    component: Orders,
  },
  {
    exact: true,
    path: "/settings",
    name: "settings",
    component: Settings,
  },
  {
    exact: true,
    path: "/notes",
    name: "notes",
    component: Notes,
  },

  {
    path: "*",
    name: "redirect",
    component: () => <Navigate to={-1}
      replace={true} />,
  },
];
export default routes;
