import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router";

import { LimitRoutes } from "../../utils/viewPages";
import routesData from "./routes";

function FilterRoutes({ routes = [] }) {
  const [
    finalRoutes, setFinalRoutes
  ] = useState([]);

  useEffect(() => {
    setFinalRoutes(LimitRoutes(routes));
  }, [routes]);

  return (
    <Suspense>
      <Routes>
        {finalRoutes
          .filter((e) => e)
          .map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;

            const Component = route.component;
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                element={<Guard>
                  <Layout>
                    {route?.children ? (
                      <SubRoutes routes={route?.children} />
                    ) : (
                      <Component />
                    )}
                  </Layout>
                </Guard>}
              />
            );
          })}
      </Routes>
    </Suspense>
  );
}

function SubRoutes({ routes = [] }) {
  return (
    <Suspense>
      <Routes>
        {routes
          .filter((e) => e)
          .map((route, i) => {
            const Component = route.component;
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                element={<Component />}
              />
            );
          })}
      </Routes>
    </Suspense>
  );
}

const renderRoutes = () => {
  return <FilterRoutes routes={routesData} />;
};

const routes = { renderRoutes };
export default routes;
