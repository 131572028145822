import { Button, Divider, Row, Spin } from "antd";
import moment from "moment";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Col, Container } from "react-bootstrap";
import { FaRetweet } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import * as constants from "../../context/constants";
import { MapContext } from "../../context/mapContext";
import {
  getLiveDetailById,
  getOrderbyOrderNumber,
} from "../../redux/admin/action";
import { ukCurrancy } from "../../utils/convertions";
import LiveMap from "./liveMap";
import ShowTime from "./showTime";
import Singleitem from "./Singleitem";

function Index() {
  const { number } = useParams();
  const storeDispatch = useDispatch();
  const [
    order, setOrder
  ] = useState(null);
  const [
    loader, setLoader
  ] = useState(true);
  const { state, dispatch } = useContext(MapContext);
  const {
    mainDistance,
    riderToRestaurantDistance,
    riderToUserDistance,
    riderDetail,
  } = state;

  const stopLoader = (data) => {
    setOrder((prev) => ({ ...prev, liveData: data }));
  };
  const getLiveOrder = useCallback(
    (data) => {
      setLoader(false);

      if (data?.id) {
        setOrder(data);
        storeDispatch(getLiveDetailById(data.id, stopLoader));
      }
    },
    [storeDispatch]
  );
  useEffect(() => {
    if (number) {
      storeDispatch(getOrderbyOrderNumber(number, getLiveOrder));
    }
  }, [
    number, storeDispatch, getLiveOrder
  ]);
  const refresh = () => {
    if (number) {
      setLoader(true);
      dispatch({
        type: constants.RESET_STATE,
      });
      storeDispatch(getOrderbyOrderNumber(number, getLiveOrder));
    }
  };
  return (
    <Container fluid
      style={ { backgroundColor: "#fafafa" } }>
      <Spin spinning={loader}>
        <Row className="gap-3">
          <Col lg={12}
            className="text-center">
            <h2 className="text-yellow-500 capitalize font-medium pt-4">
              Order Information
            </h2>
          </Col>
          <Divider className="m-0 p-0 bg-yellow-500 h-1" />
          <Col lg={6}
            className="bg-white p-3  shadow-md rounded-lg">
            <Row className="gap-3">
              <Col lg={12}>
                <h3 className="text-yellow-500 capitalize font-medium text-center">
                  Order Details
                </h3>
              </Col>
              <Divider className="m-0 p-0 bg-yellow-500 " />
              <Col lg={12}
                className="flex flex-col">
                {order?.items?.map((item, index) => (
                  <Singleitem
                    qtyshow={true}
                    deletebtn={true}
                    item={item}
                    key={index}
                  />
                ))}
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Subtotal:
                </h1>
                <p>
                  {ukCurrancy.format(order?.subtotal)}
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Delivery Fee:
                </h1>
                <p>
                  {ukCurrancy.format(order?.appliedDeliveryFee)}
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Service Fee:
                </h1>
                <p>
                  {ukCurrancy.format(order?.service)}
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Discount Fee:
                </h1>
                <p>
                  {ukCurrancy.format(order?.discount?.Discount || 0)}
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Rider Tips:
                </h1>
                <p>
                  {ukCurrancy.format(order?.ridertip)}
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Minimum Order Fee:
                </h1>
                <p>
                  {ukCurrancy.format(order?.miniOrderFee)}
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Total:
                </h1>
                <p>
                  {ukCurrancy.format(order?.total + order?.ridertip)}
                </p>
              </Col>
              <Divider className="m-0 p-0 " />
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Order Number:
                </h1>
                <p>
                  {order?.orderNumber}
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Type:
                </h1>
                <p className="capitalize">
                  {order?.type}
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
When:
                </h1>
                <p className="capitalize">
                  {order?.when}
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Created At:
                </h1>
                <p>
                  {moment(Number(order?.createdAt)).format("DD MMM YYYY HH:mm")}
                  (
                  {moment(Number(order?.createdAt)).fromNow()}
)
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
City:
                </h1>
                <p>
                  {order?.clientInfo?.location?.city}
                </p>
              </Col>
              <Col lg={12}
                className="flex justify-between items-center">
                <h1 className="text-base">
Order Status
                </h1>
                <p className=" capitalize">
                  {order?.status}
                </p>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="gap-3">
              <Col lg={12}
                className="bg-white p-3  shadow-md rounded-lg">
                <Row>
                  <Col lg={12}>
                    <h3 className="text-yellow-500 capitalize font-medium text-center">
                      Restaurant Details
                    </h3>
                  </Col>
                  <Divider className="m-0 p-0 bg-yellow-500 " />
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Name:
                    </h1>
                    <p>
                      {order?.restaurant?.restInformation.name}
                    </p>
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Address:
                    </h1>
                    <p>
                      {order?.restaurant?.restInformation?.location?.address}
                    </p>
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Phone:
                    </h1>
                    <p>
                      {order?.restaurant?.restInformation?.phone}
                    </p>
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Accepted At
                    </h1>
                    <ShowTime time={order?.acceptedTime} />
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Prepare Start At
                    </h1>
                    <ShowTime time={order?.prepareStartTime} />
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Prepare End At
                    </h1>
                    <ShowTime time={order?.preparedTime} />
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
kitchen Status
                    </h1>
                    <p className=" capitalize">
                      {order?.kitchen}
                    </p>
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Distance from Client:
                    </h1>
                    <p>
                      {mainDistance?.distance.text}
                    </p>
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Time from Client:
                    </h1>
                    <p>
                      {mainDistance?.duration.text}
                    </p>
                  </Col>
                </Row>
              </Col>
              {order?.type === "delivery" && (
                <Col lg={12}
                  className="bg-white p-3  shadow-md rounded-lg">
                  <Row>
                    <Col lg={12}>
                      <h3 className="text-yellow-500 capitalize font-medium text-center">
                        Rider Details
                      </h3>
                    </Col>
                    <Divider className="m-0 p-0 bg-yellow-500 " />
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Name:
                      </h1>
                      <p>
                        {order?.riderdetail?.name}
                      </p>
                    </Col>
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Phone:
                      </h1>
                      <p>
                        {order?.riderdetail?.phone}
                      </p>
                    </Col>
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Accepted At
                      </h1>
                      <ShowTime time={order?.riderAcceptedTime} />
                    </Col>
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Reach Restaurant At
                      </h1>
                      <ShowTime time={order?.riderReachTimeByRest} />
                    </Col>
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Collected At
                      </h1>
                      <ShowTime time={order?.riderCollectedTime} />
                    </Col>
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Reach client At
                      </h1>
                      <ShowTime time={order?.riderReachedTimeByClient} />
                    </Col>
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Delivered At
                      </h1>
                      <ShowTime time={order?.riderDeliveredTime} />
                    </Col>
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Rider Status
                      </h1>
                      <p className=" capitalize">
                        {order?.liveData?.riderStatus}
                      </p>
                    </Col>
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Live At
                      </h1>
                      <ShowTime time={riderDetail?.liveAt} />
                    </Col>
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Distance from Restaurant:
                      </h1>
                      <p>
                        {riderToRestaurantDistance?.distance.text}
                      </p>
                    </Col>
                    <Col lg={12}
                      className="flex justify-between items-center">
                      <h1 className="text-base">
Time from Restaurant:
                      </h1>
                      <p>
                        {riderToRestaurantDistance?.duration.text}
                      </p>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col lg={12}
                className="bg-white p-3  shadow-md rounded-lg">
                <Row>
                  <Col lg={12}>
                    <h3 className="text-yellow-500 capitalize font-medium text-center">
                      User Details
                    </h3>
                  </Col>
                  <Divider className="m-0 p-0 bg-yellow-500 " />
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Name:
                    </h1>
                    <p>
                      {order?.clientInfo?.name}
                    </p>
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Email:
                    </h1>
                    <p>
                      {order?.clientInfo?.email}
                    </p>
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Phone:
                    </h1>
                    <p>
                      {order?.clientInfo?.phone}
                    </p>
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Address:
                    </h1>
                    <p>
                      {order?.clientInfo?.location.address}
                    </p>
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Distance from Rider:
                    </h1>
                    <p>
                      {riderToUserDistance?.distance.text}
                    </p>
                  </Col>
                  <Col lg={12}
                    className="flex justify-between items-center">
                    <h1 className="text-base">
Time from Rider:
                    </h1>
                    <p>
                      {riderToUserDistance?.duration.text}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="gap-3">
          <Col lg={12}
            className="text-center">
            <h1 className="text-yellow-500 capitalize font-medium pt-4">
              Order Tracking
            </h1>
          </Col>
          <Divider className="m-0 p-0 bg-yellow-500 h-1" />
          <Col className="flex justify-end">
            <Button onClick={refresh}
              type="primary">
              <FaRetweet />
            </Button>
          </Col>
          <Col lg={12}
            className="my-4">
            {order && <LiveMap order={order} />}
          </Col>
        </Row>
      </Spin>
    </Container>
  );
}
export default memo(Index);
