import { DatePicker,notification } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import Uploadwithtitle from "../../components/Uploadwithtitle";
import { addRiderDetail, updateRiderDetail } from "../../redux/rider/action";

function Index({ as, info, show, onHide, onAdd, onUpdate }) {
  const [
    submitloading, setsubmitloading
  ] = useState(false);
  const [
    insuranceCertificateExpiry, setInsuranceCertificateExpiry
  ] =
    useState("");
  const [
    insuranceSupportingExpiry, setInsuranceSupportingExpiry
  ] =
    useState("");
  const [
    motTestCertificateExpiry, setMotTestCertificateExpiry
  ] = useState("");
  const [
    phvLicenceExpiry, setPhvLicenceExpiry
  ] = useState("");
  const [
    logBookExpiry, setLogBookExpiry
  ] = useState("");
  const [
    publicLiabilityInsuranceExpiry, setPublicLiabilityInsuranceExpiry
  ] =
    useState("");
  const [
    personalInsuranceNumberExpiry, setPersonalInsuranceNumberExpiry
  ] =
    useState("");

  const [
    vehicle_Document, setVehicle_Document
  ] = useState({
    insuranceCertificate: null,
    insuranceSupporting: null,
    motTestCertificate: null,
    publicLiabilityInsurance: null,
    phvLicence: null,
    logBook: null,
    personalInsuranceNumber: null,
  });
  const stopLoader = (data) => {
    if (data) {
      if (as === "NEW") {
        onAdd(data);
        notification.open({
          message: "Documents Added Successfully!",
          type: "success",
          placement: "top",
        });
      } else {
        onUpdate(data);
        notification.open({
          message: "Documents Updated Successfully!",
          type: "success",
          placement: "top",
        });
      }
      onHide?.();
    }
    setsubmitloading(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (info) {
      setVehicle_Document({
        insuranceCertificate: info.insuranceCertificate.insuranceCertificate,
        insuranceSupporting: info.insuranceSupporting.insuranceSupporting,

        motTestCertificate: info.motTestCertificate.motTestCertificate,
        publicLiabilityInsurance:
          info.publicLiabilityInsurance.publicLiabilityInsurance,
        phvLicence: info.phvLicence.phvLicence,
        logBook: info.logBook.logBook,
        personalInsuranceNumber: info.logBook.logBook,
      });
      setInsuranceCertificateExpiry(
        info.insuranceCertificate.insuranceCertificateExpiry
      );
      setInsuranceSupportingExpiry(
        info.insuranceSupporting.insuranceSupportingExpiry
      );
      setMotTestCertificateExpiry(
        info.motTestCertificate.motTestCertificateExpiry
      );
      setPersonalInsuranceNumberExpiry(
        info.personalInsuranceNumber.personalInsuranceNumberExpiry
      );
      setLogBookExpiry(info.logBook.logBookExpiry);
      setPhvLicenceExpiry(info.phvLicence.phvLicenceExpiry);
      setPublicLiabilityInsuranceExpiry(
        info.publicLiabilityInsurance.publicLiabilityInsuranceExpiry
      );
    }
  }, [info]);
  const onUploadHandle = (e) => {
    setVehicle_Document({
      ...vehicle_Document,
      ...e,
    });
  };
  const saveInDatabase = (e) => {
    e.preventDefault();
    if (
      insuranceCertificateExpiry === "" ||
      insuranceSupportingExpiry === "" ||
      motTestCertificateExpiry === "" ||
      phvLicenceExpiry === "" ||
      logBookExpiry === "" ||
      publicLiabilityInsuranceExpiry === "" ||
      personalInsuranceNumberExpiry === ""
    ) {
      if (insuranceCertificateExpiry === "") {
        document.getElementById("insuranceCertificateError").textContent =
          "Please enter expiry date!";
      }
      if (insuranceSupportingExpiry === "") {
        document.getElementById("insuranceSupportingError").textContent =
          "Please enter expiry date!";
      }
      if (motTestCertificateExpiry === "") {
        document.getElementById("motTestCertificateError").textContent =
          "Please enter expiry date!";
      }
      if (phvLicenceExpiry === "") {
        document.getElementById("phvLicenceError").textContent =
          "Please enter expiry date!";
      }
      if (logBookExpiry === "") {
        document.getElementById("logBookError").textContent =
          "Please enter expiry date!";
      }
      if (publicLiabilityInsuranceExpiry === "") {
        document.getElementById("publicLiabilityInsuranceError").textContent =
          "Please enter expiry date!";
      }
      if (personalInsuranceNumberExpiry === "") {
        document.getElementById("personalInsuranceNumberError").textContent =
          "Please enter expiry date!";
      }
    } else {
      let insuranceCertificate = vehicle_Document.insuranceCertificate;
      let insuranceSupporting = vehicle_Document.insuranceSupporting;
      let motTestCertificate = vehicle_Document.motTestCertificate;
      let publicLiabilityInsurance = vehicle_Document.publicLiabilityInsurance;
      let phvLicence = vehicle_Document.phvLicence;
      let logBook = vehicle_Document.logBook;
      let personalInsuranceNumber = vehicle_Document.personalInsuranceNumber;

      const finalData = {
        insuranceCertificate: {
          insuranceCertificate,
          insuranceCertificateExpiry,
        },
        insuranceSupporting: {
          insuranceSupporting,
          insuranceSupportingExpiry,
        },
        motTestCertificate: {
          motTestCertificate,
          motTestCertificateExpiry,
        },
        publicLiabilityInsurance: {
          publicLiabilityInsurance,
          publicLiabilityInsuranceExpiry,
        },
        phvLicence: {
          phvLicence,
          phvLicenceExpiry,
        },
        logBook: {
          logBook,
          logBookExpiry,
        },
        personalInsuranceNumber: {
          personalInsuranceNumber,
          personalInsuranceNumberExpiry,
        },
        isCompleted: true,
      };
      setsubmitloading(true);
      if (as === "NEW") {
        dispatch(addRiderDetail("vehicle_Document", finalData, stopLoader));
        return;
      }
      dispatch(updateRiderDetail(info.id, finalData, stopLoader));
    }
  };
  const onRemoveHandler = (name) => {
    setVehicle_Document((p) => {
      return {
        ...p,
        [name]: null,
      };
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addModel"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Vehicle Documents
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="gap-3 flex items-center justify-center">
            <Col lg={7}>
              <Uploadwithtitle
                key={"insuranceCertificate"}
                onRemove={onRemoveHandler}
                value={vehicle_Document?.insuranceCertificate}
                onUpload={onUploadHandle}
                name="insuranceCertificate"
                title="1. Insurance Certificate"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={insuranceCertificateExpiry
                  ? moment(insuranceCertificateExpiry, "DD MMM YYYY")
                  : ""}
                onChange={(e) => {
                  setInsuranceCertificateExpiry(
                    moment(e).format("DD MMM YYYY")
                  );
                  document.getElementById(
                    "insuranceCertificateError"
                  ).textContent = "";
                }}
                placeholder="Expiry date"
                required
              />
              <p id="insuranceCertificateError"
                style={ { color: "red" } }></p>
            </Col>
          </Row>
          <Row className="gap-3 flex items-center justify-center">
            <Col lg={7}>
              <Uploadwithtitle
                key={"insuranceSupporting"}
                onRemove={onRemoveHandler}
                value={vehicle_Document?.insuranceSupporting}
                onUpload={onUploadHandle}
                name="insuranceSupporting"
                title="2. Insurance Supporting Documents"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={insuranceSupportingExpiry
                  ? moment(insuranceSupportingExpiry, "DD MMM YYYY")
                  : ""}
                onChange={(e) => {
                  setInsuranceSupportingExpiry(
                    moment(e).format("DD MMM YYYY")
                  );
                  document.getElementById(
                    "insuranceSupportingError"
                  ).textContent = "";
                }}
                placeholder="Expiry date"
                required
              />
              <p id="insuranceSupportingError"
                style={ { color: "red" } }></p>
            </Col>
          </Row>
          <Row className="gap-3 flex items-center justify-center">
            <Col lg={7}>
              <Uploadwithtitle
                onRemove={onRemoveHandler}
                onUpload={onUploadHandle}
                key={"motTestCertificate"}
                value={vehicle_Document?.motTestCertificate}
                name="motTestCertificate"
                title="3. MOT Test Certificate"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={motTestCertificateExpiry
                  ? moment(motTestCertificateExpiry, "DD MMM YYYY")
                  : ""}
                onChange={(e) => {
                  setMotTestCertificateExpiry(
                    moment(e).format("DD MMM YYYY")
                  );
                  document.getElementById(
                    "motTestCertificateError"
                  ).textContent = "";
                }}
                placeholder="Expiry date"
                required
              />
              <p id="motTestCertificateError"
                style={ { color: "red" } }></p>
            </Col>
          </Row>
          <Row className="gap-3 flex items-center justify-center">
            <Col lg={7}>
              <Uploadwithtitle
                onRemove={onRemoveHandler}
                onUpload={onUploadHandle}
                key={"publicLiabilityInsurance"}
                value={vehicle_Document?.publicLiabilityInsurance}
                name="publicLiabilityInsurance"
                title="4. Public Liability Insurance"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={publicLiabilityInsuranceExpiry
                  ? moment(publicLiabilityInsuranceExpiry, "DD MMM YYYY")
                  : ""}
                onChange={(e) => {
                  setPublicLiabilityInsuranceExpiry(
                    moment(e).format("DD MMM YYYY")
                  );
                  document.getElementById(
                    "publicLiabilityInsuranceError"
                  ).textContent = "";
                }}
                placeholder="Expiry date"
                required
              />
              <p
                id="publicLiabilityInsuranceError"
                style={ { color: "red" } }
              ></p>
            </Col>
          </Row>
          <Row className="gap-3 flex items-center justify-center">
            <Col lg={7}>
              <Uploadwithtitle
                onRemove={onRemoveHandler}
                onUpload={onUploadHandle}
                key={"phvLicence"}
                value={vehicle_Document?.phvLicence}
                name="phvLicence"
                title="5. Private Hire Vehicle License (PHV)"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={phvLicenceExpiry
                  ? moment(phvLicenceExpiry, "DD MMM YYYY")
                  : ""}
                onChange={(e) => {
                  setPhvLicenceExpiry(moment(e).format("DD MMM YYYY"));
                  document.getElementById("phvLicenceError").textContent = "";
                }}
                placeholder="Expiry date"
                required
              />
              <p id="phvLicenceError"
                style={ { color: "red" } }></p>
            </Col>
          </Row>
          <Row className="gap-3 flex items-center justify-center">
            <Col lg={7}>
              <Uploadwithtitle
                onRemove={onRemoveHandler}
                onUpload={onUploadHandle}
                key={"personalInsuranceNumber"}
                value={vehicle_Document?.personalInsuranceNumber}
                name="personalInsuranceNumber"
                title="6. Personal Insurance Number"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={personalInsuranceNumberExpiry
                  ? moment(personalInsuranceNumberExpiry, "DD MMM YYYY")
                  : ""}
                onChange={(e) => {
                  setPersonalInsuranceNumberExpiry(
                    moment(e).format("DD MMM YYYY")
                  );
                  document.getElementById(
                    "personalInsuranceNumberError"
                  ).textContent = "";
                }}
                placeholder="Expiry date"
                required
              />
              <p
                id="personalInsuranceNumberError"
                style={ { color: "red" } }
              ></p>
            </Col>
          </Row>
          <Row className="gap-3 flex items-center justify-center">
            <Col lg={7}>
              <Uploadwithtitle
                onRemove={onRemoveHandler}
                onUpload={onUploadHandle}
                key={"logBook"}
                value={vehicle_Document?.logBook}
                name="logBook"
                title="7. Logbook (V5C)"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={logBookExpiry ? moment(logBookExpiry, "DD MMM YYYY") : ""}
                onChange={(e) => {
                  setLogBookExpiry(moment(e).format("DD MMM YYYY"));
                  document.getElementById("logBookError").textContent = "";
                }}
                placeholder="Expiry date"
                required
              />
              <p id="logBookError"
                style={ { color: "red" } }></p>
            </Col>
          </Row>
          <Col lg={12}>
            <Button onClick={saveInDatabase}>
              {submitloading ? "Saving..." : "Save"}
            </Button>
          </Col>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
