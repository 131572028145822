import { DatePicker, notification } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import Uploadwithtitle from "../../components/Uploadwithtitle";
import { addRiderDetail, updateRiderDetail } from "../../redux/rider/action";

function Index({ as, info, show, onHide, onAdd, onUpdate }) {
  const [
    submitloading, setsubmitloading
  ] = useState(false);
  const [
    DVLAPlasticExpiry, setDVLAPlasticExpiry
  ] = useState("");
  const [
    DVLAElectronicCodeExpiry, setDVLAElectronicCodeExpiry
  ] = useState("");
  const [
    privateHireExpiry, setPrivateHireExpiry
  ] = useState("");

  const [
    driving_License, setDriving_License
  ] = useState({
    DVLAPlastic: null,
    DVLAElectronicCode: null,
    privateHire: null,
  });
  const stopLoader = (data) => {
    if (data) {
      if (as === "NEW") {
        onAdd(data);
        notification.open({
          message: "Documents Added Successfully!",
          type: "success",
          placement: "top",
        });
      } else {
        onUpdate(data);
        notification.open({
          message: "Documents Updated Successfully!",
          type: "success",
          placement: "top",
        });
      }
      onHide?.();
    }
    setsubmitloading(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (info) {
      setDriving_License({
        DVLAPlastic: info.DVLAPlastic.DVLAPlastic,
        DVLAElectronicCode: info.DVLAElectronicCode.DVLAElectronicCode,
        privateHire: info.privateHire.privateHire,
      });

      setDVLAElectronicCodeExpiry(
        info.DVLAElectronicCode.DVLAElectronicCodeExpiry
      );
      setDVLAPlasticExpiry(info.DVLAPlastic.DVLAPlasticExpiry);
      setPrivateHireExpiry(info.privateHire.privateHireExpiry);
    }
  }, [info]);
  const onUploadHandle = (e) => {
    setDriving_License({
      ...driving_License,
      ...e,
    });
  };
  const saveInDatabase = (e) => {
    e.preventDefault();
    if (
      DVLAPlasticExpiry === "" ||
      DVLAElectronicCodeExpiry === "" ||
      privateHireExpiry === ""
    ) {
      if (DVLAPlasticExpiry === "") {
        document.getElementById("DVLAPlasticError").textContent =
          "Please enter expiry date!";
      }

      if (DVLAElectronicCodeExpiry === "") {
        document.getElementById("DVLAElectronicCodeError").textContent =
          "Please enter expiry date!";
      }

      if (privateHireExpiry === "") {
        document.getElementById("privateHireError").textContent =
          "Please enter expiry date!";
      }
    } else {
      let DVLAPlastic = driving_License.DVLAPlastic;
      let DVLAElectronicCode = driving_License.DVLAElectronicCode;
      let privateHire = driving_License.privateHire;

      const finalData = {
        DVLAPlastic: {
          DVLAPlastic,
          DVLAPlasticExpiry,
        },
        DVLAElectronicCode: {
          DVLAElectronicCode,
          DVLAElectronicCodeExpiry,
        },
        privateHire: {
          privateHire,
          privateHireExpiry,
        },
        isCompleted: true,
      };

      setsubmitloading(true);
      if (as === "NEW") {
        dispatch(addRiderDetail("driving_License", finalData, stopLoader));
        return;
      }
      dispatch(updateRiderDetail(info.id, finalData, stopLoader));
    }
  };
  const onRemoveHandler = (name) => {
    setDriving_License((p) => {
      return {
        ...p,
        [name]: null,
      };
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addModel"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Driving licence
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="gap-3 flex items-center justify-center">
            <Col lg={7}>
              <Uploadwithtitle
                onRemove={onRemoveHandler}
                value={driving_License["DVLAPlastic"]}
                onUpload={onUploadHandle}
                name="DVLAPlastic"
                title="1. DVLA Plastic Driving Licence"
              />
            </Col>
            <Col>
              <DatePicker
                className="h-9 w-52"
                format={"DD MMM YYYY"}
                value={DVLAPlasticExpiry
                  ? moment(DVLAPlasticExpiry, "DD MMM YYYY")
                  : ""}
                onChange={(e) => {
                  setDVLAPlasticExpiry(moment(e).format("DD MMM YYYY"));
                  document.getElementById("DVLAPlasticError").textContent = "";
                }}
                placeholder="Expiry date"
                required
              />
              <p id="DVLAPlasticError"
                style={ { color: "red" } }></p>
            </Col>
            <Row className="gap-3 flex items-center justify-center">
              <Col lg={7}>
                <Uploadwithtitle
                  onRemove={onRemoveHandler}
                  value={driving_License["DVLAElectronicCode"]}
                  onUpload={onUploadHandle}
                  name="DVLAElectronicCode"
                  title="2. DVLA Electronic Code"
                />
              </Col>
              <Col>
                <DatePicker
                  className="h-9 w-52"
                  format={"DD MMM YYYY"}
                  value={DVLAElectronicCodeExpiry
                    ? moment(DVLAElectronicCodeExpiry, "DD MMM YYYY")
                    : ""}
                  onChange={(e) => {
                    setDVLAElectronicCodeExpiry(
                      moment(e).format("DD MMM YYYY")
                    );
                    document.getElementById(
                      "DVLAElectronicCodeError"
                    ).textContent = "";
                  }}
                  placeholder="Expiry date"
                  required
                />
                <p id="DVLAElectronicCodeError"
                  style={ { color: "red" } }></p>
              </Col>
            </Row>
            <Row className="gap-3 flex items-center justify-center">
              <Col lg={7}>
                <Uploadwithtitle
                  onRemove={onRemoveHandler}
                  onUpload={onUploadHandle}
                  value={driving_License["privateHire"]}
                  name="privateHire"
                  title="3. Private Hire Driver Licence / PCO Licence"
                />
              </Col>
              <Col>
                <DatePicker
                  className="h-9 w-52"
                  format={"DD MMM YYYY"}
                  value={privateHireExpiry
                    ? moment(privateHireExpiry, "DD MMM YYYY")
                    : ""}
                  onChange={(e) => {
                    setPrivateHireExpiry(moment(e).format("DD MMM YYYY"));
                    document.getElementById("privateHireError").textContent =
                      "";
                  }}
                  placeholder="Expiry date"
                  required
                />
                <p id="privateHireError"
                  style={ { color: "red" } }></p>
              </Col>
            </Row>
            <Col lg={12}>
              <Button onClick={saveInDatabase}>
                {submitloading ? "Saving..." : "Save"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Index);
