import React from "react";
import { Navigate } from "react-router-dom";

import Bases from "../../views/cityDetail/bases";
import CityBasicInformation from "../../views/cityDetail/basic-information";
import HolidaySurcharge from "../../views/cityDetail/holidaySurcharge";
import Shifts from "../../views/cityDetail/shifts";
import Tariffs from "../../views/cityDetail/Tariffs";
import WeekdaysSurcharges from "../../views/cityDetail/weekdaysSurcharges";
import Zones from "../../views/cityDetail/zones";


const routes = [
  {
    exact: true,
    path: "/",
    name: "basicInformation",
    component: CityBasicInformation,
  },
  {
    exact: true,
    path: "/bases",
    name: "bases",
    component: Bases,
  },
  {
    exact: true,
    path: "/shifts",
    name: "shifts",
    component: Shifts,
  },
  {
    exact: true,
    path: "/tariffs",
    name: "tariffs",
    component: Tariffs,
  },
  {
    exact: true,
    path: "/weekday-surcharges",
    name: "Weekday-surcharges",
    component: WeekdaysSurcharges,
  },
  {
    exact: true,
    path: "/holiday-surcharges",
    name: "holiday-surcharges",
    component: HolidaySurcharge,
  },
  {
    exact: true,
    path: "/zones",
    name: "zones",
    component: Zones,
  },
  {
    path: "*",
    name: "redirect",
    component: () => <Navigate to={-1}
      replace={true} />,
  },
];
export default routes;
