import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import NavigoTable from "../../../components/NavigoTable";
import {
  addCityHolidaySurcharges,
  getCityHolidaySurcharges,
  getCityShifts,
  getCitySubCollection,
  removeCityHolidaySurchargesById,
} from "../../../redux/admin/action";
import HolidayRow from "./holidayRow";

function Index() {
  const columns = [
    { label: "Message", key: "message" },
    { label: "Action", key: "action" },
    { label: "Factor value", key: "factorValue" },
    { label: "From -> To", key: "fromTo" },
    { label: "Shift", key: "shift" },

    { label: "Delete", key: "delete" },
  ];
  const dispatch = useDispatch();
  const { slug } = useParams();
  const city = useSelector((state) => state.admin.city?.[slug]);
  const [
    initialValue, setInitialValue
  ] = useState([]);
  const addNewShiftHandler = useCallback(() => {
    const payload = {
      fromTo: [
        moment().format("DD MMM YYYY"), moment().format("DD MMM YYYY")
      ],
      action: "add",
    };
    dispatch(
      addCityHolidaySurcharges(payload, city?.id, (data) => {
        let newShifts = [
          ...initialValue, data
        ];
        setInitialValue(newShifts);
      })
    );
  }, [
    city, dispatch, initialValue
  ]);
  const onDeleteHandler = useCallback(
    (id) => {
      dispatch(
        removeCityHolidaySurchargesById(city?.id, id, () => {
          setInitialValue((prev) => prev.filter((pr) => pr.id !== id));
        })
      );
    },
    [
      city, dispatch
    ]
  );
  const [
    shifts, setShifts
  ] = useState([]);
  useEffect(() => {
    if (city) {
      dispatch(
        getCityShifts(city?.id, (data) => {
          setShifts(data);
        })
      );
      dispatch(
        getCityHolidaySurcharges(city?.id, (data) => {
          setInitialValue(data);
        })
      );
    }
  }, [
    city, dispatch
  ]);
  const [
    loader, setLoader
  ] = useState(false);
  const saveTariffHandler = useCallback(() => {
    setLoader(true);
    dispatch(
      getCitySubCollection(
        initialValue,
        city?.id,
        "HolidaySurcharges",
        (data) => {
          setInitialValue(data);
          setLoader(false);
        }
      )
    );
  }, [
    city, dispatch, initialValue
  ]);
  return (
    <Container fluid
      className=" w-100">
      <Row className="gap-2">
        <Col lg={12}>
          <h2 className="fw-bold">
Holiday / Rush Hours surcharge
          </h2>
        </Col>
        <Col lg={12}
          className="text-danger">
          <p>
            This option allows to set different rates at times of the day(s)
            e.g. &ldquo;Holidays&ldquo; and &ldquo;Rush hours&ldquo;.
            <br />
            To increase the price the Factor value has to be set to more than 1.
            e.g. 1.5 will result in a 50% increase.
            <br />
            To decrease the price the Factor value has to be set to less than 1.
            e.g. 0.9 will result in a 10% decrease.
          </p>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            onClick={addNewShiftHandler}
            type="primary"
            className="  fw-bold  "
          >
            <PlusOutlined className="m-auto" />
            Add new holiday surcharge
          </Button>
        </Col>
        <Col lg={12}
          className=" bg-white p-3">
          <NavigoTable
            columns={columns}
            data={initialValue}
            renderItem={(item) => {
              return (
                <HolidayRow
                  onDelete={onDeleteHandler}
                  item={item}
                  shifts={shifts}
                  onChange={(e) => {
                    const newDistance = initialValue.map((a) => {
                      if (a.id === e.id) {
                        return {
                          ...a,
                          ...e,
                        };
                      }
                      return a;
                    });
                    setInitialValue(newDistance);
                  }}
                />
              );
            }}
          />
        </Col>
        <Col lg={12}>
          <Button
            loading={loader}
            onClick={saveTariffHandler}
            type="primary"
            className="  fw-bold  "
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
