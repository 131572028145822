import { Alert, Snackbar } from "@mui/material";
import { Formik } from "formik";
import React, { memo, useState } from "react";
import { Button, Col, Form, FormGroup, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

import { updateUserPhone } from "../../../redux/admin/action";
import changePhoneValidation from "../../../validations/changePhoneValidation";

function Index() {
  const [
    updated, setUpdated
  ] = useState(false);

  const [
    loader, setLoader
  ] = useState(false);

  const dispatch = useDispatch();

  const updateProfile = (e) => {
    setLoader(true);

    dispatch(updateUserPhone(e.newPhone, stoploader));
  };
  const stoploader = (error) => {
    setLoader(false);
    if (error) {
      swal(error);
      return;
    }
    setUpdated(true);
  };
  const closeSnackBar = () => {
    setUpdated(false);
  };
  return (
    <Col lg={6}
      className="p-4">
      <h5 style={ { fontWeight: "bold" } }>
Change Contact Details
      </h5>
      <Snackbar open={updated}
        autoHideDuration={2000}
        onClose={closeSnackBar}>
        <Alert
          variant="filled"
          onClose={closeSnackBar}
          severity="success"
          sx={ { width: "100%" } }
        >
          Profile Updated!
        </Alert>
      </Snackbar>
      <Formik
        initialValues={ {
          oldPhone: "",
          newPhone: "",
        } }
        validationSchema={changePhoneValidation}
        onSubmit={updateProfile}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form
            onSubmit={handleSubmit}
            className=" profile-from d-flex gap-2 justify-content-center flex-column align-items-center"
          >
            <FormGroup className=" w-full">
              <PhoneInput
                defaultCountry="GB"
                name="oldPhone"
                value={values?.oldPhone}
                placeholder="Old Phone Number"
                className="w-full h-12"
                onChange={(phone) => setFieldValue("oldPhone", phone)}
              />
              {touched.oldPhone && errors.oldPhone && (
                <span className="w-100 text-danger ">
                  {errors.oldPhone}
                </span>
              )}
            </FormGroup>
            <FormGroup className=" w-full">
              <PhoneInput
                defaultCountry="GB"
                name="newPhone"
                value={values?.newPhone}
                placeholder="New Phone Number"
                className="w-full h-12"
                onChange={(phone) => setFieldValue("newPhone", phone)}
              />
              {touched.newPhone && errors.newPhone && (
                <span className="w-100 text-danger ">
                  {errors.newPhone}
                </span>
              )}
            </FormGroup>
            <Button
              type="submit"
              className="rounded fw-bolder text-white rounded-2 mt-2"
              variant="warning"
              disabled={loader}
            >
              {loader ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="mr-2"
                    variant="dark"
                    size="sm"
                  />
                  Saving...
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Col>
  );
}

export default memo(Index);
