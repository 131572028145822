export const BASENAME = "/";
export const IMAGE_SIZE = 502400; // 100kb
export const RESTAURANT_COLLECTION = "restaurants";
export const USER_COLLECTION = "users";
export const RIDER_COLLECTION = "drivers";
export const VEHICLES_TYPES_COLLECTION = "vehicles-types";
export const BASES_COLLECTION = "bases";

export const PRICING_COLLECTION = "pricing";

export const VEHICLES__COLLECTION = "vehicles";

export const TARRIF_COLLECTION = "tarrif";
export const NOTIFICATIONS_COLLECTION = "notifications";

export const RIDES_COLLECTION = "rides";
export const BLOG_COLLECTION = "blogs";

export const HOLIDAY_SURCHARGE = "holiday-surcharge";

export const ADMIN_COLLECTION = "admins";
export const SITE_COLLECTION = "site";

export const CHAT_COLLECTION = "live-chat-sessions";

export const ORDER_COLLECTION = "orders";

export const PAYMENT_COLLECTION = "payments"
export const INVOICE_COLLECTION = "invoices";

export const CATEGORYS_COLLECTION = "categorys";
export const ITEMS_COLLECTION = "items";
export const CAREER_COLLECTION = "careers";
export const TEAM_COLLECTION = "team";
export const ADMINROLE_COLLECTION = "adminsRoles";
export const CITIES_COLLECTION = "cities";
export const VOUCHER_COLLECTION = "vouchers";
export const firebase_bucket = "navigo-bucket";
export const ADMIN_SITE =
  process.env.NODE_ENV === "production"
    ? "https://admins.boomaeats.com"
    : "http://localhost:3000";
export const RIDER_SITE = "https://riders.boomaeats.com";
export const RESTAURANT_SITE = "https://partners.boomaeats.com";

export const rideStatus = {
  ACTIVE: "active",
  COMPLETED: "completed",
  CANCELED: "canceled",
};

export const driverStatus = {
  UNASSIGNED: "unAssigned",
  ASSIGNED: "assigned",
  ONTHEWAYORIGIN: "onTheWayOrigin",
  ARRIVED: "arrived",
  ONTHEWAYDESTINATION: "onTheWayDestination",
  REACHED: "reached",
};
