/* eslint-disable no-case-declarations */
import * as constants from "./constants";

let initState = {
  user: null,

  token: null,
  settings: null,
  shifts: [],
  invoices: [],
};

function authReducer(state = initState, action) {
  let { payload } = action;
  switch (action.type) {
  case constants.SET_USER:
    return {
      ...state,
      user: payload,
    };
  case constants.UPDATE_USER:
    return {
      ...state,
      user: {
        ...state.user,
        ...payload,
      },
    };
  case constants.UPDATE_SITE_SETTINGS:
    return {
      ...state,
      settings: {
        ...state.settings,
        [payload.doc]: {
          ...state.settings[payload.doc],
          ...payload.data,
        },
      },
    };
  case constants.UPDATE_TODAY_SHIFT:
    const newShifts = state.shifts.map((shift) => {
      if (shift.id === payload.id) {
        return { ...shift, ...payload };
      }
      return shift;
    });
    return {
      ...state,
      shifts: newShifts,
    };
  case constants.GET_INVOICES:
    return {
      ...state,
      invoices: payload,
    };
  case constants.GET_TODAY_SHIFTS:
    return {
      ...state,
      shifts: payload,
    };
  case constants.ADD_TODAY_SHIFT:
    const newAddShifts = state.shifts.concat(payload);
    return {
      ...state,
      shifts: newAddShifts,
    };
  case constants.SET_TOKKEN:
    return {
      ...state,
      token: payload,
    };
  case constants.SITE_SETTING:
    return {
      ...state,
      settings: payload,
    };

  case constants.LOGOUT:
    return {
      state: initState,
    };
  default:
    return state;
  }
}

export default authReducer;
