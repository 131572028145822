import React, { memo, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

// title, headerClass, action, renderData, lg
function Index({ lg , action, title , renderData}) {
  // const [data, setData] = useState(1);
  // const [loader, setLoader] = useState(false);
  // const dispatch = useDispatch();

  // const stopLoader = (value) => {
  //   setLoader(false);
  //   setData(value);
  // };

  // useEffect(() => {
  //   if (action) {
  //     setLoader(true);
  //     dispatch(action?.(stopLoader));
  //   }
  // }, [action, dispatch]);

  return (
    <Col lg={lg} className={`bg-white shadow-md rounded-3 border-4 border-blue-200`}>
      <Col className={`p-3`}>
          <h1 className="text-black fw-bold pb-1">
            { renderData }
          </h1>
          <h5 className="text-black fw-bold">
            {title}
          </h5>
      </Col>

      {/* <Row>
        <Col
          className={`border-bottom rounded-t-lg livebox-head   p-3 ${borderClass}`}
        >
          <h1 className="text-white text-sm fw-bold">
            {title}
          </h1>
        </Col>
        <Col lg={12}
          className="p-3">
          {loader && <Spinner animation="border"
            size="sm" />}
          {!loader && renderData?.(data)}
        </Col>
      </Row> */}
    </Col>
  );
}

export default memo(Index);
