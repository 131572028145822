import {
  FaBook,
  FaChartLine,
  FaCreditCard,
  FaFileInvoice,
  FaIdCard,
  FaShoppingBasket,
  FaUserTimes,
} from "react-icons/fa";

const RiderLinks = [
  {
    id: 2,
    url: "/",
    name: "profile",
    text: "Profile",
    icon: FaIdCard,
  },
  {
    id: 3,
    url: "/orders",
    name: "orders",
    text: "Rides History",
    icon: FaShoppingBasket,
  },

  {
    id: 4,
    name: "Payment History",
    text: "Payment History",
    icon: FaCreditCard,
    Children: [
      {
        id: 9,
        url: "/payment-history/payment-method",
        name: "payment-method",
        text: "Payment Method",
        icon: FaCreditCard,
      },
      {
        id: 9,
        url: "/payment-history/payment-summary",
        name: "payment-summary",
        text: "Payment Summary",
        icon: FaChartLine,
      },
    ],
  },
  {
    id: 4,
    url: "/schedule",
    name: "schedule",
    text: "Schedule",
    icon: FaUserTimes,
  },
  {
    id: 1,
    url: "/documents",
    name: "documents",
    text: "Documents",
    icon: FaBook,
  },
  {
    id: 5,
    url: "/finance",
    name: "finance",
    text: "Earnings",
    icon: FaFileInvoice,
  },

  {
    id: 1,
    url: "/notes",
    name: "notes",
    text: "Notes",
    icon: FaBook,
  },
];

export default RiderLinks;
