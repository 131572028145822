// import "boomaeats-chat/dist/index.css";

import { Divider, Empty, List } from "antd";
import React, { memo, useEffect } from "react";

import ChatItems from "./ChatItems";

function Index({ sessions }) {
  const [
    finalSessions, setFinalSessions
  ] = React.useState([]);

  useEffect(() => {
    setFinalSessions(sessions);
  }, [sessions]);

  return (
    <div
      style={ {
        width: 700,
        height: 400,
        overflowY: "auto",
      } }
    >
      <div className="flex items-center">
        <h1 className="text-base text-center flex-grow m-0 p-2">
          Chat Support
        </h1>
      </div>
      <Divider className="m-0" />
      <div className="p-2">
        {finalSessions.length > 0 ? (
          <List
            className="gap-2"
            dataSource={finalSessions}
            renderItem={(item) => <ChatItems chatType="live"
              item={item} />}
          ></List>
        ) : (
          <Empty description="No Chat Sessions" />
        )}
      </div>
    </div>
  );
}

export default memo(Index);
