import { Alert, Snackbar } from "@mui/material";
import React, { memo } from "react";

function Index({ msg, open, onClose }) {
  return (
    <Snackbar open={open}
      autoHideDuration={2000}
      onClose={onClose}>
      <Alert
        variant="filled"
        onClose={onClose}
        severity="success"
        sx={ { width: "100%" } }
      >
        {msg}
      </Alert>
    </Snackbar>
  );
}

export default memo(Index);
