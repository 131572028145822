import "chart.js/auto";

import { DatePicker, Empty, Radio, Space, Spin } from "antd";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";

import DropDown from "../../../components/dropdown";
import { getRides, getCitys, getOrderHistoryByDate , getRideHistoryByDate} from "../../../redux/admin/action";
import { ukCurrancy } from "../../../utils/convertions";
import {
  filterCompanyEarnOrder,
  filterCompletedOrder,
  getFilterbyDays,
} from "./pieConfig";

function Index() {
  const [date, setDate] = useState([
    moment(new Date()).startOf("d"),
    moment(new Date()).endOf("d"),
  ]);
  const dispatch = useDispatch();
  
  const [loader, setLoader] = useState(true);

  // const [orders, setOrders] = useState([]);

  const [rides, setRides] = useState([]);
  const [ridesPie, setRidesPie] = useState(null);
  const [earnPie, setEarnPie] = useState(null);
  const { user } = useSelector((state) => state.auth);

  const stopLoader = ({ rides }) => {
    // console.log("Summary Rides ==> ", rides);

    setLoader(false);
    setRides(rides);
    setRidesPie(filterCompletedOrder(rides));
    setEarnPie(filterCompanyEarnOrder(rides));
    
  };

  const setDateHandle = (e) => {
    setDate(e);
  };

  const [city, setCity] = useState(null);

  useEffect(() => {
    dispatch(getRides(() => {}));
  }, [dispatch]);

  useEffect(() => {
    if (!date) return;

    setLoader(false);
    dispatch(
      getRideHistoryByDate(
        {
          start: moment(date[0]).startOf("d").format("x"),
          end: moment(date[1]).endOf("d").format("x"),
        },

        city,
        stopLoader
      )
    );
  }, [date, dispatch, city]);
  
  const [cities, setCities] = useState([]);

  const citiesHandle = useCallback((data) => {
      if (user?.specificCities) {
        let citys = user?.citys?.map((a) => {
          return data.find((e) => e.name === a);
        });
        setCities(citys);
        setCity(citys?.[0].name);
        return;
      }
      setCities(data);
      setCity("ALL");
    },
    [user]
  );
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    dispatch(getCitys(citiesHandle));
  }, [dispatch, citiesHandle]);

  const summaryFilter = (e) => {
    setSummary(e.target.value);
    setDate(getFilterbyDays(e.target.value));
  };
  const { RangePicker } = DatePicker;

  // console.log("eejendahnfdckj =++> ", earnPie?.completedOrders);

  const noOfRides = earnPie?.completedOrders?.length;

  const ridesSales = earnPie?.completedOrders?.reduce((e, c) => e + parseFloat(c?.cost || 0), 0) || 0;

  const rideDiscounts = earnPie?.completedOrders?.reduce((e, c) => e +  parseFloat(c?.discount || 0), 0) || 0;

  const grossSales = earnPie?.completedOrders?.reduce((e, c) => e + parseFloat(c?.riderTake || 0), 0) || 0;

  const driverTake = earnPie?.completedOrders?.reduce((e, c) => e + parseFloat(c?.driverTake || 0), 0) || 0;

  const platform = earnPie?.completedOrders?.reduce((e, c) => e + parseFloat(c?.platformFee || 0), 0) || 0;

  const platformFees = parseFloat(ridesSales) * parseFloat(platform / 100);

  const navigoTake = parseFloat(grossSales) -  parseFloat(driverTake);

  const netSales = parseFloat(grossSales) -  parseFloat(driverTake);

  // console.log(" Gross Sales", grossSales);
  // console.log(" Net Sales", netSales);

  return (
    <Spin spinning={loader}>
      <Container fluid>
        <Row className=" flex justify-between gap-4 ">
          <Col lg={12}>
            <h2 className="fw-bold">
              Rides Summary
            </h2>
          </Col>
          <Col lg={12}
            className="bg-white p-3 shadow-md rounded-lg">
            <Row className="flex items-center gap-2">
              <Col lg={1}
                className=" fw-bold">
                Filters:
              </Col>
              <Col lg={3}
                className="p-0 ">
                <RangePicker
                  format="DD MMM YYYY"
                  value={date}
                  className="w-full"
                  onChange={setDateHandle}
                />
              </Col>
              <Col lg={3}>
                <DropDown type={summary ? `Summary- ${summary}` : "Summary"}>
                  <div
                    className="bg-white shadow-lg p-3 "
                    style={ { width: "200px" } }
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      value={summary}
                      onChange={summaryFilter}
                    >
                      <Space direction="vertical">
                        {[
                          "Daily", "Weekly", "Monthly", "Yearly"
                        ].map(
                          (value, index) => (
                            <Radio
                              className=" text-base fw-bold capitalize"
                              key={index}
                              value={value}
                            >
                              {value}
                            </Radio>
                          )
                        )}
                      </Space>
                    </Radio.Group>
                  </div>
                </DropDown>
              </Col>
              <Col lg={2}>
                <DropDown
                  type={city && city !== "ALL" ? `City- ${city}` : "All Cities"}
                >
                  <div
                    className="bg-white shadow-lg p-3 "
                    style={ { width: "200px" } }
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      value={city}
                      onChange={(e) => setCity(e.target?.value)}
                    >
                      <Space direction="vertical">
                        {!user?.specificCities && (
                          <Radio
                            className=" text-base fw-bold capitalize"
                            value={"ALL"}
                          >
                            All
                          </Radio>
                        )}
                        {cities.map((value, index) => (
                          <Radio
                            className=" text-base fw-bold capitalize"
                            key={index}
                            value={value.name}
                          >
                            {value.name}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </div>
                </DropDown>
              </Col>
            </Row>
          </Col>
          {rides?.length > 0 ? (
            <>
              <Col lg={6}
                className="shadow-lg bg-white p-0 rounded-lg ">
                <h3 className="fw-bold text-md Rest_mainColor text-white p-3 rounded-t-lg">
                  Rides
                </h3>
                <div className="h-72 p-2">
                  {ridesPie && (
                    <Pie
                      data={ridesPie}
                      options={ {
                        animation: true,
                        legend: {
                          labels: { usePointStyle: true, boxWidth: 8 },
                        },
                        plugins: {
                          legend: {
                            labels: {
                              usePointStyle: true,
                              boxWidth: 8,
                              font: { size: 14, weight: "bolder" },
                            },
                          },
                          tooltip: {
                            enabled: true,
                            displayColors: false,
                            bodyFont: { size: 14, weight: "bold" },
                          },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                      } }
                    />
                  )}
                </div>
              </Col>
              <Col className=" shadow-lg bg-white p-0 rounded-lg ">
                <h3 className="fw-bold text-md Rest_mainColor text-white p-3 rounded-t-lg">
                  Earnings
                </h3>
                <div className="h-72 p-2">
                  {earnPie && (
                    <Pie
                      data={earnPie?.data}
                      options={ {
                        responsive: true,
                        maintainAspectRatio: false,

                        plugins: {
                          legend: {
                            labels: {
                              usePointStyle: true,
                              boxWidth: 8,
                              font: { size: 14, weight: "bolder" },
                            },
                          },
                          tooltip: {
                            enabled: true,
                            displayColors: false,
                            callbacks: {
                              label: (tooltipItem) => {
                                return `${
                                  tooltipItem.label
                                }: ${ukCurrancy.format(tooltipItem.parsed)}`;
                              },
                            },
                            bodyFont: { size: 14, weight: "bold" },
                          },
                        },
                      } }
                    />
                  )}
                </div>
              </Col>
              <Col
                lg={12}
                className=" h-auto shadow-lg bg-white p-0 rounded-lg "
              >
                <h3 className="fw-bold text-md Rest_mainColor text-white p-3 rounded-t-lg">
                  Analysis
                </h3>
                <Row className="p-3">
                  <Col lg={12}
                    className="border-b-2">
                    <h1 className="text-base fw-bold">
                      Sales
                      {" "}
                    </h1>
                  </Col>
                  <Col lg={12}
                    className="border-b-2">
                    <div className="w-100 flex justify-between pt-3">
                      <p>
                        Number of Rides:
                      </p>
                      <p>
                        {noOfRides}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12}
                    className="border-b-2">
                    <div className="w-100 flex justify-between pt-3">
                      <p>
                        Rides Costs:
                      </p>
                      <p>
                        {ukCurrancy.format(ridesSales)}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12}
                    className="border-b-2">
                    <div className="w-100 flex justify-between pt-3">
                      <p>
                        Discounts:
                      </p>
                      <p>
                      -
                        {" "}
                        {ukCurrancy.format(rideDiscounts)}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12}
                    className="border-b-2">
                    <div className="w-100 flex justify-between pt-3">
                      <p>
                        Platform Fees:
                      </p>
                      <p>                        
                        {ukCurrancy.format(platformFees)}
                      </p>
                    </div>
                  </Col>
                  
                  <Col lg={12}
                    className="border-b-2">
                    <div className="w-100 flex justify-between pt-3">
                      <p className="text-base fw-bold">
                        Gross Sales:
                      </p>
                      <p className="text-base fw-bold">
                        {ukCurrancy.format(grossSales)}
                      </p>
                    </div>
                  </Col>
                  {/* <Col lg={12}
                    className="border-b-2">
                    <div className="w-100 flex justify-between pt-3">
                      <p>
                        Platform Fees:
                      </p>
                      <p>
                        -
                        {" "}
                        {ukCurrancy.format(platformFees)}
                      </p>
                    </div>
                  </Col> */}
                  <Col lg={12}
                    className="border-b-2">
                    <div className="w-100 flex justify-between pt-3">
                      <p>
                        Driver Take:
                      </p>
                      <p>
                        -
                        {" "}
                        {ukCurrancy.format(driverTake)}
                      </p>
                    </div>
                  </Col>
                  {/* <Col lg={12}
                    className="border-b-2">
                    <div className="w-100 flex justify-between pt-3">
                      <p>
                        Navigo Take:
                      </p>
                      <p>
                      -
                        {" "}
                        {ukCurrancy.format(navigoTake)}
                      </p>
                    </div>
                  </Col> */}
                  <Col lg={12}>
                    <div className="w-100 flex justify-between pt-3">
                      <p className="text-base fw-bold">
                        Net Sales: (Navigo Take)
                      </p>
                      <p className="text-base fw-bold">
                      {ukCurrancy.format(netSales)}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <Empty
              imageStyle={ { height: 200 } }
              description={<h1 className="text-base fw-bold">
                  No Rides have taken place in this period/city.
              </h1>}
            />
          )}
        </Row>
      </Container>
    </Spin>
  );
}
export default memo(Index);
