import { Formik } from "formik";
import React, { memo } from "react";
import { Button, Col, FloatingLabel, Form } from "react-bootstrap";

function Index() {
  return (
    <Col className="p-4">
      <h5 style={ { fontWeight: "bold" } }>
I need help regarding:
      </h5>
      <Formik>
        {() => (
          <Form className="flex align-middle flex-col">
            <Form.Select aria-label="Select Option"
              size="lg">
              <option>
Select Option
              </option>
              <option value="1">
Option One
              </option>
              <option value="2">
Option Two
              </option>
              <option value="3">
Option Three
              </option>
            </Form.Select>
            <h6 className="font-bold text-lg">
Explain:
            </h6>
            <FloatingLabel controlId="floatingTextarea2">
              <Form.Control as="textarea"
                style={ { height: "100px" } } />
            </FloatingLabel>
            <Button
              type="submit"
              className="rounded fw-bolder text-white rounded-2 m-auto mt-4"
              variant="warning"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Col>
  );
}

export default memo(Index);
