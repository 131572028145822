/* eslint-disable no-async-promise-executor */
import {
  ADMIN_COLLECTION,
  INVOICE_COLLECTION,
  ORDER_COLLECTION,
  SITE_COLLECTION,
  PAYMENT_COLLECTION,
} from "../../config";
import { INSTANCE } from "../../config/axiosInstance";
import { auth, firestore, storage } from "../../config/firebase";

export default class AuthApi {
  constructor() { }
  async userRegister({ email, pass }) {
    return await auth().createUserWithEmailAndPassword(email, pass);
  }
  async addUserInFirestore(data, id) {
    const userRef = firestore().collection(ADMIN_COLLECTION).doc(id);
    const res = await userRef.set(data);
    return res ? false : true;
  }
  async uploadFile(acceptedFiles) {
    return await new Promise(async (r, eror) => {
      var array = [];
      for (var i = 0; i < Array.from(acceptedFiles).length; i++) {
        try {
          let bucketName = "RiderImages";
          var filename = Array.from(acceptedFiles)[i].name.split(".");
          var ext = filename[filename.length - 1];
          let storageRef = storage().ref(`${bucketName}/${Date.now()}.${ext}`);
          let upload = await storageRef.put(Array.from(acceptedFiles)[i]);
          const downloadURL = await upload.ref.getDownloadURL();
          array.push({
            url: downloadURL,
            type: acceptedFiles[i].type,
          });
        } catch (error) {
          eror(error);
        }
      }
      r(array);
    });
  }

  async loginWithemailAndPassword({ email, pass }) {
    return await auth().signInWithEmailAndPassword(email, pass);
  }
  async emailIsAdmin(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/user/emailIsAdmin",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
  async getInvoicesByUserId(id) {
    const userRef = firestore()
      .collection(INVOICE_COLLECTION)
      .where("userId", "==", id)
      .orderBy("createdAt", "desc");

    const res = await userRef.get();

    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }

  async Logout() {
    const res = await auth().signOut();
    return res ? false : true;
  }

  async getCurrentUser() {
    const res = auth();

    return res;
  }

  async getOrSetFirestoreUser(data, id) {
    const userRef = firestore().collection(ADMIN_COLLECTION).doc(id);
    const doc = await userRef.get(data);
    if (doc.exists) {
      return { ...doc.data(), id: doc.id };
    }
    const res = await userRef.set(data);
    return res;
  }
  async forgetPassword(email) {
    return await auth().sendPasswordResetemail(email);
  }

  async updatePassword(email, oldPassword, password) {
    const userRef = auth().currentUser;

    const emailCred = auth.emailAuthProvider.credential(email, oldPassword);
    const { user } = await userRef.reauthenticateWithCredential(emailCred);
    const res = await user.updatePassword(password);
    return res ? false : true;
  }

  async getAdminById(id) {
    const restRef = firestore().collection(ADMIN_COLLECTION).doc(id);
    const doc = await restRef.get();
    return doc.exists ? { ...doc.data(), id: doc.id } : null;
  }
  async updateSiteSettings(doc, data) {
    const orderRef = firestore().collection(SITE_COLLECTION).doc(doc);

    const res = await orderRef.set(data, { merge: true });
    return res ? false : true;
  }
  async updateSiteSettingsSchedule(doc, data) {
    const orderRef = firestore().collection(SITE_COLLECTION).doc(doc);

    const res = await orderRef.update(data);
    return res ? false : true;
  }
  async getSiteSetting() {
    const userRef = firestore().collection(SITE_COLLECTION);
    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }
  async updateShift(data, shiftId, id) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("shifts")
      .doc(shiftId);
    const res = await userRef.update(data);
    return res ? false : { ...data, id: shiftId };
  }
  async addShift(data, shiftId, id) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(id)
      .collection("shifts")
      .doc(shiftId);
    const res = await userRef.set(data);
    return res ? false : { ...data, id: shiftId };
  }

  // Get Shifts
  async getTodayAdminShifts(start, end, userId) {
    const userRef = firestore()
      .collection(ADMIN_COLLECTION)
      .doc(userId)
      .collection("shifts")
      .where("date", ">=", start)
      .where("date", "<=", end)

      .orderBy("date", "asc");

    const res = await userRef.get();
    return res.empty
      ? []
      : res.docs.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }

  async updateOrderFirestore(data, id) {
    const restRef = firestore().collection(ORDER_COLLECTION).doc(id);
    const res = await restRef.update(data);
    return res ? false : true;
  }

  async sendSupportemail(data) {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/support",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  }
}
