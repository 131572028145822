import { CheckSquareOutlined,CloseSquareOutlined } from "@ant-design/icons";
import { Edit } from "@mui/icons-material";
import { Button, Input, Table } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import AsCancel from "../../modals/CancelOrder";
import AsComplete from "../../modals/CompleteOrder";
import OrderItem from "../../modals/OrderItem";
import { getRides , getSyncRides } from "../../redux/admin/action";
import AddRideModal from "../../modals/AddRideModal";
import RideStatusTag from "../RideStatusTag/RideStatusTag";

const { Search } = Input;

function Index({ setCount }) {

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [items] = useState(null);
  const [modalCompShow, setmodalCompShow] = useState(false);
  const [modalCancShow, setmodalCancShow] = useState(false);
  const [order, setOrder] = useState(null);
  const [searchedText, setSearchedText] = useState("");
  const { rides } = useSelector((state) => state.admin);
  const [ rideData , setRideData ] = useState(null);
   

  const [finalRides, setFinalRides] = useState();

  const editHandler = (ride) => {
    // console.log("Inside Ride dashboard table ==> ", ride);
    setRideData(ride);
    setModalShow(true);
  };

  const columns = [
    {
      title: "Ride Status",
      align: "center",
      dataIndex: "status",
      key: "status",
      className: "capitalize",
      width: 180,
      render: (status) => <RideStatusTag status={status} /> 
    },
    {
      title: "Ride #",
      align: "center",
      dataIndex: "key",
      key: "key",
      width: 120,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    {
      title: "Pickup time",
      align: "center",
      dataIndex: "when",
      key: "when",
      className: "capitalize",
      width: 150,
      render: (_, record) => record.time,
    },
    {
      title: "Rider",
      align: "center",
      dataIndex: "name",
      key: "name",
      width: 220,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record.origin?.address)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.destination?.address)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.phone).toLowerCase().includes(value.toLowerCase()) ||
          String(record.orderNumber)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.id.toString().split("-")[0])
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      render: (_, record) => record?.passenger?.name,
    },
    {
      title: "Driver",
      align: "center",
      dataIndex: "drivers",
      key: "drivers",
      className: "capitalize",
      width: 120,
      render: (_, record) => (
        <>
          {record?.drivers.length === 0 
            ? ( "-" ) 
            : (
              <>
                {
                  record?.drivers.map((driver) => (
                    <div>{driver.label}</div>
                  ))
                }
              </>
              ) 
          }
        </>
      ),
    },
    {
      title: "Pickup location",
      align: "center",
      dataIndex: "origin",
      key: "origin",
      className: "capitalize",
      width: 120,
      render: (origin) => origin?.address,
    },
    {
      title: "Additional Stops",
      align: "center",
      dataIndex: "stops",
      key: "stops",
      className: "capitalize",
      width: 120,
      render: (_, record) => (
        <>
          {record?.stops.length === 0 
            ? ( "-" ) 
            : (
              <>
                {
                  record?.stops.map((stop) => (
                    <div>{stop.address}</div>
                  ))
                }
              </>
              ) 
          }
        </>
      ),
    },
    {
      title: "Destination",
      align: "center",
      dataIndex: "destination",
      key: "destination",
      className: "capitalize",
      width: 120,
      render: (_, record) => record?.destination?.address,
    },
    {
      title: "Distance",
      align: "center",
      dataIndex: "distance",
      key: "distance",
      width: 120,
      render: (_, record) => record?.estimatedDistance || "remove me",
    },
    {
      title: "Fare",
      align: "center",
      dataIndex: "cost",
      key: "cost",
      width: 120,
      render: (_, record) => (
        // ukCurrancy.format(e? e : 0)
        <>
          {" "}
          &pound;
          {" "}
          {record.estimatedCost || record.cost
            ? record.estimatedCost
              ? record.estimatedCost
              : record.cost
            : 0}
        </>
      ),
    },
    {
      title: "Actions",
      key: "operation",
      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <div className="d-flex flex-col align-items-center justify-content-center">
          {( 
              record.status === "requesting" ||              
              record.status === "canceledByDriver" ||
              record.status === "requestTimeout"
              ) && (
              <>
                <Edit
                  disabled={!modalShow}
                  onClick={() => editHandler(record)}                
                />
              </>
            )}
              {/* Complete Button */}
              <Button
                type="text"
                className="m-1 fw-bold"
                title="Mark as completed"
                onClick={() => {
                  setOrder(record);
                  setmodalCompShow(true);
                }}
              >
                <CheckSquareOutlined
                  style={ { color: "green", fontSize: 20, margin: "2px" } }
                />
              </Button>
              {/* Cancel Button */}
              <Button
                className="m-1 fw-bold"
                type="text"
                title="Mark as cancelled"
                onClick={() => {
                  setOrder(record);
                  setmodalCancShow(true);
                }}
              >
                <CloseSquareOutlined
                  style={ { color: "red", fontSize: 20, margin: "2px" } }
                />
              </Button>
            {/* {(record.status === "requesting" || record.status === "pending" || record.status === "driverAssigned") && ( */}
            {/* {( 
              record.status === "pending" || 
              record.status === "requesting" ||
              record.status === "driverAssigned" ||
              record.status === "canceledByDriver" ||
              record.status === "requestTimeout" ||
              record.status === "inProgress" 
              ) && (
              <>
                <Button
                  type="text"
                  className="m-1 fw-bold"
                  title="Mark as completed"
                  onClick={() => {
                    setOrder(record);
                    setmodalCompShow(true);
                  }}
                >
                  <CheckSquareOutlined
                    style={ { color: "green", fontSize: 20, margin: "2px" } }
                  />
                </Button>
                <Button
                  className="m-1 fw-bold"
                  type="text"
                  title="Mark as cancelled"
                  onClick={() => {
                    setOrder(record);

                    setmodalCancShow(true);
                  }}
                >
                  <CloseSquareOutlined
                    style={ { color: "red", fontSize: 20, margin: "2px" } }
                  />
                </Button>
                {" "}
              </>
            )} */}
          </div>
        );
      },
    },
  ];

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    // dispatch(getRides(stopLoader));
    dispatch(getSyncRides(stopLoader));
  }, [dispatch]);

  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    if (rides) {
      const filteredRides = rides?.filter((ride) => {
        return ride.date === moment().format("DD MMM YYYY");
      });
      const uniqueIdsSet = new Set();
      const filteredUnique = filteredRides?.filter((ride) => {
        if (!uniqueIdsSet.has(ride?.id)) {
          uniqueIdsSet.add(ride?.id);
          return true; // Include the ride in the filtered array.
        }
        return false; // Skip rides with duplicate IDs.
      });
      
      const finilizeRides = filteredUnique?.filter((ride) => {
        return (
            ride.status === "pending" ||
            ride.status === "requesting" ||
            ride.status === "driverAssigned" || 
            ride.status === "canceledByDriver" ||
            ride.status === "requestTimeout" ||
            ride.status === "inProgress" || 
            ride.status === "In Progress" || 
            ride.status === "complete"
          );
      });

      
      // console.log("rides at dashboard -- ",filteredRides)
      setCount(finilizeRides?.length);

      setFinalRides(finilizeRides);
    }
  }, [rides, setCount]);

  return (
    <>
      <Container fluid
        className=" w-100 pb-5">
        <Row className="gap-5">
          <Col lg={12}
            className="shadow rounded-3 bg-white p-3 gap-3">
            <Search
              placeholder="Search rides..."
              allowClear
              size="large"
              onSearch={(value) => setSearchedText(value)}
              onChange={(e) => setSearchedText(e.target.value)}
              style={ {
                width: 300,
                marginBottom: "10px",
                float: "right",
              } }
              className="searchBar"
            />
            <Table
              bordered
              className="table-curved adminTable"
              columns={columns}
              dataSource={finalRides}
              pagination={false}
              loading={loader}
              scroll={ { x: 1500, y: 500 } }
            />
          </Col>
        </Row>
      </Container>

      {/* {modalShow && (
        <OrderItem
          items={items}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )} */}

      {modalShow && (
        <AddRideModal
          rideData={rideData}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}

      {modalCompShow && (
        <AsComplete
          order={order}
          show={modalCompShow}
          onHide={() => setmodalCompShow(false)}
        />
      )}
      {modalCancShow && (
        <AsCancel
          order={order}
          show={modalCancShow}
          onHide={() => setmodalCancShow(false)}
        />
      )}
    </>
  );
}

export default memo(Index);
