import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Input, Table, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ActionBtn from "../../../components/actionBtn";
import AddAdminLeave from "../../../modals/addadminleave";
import {
  deleteLeaveToFirestore,
  getAdminLeaves,
} from "../../../redux/admin/action";

const { RangePicker } = DatePicker;
const leaveTypes = {
  EAL: "Authorized Leave",
  EUAL: "Unauthorized Leave",
};

const leaveReasons = {
  AL: "Annual Leave",
  SL: "Sick Leave",
  CL: "Compassionate Leave",
  TL: "Training Leave",
  PH: "Public Holiday",
};
const ReasonsArray = [
  {
    label: "Annual",
    value: "AL",
    color: "green",
  },
  {
    label: "Sick",
    value: "SL",
    color: "blue",
  },
  {
    label: "Compassionate",
    value: "CL",
    color: "pink",
  },
  {
    label: "Training",
    value: "TL",
    color: "yellow",
  },
  {
    label: "Public Holiday",
    value: "PH",
    color: "pink",
  },
];
function Index() {
  const [
    show, setShow
  ] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [
    loader, setLoader
  ] = useState(false);
  const { leaves, profileAdmin } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [
    filterLeaves, setFilterLeaves
  ] = useState([]);
  const [
    date, setDate
  ] = useState([
    moment(new Date()).startOf("year"),
    moment(new Date()).endOf("year"),
  ]);
  const [
    selectedLeave, setSelectedLeave
  ] = useState(null);
  const setDateHandle = (e) => {
    if (!e) return;
    setDate(e);
  };
  const searchtable = (e) => {
    if (e?.target?.value) {
      let filterbyorder = leaves.filter((order) =>
        order?.type.includes(e.target.value)
      );
      setFilterLeaves(filterbyorder);
    } else {
      setFilterLeaves([]);
    }
  };
  const finalLeaves = filterLeaves?.length > 0 ? filterLeaves : leaves;
  const deleteLeave = (leave) => (cb) => {
    dispatch(deleteLeaveToFirestore(leave.id, cb));
  };
  const columns = [
    {
      title: "Created At",
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (e) => moment(Number(e)).format("DD-MMM-YY/hh:mm A"),
    },
    {
      title: "Title",
      align: "center",
      dataIndex: "title",
      key: "title",
      className: "capitalize",
      width: 120,
    },
    {
      title: "Type",
      align: "center",
      dataIndex: "type",
      key: "type",
      width: 120,
      render: (e) => leaveTypes[e],
    },
    {
      title: "Day's",
      align: "center",
      dataIndex: "day",
      key: "day",
      className: "capitalize",
      width: 120,
      render: (_, record) => {
        return (
          <div>
            {record?.day
              ?.map((d) => moment(Number(d)).format("DD-MMM-YYYY"))
              .join(" to ")}
            {" "}
            (
            {moment(Number(record.day[1]))
              .startOf("d")
              .diff(moment(Number(record.day[0])).startOf("d"), "d") + 1}
            {" "}
            day&apos;s )
          </div>
        );
      },
    },
    {
      title: "Reason",
      align: "center",
      dataIndex: "reason",
      key: "reason",
      className: "capitalize",
      width: 120,
      render: (e) => leaveReasons[e],
    },

    {
      title: "Actions",
      key: "operation",
      width: 120,
      align: "center",
      render: (_, record) => (
        <div className="d-flex align-items-center justify-content-center">
          <Tooltip title={"Edit"}>
            <EditOutlined
              onClick={() => {
                setSelectedLeave(record);
                handleShow();
              }}
              style={ {
                color: "green",
                fontSize: 15,
                margin: "2px",
              } }
            />
          </Tooltip>
          <ActionBtn
            action={deleteLeave(record)}
            confirmMsg={"Want to delete this rider?"}
           
          >
            <Tooltip title={"delete"}>
              <DeleteOutlined
                style={ { color: "red", fontSize: 15, margin: "2px" } }
              />
            </Tooltip>
          </ActionBtn>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoader(true);
    dispatch(
      getAdminLeaves(
        {
          start: moment(date[0]).format("x"),
          end: moment(date[1]).format("x"),
        },
        stopLoader
      )
    );
  }, [
    dispatch, date
  ]);
  const stopLoader = () => {
    setLoader(false);
  };
  const totalLeave = leaves.reduce((a, c) => {
    const days =
      moment(Number(c?.day[1]))
        .startOf("d")
        .diff(moment(Number(c?.day[0])).startOf("d"), "d") + 1;

    return a + days;
  }, 0);
  return (
    <Container fluid>
      <Row className="gap-3">
        <Col lg={12}>
          <h1 className="fw-bold">
Absence
          </h1>
        </Col>
        <Col lg={12}
          className="flex justify-end">
          <Button
            type="primary"
            className="fw-bold"
            onClick={() => {
              setSelectedLeave(null);
              handleShow();
            }}
          >
            Add Leave
          </Button>
        </Col>
        <Col lg={12}
          className="flex  justify-end items-center flex-wrap ">
          <div className="flex  flex-row items-center  ">
            <RangePicker
              style={ {
                minWidth: 250,
              } }
              format="DD MMM YYYY"
              value={date}
              onChange={setDateHandle}
            />
            <Input
              onChange={searchtable}
              className="m-1"
              placeholder="Leave Type"
              prefix={<SearchOutlined />}
            />
          </div>
        </Col>
        <Col lg={12}
          className="flex  justify-between items-center flex-wrap ">
          <div className="flex flex-wrap ">
            {ReasonsArray?.map((reason,index) => {
              const filterLeaves = leaves.filter(
                (leave) => leave.reason === reason.value
              );
              const totalLeaves = filterLeaves.reduce((a, c) => {
                const days =
                  moment(Number(c?.day[1]))
                    .startOf("d")
                    .diff(moment(Number(c?.day[0])).startOf("d"), "d") + 1;

                return a + days;
              }, 0);
              return (
                <p
                  key={index}
                  className={`text-lg mx-1 px-2 my-0  " border-r-2 border-yellow-400"
                    `}
                >
                  {reason.label}
                  {" "}
:
                  {" "}
                  <span className={`text-${reason.color}-700`}>
                    {totalLeaves}
                    {reason.value === "AL" &&
                      `/${profileAdmin?.totalLeaves || 0}`}
                    {reason.value === "PH" &&
                      `/${profileAdmin?.totalPublicHolidays || 0}`}
                  </span>
                </p>
              );
            })}
            <p className={"text-lg mx-1 px-2 my-0"}>
              Total: 
              {" "}
              <span>
                {totalLeave}
              </span>
            </p>
          </div>
        </Col>
        <Col lg={12}
          className="bg-white p-2 shadow-md">
          <Table
            dataSource={finalLeaves.sort((a, b) => {
              return a.createdAt < b.createdAt ? -1 : 1;
            })}
            loading={loader}
            bordered
            className="table-curved adminTable"
            columns={columns}
            pagination={false}
            scroll={ { y: 500 } }
          />
        </Col>
      </Row>
      {show && (
        <AddAdminLeave
          prevLeave={selectedLeave}
          show={show}
          onHide={handleClose}
        />
      )}
    </Container>
  );
}

export default memo(Index);
