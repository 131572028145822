import "chart.js/auto";

import { DatePicker, Radio, Space } from "antd";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Bar, Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";

import DropDown from "../../../components/dropdown";
import { getCitys } from "../../../redux/admin/action";
import Chart from "./Chart";

function Index() {
  const [date, setDate] = useState(moment());

  const setDateHandle = (e) => {
    if (!e) return;
    setDate(e);
  };

  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  
  const citiesHandle = useCallback(
    (data) => {
      if (user?.specificCities) {
        let citys = user?.citys?.map((a) => {
          return data.find((e) => e.name === a);
        });
        setCities(citys);
        setCity(citys?.[0].name);
        return;
      }
      setCities(data);
      setCity("ALL");
    },
    [user]
  );

  useEffect(() => {
    dispatch(getCitys(citiesHandle));
  }, [
    dispatch, citiesHandle
  ]);

  return (
    <Container fluid>
      <Row className="flex gap-4 justify-between ">
        <Col lg={12}>
          <h2 className="fw-bold">
            Ride Trends
          </h2>
        </Col>
        <Col lg={12}
          className="bg-white p-3 shadow-md rounded-lg">
          <Row className="flex gap-2 items-center">
            <Col lg={1}
              className=" fw-bold">
              Filters:
            </Col>
            <Col lg={3}
              className="p-0 ">
              <DatePicker
                format="DD MMM YYYY"
                value={date}
                className="w-full"
                onChange={setDateHandle}
              />
            </Col>
            <Col lg={2}
              className="p-0">
              <DropDown
                type={city && city !== "ALL"
                  ? `City- ${city}`
                  : "All Cities" || "Waiting..."}
              >
                <div
                  className="bg-white shadow-lg p-3 "
                  style={ { width: "200px" } }
                >
                  <Radio.Group
                    buttonStyle="solid"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target?.value);
                    }}
                  >
                    <Space direction="vertical">
                      {!user?.specificCities && (
                        <Radio
                          className=" text-base fw-bold capitalize"
                          value={"ALL"}
                        >
                          All
                        </Radio>
                      )}
                      {cities.map((value, index) => (
                        <Radio
                          className=" text-base fw-bold capitalize"
                          key={index}
                          value={value.name}
                        >
                          {value.name}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </div>
              </DropDown>
            </Col>
          </Row>
        </Col>
        <Chart date={date}
          city={city}
          Chart={Line}
          type="day"
          title="Daily" />
        <Chart date={date}
          city={city}
          Chart={Bar}
          type="week"
          title="weekly" />
        <Chart
          date={date}
          city={city}
          Chart={Bar}
          type="month"
          title="monthly"
        />
        <Chart date={date}
          city={city}
          Chart={Bar}
          type="year"
          title="yearly" />
      </Row>
    </Container>
  );
}
export default memo(Index);
