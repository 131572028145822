import moment from "moment";
import slugify from "slugify";

export function tConvert(time) {
  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time,];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}
export const ukCurrancy = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",

  maximumFractionDigits: 2,
});
export default function toTimestamp(strDate) {
  var datum = Date.parse(strDate);
  return datum;
}
export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}
export const addCreateHistory = (data, user) => {
  return {
    ...data,
    createdAt: moment().format("x"),
    updatedAt: moment().format("x"),
    createdBy: user.id,
    updatedBy: user.id,
  };
};
export const addUpdateHistory = (data, user) => {
  return {
    ...data,

    updatedAt: moment().format("x"),

    updatedBy: user.id,
  };
};
export const createSlug = (value) => {
  return slugify(value, {
    lower: true,
    strict: true,
    remove: /[*+~.()'"!:@]/g,
    replacement: "-",
  });
};
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
export const getCartTotal = (cartItem) => {
  const allExtras = cartItem?.extras
    ?.map((e) => e?.extra)
    ?.flat(1)
    ?.filter((e) => e);

  const totalExtras = allExtras?.reduce(
    (a, b) => a + parseFloat(b?.price) * b.qty,
    0
  );

  const totalVarient = cartItem?.varients?.reduce(
    (a, b) => a + parseFloat(b?.variant?.price),
    0
  );
  const total = cartItem?.qty * cartItem?.price + totalExtras + totalVarient;

  return total;
};
export const enumerateDaysBetweenDates = (startDate, endDate) =>{
  let now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("DD MMM YYYY"));
    now.add(1, "days");
  }

  return dates;
};
