import { DeleteOutlined } from "@ant-design/icons";
import { Collapse, Spin } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteAdminNote,
  deleteRestaurantNote,
  deleteRiderNote,
  deleteUserNote,
  getAdminById,
} from "../../redux/admin/action";

const { Panel } = Collapse;

function Index({ note, onSuccess, as }) {
  const [
    deleting, setDeleting
  ] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const stopDeleting = (isDeleted) => {
    if (isDeleted) {
      onSuccess(note);
    }
    setDeleting(false);
  };
  const deleteNote = () => {
    if (window.confirm("Want to delete this note?")) {
      setDeleting(true);

      if (as === "user") {
        dispatch(deleteUserNote(note.id, stopDeleting));
      } else if (as === "rider") {
        dispatch(deleteRiderNote(note.id, stopDeleting));
      } else if (as === "admin") {
        dispatch(deleteAdminNote(note.id, stopDeleting));
      } else {
        dispatch(deleteRestaurantNote(note.id, stopDeleting));
      }
    }
  };
  const [
    name, setName
  ] = useState("");
  useEffect(() => {
    if (note && user) {
      if (note.createdBy === user.id) {
        setName(user.name);
        return;
      }
      dispatch(getAdminById(note.createdBy, (data) => setName(data.name)));
    }
  }, [
    note, user, dispatch
  ]);
  return (
    <Col lg={12}
      className="my-2">
      <Spin spinning={deleting}>
        <Collapse
          expandIcon={(props) => {
            return props.isActive ? (
              <FaArrowUp style={ { color: "white" } } />
            ) : (
              <FaArrowDown style={ { color: "white" } } />
            );
          }}
          accordion
          expandIconPosition="right"
        >
          <Panel
            className="bg-gray-500 rounded shadow"
            header={<h1 className="text-white text-base fw-bold">
              {note?.title}
              {note?.ordernumber && ` (${note?.ordernumber})`}
            </h1>}
            key="1"
            extra={<DeleteOutlined
              onClick={deleteNote}
              className=" fw-bold"
              style={ { color: "red", fontWeight: 900 } }
            />}
          >
            <div
              className="contact-box break-words "
              dangerouslySetInnerHTML={ { __html: note?.desc } }
            />
            <div className="flex justify-end">
              <p className="mr-2 font-light">
                <span className="fw-bold">
                  {name}
                </span>
              </p>
              <p className="text-sm font-light">
                {moment(Number(note?.createdAt)).format("DD MMM YYYY hh:mm a")}
              </p>
            </div>
          </Panel>
        </Collapse>
      </Spin>
    </Col>
  );
}

export default memo(Index);
