import { Checkbox } from "antd";
import React, { memo, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import SnackBarCustom from "../../components/SnackBarCustom";
import {
  addVoucher,
  getCitys,
  getUsers,
  updateVoucher,
} from "../../redux/admin/action";
import { gerenatePassWord } from "../../utils/gerenatePassword";

function Index({ show, onHide, prevVoucher }) {
  const { users, vouchers, cities } = useSelector((state) => state.admin);

  const [
    voucher, setvoucher
  ] = useState({
    amount: 0,
    limit: 0,
    name: "",
    for: "all",
    users: [],
    city: [],
    code: gerenatePassWord(6),
    customCode: false,
  });
  const [
    errors, seterrors
  ] = useState([]);
  const [
    loader, setLoader
  ] = useState(false);
  const [
    added, setAdded
  ] = useState(false);

  const inputChange = (e) => {
    const { name, value } = e.target;
    setvoucher((p) => ({ ...p, [name]: value }));
  };

  const dispatch = useDispatch();

  const savevoucher = (e) => {
    e.preventDefault();
    seterrors([]);
    let error = [];
    if (!voucher.limit) {
      error.push("limitEmpty");
    }
    if (!voucher.amount) {
      error.push("amountEmpty");
    }
    if (voucher.name === "") {
      error.push("nameEmpty");
    }
    if (voucher.city === "" || voucher.city.length < 1) {
      error.push("cityEmpty");
    }
    if (voucher.code === "" && voucher.customCode) {
      error.push("codeEmpty");
    }

    if (
      !prevVoucher &&
      voucher.code.length &&
      vouchers.find((e) => e.code.includes(voucher.code))
    ) {
      error.push("codeAlready");
    }
    if (
      prevVoucher &&
      prevVoucher.code !== voucher.code &&
      vouchers.find((e) => e.code.includes(voucher.code))
    ) {
      error.push("codeAlready");
    }
    if (voucher.users.length < 1 && voucher.for === "specific") {
      error.push("userEmpty");
    }

    if (error.length) {
      seterrors(error);
    } else {
      seterrors([]);
      setLoader(true);
      if (prevVoucher) {
        dispatch(updateVoucher(voucher, prevVoucher.id, stopLoader));
        return;
      }
      dispatch(addVoucher(voucher, stopLoader));
    }
  };

  const stopLoader = () => {
    setLoader(false);
    onHide();
  };
  const onClose = () => {
    setAdded(false);
  };
  const [
    adder, setAdder
  ] = useState(false);
  const stopAdder = () => {
    setAdder(false);
  };
  useEffect(() => {
    setAdder(true);
    if (prevVoucher) {
      setvoucher({
        amount: prevVoucher.amount,
        limit: prevVoucher.limit,
        name: prevVoucher.name,
        for: prevVoucher.for,
        users: prevVoucher.users,
        code: prevVoucher.code,
        customCode: prevVoucher.customCode,
        city: prevVoucher.city,
      });
    }
    dispatch(getUsers(stopAdder));
    dispatch(getCitys(() => {}));
  }, [
    dispatch, prevVoucher
  ]);
  const citiesOptions = useMemo(() => {
    const filters = cities.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
    return [{ label: "All", value: "all" }].concat(filters);
  }, [cities]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Voucher
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={savevoucher}>
          <Form.Group controlId="formBasicDiscount">
            <Form.Label>
Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              name="name"
              value={voucher?.name}
              onChange={inputChange}
            />
            {errors.includes("nameEmpty") && (
              <span className="w-100 text-danger"
                style={ { fontSize: "12px" } }>
                Enter name
              </span>
            )}
          </Form.Group>
          <Form.Group controlId="formBasicDiscount">
            <Form.Label>
Amount
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              name="amount"
              value={voucher?.amount}
              onChange={inputChange}
            />
            {errors.includes("amountEmpty") && (
              <span className="w-100 text-danger"
                style={ { fontSize: "12px" } }>
                Enter voucher amount
              </span>
            )}
          </Form.Group>
          <Form.Group className="mt-3"
            controlId="formBasicPassword">
            <Form.Label>
Use limit
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Limit"
              name="limit"
              value={voucher?.limit}
              onChange={inputChange}
            />
            {errors.includes("limitEmpty") && (
              <span className="w-100 text-danger"
                style={ { fontSize: "12px" } }>
                Enter limit`s
              </span>
            )}
          </Form.Group>
          <Form.Group className="mt-3"
            controlId="formBasicPassword">
            <Form.Label>
City
            </Form.Label>
            <Select
              isLoading={adder}
              value={voucher?.city === "all"
                ? { label: "All", value: "all" }
                : voucher?.city?.map((e) => {
                  return {
                    value: e,
                    label: citiesOptions?.find((a) => a.value === e)?.label,
                  };
                })}
              className="basic-single"
              classNamePrefix="select"
              onChange={(a) => {
                if (voucher.city === "all") {
                  setvoucher((e) => {
                    return {
                      ...e,
                      city: [a?.value],
                    };
                  });
                  return;
                }
                if (a.find((b) => b.value === "all")) {
                  setvoucher((e) => {
                    return {
                      ...e,
                      city: "all",
                    };
                  });
                  return;
                }

                const newArray = a.map((e) => e.value);
                setvoucher((e) => {
                  return {
                    ...e,
                    city: newArray,
                  };
                });
              }}
              isMulti={!voucher.city.includes("all")}
              isSearchable={true}
              name="color"
              options={citiesOptions}
            />
            {errors.includes("cityEmpty") && (
              <span className="w-100 text-danger"
                style={ { fontSize: "12px" } }>
                Atleast select one city
              </span>
            )}
          </Form.Group>
          <div className="mt-2">
            <Checkbox
              checked={voucher.for === "specific" ? true : false}
              name="for"
              onChange={(a) => {
                const { checked } = a.target;
                setvoucher((e) => {
                  return {
                    ...e,
                    for: checked ? "specific" : "all",
                  };
                });
              }}
            >
              For specific users
            </Checkbox>
          </div>
          {voucher.for === "specific" && (
            <Form.Group className="mt-3"
              controlId="formBasicPassword">
              <Form.Label>
User
              </Form.Label>
              <Select
                value={voucher?.users?.map((e) => {
                  return {
                    value: e,
                    label: users.find((a) => a.id === e)?.name,
                  };
                })}
                isLoading={adder}
                className="basic-single"
                classNamePrefix="select"
                onChange={(e) => {
                  const newArray = e.map((e) => e.value);
                  setvoucher((e) => {
                    return {
                      ...e,
                      users: newArray,
                    };
                  });
                }}
                isMulti
                isSearchable={true}
                name="color"
                options={users.map((e) => {
                  return {
                    label: e.name,
                    value: e.id,
                  };
                })}
              />
              {errors.includes("userEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={ { fontSize: "12px" } }
                >
                  Atleast select one user
                </span>
              )}
            </Form.Group>
          )}
          <div className="mt-2">
            <Checkbox
              checked={voucher.customCode}
              name="for"
              onChange={(a) => {
                const { checked } = a.target;
                setvoucher({
                  ...voucher,
                  customCode: checked ? true : false,
                });
              }}
            >
              Custom voucher code
            </Checkbox>
          </div>
          {voucher.customCode && (
            <Form.Group className="mt-3"
              controlId="formBasicPassword">
              <Form.Label>
Voucher code
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter code"
                name="code"
                value={voucher?.code}
                onChange={inputChange}
              />
              {errors.includes("codeEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={ { fontSize: "12px" } }
                >
                  Enter code
                </span>
              )}
              {errors.includes("codeAlready") && (
                <span
                  className="w-100 text-danger"
                  style={ { fontSize: "12px" } }
                >
                  Code already exist
                </span>
              )}
            </Form.Group>
          )}
          <Button
            disabled={loader}
            variant="warning"
            type="submit"
            className=" mt-3 fw-bolder text-white btn-sm"
          >
            {loader ? "Adding..." : "Add"}
          </Button>
        </Form>
      </Modal.Body>
      <SnackBarCustom msg={"Notes added"}
        open={added}
        onClose={onClose} />
    </Modal>
  );
}

export default memo(Index);
