import { PlusOutlined } from "@ant-design/icons";
import { Tag, TimePicker } from "antd";
import moment from "moment";
import { TweenOneGroup } from "rc-tween-one";
import  React,{ memo, useRef, useState } from "react";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";

const { RangePicker } = TimePicker;

function Index({ tags, onChange }) {
  const [
    inputVisible, setInputVisible
  ] = useState(false);
  const [
    inputValue, setInputValue
  ] = useState([
    moment(), moment()
  ]);

  const handleClose = (removedTag) => {
    const tagss = tags.filter((tag) => tag !== removedTag);
    onChange(tagss);
  };
  const tagInputRef = useRef();
  const showInput = () => {
    setInputVisible(true);
    tagInputRef?.current?.focus();
  };

  const handleInputChange = (e) => {
    setInputValue(e);
  };

  const handleInputConfirm = () => {
    if (tags.length === 2) {
      swal("Dear!", "You can only add two time slot!", "info");
      return;
    }
    let tagss = [];
    if (inputValue[0] && inputValue[1]) {
      const finalInputValue = {};
      inputValue?.forEach((e, i) => {
        finalInputValue[i === 0 ? "start" : "end"] = e.format("HH:mm");
        finalInputValue.id = uuidv4();
      });

      tagss = [
        ...tags, finalInputValue
      ];

      onChange(tagss);

      setInputVisible(false);
      setInputValue("");
    }
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        className=" capitalize"
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag.start}
        {" "}
-
        {tag.end}
      </Tag>
    );
    return (
      <span key={tag}
        style={ { display: "inline-block" } }>
        {tagElem}
      </span>
    );
  };

  const tagChild = tags?.map(forMap);
  return (
    <div className="border p-3"
      style={ { marginBottom: 16 } }>
      <TweenOneGroup
        enter={ {
          scale: 0.8,
          opacity: 0,
          type: "from",
          duration: 100,
        } }
        onEnd={(e) => {
          if (e.type === "appear" || e.type === "enter") {
            e.target.style = "display: inline-block";
          }
        }}
        leave={ { opacity: 0, width: 0, scale: 0, duration: 200 } }
        appear={false}
      >
        {tagChild}
      </TweenOneGroup>
      {inputVisible && (
        <RangePicker
          format={"HH:mm"}
          showNow
          size="small"
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          value={inputValue}
        />
      )}
      {!inputVisible && (
        <Tag onClick={showInput}>
          <PlusOutlined />
          {" "}
New
        </Tag>
      )}
    </div>
  );
}

export default memo(Index);
