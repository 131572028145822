import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { memo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import LiveItemsbox from "../../components/LiveItemsbox";
import LiveRidesDashboard from "../../components/LiveRidesDashboard";
import RealTimeMap from "../../components/Realtimemap";
import AddRideModal from "../../modals/AddRideModal";

import {
  getLatestPendingOrders,
  getNumberOfRegisterUsers,
  getRidersLive,
  getOnlineDrivers,
} from "../../redux/admin/action";

function Index() {
  const [showRideModal, setShowRideModal] = useState(false);

  const [count, setCount] = useState(0);
  const [ rideData , setRideData ] = useState(null);

  const addRideHandler = () => {
    setRideData(null);
    setShowRideModal(true);
  };

  return (
    <Container fluid>
      <Row className="gap-3">
        <Col lg={12}>
          <h2 className="fw-bold">
            Dashboard
          </h2>
        </Col>
        <LiveItemsbox
          lg={4}
          headerClass="admin_mainColor"
          action={getLatestPendingOrders}
          title={"New Rides"}
          renderData={(data) => {
            return <div>
              {data || "Empty"}
            </div>;
          }}
        />
        {/* <LiveItemsbox
          headerClass="admin_mainColor"
          action={getRidersLive}
          title={"Online Drivers"}
          renderData={(data) => {
            return <div>
              {data?.length || "Empty"}
            </div>;
          }}
        /> */}
        <LiveItemsbox
          headerClass="admin_mainColor"
          action={getOnlineDrivers}
          title={"Online Drivers"}
          renderData={() => {
            return <div>
              {count !== 0 ? count : "Empty"}
            </div>;
          }}
        />
        <LiveItemsbox
          headerClass="admin_mainColor"
          action={getNumberOfRegisterUsers}
          title={"Today's Bookings"}
          renderData={() => {
            return <div>
              {count !== 0 ? count : "Empty"}
            </div>;
          }}
        />
        <Col lg={12}
          className="text-end">
          <Button
            onClick={addRideHandler}
            type="primary"
            className="fw-bold"
          >
            <PlusOutlined className="m-auto" />
            Add New Ride
          </Button>
        </Col>
        <Col lg={12}>
          <RealTimeMap size={12}
            title={"Realtime Rides"} />
        </Col>
        <Col lg={12}
          className="p-0">
          <LiveRidesDashboard setCount={setCount} />
        </Col>
      </Row>
      {showRideModal && (
        <AddRideModal
          rideData={rideData}
          show={showRideModal}
          onHide={() => setShowRideModal(false)}
        />
      )}
    </Container>
  );
}

export default memo(Index);
