import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { getUsers } from "../../redux/admin/action";

function PassengerSelection({ onPassengerChange, value , passenger, rideData }) {
  const [loader, setLoader] = useState(false);
  const [initialValue, setInitialValue] = useState(null);
  const { users } = useSelector((state) => state.admin);

  const stopLoader = () => {
    setLoader(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    dispatch(getUsers(stopLoader));
  }, [dispatch]);

  const usersOptions = useMemo(() => {
    // console.log("my users",users);
    return users.map((user) => {
      return {
        label: user.name,
        value: user.id,
      };
    });
  }, [users]);

    // Add Ride Case
    // useEffect(() => {
    //   if (value) {
    //     setInitialValue(usersOptions.find((e) => e.value === value));
    //   }
    // }, [value, usersOptions]);

  // Edit Ride Case 
  useEffect(() => {
    if (rideData && rideData.passenger && rideData.passenger.id === passenger?.id) {
      // console.log("Passenger name ====> ", rideData?.passenger?.name);
      // If rideData has passenger info and it matches the current passenger id, set the initial value
      setInitialValue({
        label: rideData?.passenger?.name,
        value: rideData?.passenger?.id,
      });
    }
  }, [rideData, passenger]);

    // Setting Passenger data for ride details
    useEffect(() => {
      // console.log("workings passenger");  
      if (initialValue && initialValue?.value !== passenger?.id) {
        const findedUser = users?.find((user) => user.id === initialValue.value);
        // console.log("findedUser ==>",findedUser);
        if (findedUser) {
          onPassengerChange({
            ...passenger,
            name: findedUser?.name || "",
            email: findedUser?.email || "",
            phoneNumber: findedUser?.phoneNumber || "",
            id: findedUser.id,
          });
        }
      }
    }, [initialValue, onPassengerChange, passenger, users]);

  return (
    <Select
      isLoading={loader}
      className="w-100 h-12"
      value={initialValue}
      styles={ {
        control: (styles) => ({
          ...styles,
          height: "3rem",
        }),
      } }
      onChange={(e) => {
        setInitialValue(e);
        // onPassengerChange(e.value);
      }}
      // onChange={(selectedOption) => {
      //   setInitialValue(selectedOption);
      //   onPassengerChange({
      //     ...passenger,
      //     id: selectedOption.value,
      //     name: selectedOption.label,
      //     // email: selectedOption.label,
      //   });
      // }}
      options={usersOptions}
      placeholder="Choose passenger"
      isSearchable={true}
      name="passenger"
      isDisabled={passenger?.isNew}
      // type="text"
    />
  );
}

export default PassengerSelection;
