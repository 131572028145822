/* eslint-disable max-params */
/* eslint-disable no-console */

import { notification } from "antd";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { showErrorToast } from "../../components/toaster";
import { removeUndefinedForObject } from "../../utils/clearData";
import toTimestamp, {
  addCreateHistory,
  addUpdateHistory,
  createSlug
} from "../../utils/convertions";
import { makeId } from "../../utils/gerenatePassword";
import { getDistanceBetweenPointsNew } from "../../utils/getDistance";
import AdminApi from "./adminApi";
import * as constants from "./constants";
import { async } from "rxjs";

const adminApi = new AdminApi();
export const getLiveOrders = (cb) => async (dispatch) => {
  try {
    const orderRef = await adminApi.getLiveOrders();

    orderRef.on("value", async (snap) => {
      if (!snap.val()) return cb();
      const orders = await Promise.all(
        Object.keys(snap.val()).map(async (key) => {
          const order = await adminApi.getOrderById(key);
          return order;
        })
      );

      dispatch({ type: constants.GET_ADMIN_LIVE_ORDERS, payload: orders });
      cb();
    });
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const getPendingOrders = (cb) => async () => {
  try {
    const orderRef = await adminApi.getLiveOrders();

    orderRef.on("value", async (snap) => {
      if (!snap.val()) return cb();
      const orders = await Promise.all(
        Object.keys(snap.val()).map(async (key) => {
          const order = await adminApi.getOrderById(key);
          return order;
        })
      );

      cb(orders?.filter((e) => e.status === "pending")?.length);
    });
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const getActiveOrders = (cb) => async () => {
  try {
    const orderRef = await adminApi.getLiveOrders();

    orderRef.on("value", async (snap) => {
      if (!snap.val()) return cb();
      const orders = await Promise.all(
        Object.keys(snap.val()).map(async (key) => {
          const order = await adminApi.getOrderById(key);
          return order;
        })
      );

      cb(orders?.filter((e) => e.status === "accepted")?.length);
    });
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const uploadFiles = (acceptedFiles, cb) => async () => {
  try {
    const files = await adminApi.uploadImage(acceptedFiles);

    cb?.(files);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const addDriverCarType = (type, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;
  try {
    const data = await adminApi.addDriverCarType(
      addCreateHistory({ ...type, isActive: true }, user),
      uuidv4()
    );
    dispatch({
      type: constants.ADD_VEHICLE_TYPE,
      payload: data,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const getVehicles = (cb) => async (dispatch) => {
  try {
    const data = await adminApi.getVehicles();
    dispatch({
      type: constants.GET_DRIVER_VEHICLES,
      payload: data,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const getVehiclesByType = (type, cb) => async () => {
  try {
    const data = await adminApi.getVehiclesByType(type);

    cb?.(data);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const updateDriverCarType =
  (type, id, cb) => async (dispatch, getState) => {
    const { user } = getState().auth;

    try {
      const data = await adminApi.updateDriverCarType(
        addUpdateHistory(type, user),
        id
      );
      dispatch({
        type: constants.UPDATE_VEHICLE_TYPE,
        payload: data,
      });
      cb?.();
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };

export const getSiteTeam = (cb) => async () => {
  try {
    const team = await adminApi.getSiteTeam();

    cb?.(team);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(false, error.code);
  }
};
export const addNewRestType = (data, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;
  try {
    const id = uuidv4();
    const res = await adminApi.addNewRestType(addCreateHistory(data, user), id);

    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(false, error.code);
  }
};
export const removeRestaurantType = (id, cb) => async () => {
  try {
    const res = await adminApi.removeRestaurantType(id);

    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getAllRestaurantTypes = (cb) => async () => {
  try {
    const res = await adminApi.getAllRestaurantTypes();

    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(false, error.code);
  }
};
export const getOrderById = (id, cb) => async () => {
  try {
    const order = await adminApi.getOrderById(id);

    cb(order);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const removeCategoryById = (id, cb) => async () => {
  try {
    const res = await adminApi.removeCategoryById(id);

    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const addNewCategory = (data, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;
  try {
    const id = uuidv4();
    const category = await adminApi.addNewCategory(
      addCreateHistory(data, user),
      id
    );
    dispatch({ type: constants.ADD_CATEGORY, payload: category });
    cb?.();
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};
export const updateCateById = (data, id, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;

  try {
    const category = await adminApi.updateCateById(
      addUpdateHistory(data, user),
      id
    );
    dispatch({ type: constants.UPDATE_CATEGORY, payload: category });

    cb?.();
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

export const getAllCategorys = (cb) => async (dispatch) => {
  try {
    const data = await adminApi.getAllCategorys();

    dispatch({ type: constants.GET_CATEGORIES, payload: data });
    cb?.();
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};
export const cancelOrderByAdmin =
  (order, deduction, cb) => async (dispatch, getState) => {
    try {
      const id = getState().auth.user.id;
      const orderObject = {
        paid: order?.paid === "paid" ? "paid" : "cancelled",
        status: "rejected",
        kitchen: "rejected",
        deduction,
        rejected: {
          by: id,
          status: "admin",

          at: toTimestamp(new Date()),
        },
      };
      if (order?.paid === "pending") {
        await adminApi.cancelPayment({ paymentId: order?.payment?.id });
      }

      const isUpdated = await adminApi.updateOrderFirestore(
        orderObject,
        order?.id
      );
      const res = isUpdated
        ? await adminApi.removeOrderDatabase(order?.id)
        : false;
      if (res) {
        dispatch({ type: constants.REMOVE_ORDER, payload: order?.id });
        cb(res);
        return;
      }
      cb?.();
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const completeOrderByAdmin =
  (order, reason, cb) => async (dispatch, getState) => {
    try {
      const id = getState().auth.user.id;
      const orderObject = {
        status: "completed",
        completedAt: toTimestamp(new Date()),
        completedBy: {
          id: id,
          status: "admin",
          reason: reason,
        },
      };

      const isUpdated = await adminApi.updateOrderFirestore(
        orderObject,
        order?.id
      );
      const res = isUpdated
        ? await adminApi.removeOrderDatabase(order?.id)
        : false;
      if (res) {
        dispatch({ type: constants.REMOVE_ORDER, payload: order?.id });
        cb(res);
        return;
      }
      cb?.();
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const getUnboundRiders = () => async (dispatch, getState) => {
  const user = getState().auth.user;
  if (user?.admin) {
    try {
      const orderRef = await adminApi.getUnboundRiders();

      orderRef?.on("value", (snap) => {
        const riders =
          snap.val() &&
          Object.keys(snap?.val())?.map((key) => {
            return {
              id: key,
              ...snap.val()[key],
            };
          });

        dispatch({ type: constants.GET_UNBOUND_RIDERS, payload: riders });
      });
    } catch (error) {
      // console.log(error);
      showErrorToast(error.message || error?.response?.message);
    }
  }
};

export const getAvaiableRiders =
  (location, cb) => async (dispatch, getState) => {
    const { unBoundRiders } = getState().admin;
    try {
      const riders =
        location &&
        unBoundRiders?.map((rider) => {
          const distance = getDistanceBetweenPointsNew(
            location?.latValue,
            location?.lngValue,
            rider?.liveLocation?.latitude,
            rider?.liveLocation?.longitude
          );
          if (distance <= 3) {
            return {
              value: rider.id,
              label: rider.name,
            };
          }
          return null;
        });
      cb?.(riders);
    } catch (error) {
      cb?.();
      showErrorToast(error.message || error?.response?.message);
    }
  };

export const alotRiderByAdmin =
  (riderid, orderid, cb) => async (dispatch, ) => {
    try {
      const data = {
        riderStatus: "idle",

        riderid,
      };

      const isUpdated = await adminApi.updateOrderDatabase(data, orderid);

      const isUpdate = isUpdated
        ? await adminApi.riderUpdateDatabase({ isBound: true }, riderid)
        : false;

      if (isUpdate) {
        dispatch({ type: constants.REMOVE_RIDER, payload: riderid });
      }
      cb();
    } catch (error) {
      cb();
      showErrorToast(error.message || error?.response?.message);
    }
  };
export const getOrderHistory = (cb) => async () => {
  try {
    const orders = await adminApi.orderHistory();

    cb?.(orders.length);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

// Get Rides by Dates
export const getRideHistoryByDate =
  ({ start, end }, city, cb) =>
    async (dispatch) => {
      try {
        const rides = await adminApi.rideHistoryByDate(start, end, city);

        // console.log("history rides ==> ", rides);

        dispatch({ type: constants.GET_RIDES_HISTORY, payload: rides });

        cb?.({ rides, start, end });

      } catch (error) {

        // console.log(error);
        cb?.();
        showErrorToast(error.message || error?.response?.message);

      }

};

export const getOrderHistoryByDate =
  ({ start, end }, city, cb) =>
    async (dispatch) => {
      try {
        const orders = await adminApi.orderHistoryByDate(start, end, city);

        dispatch({ type: constants.GET_ORDERS_HISTORY, payload: orders });
        cb?.({ orders, start, end });
      } catch (error) {
        // console.log(error);
        cb?.();
        showErrorToast(error.message || error?.response?.message);
      }
    };

export const getAdmins = (cb) => async (dispatch, ) => {
  try {
    let admins = await adminApi.getAdmins();

    dispatch({
      type: constants.GET_ADMINS,
      payload: admins,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const setPublicCareers = (data, cb) => async (dispatch) => {
  // console.log("data", data);
  try {
    const isSet = await adminApi.setPublicCareers(data);
    dispatch({
      type: constants.ADD_CAREER,
      payload: data,
    });
    // cb?.();
    cb(isSet);
    notification.open({
      type: "success",
      message: "Job posted successfully!",
      placement: "top",
    });
    return data;
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getPublicCareers = () => async (dispatch) => {
  try {
    const data = await adminApi.getPublicCareers();
    dispatch({
      type: constants.GET_CAREERS,
      payload: data,
    });
    // cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const deletePublicCareer = (id, cb) => async (dispatch) => {
  // console.log("delete id in action", id);
  try {
    const deleted = await adminApi.deletePublicCareer(id);
    dispatch({
      type: constants.DELETE_CAREER,
      payload: deleted,
    });
    cb(deleted);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getPublicCareersById = (id, cb) => async () => {
  try {
    const career = await adminApi.getPublicCareersById(id);

    cb(career);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updatePublicCareersById = (id, data, cb) => async (dispatch) => {
  try {
    const updated = await adminApi.updatePublicCareersById(id, data);
    dispatch({
      type: constants.UPDATE_CAREER,
      payload: updated,
    });
    cb(updated);
    notification.open({
      type: "success",
      placement: "top",
      message: "Career updated!"
    })
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const setSiteTeam = (data, cb) => async () => {
  try {
    const updated = await adminApi.setSiteTeam(data);

    cb(updated);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const deleteTeamMember = (id, cb) => async () => {
  try {
    const updated = await adminApi.deleteTeamMember(id);

    cb(updated);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updateTeamMember =
  (data, id, cb) => async () => {
    try {
      const updated = await adminApi.updateTeamMember(data, id);

      cb(updated);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const getUsers = (cb) => async (dispatch) => {
  try {
    const users = await adminApi.getUsers();
    // console.log("getUsers.....",users)
    dispatch({
      type: constants.GET_USERS,
      payload: users,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getVehicleTypes = (cb) => async (dispatch) => {
  try {
    const users = await adminApi.getVehicleTypes();

    dispatch({
      type: constants.GET_VEHICLE_TYPES,
      payload: users,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getAllVehicleTypes = (cb) => async (dispatch) => {
  try {
    const users = await adminApi.getAllVehicleTypes();

    dispatch({
      type: constants.GET_VEHICLE_TYPES,
      payload: users,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getPricings = (cb) => async (dispatch) => {
  try {
    const users = await adminApi.getPricings();

    dispatch({
      type: constants.GET_PRICINGS,
      payload: users,
    });
    cb?.();
  } catch (error) {
    cb?.();

    showErrorToast(error.message || error?.response?.message);
  }
};
export const deletePricings = (id, cb) => async (dispatch) => {
  try {
    const users = await adminApi.deletePricings(id);

    dispatch({
      type: constants.DELETE_PRICINGS,
      payload: users,
    });
    cb?.();
  } catch (error) {
    cb?.();

    showErrorToast(error.message || error?.response?.message);
  }
};

export const savePricings = (payload, id, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;
  try {
    const doc = await adminApi.savePricings(
      addUpdateHistory(payload, user),
      id || uuidv4()
    );
    dispatch({
      type: constants.UPDATE_PRICINGS,
      payload: doc,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const addNewCityTariff =
  (tariff, cityId, cb) => async (dispatch, getState) => {
    const { user } = getState().auth;
    try {
      const doc = await adminApi.addNewCityTariff(
        addCreateHistory(tariff, user),
        cityId,
        uuidv4()
      );

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const addCityNewShift =
  (shift, cityId, cb) => async (dispatch, getState) => {
    const { user } = getState().auth;
    try {
      const doc = await adminApi.addCityNewShift(
        addCreateHistory(shift, user),
        cityId,
        uuidv4()
      );

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const addCityHolidaySurcharges =
  (shift, cityId, cb) => async (dispatch, getState) => {
    const { user } = getState().auth;
    try {
      const doc = await adminApi.addCityHolidaySurcharges(
        addCreateHistory(shift, user),
        cityId,
        uuidv4()
      );

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const addCityWeekDaySurcharge =
  (shift, cityId, cb) => async (dispatch, getState) => {
    const { user } = getState().auth;
    try {
      const doc = await adminApi.addCityWeekDaySurcharge(
        addCreateHistory(shift, user),
        cityId,
        uuidv4()
      );

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const removeTariffById =
  (cityId, id, cb) => async () => {
    try {
      const doc = await adminApi.removeTariffById(cityId, id);

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const removeShiftById =
  (cityId, id, cb) => async () => {
    try {
      const doc = await adminApi.removeShiftById(cityId, id);

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const removeCityHolidaySurchargesById =
  (cityId, id, cb) => async () => {
    try {
      const doc = await adminApi.removeCityHolidaySurchargesById(cityId, id);

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const removeCityWeekDaySurchargeById =
  (cityId, id, cb) => async () => {
    try {
      const doc = await adminApi.removeCityWeekDaySurchargeById(cityId, id);

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const getTariffsByCityId =
  (cityId, cb) => async () => {
    try {
      const doc = await adminApi.getTariffsByCityId(cityId);

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const getCityShifts = (cityId, cb) => async () => {
  try {
    const doc = await adminApi.getCityShifts(cityId);

    cb(doc);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getCitySubCollection1 = async (data, cityId, subCollection, cb) => {
  try {
    const dataArray = Array.isArray(data) ? data : [data]; // Convert to array if not already
    // console.log("I am at SubColletion gert ==?");
    const results = [];
    for (let i = 0; i < dataArray.length; i++) {
      const dta = dataArray[i];
      const doc = await adminApi.getCitySubCollection(
        removeUndefinedForObject(dta),
        cityId,
        subCollection,
        dta.id
      );
      results.push(doc);
    }

    if (cb) {
      cb(results);
    }
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};


export const getCitySubCollection = (data, cityId, subCollection, cb) => async () => {
    try {
      const results = await Promise.all(
        data.map(async (dta) => {
          const doc = await adminApi.getCitySubCollection(
            removeUndefinedForObject(dta),
            cityId,
            subCollection,
            dta.id
          );
          return doc;
        })
      );

      cb(results);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const getCityHolidaySurcharges =
  (cityId, cb) => async () => {
    try {
      const doc = await adminApi.getCityHolidaySurcharges(cityId);

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const getCityWeekDaysSurcharges =
  (cityId, cb) => async () => {
    try {
      const doc = await adminApi.getCityWeekDaysSurcharges(cityId);

      cb(doc);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const getCityBases = (cityId, cb) => async () => {
  try {
    const doc = await adminApi.getCityBases(cityId);

    cb(doc);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getCityZones = (cityId, cb) => async () => {
  try {
    const doc = await adminApi.getCityZones(cityId);

    cb(doc);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const addCityBase = (base, cityId, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;
  try {
    const doc = await adminApi.addNewCityBase(
      addCreateHistory(base, user),
      cityId,
      uuidv4()
    );

    cb(doc);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const addCityZones = (base, cityId, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;
  try {
    const doc = await adminApi.addCityZones(
      addCreateHistory({...base,isActive:false}, user),
      cityId,
      uuidv4()
    );

    cb(doc);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const savePricingsALL = (payloads, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;
  try {
    const saveAll = await Promise.all(
      payloads.map(async (payload) => {
        const doc = await adminApi.savePricings(
          addUpdateHistory(payload, user),
          payload.id
        );
        return doc;
      })
    );

    dispatch({
      type: constants.ADD_PRICINGS,
      payload: saveAll,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getVehicleTypeById = (id, cb) => async () => {
  try {
    const users = await adminApi.getVehicleTypeById(id);
    cb(users);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const saveBaseOffice = (values, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;
  try {
    const users = await adminApi.saveBaseOffice(
      addCreateHistory({ ...values, isActive: false }, user),
      uuidv4()
    );
    dispatch({
      type: constants.ADD_BASE,
      payload: users,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const deleteBaseOffice =
  (cityId, id, cb) => async () => {
    try {
      const users = await adminApi.deleteBaseOffice(cityId, id);

      cb(users);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const deleteZoneOffice =
  (cityId, id, cb) => async () => {
    try {
      const users = await adminApi.deleteZoneOffice(cityId, id);

      cb(users);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const getBaseOffices = (cb) => async (dispatch,) => {
  try {
    const users = await adminApi.getBaseOffices();
    dispatch({
      type: constants.GET_BASES,
      payload: users,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getCityActiveBaseOffices = (cb) => async (dispatch) => {
  try {
    const users = await adminApi.getActiveBaseOffices();
    dispatch({
      type: constants.GET_BASES,
      payload: users,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getDriverBetweenDates =
  (dates, cb) => async (dispatch) => {
    try {
      const riders = await adminApi.getDriverBetweenDates(dates);
      dispatch({
        type: constants.GET_RIDERS,
        payload: riders,
      });
      cb();
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };


export const getRidesBetweenDates =
  (dates, cb) => async (dispatch) => {
    try {
      const rides = await adminApi.getRidesBetweenDates(dates);
      dispatch({
        type: constants.GET_RIDE,
        payload: rides,
      });
      cb();
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const updateBaseOffice =
  (values, id, cityId, cb) => async (dispatch, getState) => {
    const { user } = getState().auth;

    try {
      const users = await adminApi.updateBaseOffice(
        addUpdateHistory(values, user),
        id,
        cityId
      );

      cb(users);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const updateZoneOffice =
  (values, id, cityId, cb) => async (dispatch, getState) => {
    const { user } = getState().auth;

    try {
      const users = await adminApi.updateZoneOffice(
        addUpdateHistory(values, user),
        id,
        cityId
      );

      cb(users);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const addAdminFirestore = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;
  try {
    const id = uuidv4();
    user = {
      ...user,
      admin: true,
      adminProved: false,
      active: false,
      slug: createSlug(user.name).replace(" ", "-"),
      loginfrom: "firebase",
    };
    const { data } = await adminApi.checkUseremail({ email: user.email });
    // console.log("before api admin", data);
    const newUser = data.code === "auth/user-found" ? null : await adminApi.addAdminFirestore(addCreateHistory(user, admin), id);
    // console.log("after api admin" , newUser);

    if (newUser) {
      // console.log("Inside user if ");

      //Send Admin Welcome Email
      await adminApi.sendWellcomeAdminemail({
        email: user.email,
        name: user.name,
      });
      //  if(newUser?.active){
      // //Activate Admin Email
        // await adminApi.activateAdminById({ userId: id });
      //  }
      dispatch({ type: constants.ADD_ADMIN, payload: newUser });
      cb(true);
      return;


    }
    cb(data);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb();
  }
};

// Create Rider (User) by Admin
export const addUserFirestore = (user, cb) => async (dispatch, getState) => {
  // console.log("Creating Rider .... ");
  const admin = getState().auth.user;
  try {
    const id = uuidv4();
    user = {
      ...user,
      slug: createSlug(user.name).replace(" ", "-"),
      isProved: false,
      isActive: false,
      role: "user",
      loginfrom: "firebase",
    };
    
    const { data } = await adminApi.checkUseremail({ email: user.email });
    const isAdded = data.code === "auth/user-found" ? false : await adminApi.addUserFirestore(addCreateHistory(user, admin), id);

    if (isAdded) {

      // Send Welcome Email to Rider
      await adminApi.sendWellcomeUseremail({
        email: user.email,
        name: user.name,
      });

      //Activate Rider (User) Email
      await adminApi.activateUserById({ userId: id });

      dispatch({ type: constants.ADD_USER, payload: { ...user, id } });


      cb(true);
      return;

    }

    cb(data);

  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }

};


export const getLatestPendingOrders = (cb) => async () => {
  try {
    const orders = await adminApi.getLatestPendingOrders();

    cb(orders?.length);
  } catch (error) {
    // console.log(error);
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getResturantBySlug = (slug, cb) => async (dispatch) => {
  try {
    const rest = await adminApi.getRestaurantBySlug(slug);

    dispatch({ type: constants.FETCH_PROFILE_RESTAURANT, payload: rest });
    cb(rest);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const removeRiderProfileImage = (cb) => async (dispatch, getState) => {
  try {
    const id = getState().admin.profileRider.id;
    const user = getState().auth.user;

    const data = { profileImage: null };
    const userObject = await adminApi.updateRiderFirstore(
      addUpdateHistory(data, user),
      id
    );

    dispatch({
      type: constants.UPDATE_PROFILE_RIDER,
      payload: userObject,
    });

    cb(userObject);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const removeAdminProfileImage = (cb) => async (dispatch, getState) => {
  try {
    const id = getState().admin.profileAdmin.id;
    const user = getState().auth.user;

    const data = { photoURL: null };
    const userObject = await adminApi.updateAdminMember(
      addUpdateHistory(data, user),
      id
    );

    dispatch({
      type: constants.UPDATE_PROFILE_ADMIN,
      payload: userObject,
    });

    cb(userObject);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const removeUserProfileImage = (cb) => async (dispatch, getState) => {
  try {
    const id = getState().admin.profileUser.id;
    const user = getState().auth.user;

    const data = { photoURL: null };
    const userObject = await adminApi.updateUserFirestore(
      addUpdateHistory(data, user),
      id
    );

    dispatch({
      type: constants.UPDATE_PROFILE_USER,
      payload: userObject,
    });

    cb(userObject);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updateRiderProfile = (data, cb) => async (dispatch, getState) => {
  try {
    const rider = getState().admin.profileRider;
    const user = getState().auth.user;

    if (rider.email !== data.email) {
      await adminApi.updateMemberemail({ email: data.email, id: rider.id });
    }
    const riderObject = await adminApi.updateRiderFirstore(
      addUpdateHistory(data, user),
      rider.id
    );

    dispatch({
      type: constants.UPDATE_PROFILE_RIDER,
      payload: riderObject,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const updateAdminPayment =
  (data, payId, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileAdmin.id;
    const user = getState().auth.user;
    try {
      const res = await adminApi.updateAdminPayment(
        id,
        payId,
        addUpdateHistory(data, user)
      );
      dispatch({ type: constants.UPDATE_PAYMENTS, payload: res });
      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };
export const addAdminPayment = (data, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileAdmin.id;
  const user = getState().auth.user;
  try {
    const res = await adminApi.addAdminPayment(
      id,
      uuidv4(),
      addCreateHistory(data, user)
    );
    dispatch({ type: constants.ADD_PAYMENT, payload: res });
    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const getAdminPayments = (cb) => async (dispatch, getState) => {
  const user = getState().admin.profileAdmin;

  try {
    const details = await adminApi.getAdminPayments(user?.id);

    dispatch({ type: constants.GET_PAYMENTS, payload: details });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const removeAdminPayment = (payId, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileAdmin.id;

  try {
    const res = await adminApi.removeAdminPayment(id, payId);
    dispatch({ type: constants.REMOVE_PAYMENT, payload: res });
    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const updateAdminProfile = (data, cb) => async (dispatch, getState) => {
  try {
    const admin = getState().admin.profileAdmin;
    const user = getState().auth.user;
    let slugRider = null;
    if (admin.slug !== data.slug) {
      slugRider = await adminApi.getAdminBySlug(data.slug);
    }
    if (data.role !== admin.role) {
      await adminApi.updateAdminRoleAuth({
        role: data.role,
        id: admin.id,
      });
    }
    if (slugRider) {
      cb("name already exist");
      return;
    }

    if (admin.email !== data.email) {
      await adminApi.updateMemberemail({ email: data.email, id: admin.id });
    }
    const riderObject = await adminApi.updateAdminMember(
      addUpdateHistory(data, user),
      admin.id
    );

    dispatch({
      type: constants.UPDATE_PROFILE_ADMIN,
      payload: riderObject,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const updateUserProfile = (data, cb) => async (dispatch, getState) => {
  try {
    const user = getState().admin.profileUser;
    const admin = getState().auth.user;

    let slugRider = null;
    if (user.slug !== data.slug) {
      slugRider = await adminApi.getUserBySlug(data.slug);
    }

    if (slugRider) {
      cb("name already exist");
      return;
    }

    if (user.email !== data.email) {
      await adminApi.updateMemberemail({ email: data.email, id: user.id });
    }
    const riderObject = await adminApi.updateUserFirestore(
      addUpdateHistory(data, admin),
      user.id
    );

    dispatch({
      type: constants.UPDATE_PROFILE_USER,
      payload: riderObject,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updateUserPassword = (data, cb) => async (dispatch, getState) => {
  try {
    const user = getState().admin.profileUser;
    const riderObject = await adminApi.updateUserPassword(data, user.id);

    dispatch({
      type: constants.UPDATE_PROFILE_USER,
      payload: riderObject,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updateUserPayment = (data, cb) => async (dispatch, getState) => {
  try {
    const user = getState().admin.profileUser;
    const riderObject = await adminApi.updateUserPayment(data, user.id);

    dispatch({
      type: constants.UPDATE_PROFILE_USER,
      payload: riderObject,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updateUserPhone = (data, cb) => async (dispatch, getState) => {
  try {
    const user = getState().admin.profileUser;
    const riderObject = await adminApi.updateUserPhone(data, user.id);

    dispatch({
      type: constants.UPDATE_PROFILE_USER,
      payload: riderObject,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updateUserAddress = (data, cb) => async (dispatch, getState) => {
  try {
    const user = getState().admin.profileUser;
    const riderObject = await adminApi.updateUserAddress(data, user.id);

    dispatch({
      type: constants.UPDATE_PROFILE_USER,
      payload: riderObject,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const updateRiderProfileImage =
  (images, cb) => async (dispatch, getState) => {
    try {
      const admin = getState().auth.user;
      const id = getState().admin.profileRider.id;

      const imageUrls = await adminApi.uploadImage(images);
      const data = { profileImage: imageUrls[0] };
      const user = await adminApi.updateRiderFirstore(
        addUpdateHistory(data, admin),
        id
      );

      dispatch({
        type: constants.UPDATE_PROFILE_RIDER,
        payload: user,
      });

      cb(user);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const updateAdminProfileImage =
  (images, cb) => async (dispatch, getState) => {
    try {
      const admin = getState().auth.user;
      const id = getState().admin.profileAdmin.id;
      const imageUrls = await adminApi.uploadImage(images);
      const data = { photoURL: imageUrls[0] };
      const user = await adminApi.updateAdminMember(
        addUpdateHistory(data, admin),
        id
      );

      dispatch({
        type: constants.UPDATE_PROFILE_ADMIN,
        payload: user,
      });

      cb(user);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const updateUserProfileImage =
  (images, cb) => async (dispatch, getState) => {
    try {
      const admin = getState().auth.user;
      const id = getState().admin.profileUser.id;

      const imageUrls = await adminApi.uploadImage(images);
      const data = { photoURL: imageUrls[0] };
      const user = await adminApi.updateUserFirestore(
        addUpdateHistory(data, admin),
        id
      );

      dispatch({
        type: constants.UPDATE_PROFILE_USER,
        payload: user,
      });

      cb(user);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const getRiderBySlug = (slug, cb) => async (dispatch) => {
  try {
    const rest = await adminApi.getRiderBySlug(slug);

    dispatch({ type: constants.FETCH_PROFILE_RIDER, payload: rest });
    cb(rest);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

// Get Driver (rider) Profile by ID;
export const getRiderById = (id, cb) => async (dispatch) => {
  try {
    const rest = await adminApi.getRiderById(id);
    dispatch({ type: constants.FETCH_PROFILE_RIDER, payload: rest });
    cb(rest);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getAdminBySlug = (slug, cb) => async (dispatch) => {
  try {
    const rest = await adminApi.getAdminBySlug(slug);

    dispatch({ type: constants.FETCH_PROFILE_ADMIN, payload: rest });
    cb(rest);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getUserBySlug = (slug, cb) => async (dispatch) => {
  try {
    const rest = await adminApi.getUserBySlug(slug);

    dispatch({ type: constants.FETCH_PROFILE_USER, payload: rest });
    cb(rest);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

// Get Rider (User) Profile by ID;
export const getUserById = (id, cb) => async (dispatch) => {
  try {
    const rest = await adminApi.getUserById(id);

    dispatch({ type: constants.FETCH_PROFILE_USER, payload: rest });
    cb(rest);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getNumberOfRegisterUsers = (cb) => async () => {
  try {
    const orders = await adminApi.getNumberOfRegisterUsers();

    cb(orders?.length);
  } catch (error) {
    // console.log(error);
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

// Get Online Drivers
export const getOnlineDrivers1 = (cb) => async (dispatch) => {
  try {

    const result = await adminApi.getDriversOnline();

    // console.log("==> Check online driver", rider);

    if(result){
      dispatch({ type: constants.GET_UNBOUND_RIDERS, payload: result });
    }
    cb?.(true);
  } catch (error) {
    // console.log(error);
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getOnlineDrivers = (cb) => async () => {
  try {
    const result = await adminApi.getDriversOnline();

    // console.log("==> Check online driver", result);

    cb(result?.length);
  } catch (error) {
    // console.log(error);
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getRidersLive = (cb) => async (dispatch) => {
  try {
    const rider = await adminApi.getRidersLive();

    // console.log("Check online driver", rider);

    rider.on("value", async (snap) => {
      if (!snap.exists()) return cb([]);

      const riders = await Promise.all(
        Object.keys(snap.val()).map(async (e) => {
          const rider = await adminApi.getRiderById(e);
          // console.log("online driver", rider);
          return {
            ...rider,
            liveAt: snap.val()[e].liveAt,
            liveLocation: snap.val()[e].liveLocation,
            isBound: snap.val()[e].isBound,
            vehicle: snap.val()[e].vehicle,
          };
        })
      );
      dispatch({ type: constants.GET_UNBOUND_RIDERS, payload: riders });
      cb(riders);
    });
  } catch (error) {
    // console.log(error);
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getLiveOrderByRider =
  (riderId, cb) => async () => {
    try {
      const orders = await adminApi.getLiveOrderByRider(riderId);

      cb(orders);
    } catch (error) {
      // console.log(error);
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const addRestFirestore =
  (dataObject, cb) => async (dispatch, getState) => {
    const user = getState().auth.user;
    try {
      const id = uuidv4();
      dataObject = {
        ...dataObject,

        adminProved: false,
        active: false,
        restaurant: true,

        slug: createSlug(dataObject.name).replace(" ", "-"),
        loginfrom: "firebase",
      };
      const { data } = await adminApi.checkUseremail({
        email: dataObject.email,
      });
      const isAdded =
        data.code === "auth/user-found"
          ? false
          : await adminApi.addRestFirestore(
            addCreateHistory(dataObject, user),
            id
          );

      if (isAdded) {
        await adminApi.sendWellcomeRestemail({
          email: dataObject.email,
          name: dataObject.name,
          id,
        });
        dispatch({
          type: constants.ADD_RESTAURANT,
          payload: { ...dataObject, id },
        });
        cb(true);
        return;
      }
      cb(data);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const activateAdminById = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;
  // console.log("Admin activate ==> ", user.id);
  try {
    const { data } = await adminApi.activateAdminById({ userId: user.id });
    // if (!data.id) {
      // cb?.(null);
      // return;
    // }
    const dataObject = {
      active: true,
      adminProved: true,
    };
    const res = await adminApi.updateAdminMember(
      addUpdateHistory(dataObject, admin),
      user.id
    );
    //  if(res?.active){
    // //Activate Admin Email
    // //  debugger
    //   await adminApi.activateAdminById({ userId: user?.id });
    //  }

    dispatch({
      type: constants.UPDATE_ADMIN,
      payload: res,
    });

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
    // console.log('arfeen=>',error)
  }
};

export const activateUserById = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;

  try {
    // console.log("data of activate")

    // const { data } = await adminApi.activateUserById({ userId: user.id });
    // console.log("data of activate",data)

    // if (!data.id) {
      // cb?.(null);
      // return;
    // }
    const dataObject = {
      isActive: true,
      isProved: true,
    };
    const res = await adminApi.updateUserFirestore(
      addUpdateHistory(dataObject, admin),
      user.id
    );
    dispatch({
      type: constants.UPDATE_USER,
      payload: res,
    });

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};
export const activateVehicleTypeById =
  (user, cb) => async (dispatch, getState) => {
    const admin = getState().auth.user;

    try {
      const dataObject = {
        isActive: true,
      };
      const res = await adminApi.updateDriverCarType(
        addUpdateHistory(dataObject, admin),
        user.id
      );
      dispatch({
        type: constants.UPDATE_VEHICLE_TYPE,
        payload: res,
      });

      cb?.(true);
    } catch (error) {
      cb?.();
      showErrorToast(error.message || error?.response?.message);
    }
  };

export const activateRestById = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;
  try {
    const { data } = await adminApi.activateRestById({ userId: user.id });

    if (!data.id) {
      cb?.(null);
      return;
    }
    const dataObject = {
      active: true,
      adminProved: true,
    };
    const res = await adminApi.updateRestaurantFirestore(
      addUpdateHistory(dataObject, admin),
      data.id
    );
    dispatch({
      type: constants.UPDATE_RESTAURANT,
      payload: res,
    });

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

// UnProved or de-activate Driver
export const deActivateRiderById = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;
  try {
    const dataObject = {
      isProved: false,
    };
    const res = await adminApi.updateRiderFirestore(
      addUpdateHistory(dataObject, admin),
      user.id
    );

    dispatch({
      type: constants.UPDATE_RIDER,
      payload: res,
    });

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

//Approved Driver Document
export const approvedRiderDocument = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;
  try{
    const dataObject = {
      isProved: true,
    };
    const res = await adminApi.updateRiderFirestore(
      addUpdateHistory(dataObject, admin),
      user.id
    );

    if(res){
      dispatch({
        type: constants.APPROVED_DOCUMENTS,
        payload: res,
      });
    }

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

//Disapproved Driver Document
export const disapprovedRiderDocument = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;
  try{
    const dataObject = {
      isProved: false,
    };
    const res = await adminApi.updateRiderFirestore(
      addUpdateHistory(dataObject, admin),
      user.id
    );

    if(res){
      dispatch({
        type: constants.APPROVED_DOCUMENTS,
        payload: res,
      });
    }

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

// activate Driver
export const activateRiderById = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;
  try {
    const dataObject = {
      isProved: true,
    };
    if (!user.driverId) {
      Object.assign(dataObject, {
        driverId: `NT-${makeId(6)}`,
      });
    }
    const res = await adminApi.updateRiderFirestore(
      addUpdateHistory(dataObject, admin),
      user.id
    );
    dispatch({
      type: constants.UPDATE_RIDER,
      payload: res,
    });

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

// de-activate Driver
export const deActivateUserById = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;

  try {
    // const { data } = await adminApi.deActivateUserById({ userId: user.id });
    // if (!data.id) {
      // cb?.(null);
      // return;
    // }
    const dataObject = {
      isProved: false,
    };
    const res = await adminApi.updateUserFirestore(
      addUpdateHistory(dataObject, admin),
      user.id
    );

    dispatch({
      type: constants.UPDATE_USER,
      payload: res,
    });

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};
export const deActivateVehicleTypeById =
  (user, cb) => async (dispatch, getState) => {
    const admin = getState().auth.user;

    try {
      const dataObject = {
        isActive: false,
      };
      const res = await adminApi.updateDriverCarType(
        addUpdateHistory(dataObject, admin),
        user.id
      );

      dispatch({
        type: constants.UPDATE_VEHICLE_TYPE,
        payload: res,
      });

      cb?.(true);
    } catch (error) {
      cb?.();
      showErrorToast(error.message || error?.response?.message);
    }
  };

export const deActivateVehicleById =
  (user, cb) => async (dispatch, getState) => {
    const admin = getState().auth.user;

    try {
      const dataObject = {
        isActive: false,
      };
      const res = await adminApi.updateDriverCar(
        addUpdateHistory(dataObject, admin),
        user.id
      );

      dispatch({
        type: constants.UPDATE_DRIVER_VEHICLE,
        payload: res,
      });

      cb?.(true);
    } catch (error) {
      cb?.();
      showErrorToast(error.message || error?.response?.message);
    }
  };

export const activateVehicleById = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;

  try {
    const dataObject = {
      isActive: true,
    };
    const res = await adminApi.updateDriverCar(
      addUpdateHistory(dataObject, admin),
      user.id
    );

    dispatch({
      type: constants.UPDATE_DRIVER_VEHICLE,
      payload: res,
    });

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

export const deActivateAdminById = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;

  try {
    // const { data } = await adminApi.deActivateAdminById({ userId: user.id });
    // if (!data.id) {
      // cb?.(null);
      // return;
    // }
    const dataObject = {
      adminProved: false,
    };
    const res = await adminApi.updateAdminMember(
      addUpdateHistory(dataObject, admin),
      user.id
    );

    dispatch({
      type: constants.UPDATE_ADMIN,
      payload: res,
    });

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

export const deActivateRestById = (user, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;
  try {
    const { data } = await adminApi.deActivateRestById({ userId: user.id });

    if (!data.id) {
      cb?.(null);
      return;
    }
    const dataObject = {
      adminProved: false,
    };
    const res = await adminApi.updateRestaurantFirestore(
      addUpdateHistory(dataObject, admin),
      data.id
    );

    dispatch({
      type: constants.UPDATE_RESTAURANT,
      payload: res,
    });

    cb?.(true);
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};


export const getAllVouchers = (cb) => async (dispatch) => {
  try {
    const newAdress = await adminApi.getAllVouchers();
    dispatch({ type: constants.GET_ALL_VOUCHERS, payload: newAdress });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const activateUserVoucher = (id, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;
  try {
    const data = {
      active: true,
    };
    const newAdress = await adminApi.updateVoucher(
      addUpdateHistory(data, admin),
      id
    );
    dispatch({ type: constants.UPDATE_VOUCHERS, payload: newAdress });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const deActivateUserVoucher = (id, cb) => async (dispatch, getState) => {
  const admin = getState().auth.user;
  try {
    const data = {
      active: false,
    };
    const newAdress = await adminApi.updateVoucher(
      addUpdateHistory(data, admin),
      id
    );
    dispatch({ type: constants.UPDATE_VOUCHERS, payload: newAdress });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const deleteUserVoucher = (id, cb) => async (dispatch) => {
  try {
    const newAdress = await adminApi.deleteVoucher(id);
    dispatch({ type: constants.REMOVE_VOUCHER, payload: newAdress });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getUsersByIds = (userIds, cb) => async () => {
  try {
    const users = await Promise.all(
      userIds.map(async (userId) => {
        const user = await adminApi.getUserById(userId);
        return user;
      })
    );

    cb?.(users);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const addVoucher = (data, cb) => async (dispatch, getState) => {
  const user = getState().auth.user;

  try {
    data = {
      ...data,
      active: false,
    };
    const newAdress = await adminApi.addVoucher(
      addCreateHistory(data, user),
      uuidv4()
    );
    dispatch({ type: constants.ADD_VOUCHER, payload: [{ ...newAdress }] });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updateVoucher = (data, id, cb) => async (dispatch, getState) => {
  const user = getState().auth.user;

  try {
    const newAdress = await adminApi.updateVoucher(
      addUpdateHistory(data, user),
      id
    );
    dispatch({ type: constants.UPDATE_VOUCHERS, payload: newAdress });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const deleteUserById = (user, cb) => async (dispatch) => {
  try {
    // console.log("deleting the user---->call")
    // if (user.isActive) {
    //   await adminApi.deleteUserById({ userId: user.id });
    // }
    const data = await adminApi.removeUserById(user.id);
    dispatch({
      type: constants.DELETE_USER,
      payload: data,
    });

    cb?.();
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};
export const deleteVehicleTypeById =
  (user, cb) => async (dispatch) => {
    try {
      const data = await adminApi.removeVehicleTypeById(user.id);
      dispatch({
        type: constants.DELETE_VEHICLE_TYPE,
        payload: data,
      });

      cb?.();
    } catch (error) {
      cb?.();
      showErrorToast(error.message || error?.response?.message);
    }
  };

export const deleteAdminById = (user, cb) => async (dispatch) => {
  // console.log('cb is =>',cb);
  try {
    // if (user.active) {
      // await adminApi.deleteAdminById({ userId: user.id });
    // }
    const data = await adminApi.deleteAdmin(user.id);
    dispatch({
      type: constants.DELETE_ADMIN,
      payload: data,
    });

    cb?.();
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
    // console.log(error);
  }
};

export const deleteRestById = (user, cb) => async (dispatch) => {
  try {
    if (user.active) {
      await adminApi.deleteRestById({ userId: user.id });
    }
    const res = await adminApi.removeRestaurantById(user.id);

    dispatch({
      type: constants.DELETE_RESTAURANT,
      payload: res,
    });

    cb?.();
  } catch (error) {
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};

export const deleteRiderById = (user, cb) => async (dispatch) => {
  try {
    // console.log(user,cb,"inside the deleter call of rider")

    // const res = await adminApi.deleteRiderById({ userId: user.id });

    const res = await adminApi.removeRiderById(user.id);
    // console.log(res,"response -- delete rider")
    dispatch({
      type: constants.DELETE_RIDER,
      payload: res,
    });

    cb?.();
  } catch (error) {
    // console.log(error,"erorr on deleteRiderById")
    cb?.();
    showErrorToast(error.message || error?.response?.message);
  }
};
export const addDriverVehicle = (type, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;
  try {
    const carId = uuidv4();

    if(type.driver){
      const driverId = type.driver;
      const rider = await adminApi.getRiderById(driverId);

      if(rider.vehicleType || rider.vehicleStatus == true){
        showErrorToast("Driver Already Assigned with vehicle!");
        // console.log("===========>", rider.vehicleType);
      }else{
        const eventData = {
          vehicleType: carId,
          vehicleStatus: true, 
        };
        const res = await adminApi.updateDriverVehicleType(addUpdateHistory(eventData, user), driverId);
        if(res){
          const data = await adminApi.addDriverCar(addCreateHistory(type, user), carId);
          dispatch({
            type: constants.ADD_DRIVER_VEHICLE,
            payload: data,
          });
        }
      }
    }

    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const updateDriverVehicle = (type, driver ,id, cb) => async (dispatch, getState) => {
    const { user } = getState().auth;
    // console.log("update car driver", driver); //old driver
    // console.log("update car type", type); // new driver
    try {
      if(type.driver){
        const driverId = type.driver; 
        const rider = await adminApi.getRiderById(driverId); //to get new driver data
        let res,res2,confirmAction;

        const eventData = { 
          vehicleType: id,
          vehicleStatus: true, 
        }; // this will assign new vehicle to this driver
        const eventData2 = {
          vehicleType: "",
          vehicleStatus: false, 
        }; // this will remove vehicle from previously assigned driver

        if(rider.vehicleType || rider.vehicleStatus == true){ 
          confirmAction = window.confirm(`This Driver Already Assigned with vehicle! Do you want to update driver vehicle? This will add new vehicle and remove previous.`);
          if (confirmAction) { // User clicked 'OK' in the confirmation dialog
            res = await adminApi.updateDriverVehicleType(addUpdateHistory(eventData, user), driverId); //add new driver vehicle
            if(res){
              res2 = await adminApi.updateDriverVehicleType(addUpdateHistory(eventData2, user), driver); //remove old driver vehicle
            }
          }
        }else{
          res = await adminApi.updateDriverVehicleType(addUpdateHistory(eventData, user), driverId); //add new driver vehicle
          if(res){
            res2 = await adminApi.updateDriverVehicleType(addUpdateHistory(eventData2, user), driver); //remove old driver vehicle
          }
        }
          // Update Car Vehicle Data
          if(res && res2){
            const data = await adminApi.updateDriverVehicle(addUpdateHistory(type, user), id); 
            dispatch({
              type: constants.UPDATE_DRIVER_VEHICLE,
              payload: data,
            });
          }
      }
      cb?.();

    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };

export const deleteDriverVehicle = (id, cb) => async (dispatch) => {
  try {
    const data = await adminApi.deleteDriverVehicle(id);
    dispatch({
      type: constants.DELETE_DRIVER_VEHICLE,
      payload: data,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const getRiders = (cb) => async (dispatch) => {
  try {
    const riders = await adminApi.getRiders();

    // console.log("get riders in action",riders)
    dispatch({
      type: constants.GET_RIDERS,
      payload: riders,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getRidersByFilters = ({ start, end }, onlineStatus, status, cb) => async (dispatch) => {
      try {
        let riders = [];
        riders = await adminApi.getRidersByStatus(
          start,
          end,
          onlineStatus,
          status
        );

        // console.log("sssss ", riders);

        dispatch({
          type: constants.GET_RIDERS,
          payload: riders,
        });
        cb();
      } catch (error) {
        showErrorToast(error.message || error?.response?.message);
        // console.log(error);
        cb?.();
      }
    };

// TODO: Saving Invoices
export const saveInvoice =
  (blob, data, rides, as, cb) => async (dispatch, getState) => {
    const user = getState().auth.user;
    try {
      const file = await adminApi.uploadBlob(blob);
      await Promise.all(
        rides?.map(async (ride) => {
          return await adminApi.updateRideFirestore({ [as]: true }, ride.id);
        })
      );

      const dataObject = {
        ...data,
        url: file,
      };

      const id = uuidv4();
      const res = await adminApi.saveInvoice(addCreateHistory(dataObject, user), id);

      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };


export const addAdminRole = (data, cb) => async (dispatch, getState) => {
  const user = getState().auth.user;
  try {
    const id = uuidv4();
    const role = await adminApi.addAdminRole(addCreateHistory(data, user), id);

    dispatch({
      type: constants.ADD_ADMIN_ROLE,
      payload: role,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const updateAdminMember =
  (data, member, cb) => async (dispatch) => {
    try {
      if (member.email !== data.email && member.active) {
        await adminApi.updateMemberemail({ email: data.email, id: member.id });
      }
      const res = await adminApi.updateAdminMember(data, member.id);

      dispatch({
        type: constants.UPDATE_ADMIN,
        payload: res,
      });

      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };
export const updateAdminRole = (data, id, cb) => async (dispatch, getState) => {
  const user = getState().auth.user;
  try {
    const role = await adminApi.updateAdminRole(
      addUpdateHistory(data, user),
      id
    );

    dispatch({
      type: constants.UPDATE_ADMIN_ROLE,
      payload: role,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getSiteRoles = (cb) => async (dispatch) => {
  try {
    const roles = await adminApi.getSiteRoles();

    dispatch({
      type: constants.GET_ADMIN_ROLE,
      payload: roles,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const deleteAdminRole = (admin, cb) => async (dispatch) => {
  try {
    const role = await adminApi.deleteAdminRole(admin?.id);

    dispatch({
      type: constants.REMOVE_ADMIN_ROLE,
      payload: role,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getRestaurants = (cb) => async (dispatch) => {
  try {
    const restaurants = await adminApi.getRestaurants();

    dispatch({
      type: constants.GET_RESTAURANTS,
      payload: restaurants.map((e) => {
        return {
          ...e,
          ownerName: e?.name,
        };
      }),
    });
    cb();
  } catch (error) {
    // console.log(error);
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

//get Driver Documents
export const getRiderDetailInfo = (cb) => async (dispatch, getState) => {
  const id = getState().admin.profileRider.id;
  try {
    const info = await adminApi.getRiderDetailInfo(id);
    // console.log("info in action", info);
    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

//get Driver Earnings
export const getRiderEarnings = (cb) => async (dispatch, getState) => {

  const id = getState().admin.profileRider.id;
  try {
    const info = await adminApi.getRiderEarnings(id);

    dispatch({ type: constants.GET_DRIVER_EARNINGS, payload: info });

    // console.log("info in action", info);
    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

//get Invoices by driver id
export const getDriverInvoices = (cb) => async (dispatch, getState) => {
  const id = getState().admin.profileRider.id;

  try {
    const info = await adminApi.getInvoicesbyId( id, "driver");

    dispatch({ type: constants.GET_DRIVER_INVOICES, payload: info });

    // console.log("info in action", info);
    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getAdminDetailInfo = (cb) => async (dispatch, getState) => {
  const id = getState().admin.profileAdmin.id;
  try {
    const info = await adminApi.getAdminDetailInfo(id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getRestDetailInfo = (cb) => async (dispatch, getState) => {
  const id = getState().admin.profileRestaurant.id;
  try {
    const info = await adminApi.getRestDetailInfo(id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const addAdminDetail = (by, data, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileAdmin.id;
  const user = getState().auth.user;
  try {
    const res = await adminApi.addAdminDetail(
      id,
      by,
      addCreateHistory(data, user)
    );

    cb?.(res);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const addRestaurantDetail =
  (by, data, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileRestaurant.id;
    const user = getState().auth.user;
    try {
      const res = await adminApi.addRestaurantDetail(
        id,
        by,
        addCreateHistory(data, user)
      );

      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };
export const updateAdminSchedule =
  (data, schId, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileAdmin.id;
    const user = getState().auth.user;

    try {
      const res = await adminApi.updateAdminSchedule(
        id,
        schId,
        addUpdateHistory(data, user)
      );

      dispatch({ type: constants.UPDATE_SCHEDULE, payload: res });
      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };

export const AddAdminSchedule = (data, cb) => async (dispatch, getState) => {
  const user = getState().admin.profileAdmin;
  const admin = getState().auth.user;
  const id = uuidv4();

  try {
    const details = await adminApi.AddAdminSchedule(
      addCreateHistory(data, admin),
      user?.id,
      id
    );
    dispatch({ type: constants.ADD_SCHEDULE, payload: details });
    cb?.(details);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const AddAdminShift = (data, cb) => async (dispatch, getState) => {
  const user = getState().admin.profileAdmin;
  const admin = getState().auth.user;
  const id = uuidv4();

  try {
    const details = await adminApi.AddAdminShift(
      addCreateHistory(data, admin),
      user?.id,
      id
    );
    dispatch({ type: constants.ADD_SHIFT, payload: details });
    cb?.(details);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const removeAdminShift = (id, cb) => async (dispatch, getState) => {
  const user = getState().admin.profileAdmin;

  try {
    const details = await adminApi.removeAdminShift(id, user?.id);
    dispatch({ type: constants.REMOVE_SHIFT, payload: details });
    cb?.(details);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const getAdminShiftsByDate =
  (days, cb) => async (dispatch, getState) => {
    const user = getState().admin.profileAdmin;
    try {
      const shifts = await Promise.all(
        days?.map(async (day) => {
          const res = await adminApi.getAdminShiftsByDate(day, user?.id);
          return res;
        })
      );
      let finalShits = shifts
        .flat(1)
        .filter((e) => e.day !== "Sunday" || e.day !== "Saturday");
      finalShits = finalShits.map((e) => {
        if (!e?.lockInTime) {
          const todayScheduleTime = user?.scheduleTime[e?.day?.toLowerCase()];
          if (todayScheduleTime?.length) {
            return {
              ...e,
              status: "Inactive",
              hasSchedule: true,
              workingHours: 0,

              shecduleHours: todayScheduleTime.map((time) => {
                return {
                  ...time,

                  hours:
                    moment(time.end, "HH:mm").diff(
                      moment(time.start, "HH:mm"),
                      "hours"
                    ) + 1,
                };
              }),
            };
          }
          return {
            ...e,
            status: "Inactive",

            hasSchedule: false,
            workingHours: 0,
            shecduleHours: {
              hours: 0,
            },
          };
        }
        let status = "Active";
        if (e.lockOutTime) {
          status = "completed";
        }
        let workingHours = 0;
        let shecduleHours = {
          hours:
            moment(e?.time?.[1], "HH:mm").diff(
              moment(e?.time?.[0], "HH:mm"),
              "hours"
            ) + 1,
        };
        if (e?.lockOutTime) {
          workingHours = (
            (moment(Number(e.lockOutTime)).diff(
              moment(Number(e.lockInTime)),
              "minutes"
            ) +
              1) /
            60
          ).toFixed(2);
        } else {
          const workingTime = (
            (moment().diff(moment(Number(e.lockInTime)), "minutes") + 1) /
            60
          ).toFixed(1);
          const workingTimeNumber = parseFloat(workingTime);
          if (shecduleHours.hours <= workingTimeNumber) {
            workingHours = shecduleHours.hours;
          } else {
            workingHours = workingTimeNumber;
          }
        }

        return {
          ...e,
          status,
          hasSchedule: true,
          workingHours: parseFloat(workingHours),
          shecduleHours: shecduleHours,
        };
      });
      cb(finalShits);
    } catch (error) {
      cb?.();
      showErrorToast(error.message || error?.response?.message);
    }
  };

export const getAdminAbsencesByDate =
  ({ start, end }, cb) =>
    async (dispatch, getState) => {
      const user = getState().admin.profileAdmin;
      try {
        const updatedShift = await adminApi.getAdminShiftsByDate(
          start,
          end,
          user.id
        );
        cb(updatedShift);
      } catch (error) {
        cb?.();
        showErrorToast(error.message || error?.response?.message);
      }
    };

export const getAdminShiftsByDateAndId =
  ({ start, end }, id, cb) =>
    async () => {
      try {
        const updatedShift = await adminApi.getAdminShiftsByDateandId(
          start,
          end,
          id
        );
        cb(updatedShift);
      } catch (error) {
        cb?.();
        showErrorToast(error.message || error?.response?.message);
      }
    };

export const getAllChatSessionsFirebaseByDateAndAdminPlat =
  ({ start, end }, admin, platform, cb) =>
    async () => {
      try {
        const res = await adminApi.getAllChatSessionsFirebaseByDateAndAdminPlat(
          start,
          end,
          admin,
          platform
        );
        cb(res);
      } catch (error) {
        cb?.([]);
        // console.log(error);
        showErrorToast(error.message || error?.response?.message);
      }
    };

export const getAdminRolesBychatSupport =
  (cb) => async () => {
    try {
      const res = await adminApi.getAdminRolesBychatSupport();
      cb(res);
    } catch (error) {
      cb([]);

      showErrorToast(error.message || error?.response?.message);
    }
  };

export const getAllAdminsByRoles =
  (roles, cb) => async () => {
    try {
      const finalAdmins = await Promise.all(
        roles.map(async (role) => {
          const admins = await adminApi.getAllAdminsByRole(role.id);
          const adminsWithRole = admins.map((admin) => {
            return { ...admin, role: role };
          });
          return adminsWithRole;
        })
      );

      cb(finalAdmins.flat(1));
    } catch (error) {
      cb([]);

      showErrorToast(error.message || error?.response?.message);
    }
  };

export const getAllChatSessions = (cb) => async () => {
  try {
    const res = await adminApi.getAllChatSessions();
    res.on("value", (snapshot) => {
      if (!snapshot.exists()) {
        cb([]);
        return;
      }

      let sessions = [];
      snapshot.forEach((childSnapshot) => {
        sessions.push({
          id: childSnapshot.key,
          ...childSnapshot.val(),
        });
      });

      cb(sessions);
    });
  } catch (error) {
    cb([]);

    showErrorToast(error.message || error?.response?.message);
  }
};

export const updateAdminShift =
  (data, id, cb) => async (dispatch, getState) => {
    const user = getState().admin.profileAdmin;
    const admin = getState().auth.user;

    try {
      const details = await adminApi.updateAdminShift(
        id ? addUpdateHistory(data, admin) : addCreateHistory(data, admin),
        user?.id,
        id || uuidv4()
      );
      dispatch({
        type: id ? constants.UPDATE_SHIFT : constants.ADD_SHIFT,
        payload: details,
      });
      cb?.(details);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };

export const getAdminSchedules = (cb) => async (dispatch, getState) => {
  const user = getState().admin.profileAdmin;

  try {
    const details = await adminApi.getAdminSchedules(user?.id);

    dispatch({ type: constants.GET_SCHEDULES, payload: details });

    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const updateChatSessionById =
  (data, id, cb) => async () => {
    try {
      const details = await adminApi.updateChatSessionById(data, id);

      cb?.(details);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };

export const getSessionsById = (id, cb) => async () => {
  try {
    const details = await adminApi.getSessionsById(id);

    cb?.(details);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const getLiveSessionsById = (id, cb) => async () => {
  try {
    const res = await adminApi.getLiveSessionsById(id);
    res.on("value", (snapshot) => {
      if (!snapshot.exists()) {
        cb(null);
        return;
      }
      const session = {
        id: snapshot.key,
        ...snapshot.val(),
      };

      cb(session);
    });
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const saveSession = (data, id, cb) => async () => {
  try {
    await adminApi.removeSessionDatabase(id);

    const details = await adminApi.saveSessionfirebase(data, id);

    cb?.(details);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const getAdminShifts = (cb) => async (dispatch, getState) => {
  const user = getState().admin.profileAdmin;

  try {
    const details = await adminApi.getAdminShifts(user?.id);

    dispatch({ type: constants.GET_SHIFTS, payload: details });

    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const updateAdminDetail =
  (by, data, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileAdmin.id;
    const user = getState().auth.user;
    try {
      const res = await adminApi.updateAdminDetail(
        id,
        by,
        addUpdateHistory(data, user)
      );

      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };

export const updateRestaurantDetail =
  (by, data, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileRestaurant.id;
    const user = getState().auth.user;
    try {
      const res = await adminApi.updateRestaurantDetail(
        id,
        by,
        addUpdateHistory(data, user)
      );

      cb?.(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    }
  };

export const deleteRiderDocument =
  (docId, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileRider.id;
    try {
      const info = await adminApi.deleteRiderDocument(id, docId);

      cb(info);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const deleteAdminDocument =
  (docId, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileAdmin.id;
    try {
      const info = await adminApi.deleteAdminDocument(id, docId);

      cb(info);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const deleteRestaurantDocument =
  (docId, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileRestaurant.id;
    try {
      const info = await adminApi.deleteRestaurantDocument(id, docId);

      cb(info);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const getOrderbyOrderNumber =
  (number, cb) => async () => {
    try {
      const info = await adminApi.getOrderbyOrderNumber(number);

      cb(info);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const getLiveDetailById = (id, cb) => async () => {
  try {
    const order = await adminApi.getLiveDetailById(id);

    order.on("value", (snapshot) => {
      if (!snapshot.exists()) {
        cb?.();
      }
      cb(snapshot.val());
    });
  } catch (error) {
    // console.log(error);
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getRiderLiveDetail = (id, cb) => async () => {
  try {
    const order = await adminApi.getRiderLiveDetail(id);

    order.on("value", (snapshot) => {
      if (!snapshot.exists()) {
        cb?.();
      }
      cb(snapshot.val());
    });
  } catch (error) {
    // console.log(error);
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getAllRestaurants = (cb) => async () => {
  try {
    const info = await adminApi.getAllRestaurants();

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

// Get all Drivers
export const getAllRiders = (cb) => async () => {
  try {
    const info = await adminApi.getAllRiders();

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

// Get all Riders
export const getAllUsers = (cb) => async () => {
  try {
    const info = await adminApi.getAllUsers();

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getOrderbyRestaurant =
  (id, from, to, cb) => async () => {
    try {
      const info = await adminApi.getOrdersbyRestaurant(id, from, to);

      cb(info);
    } catch (error) {
      // console.log(error);
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const getPaidOrderByTime =
  (from, to, cb) => async () => {
    try {
      const info = await adminApi.getPaidOrderByTime(from, to);

      cb(info);
    } catch (error) {
      // console.log(error);
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

  // get paid rides by time
  export const getPaidRidesByTime =
  (from, to, cb) => async () => {
    try {
      const info = await adminApi.getPaidRidesByTime(from, to);

      cb(info);
    } catch (error) {
      // console.log(error);
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };


export const getCitys = (cb) => async (dispatch,) => {
  try {
    const info = await adminApi.getCitys();
    // console.log("info -- citiies",info)
    dispatch({
      type: constants.GET_CITIES,
      payload: info,
    });
    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getCityById = (id, cb) => async () => {
  try {
    const info = await adminApi.getCity(id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getCityBySlug = (slug, cb) => async (dispatch) => {
  try {
    const info = await adminApi.getCityBySlug(slug);
    dispatch({
      type: constants.GET_CITY,
      payload: info,
    });
    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getCityBySlugAndSubCollections =
  (slug, cb) => async () => {
    try {
      const info = await adminApi.getCityBySlugAndSubCollections(slug);
  
      cb(info);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
  
export const saveCity = (data, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;

  try {
    // console.log("City final data: ", data);
    const city = await adminApi.saveCity(
      addCreateHistory(data, user),
      uuidv4()
    );

    dispatch({
      type: constants.ADD_CITY,
      payload: city,
    });

    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const removeCity = (id, cb) => async (dispatch) => {
  try {
    const info = await adminApi.removeCity(id);
    dispatch({
      type: constants.REMOVE_CITY,
      payload: info,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updateCityStatus =
  (data, id, cb) => async (dispatch, getState) => {
    try {
      const user = getState().auth.user;
      const updatedBasicInfo = await adminApi.updateCity(
        addUpdateHistory(data, user),
        id
      );

      dispatch({
        type: constants.UPDATE_CITY,
        payload: updatedBasicInfo,
      });
      cb();
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const updateCity = (data, id, cb) => async (dispatch) => {
  try {
    const basicInformation = {
      ...data,
      slug: createSlug(data.name),
  
    };
    const updatedBasicInfo = await adminApi.updateCity(basicInformation, id);
    dispatch({
      type: constants.UPDATE_PROFILE_CITY,
      payload: updatedBasicInfo,
    });
    cb();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

// Get All Driver Rides
export const getAllDriverRides = (id, cb) => async () => {
  // console.log("Driver data", data);
  let info;
  try {
    const rider = await adminApi.getRiderById(id);
    // console.log("hello riedee==> ", rider)

    if(rider){
      info = await adminApi.getAllDriverRides(id, rider.name);
      // console.log("hello ==> ", info);
    }else{
      showErrorToast(error.message || error?.response?.message);
    }
    

    cb(
      info.map((e) => {
        return {
          ...e,
          riderdetail: rider,
        };
      })
    );

  } catch (error) {
    // console.log(error);
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

// Get Rides by Driver
export const getRidesbyDriver =
  (id, from, to, cb) => async () => {
    let info;
    try {
      const rider = await adminApi.getRiderById(id);
      // console.log("hello riedee==> ", rider)

      if(rider){
        info = await adminApi.getRidesbyDriver(id, rider.name , from , to);
        // console.log("hello ==> ", info);
      }else{
        showErrorToast(error.message || error?.response?.message);
      }
      

      cb(
        info.map((e) => {
          return {
            ...e,
            riderdetail: rider,
          };
        })
      );

    } catch (error) {
      // console.log(error);
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

// Get Rides by Users
  export const getRidesbyUsers =
  (id, from, to, cb) => async () => {
    let info;
    try {
      const rider = await adminApi.getUserById(id);
      // console.log("hello ridee==> ", rider);

      if(rider){
        info = await adminApi.getRidesbyUser(id, rider.name , from , to);
        // console.log("hello ==> ", info);
      }else{
        showErrorToast(error.message || error?.response?.message);
      }
      

      cb(
        info.map((e) => {
          return {
            ...e,
            riderdetail: rider,
          };
        })
      );

    } catch (error) {
      // console.log(error);
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };



export const getOrdersbyRider =
  (id, from, to, cb) => async () => {
    try {
      const info = await adminApi.getOrdersbyRider(id, from, to);
      const rider = await adminApi.getRiderById(id);
      cb(
        info.map((e) => {
          return {
            ...e,
            riderdetail: rider,
          };
        })
      );
    } catch (error) {
      // console.log(error);
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const addNoteForUser = (data, id, cb) => async () => {
  try {
    const info = await adminApi.addNoteForUser(data, id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const addNoteForRider = (data, id, cb) => async () => {
  try {
    const info = await adminApi.addNoteForRider(data, id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const addNoteForRestaurant =
  (data, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileRestaurant.id;
    const user = getState().auth.user;

    try {
      const info = await adminApi.addNoteForRestaurant(
        addCreateHistory(data, user),
        id,
        uuidv4()
      );

      cb(info);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const deleteUserNote = (noteId, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileUser.id;
  try {
    const info = await adminApi.deleteUserNote(id, noteId);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getAdminById = (id, cb) => async () => {
  try {
    const info = await adminApi.getAdminById(id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const deleteRestaurantNote =
  (noteId, cb) => async (dispatch, getState) => {
    const id = getState().admin.profileRestaurant.id;

    try {
      const info = await adminApi.deleteRestaurantNote(id, noteId);

      cb(info);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const deleteAdminNote = (noteId, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileAdmin.id;

  try {
    const info = await adminApi.deleteAdminNote(id, noteId);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const deleteRiderNote = (noteId, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileRider.id;
  try {
    const info = await adminApi.deleteRiderNote(id, noteId);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updateRiderFirestore =
  (data, id, cb) => async (dispatch, getState) => {
    let user = getState().auth.user;
    try {
      const isUpdated = await adminApi.updateRiderFirestore(
        addUpdateHistory(data, user),
        id
      );
      if (isUpdated) {
        dispatch({
          type: constants.UPDATE_PROFILE_RIDER,
          payload: { ...data, id },
        });
        cb(isUpdated);
        return;
      }
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const updateRide = (data, id, cb) => async (dispatch) => {
  try {
    const updatedData = await adminApi.updateRide(data, id);
    dispatch({
      type: constants.UPDATE_RIDE,
      payload: updatedData,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb(null);
  }
};

// Complete ride -> Admin Dashboard page
export const completeRide = (data, id) => async (dispatch) => {
  data.status = "completed";
  try {
    const updatedData = await adminApi.completeRide(data, id);
    dispatch({
      type: constants.COMPLETE_RIDE,
      payload: updatedData,
    });
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
  }
};

export const cancelRide = (data, id) => async (dispatch) => {
  try {
    const updatedData = await adminApi.cancelRide(data, id);
    dispatch({
      type: constants.COMPLETE_RIDE,
      payload: updatedData,
    });
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
  }
};

export const updateAdminFirestore =
  (data, id, cb) => async (dispatch, getState) => {
    let user = getState().auth.user;
    try {
      const isUpdated = await adminApi.updateAdminMember(
        addUpdateHistory(data, user),
        id
      );
      if (isUpdated) {
        dispatch({
          type: constants.UPDATE_PROFILE_ADMIN,
          payload: { ...data, id },
        });
        cb(isUpdated);
        return;
      }
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const saveLeaveToFirestore =
  (data, cb) => async (dispatch, getState) => {
    let { profileAdmin } = getState().admin;
    const user = getState().auth.user;
    try {
      const res = await adminApi.saveLeaveToFirestore(
        addCreateHistory(data, user),
        uuidv4(),
        profileAdmin.id
      );

      dispatch({
        type: constants.ADD_LEAVE,
        payload: res,
      });
      cb();
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const updateLeaveToFirestore =
  (data, id, cb) => async (dispatch, getState) => {
    let { profileAdmin } = getState().admin;
    const user = getState().auth.user;
    try {
      const res = await adminApi.updateLeaveToFirestore(
        addUpdateHistory(data, user),
        id,
        profileAdmin.id
      );

      dispatch({
        type: constants.UPDATE_LEAVE,
        payload: res,
      });
      cb();
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const deleteLeaveToFirestore =
  (id, cb) => async (dispatch, getState) => {
    let { profileAdmin } = getState().admin;

    try {
      const res = await adminApi.deleteLeaveToFirestore(id, profileAdmin.id);

      dispatch({
        type: constants.DELETE_LEAVE,
        payload: res,
      });
      cb();
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };

export const getAdminNotes = (cb) => async (dispatch, getState) => {
  const id = getState()?.admin?.profileAdmin?.id;

  try {
    const info = await adminApi.getAdminNotes(id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getAdminLeaves =
  ({ start, end }, cb) =>
    async (dispatch, getState) => {
      const id = getState()?.admin?.profileAdmin?.id;

      try {
        const info = await adminApi.getAdminLeaves(start, end, id);

        dispatch({ type: constants.GET_LEAVES, payload: info });
        cb();
      } catch (error) {
        showErrorToast(error.message || error?.response?.message);
        cb?.();
      }
    };

export const addNoteForAdmin = (data, cb) => async (dispatch, getState) => {
  const id = getState().admin.profileAdmin.id;
  const user = getState().auth.user;

  try {
    const info = await adminApi.addNoteForAdmin(
      addCreateHistory(data, user),
      id,
      uuidv4()
    );

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const updateUserFirestore =
  (data, id, cb) => async (dispatch) => {
    try {
      const isUpdated = await adminApi.updateUserFirestore(data, id);
      if (isUpdated) {
        dispatch({ type: constants.UPDATE_USER, payload: { ...data, id } });
        cb(isUpdated);
        return;
      }
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const updateRestaurantFirestore =
  (data, id, cb) => async (dispatch) => {
    try {
      const res = await adminApi.updateRestaurantFirestore(data, id);

      dispatch({
        type: constants.UPDATE_RESTAURANT,
        payload: res,
      });
      cb(res);
    } catch (error) {
      showErrorToast(error.message || error?.response?.message);
      cb?.();
    }
  };
export const getUserNotes = (id, cb) => async () => {
  try {
    const info = await adminApi.getUserNotes(id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getRiderNotes = (id, cb) => async () => {
  try {
    const info = await adminApi.getRiderNotes(id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};
export const getRestaurantsNotes = (cb) => async (dispatch, getState) => {
  const id = getState().admin.profileRestaurant.id;

  try {
    const info = await adminApi.getRestaurantsNotes(id);

    cb(info);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getDriverSchedulesByIdAndDate = ({ start, end }, id, cb) =>
    async () => {
      try {
        const info = await adminApi.getDriverSchedulesByDate(start, end, id);
        // console.log("Schedule from api", info);
        cb(info);
      } catch (error) {
        showErrorToast(error.message || error?.response?.message);
        cb?.();
      }
};

// Create driver for TestCf
export const addAppTestDriver = (rider, cb) => async(dispatch, getState) => {
  // console.log(" Before Rider ====> ", rider);
  const admin = getState().auth.user;

  // console.log("rider city", rider);

  try {
    const id = uuidv4();
    const nameParts = rider?.name.split(' ');
    
    const eventData = {
      firstName: nameParts[0],
      lastName: nameParts.slice(1).join(' '),
      displayName: rider?.name,
      name: rider?.name,
      email: rider?.email,
      signinMethod: "",
      isLive: false,
      isProved: false,
      rideStatus: false,
      phoneNumber: "",
      forum: "Dashboard",
      userId: id,
      photoUrl: "",
      securityEnabled: false,
      securityKey: "",
      securityQuestions: {},
      lastActiveLocation: {},
      city: {
        lat: "50.7244282", //Need to be change in future
        lng: "-3.5135475", //Need to be change in future
        name: "Exeter", //Need to be change in future
      },
      documentUploadStatus:false,
      role: "driver",
      vehicleType: "",
      vehicleStatus: false,
    };

    // console.log("Event Data ====> ", eventData);
    const { data } = await adminApi.checkUseremail({ email: rider.email });

    if (data.code === "auth/user-found") {
      cb(data);
      return;
    }
    const user = await adminApi.addRiderTestforCf(addCreateHistory(eventData, admin));

    // console.log("Event Data ====> ", user);

    if (!user) {
      cb();
      return;
    }
    
    // console.log("Final rider data ", user );

    // if(user){
    //   //Send Welcome Email Driver 
    //   await adminApi.sendWellcomeRideremail({
    //     id: id,
    //     email: user.email,
    //     name: user.name,8
    //   });
    // }
    

    //Activate Driver Email
    const res2 = await adminApi.activateRiderById({ userId: user.email });

    if(res2){
      dispatch({ type: constants.ADD_RIDER, payload: user });
    }

    cb(true);

  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }

};

// Create Rider ( Driver ) by Admin
export const addRiderFirestore = (rider, cb) => async (dispatch, getState) => {
  // console.log("Creating Driver .... ");
  const admin = getState().auth.user;
  try {
    const id = uuidv4();
    rider = {
      ...rider,
      slug: createSlug(rider.name).replace(" ", "-"),
      isProved: false,
      role: "driver",
      isLive: false,
    };
    const { data } = await adminApi.checkUseremail({ email: rider.email });

    if (data.code === "auth/user-found") {
      cb(data);
      return;
    }
    const user = await adminApi.addRiderFirestore(
      addCreateHistory(rider, admin),
      id
    );

    if (!user) {
      cb();
      return;
    }

    if(user){
      //Send Welcome Email Driver 
      await adminApi.sendWellcomeRideremail({
        id: id,
        email: user.email,
        name: user.name,
      });
    }
    

    //Activate Driver Email
    await adminApi.activateRiderById({ userId: id });

    dispatch({ type: constants.ADD_RIDER, payload: user });

    cb(true);

  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const addNewTarrif = (type, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;

  try {
    const data = await adminApi.addTarrif(
      addCreateHistory(type, user),

      uuidv4()
    );

    dispatch({
      type: constants.ADD_TARRIF,
      payload: data,
    });
    cb?.();
    return data;
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};
export const updateTarrif = (type, id, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;

  try {
    const data = await adminApi.updateTarrif(addUpdateHistory(type, user), id);
    dispatch({
      type: constants.UPDATE_TARRIF,
      payload: data,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const getTarrif = (cb) => async (dispatch) => {
  try {
    const data = await adminApi.getTarrif();
    dispatch({
      type: constants.GET_TARRIF,
      payload: data,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const deleteTarrif = (id, cb) => async (dispatch) => {
  try {
    const data = await adminApi.deleteTarrif(id);
    dispatch({
      type: constants.DELETE_TARRIF,
      payload: data,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    // cb?.(null);
  }
};

export const addRide = (type, cb) => async (dispatch, getState) => {
  const { user } = getState().auth;

  // console.log("Ride Add api ===> ", type);
  try {
    // console.log("sharma user,,",user)
    // console.log("sharma type",type);
    const data = await adminApi.addRide(
      addCreateHistory(type, user),
      uuidv4()
    );
    // console.log('Ride ===>',data);

    dispatch({
      type: constants.ADD_RIDE,
      payload: data,
    });

    // // Set payment payload
    // const payload = {
    //   amount: data.cost,
    //   date: data.date,
    //   time: data.time,
    //   riderPaid: false,
    //   rideId: data.id,
    //   isPaid: false,
    //   isActive: false,
    //   rider: {
    //     id: data.passenger.id,
    //     name: data.passenger.name,
    //   },
    //   driver: {
    //       id: data?.drivers[0]?.value || '',
    //     name: data?.drivers[0]?.label || '',
    //   }
    // }
    // // Add payment 
    // const res = await adminApi.addRidePayment(
    //   addCreateHistory(payload, user), 
    //   uuidv4()
    // );

    // dispatch({
    //   type: constants.ADD_PAYMENT, 
    //   payload: res,
    // });

    cb?.();
    return data;
  } catch (error) {
    // console.log('sharma err yeh hai',error);
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const getHolidaySurcharge = (cb) => async (dispatch) => {
  try {
    const data = await adminApi.getHolidaySurcharge();
    dispatch({
      type: constants.GET_HOLIDAY_SURCHARGE,
      payload: data,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

//Get and sync all rides
export const getSyncRides = (cb) => async (dispatch) => {
  // try {
    const data = await adminApi.getSyncRides();

    const handleSnapshot = (querySnapshot) => {
      const rides = [];
      querySnapshot.forEach((doc) => {
        rides.push({
          ...doc.data(),
          id: doc.id,
        });
      });
  
      dispatch({
        type: constants.GET_RIDE,
        payload: rides,
      });

      cb?.();
    };

    const handleError = (error) => {
      showErrorToast(error.message || error?.response?.message);
      cb?.(null);
    };
  
    data.onSnapshot(handleSnapshot, handleError);

};


// Get All Rides
export const getRides = (cb) => async (dispatch) => {
  try {
    const data = await adminApi.getRides();

    dispatch({
      type: constants.GET_RIDE,
      payload: data,
    });

    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

// Get All Payments
export const getPayments = (cb) => async (dispatch) => {
  try {
    const data = await adminApi.getPayments();
    dispatch({
      type: constants.GET_PAYMENTS,
      payload: data,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.(null);
  }
};

export const deleteRide = (id, cb) => async (dispatch) => {
  try {
    const data = await adminApi.deleteRide(id);
    dispatch({
      type: constants.DELETE_RIDE,
      payload: data,
    });
    cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    // cb?.(null);
  }
};

export const calculateCost = async (data) => {
  try {
    await adminApi.calculateCost(data);
    // dispatch({
    //   type: constants.ESTIMATED_COST,
    //   payload: data,
    // });
    // cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    // cb?.(null);
  }
};

export const saveBlog = (data, cb) => async (dispatch) => {
  try {
    const isSet = await adminApi.saveBlog(data);
    dispatch({
      type: constants.ADD_BLOG,
      payload: data,
    });
    // cb?.();
    cb(isSet);
    notification.open({
      type: "success",
      message: "Blog has been published!",
      placement: "top",
    });
    return data;
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getBlogs = () => async (dispatch) => {
  try {
    const data = await adminApi.getBlogs();
    dispatch({
      type: constants.GET_BLOGS,
      payload: data,
    });
    // cb?.();
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    // cb?.();
  }
};

export const deleteBlog = (id, cb) => async (dispatch) => {
  // console.log("delete id in action", id);
  try {
    const deleted = await adminApi.deleteBlog(id);
    dispatch({
      type: constants.DELETE_BLOG,
      payload: deleted,
    });
    cb(deleted);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const updateBlog = (id, data, cb) => async (dispatch) => {
  try {
    const updated = await adminApi.updateBlog(id, data);
    dispatch({
      type: constants.UPDATE_BLOG,
      payload: updated,
    });
    cb(updated);
    notification.open({
      message: "Blog updated!",
      placement: "top",
      type: "success",
    });
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};

export const getBlogById = (id, cb) => async () => {
  try {
    const blog = await adminApi.getBlogById(id);

    cb(blog);
  } catch (error) {
    showErrorToast(error.message || error?.response?.message);
    cb?.();
  }
};