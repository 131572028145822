import { BsCalendar2Week } from "react-icons/bs";
import { FaIdCard, FaRegBookmark } from "react-icons/fa";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { MdOutlineHolidayVillage } from "react-icons/md";
import { SiRedhatopenshift, SiVectorlogozone } from "react-icons/si";

const UserLinks = [
  {
    id: 1,
    url: "/",
    text: "Basic Information",
    name: "basicInformation",
    icon: FaIdCard,
  },
  {
    id: 1,
    url: "/bases",
    text: "Bases",
    name: "bases",
    icon: HiOutlineOfficeBuilding,
  },
  {
    id: 1,
    url: "/zones",
    text: "Zones",
    name: "zones",
    icon: SiVectorlogozone,
  },
  {
    id: 1,
    url: "/shifts",
    text: "Shifts",
    name: "shifts",
    icon: SiRedhatopenshift,
  },
  {
    id: 1,
    url: "/tariffs",
    text: "Tariffs",
    name: "tariffs",
    icon: FaRegBookmark,
  },
  {
    id: 1,
    url: "/weekday-surcharges",
    text: "Weekday Surcharges",
    name: "weekday-surcharges",
    icon: BsCalendar2Week,
  },
  {
    id: 1,
    url: "/holiday-surcharges",
    text: "Holiday Surcharges",
    name: "holiday-surcharges",
    icon: MdOutlineHolidayVillage,
  },
];
export default UserLinks;
