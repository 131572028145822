import { Badge, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getAdminShiftsByDateAndId } from "../../redux/admin/action";

function Index({ user }) {
  const [
    shift, setShift
  ] = useState(null);
  const dispatch = useDispatch();
  const stopLoader = (shifts) => {
    setShift(shifts.find((shift) => shift.active));
  };
  useEffect(() => {
    if (user) {
      dispatch(
        getAdminShiftsByDateAndId(
          {
            start: moment().startOf("day").format("x"),
            end: moment().endOf("day").format("x"),
          },
          user.id,
          stopLoader
        )
      );
    }
  }, [
    dispatch, user
  ]);

  return (
    <Space>
      {shift ? (
        <Badge
          count={`${shift?.time?.join(" - ")}`}
          style={ { backgroundColor: "#52c41a" } }
        />
      ) : (
        <Badge count="Not Working" />
      )}
    </Space>
  );
}

export default Index;
