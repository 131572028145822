import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Tooltip } from "antd";
import React, { memo } from "react";

function Index({ children, type }) {
  return (
    <Dropdown.Button
      className="w-full after:w-full "
      overlay={children}
      icon={<DownOutlined />}
      buttonsRender={([
        leftButton, rightButton
      ]) => [
        <Tooltip className="w-full "
          title={type}
          key="leftButton">
          {leftButton}
        </Tooltip>,
        React.cloneElement(rightButton, { loading: false }),
      ]}
    >
      <span className="font-bold w-full capitalize">
        {type}
      </span>
    </Dropdown.Button>
  );
}
export default memo(Index);
