import moment from "moment";
import React, { memo } from "react";

import { ukCurrancy } from "../../../../utils/convertions";

function Index({ rides, showDeduc , index}) {

  // const rideDate = moment(Number(rides?.createdAt)).format("DD-MMM-YY/hh:mm A");
  // const rideDate = rides?.date;
  // const rideId = rides?.id;
  // const deliveryFee = parseFloat(rides?.deliveryfree) || 0;
  // const riderfee = parseFloat(rides?.riderdetail.riderfee) || 0;
  // const ridertip = parseFloat(rides?.ridertip) || 0;
  // const deduction = rides?.deduction?.from === "rider" ? parseFloat(rides?.deduction?.fee) : 0;
  const rideDate = rides?.date;
  const rideTime = rides?.time;
  const rideId = rides?.id.toString().split("-")[0];

  const ridePickUp = rides?.origin?.address;
  const rideDropOff = rides?.destination?.address;

  const rideStops = rides?.stops.length === 0 
    ? ( "-" ) 
    : ( 
      rides?.stops.map((stop) => (
          stop.address + ", "
        ))
     );

  const customerName = rides?.passenger?.name;
  const fare = rides?.cost;

  return (
    <tr>
      <td>{ index + 1 }</td>
      <td>{ rideId }</td>
      <td>{ rideDate }</td>
      <td>{ rideTime }</td>

      <td>{ ridePickUp }</td>
      <td>{ rideDropOff }</td>
      <td>{ rideStops }</td>

      <td>{ customerName }</td>
      <td>{ ukCurrancy.format(fare) }</td>

      {/* <td>{ ukCurrancy.format(riderfee) }</td>
      <td>{ ukCurrancy.format(ridertip) }</td>
      <td>{ rides?.deduction?.from === "rider" ? ukCurrancy.format(deduction) : "0.00" }</td> */}

      {showDeduc && (
        <td>
          {rides?.deduction?.from === "rider"
            ? rides?.deduction?.reason
            : "No deductions"}
        </td>
      )}
      {/* <td>
        {ukCurrancy.format(deliveryFee + ridertip + riderfee - deduction)}
      </td> */}
    </tr>
  );
}

export default memo(Index);
