import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { updateSiteSettings } from "../../../redux/auth/action";

function Index() {
  const [errors, seterrors] = useState([]);

  const { settings } = useSelector((state) => state.auth);

  const [payments, setpayment] = useState({
    active: "",
    squareup: {
      token: "",
      LocationId: "",
    },
    paypal: {
      clientid: "",
      secret: "",
    },
  });
  var inputChange = (e) => {
    var { name, value } = e.target;
    setpayment((p) => ({ ...p, [name]: value }));
  };
  var inputsquareChange = (e) => {
    var { name, value } = e.target;
    setpayment((p) => ({
      ...p,
      squareup: { ...p.squareup, [name]: value },
    }));
  };
  var inputpaypalChange = (e) => {
    var { name, value } = e.target;
    setpayment((p) => ({
      ...p,
      paypal: { ...p.paypal, [name]: value },
    }));
  };
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };
  var savepayment = (e) => {
    e.preventDefault();

    let error = [];
    if (payments.active === "") {
      error.push("activeEmpty");
    }
    if (error.length) {
      seterrors(error);
    } else {
      seterrors([]);
      setLoader(true);
      dispatch(updateSiteSettings("paymentMethod", payments, stopLoader));
    }
  };

  useEffect(() => {
    setpayment((e) => {
      return {
        ...e,
        ...settings.paymentMethod,
      };
    });
  }, [settings]);

  return (
    <Container fluid>
      <Row className="gap-3">
        <Col lg={12}>
          <h1 className="fw-bold">Payment methods</h1>
        </Col>
        <Col
          lg={12}
          className="bg-white p-3 tablemain"
          style={{ borderRadius: "10px" }}
        >
          <form onSubmit={savepayment}>
            <Row>
              <h1
                className="border-bottom p-3"
                style={{ width: "fit-content", fontWeight: "600" }}
              >
                Payment methods
              </h1>
              {errors.includes("activeEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={{ fontSize: "12px" }}
                >
                  Atleast select on payment method
                </span>
              )}
              <Col lg={12} className="mt-3">
                <h1>Square up</h1>
                <Form.Group className="mt-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="radio"
                    checked={payments.active === "Squareup" ? true : false}
                    name="active"
                    value="Squareup"
                    onChange={inputChange}
                    label="Enable Square up"
                  />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formBasictoken">
                  <Form.Label>API/Token:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="API/Token"
                    name={"token"}
                    value={payments.squareup.token}
                    onChange={inputsquareChange}
                  />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formBasicLocation">
                  <Form.Label>Location ID:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="ID"
                    name={"LocationId"}
                    value={payments.squareup.LocationId}
                    onChange={inputsquareChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12} className="mt-3">
                <h1>PayPal</h1>
                <Form.Group className="mt-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="radio"
                    checked={payments.active === "paypal" ? true : false}
                    name="active"
                    value="paypal"
                    onChange={inputChange}
                    label="Enable Paypal"
                  />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formBasictoken">
                  <Form.Label>Paypal client id:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="id"
                    name={"clientid"}
                    value={payments.paypal.clientid}
                    onChange={inputpaypalChange}
                  />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formBasictoken">
                  <Form.Label>Paypal secret:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="secret"
                    name={"secret"}
                    value={payments.paypal.secret}
                    onChange={inputpaypalChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Button
                  variant="warning"
                  className="mt-3 fw-bolder text-white z-10 relative"
                  disabled={loader}
                  type="submit"
                >
                  {loader ? "Saving..." : "Save"}
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
