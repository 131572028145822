import { Tag } from 'antd';

const RideStatusTag = ({ status }) => {

  const getStatusTag = () => {
    switch (status) {
        case 'pending':
            return (
                <Tag color="yellow" className="rounded-pill capitalize">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </Tag>
            );
        case 'requesting':
            return (
                <Tag color="volcano" className="rounded-pill capitalize">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </Tag>
            );
        case 'driverAssigned':
            return (
                <Tag color="gold" className="rounded-pill capitalize">
                    {"Driver Assigned"}
                </Tag>
            );
        case 'inProgress':
            return (
                <Tag color="cyan" className="rounded-pill capitalize">
                    {"Driver Arrived"}
                </Tag>
            );
        case 'In Progress':
            return (
                <Tag color="cyan" className="rounded-pill capitalize">
                    {"In Progress"}
                </Tag>
            );
        case 'canceledByDriver':
            return (
                <Tag color="red" className="rounded-pill capitalize">
                    {"Canceled By Driver"}
                </Tag>
            ); 
        case 'complete':
            return (
                <Tag color="green" className="rounded-pill capitalize">
                    {"Completed"}
                </Tag>
            );     
        case 'completed':
            return (
                <Tag color="green" className="rounded-pill capitalize">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </Tag>
            );
        case 'cancelled':
            return (
                <Tag color="red" className="rounded-pill capitalize">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </Tag>
            );
        case 'requestTimeout':
            return (
                <Tag color="purple" className="rounded-pill capitalize">
                    {"Request Timeout"}
                </Tag>
            );
      default:
        return (
            <Tag color="geekblue" className="rounded-pill capitalize">{status}</Tag>
        );
    }
  };

  return getStatusTag();
};

export default RideStatusTag;
