import { GoogleMap } from "@react-google-maps/api";
import React, { memo, useEffect, useState } from "react";
import Geocode from "react-geocode";
import { FiMapPin } from "react-icons/fi";

import { getAddress } from "../../utils/addressChanger";

function Index({ value, onPlaceChange }) {
  const [
    autoComplete, setAutoComplete
  ] = useState(null);
  const [
    initialValue, setInitialValue
  ] = useState(null);
  const onLoadHandler = (value) => {
    setAutoComplete(value);
  };

  const onCenterChangeHandler = () => {
    if (autoComplete) {
      let lat = autoComplete.getCenter().lat();
      let lng = autoComplete.getCenter().lng();
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          setInitialValue(getAddress(response.results[0]));
          onPlaceChange(getAddress(response.results[0]));
        }
      );
    }
  };

  useEffect(() => {
    if (value) {
      setInitialValue(value);
    }
  }, [value]);

  return (
    <div className="map-popup-container shadow-sm">
      <GoogleMap
        options={ {
          fullscreenControl: false,
          clickableIcons: false,
          mapTypeControl: false,
        } }
        onDragEnd={onCenterChangeHandler}
        onLoad={onLoadHandler}
        mapContainerStyle={ {
          width: "100%",
          height: "100%",
          borderRadius: "10px",
        } }
        center={initialValue
          ? { lat: initialValue?.latValue, lng: initialValue?.lngValue }
          : { lat: 52.205337, lng: 0.121817 }}
        clickableIcons={false}
        zoom={18}
      />
      <div className="map-pin">
        <FiMapPin />
      </div>
    </div>
  );
}

export default memo(Index);
