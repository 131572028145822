import {
  DirectionsRenderer,
  DirectionsService,
  DistanceMatrixService,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import React, {
  Fragment,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";

import bikeRiderMarker from "../../assets/images/bikeRiderMarker.png";
import carRiderMarker from "../../assets/images/carRider.png";
import clientMarker from "../../assets/images/client.png";
import restaurantMarker from "../../assets/images/restaurantMarker.png";
import walkRider from "../../assets/images/walkRider.png";
import * as constants from "../../context/constants";
import { MapContext } from "../../context/mapContext";
import { getRiderLiveDetail } from "../../redux/admin/action";

const riderImages = {
  Car: carRiderMarker,
  Scooter: bikeRiderMarker,
  Bike: bikeRiderMarker,
  Walk: walkRider,
};
const transitMode = {
  Bike: "BICYCLING",
  Scooter: "DRIVING",

  Car: "DRIVING",

  Transit: "TRANSIT",

  Walk: "WALKING",
};
function Index({ order }) {
  const storeDispatch = useDispatch();
  const { state, dispatch } = useContext(MapContext);
  const [
    mainRoute, setMainRoute
  ] = useState(null);
  const [
    riderToRestaurantRoute, setRiderToRestaurantRoute
  ] = useState(null);
  const [
    riderToUserRoute, setRiderToUserRoute
  ] = useState(null);

  const {
    restLocation,
    riderLocation,
    clientLocation,
    mainDistance,
    riderToRestaurantDistance,
    riderToUserDistance,
    riderDetail,
  } = state;
  useEffect(() => {
    if (order) {
      if (!restLocation) {
        dispatch({
          type: constants.GET_RESTAURANT_LOCATION,
          payload: order?.restaurant?.restInformation?.location,
        });
      }

      if (!clientLocation) {
        dispatch({
          type: constants.GET_USER_LOCATION,
          payload: order?.clientInfo?.location,
        });
      }

      if (order?.riderdetail && order?.status !== "completed" && !riderDetail) {
        storeDispatch(
          getRiderLiveDetail(order?.riderdetail?.id, (data) => {
            dispatch({
              type: constants.GET_RIDER_LOCATION,
              payload: data.liveLocation,
            });
            dispatch({
              type: constants.GET_RIDER_DETAIL,
              payload: data,
            });
          })
        );
      }
    }
  }, [
    storeDispatch,
    order,
    dispatch,
    clientLocation,
    restLocation,
    riderDetail,
  ]);
  const center = useMemo(() => {
    if (order?.riderCollectedTime && order?.status !== "completed") {
      return riderLocation;
    }

    return {
      latValue: 52.205337,
      lngValue: 0.121817,
    };
  }, [
    order, riderLocation
  ]);

  return (
    <GoogleMap
      mapContainerStyle={ { width: "100%", height: "700px" } }
      center={ {
        lat: center?.latitude || center?.latValue,
        lng: center?.longitude || center?.lngValue,
      } }
      zoom={18}
    >
      {restLocation && (
        <Marker
          icon={ {
            url: restaurantMarker,
            scaledSize: new window.google.maps.Size(30, 30),
          } }
          position={ {
            lat: restLocation?.latValue,
            lng: restLocation?.lngValue,
          } }
        />
      )}
      {clientLocation && (
        <Marker
          icon={ {
            url: clientMarker,
            scaledSize: new window.google.maps.Size(30, 30),
          } }
          position={ {
            lat: clientLocation?.latValue,
            lng: clientLocation?.lngValue,
          } }
        />
      )}
      {riderLocation && (
        <Marker
          icon={ {
            url: riderImages[riderDetail?.vehicle],
            strokeColor: "red",
            fillColor: "red",
            scaledSize: new window.google.maps.Size(30, 30),
          } }
          position={ {
            lat: riderLocation?.latitude || riderLocation?.latValue,
            lng: riderLocation?.longitude || riderLocation?.lngValue,
          } }
        />
      )}
      {clientLocation && restLocation && (
        <Fragment>
          <DistanceMatrixService
            options={ {
              destinations: [{
                lat: clientLocation?.latValue,
                lng: clientLocation?.lngValue,
              },],
              origins: [{
                lat: restLocation?.latValue,
                lng: restLocation?.lngValue,
              },],
              travelMode: transitMode[riderDetail?.vehicle],
            } }
            callback={(res) => {
              if (mainDistance) {
                return;
              }
              dispatch({
                type: constants.GET_MAIN_DISTANCE,
                payload: res.rows[0].elements[0],
              });
            }}
          />
          <DirectionsService
            callback={(result) => {
              if (mainRoute) {
                return;
              }
              setMainRoute(result);
            }}
            options={ {
              origin: {
                lat: clientLocation?.latValue,
                lng: clientLocation?.lngValue,
              },

              destination: {
                lat: restLocation?.latValue,
                lng: restLocation?.lngValue,
              },
              travelMode: transitMode[riderDetail?.vehicle],
            } }
          />
          {mainRoute && (
            <DirectionsRenderer
              directions={mainRoute}
              options={ {
                markerOptions: {
                  visible: false,
                },
                polylineOptions: {
                  strokeColor: "#F8B615",
                  strokeWeight: 5,
                  strokeOpacity: 1,
                },
              } }
            />
          )}
        </Fragment>
      )}
      {riderLocation && restLocation && !order?.riderCollectedTime && (
        <Fragment>
          <DistanceMatrixService
            options={ {
              destinations: [{
                lat: riderLocation?.latitude || riderLocation?.latValue,
                lng: riderLocation?.longitude || riderLocation?.lngValue,
              },],
              origins: [{
                lat: restLocation?.latValue,
                lng: restLocation?.lngValue,
              },],
              travelMode: transitMode[riderDetail?.vehicle],
            } }
            callback={(res) => {
              if (riderToRestaurantDistance) {
                return;
              }
              dispatch({
                type: constants.GET_RIDER_TO_REST_DISTANCE,
                payload: res.rows[0].elements[0],
              });
            }}
          />
          <DirectionsService
            callback={(result) => {
              if (riderToRestaurantRoute) {
                return;
              }
              setRiderToRestaurantRoute?.(result);
            }}
            options={ {
              origin: {
                lat: riderLocation?.latitude || riderLocation?.latValue,
                lng: riderLocation?.longitude || riderLocation?.lngValue,
              },
              destination: {
                lat: restLocation?.latValue,
                lng: restLocation?.lngValue,
              },
              travelMode: transitMode[riderDetail?.vehicle],
            } }
          ></DirectionsService>
          {riderToRestaurantRoute && (
            <DirectionsRenderer
              options={ {
                directions: riderToRestaurantRoute,
                markerOptions: {
                  visible: false,
                },
                polylineOptions: {
                  strokeColor: "gray",
                  strokeWeight: 3,
                  strokeOpacity: 1,
                  zIndex: 1,
                },
              } }
            />
          )}
        </Fragment>
      )}
      {riderLocation && clientLocation && order?.riderCollectedTime && (
        <Fragment>
          <DistanceMatrixService
            options={ {
              destinations: [{
                lat: riderLocation?.latitude || riderLocation?.latValue,
                lng: riderLocation?.longitude || riderLocation?.lngValue,
              },],
              origins: [{
                lat: clientLocation?.latValue,
                lng: clientLocation?.lngValue,
              },],
              travelMode: transitMode[riderDetail?.vehicle],
            } }
            callback={(res) => {
              if (riderToUserDistance) {
                return;
              }
              dispatch({
                type: constants.GET_RIDER_TO_USER_DISTANCE,
                payload: res.rows[0].elements[0],
              });
            }}
          />
          <DirectionsService
            callback={(result) => {
              if (riderToUserRoute) {
                return;
              }
              setRiderToUserRoute(result);
            }}
            options={ {
              origin: {
                lat: riderLocation?.latitude || riderLocation?.latValue,
                lng: riderLocation?.longitude || riderLocation?.lngValue,
              },
              destination: {
                lat: clientLocation?.latValue,
                lng: clientLocation?.lngValue,
              },
              travelMode: transitMode[riderDetail?.vehicle],
            } }
          ></DirectionsService>
          {riderToUserRoute && (
            <DirectionsRenderer
              options={ {
                directions: riderToUserRoute,
                markerOptions: {
                  visible: false,
                },
                polylineOptions: {
                  strokeColor: "blue",
                  strokeWeight: 3,
                  strokeOpacity: 1,
                  zIndex: 1,
                },
              } }
            />
          )}
        </Fragment>
      )}
    </GoogleMap>
  );
}

export default memo(Index);
