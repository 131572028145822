import { Formik } from "formik";
import React, { memo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

import SnackBarCustom from "../../components/SnackBarCustom";
import {
  addRestFirestore,
  addRiderFirestore,
  addUserFirestore,
  addAppTestDriver,
} from "../../redux/admin/action";
import addRestaurantValidation from "../../validations/addrestaurants";
import addRiderValidation from "../../validations/addRider";
import addUserValidation from "../../validations/addUser";

function Index({ as, show, onHide }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [added, setAdded] = useState(false);

  const stopLoader = (resetForm) => (data) => {
    setLoader(false);
    resetForm?.();

    if (data?.code === "auth/user-found") {
      swal("Email already exist");
    } else {
      setAdded(true);
    }
  };

  const onClose = () => {
    setAdded(false);
  };
  const addUser = (value, { resetForm }) => {
    if (as === "driver") {
      setLoader(true);
      // dispatch(addRiderFirestore(value, stopLoader(resetForm))); //add driver

      // TODO: sync app thing
      dispatch(addAppTestDriver(value, stopLoader(resetForm))); //add driver

    } else if (as === "rider") {
      setLoader(true);
      dispatch(addUserFirestore(value, stopLoader(resetForm))); // add rider

      // dispatch(
      //   addUserFirestore(value, () => {
      //     stopLoader(resetForm);
      //     onHide();
      //   })
      // );
    } else if (as === "restaurant") {
      setLoader(true);
      dispatch(addRestFirestore(value, stopLoader(resetForm)));
    }
  };

  const validation =
    as === "restaurant"
      ? addRestaurantValidation
      : as === "driver"
        ? addRiderValidation
        : addUserValidation;
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"
          className="capitalize">
          Add New 
          {" "}
          {as}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={ {
            name: "",
            email: "",
          } }
          validationSchema={validation}
          onSubmit={addUser}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,

            /* and other goodies */
          }) => (
            <Form className="w-75 mx-auto"
              onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Control
                  type="text"
                  className="mt-3"
                  placeholder="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
              {errors.name && touched.name && (
                <span
                  className="w-100 text-danger text-start"
                  style={ { fontSize: "12px" } }
                >
                  {errors.name}
                </span>
              )}
              <Form.Group controlId="formBasicemail">
                <Form.Control
                  type="email"
                  className="mt-3"
                  placeholder="Enter email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <span
                    className="w-100 text-danger text-start"
                    style={ { fontSize: "12px" } }
                  >
                    {errors.email}
                  </span>
                )}
              </Form.Group>
              <Button
                disabled={loader}
                variant="warning"
                className="mt-3 fw-bolder text-white "
                type="submit"
              >
                {loader ? "Adding..." : "Add"}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          className="fw-bolder text-white"
          variant="warning"
        >
          Close
        </Button>
      </Modal.Footer>
      <SnackBarCustom msg={`${as} added!`}
        open={added}
        onClose={onClose} />
    </Modal>
  );
}

export default memo(Index);
