import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getRiderById } from "../../redux/admin/action";
import RiderRoutes from "../../routers/riderRoutes";

function Index() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);
  const { profileRider } = useSelector((state) => state.admin);
  const stopLoader = () => {
    setLoader(false);
  };

  useEffect(() => {
    if (id) {
      setLoader(true);
      dispatch(getRiderById(id, stopLoader));
    }
  }, [
    id, dispatch
  ]);

  if (loader) {
    <div>
    loading...
    </div>;
  }
  if (profileRider) {
    // console.log("profileRider", profileRider);
    return <RiderRoutes />;
  }
  return null;
}
export default memo(Index);
