import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

import { saveInvoice } from "../../../redux/admin/action";
import toTimestamp from "../../../utils/convertions";
import { makeId } from "../../../utils/gerenatePassword";
import Document from "./document";

function Index({ rides, to, from }) {

  const dispatch = useDispatch();

  const invoiceNumber = useMemo(() => makeId(6), []);

  // console.log("Uncle document:c  ++>", rides);

  const uploaddata = (e, blob) => {
    e.preventDefault();
    
    if (window.confirm("Want to save invoice for driver?")) {
      setLoader(true);

      const completedRides = rides?.filter((e) => e.paid === true);

      const total = completedRides?.reduce((a, b) => {
        const cost = b.cost || 0;
        const discount = b.discount || 0;
        const tot = parseFloat(cost) - parseFloat(discount);
        return a + tot;
      }, 0);

      const data = {
        to: toTimestamp(to),
        from: toTimestamp(from),
        userId: rides[0].riderdetail.id,
        as: "driver",
        paidStatus: true,
        invoiceNumber,
        amount: String(total.toFixed(2)),
      };

      dispatch(saveInvoice(blob, data, completedRides, "riderPaid", stopLoader));
      // stopLoader();
    }
  };

  const stopLoader = (isGerenated) => {
    setLoader(false);
    if (isGerenated) {
      swal("Dear Admin!", "Invoice saved successfully", "success");
    } else {
      swal("Dear Admin!", "Invoice already saved", "error");
    }
  };

  const [loader, setLoader] = useState(false);

  return (
    <div>
      <div style={ { position: "relative " } }
        className="p-2 ">
        <PDFDownloadLink
          document={<Document rides={rides}
          invoiceNumber={invoiceNumber} />}
          fileName={`Driver(${from}/${to}).pdf`}
          className="btn btn-warning fw-bolder text-white"
        >
          {({ blob, loading }) => (
            <>
              {loading ? "loading.." : "Download"}
              <button
                disabled={loader}
                className="btn btn-warning fw-bolder text-white"
                style={ {
                  position: "absolute",
                  right: 5,
                  top: 5,
                  zIndex: 34573485734,
                } }
                onClick={(e) => uploaddata(e, blob)}
              >
                {loader ? "Saving..." : "Save"}
              </button>
            </>
          )}
        </PDFDownloadLink>
      </div>
      <PDFViewer style={ { height: "100vh", width: "100vw" } }>
        <Document rides={rides}
          invoiceNumber={invoiceNumber} />
      </PDFViewer>
    </div>
  );
}

export default Index;
