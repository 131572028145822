import React from "react";
import { ukCurrancy } from "../../../../utils/convertions";

function Owntr({ ride }) {
  return (
    <tr>
      <td>{ride?.rideDate}</td>
      <td>{ride?.rideNumber}</td>
      <td>{ride?.driver}</td>
      <td>{ride?.passenger}</td>
      <td>{ride?.completedAt}</td>
      <td>{ ukCurrancy.format(ride?.fare) }</td>
      <td>{ride?.platformfee}</td>
      <td>{ ukCurrancy.format(ride?.total) }</td>
      <td>{ride?.driverPercentage}</td>
      <td>{ ukCurrancy.format(ride?.driverPayment) }</td>
      <td>{ ukCurrancy.format(ride?.navigoPayment) }</td>
      <td>{ride?.rideStatus}</td>
    </tr>
  );
}

export default Owntr;
