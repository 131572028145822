import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getUsersByIds } from "../../redux/admin/action";

function Index({ users }) {
  const dispatch = useDispatch();
  const [
    loader, setLoader
  ] = useState(false);
  const [
    member, setMember
  ] = useState([]);
  const stopLoader = (data) => {
    setLoader(false);
    setMember(data);
  };
  useEffect(() => {
    dispatch(getUsersByIds(users, stopLoader));
  }, [
    dispatch, users
  ]);

  const names = member?.map((item) => item?.name);

  if (loader) {
    return <div>
Loading...
    </div>;
  }
  return <div>
    {names.toString()}
  </div>;
}

export default memo(Index);
