import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { updateSiteSettings } from "../../../redux/auth/action";

function Index() {
  const { settings } = useSelector((state) => state.auth);
  const [
    siteinfo, setsiteinfo
  ] = useState({
    name: "",
    description: "",
    title: "",
    playStoreUrl: "",
    appStoreUrl: "",
    facebookUrl: "",
    instagramUrl: "",
    twitterUrl: "",
    youtubeUrl: "",
    googleUrl: "",
    keywords: "",
  });
  useEffect(() => {
    setsiteinfo((p) => {
      return {
        ...p,
        ...settings?.Settings,
      };
    });
  }, [settings]);
  const [
    errors, seterrors
  ] = useState([]);
  const [
    loader, setLoader
  ] = useState(false);
  const stopLoader = () => {
    setLoader(false);
  };
  const dispatch = useDispatch();
  const savesiteinfo = (e) => {
    e.preventDefault();
    let error = [];
    if (siteinfo.name === "") {
      error.push("NameEmpty");
    }
    if (siteinfo.description === "") {
      error.push("descriptionEmpty");
    }
    if (siteinfo.keywords === "") {
      error.push("keywordsEmpty");
    }
    if (siteinfo.title === "") {
      error.push("titleEmpty");
    }
    if (error.length) {
      seterrors(error);
    } else {
      seterrors([]);
      setLoader(true);
      dispatch(updateSiteSettings("Settings", siteinfo, stopLoader));
    }
  };
  const inputChange = (e) => {
    const { name, value } = e.target;

    setsiteinfo((p) => ({ ...p, [name]: value }));
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h1 className="fw-bold">
Site Information
          </h1>
        </Col>
        <Col lg={12}
          className=" bg-white shadow-lg ">
          <Form onSubmit={savesiteinfo}
            className="p-3">
            <Form.Group className="mt-3"
              controlId="formBasicname">
              <Form.Label>
Site name
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter site name"
                name="name"
                value={siteinfo.name}
                onChange={inputChange}
              />
              {errors.includes("NameEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={ { fontSize: "12px" } }
                >
                  Enter site name
                </span>
              )}
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicdescription">
              <Form.Label>
Site title
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter site title"
                name="title"
                value={siteinfo.title}
                onChange={inputChange}
              />
              {errors.includes("titleEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={ { fontSize: "12px" } }
                >
                  Enter site description
                </span>
              )}
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicdescription">
              <Form.Label>
Site description
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter site description"
                name="description"
                value={siteinfo.description}
                onChange={inputChange}
              />
              {errors.includes("descriptionEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={ { fontSize: "12px" } }
                >
                  Enter site description
                </span>
              )}
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicdescription">
              <Form.Label>
Site keywords
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter site keywords e.g navigo, exeter navigo"
                name="keywords"
                value={siteinfo.keywords}
                onChange={inputChange}
              />
              {errors.includes("keywordsEmpty") && (
                <span
                  className="w-100 text-danger"
                  style={ { fontSize: "12px" } }
                >
                  Enter site keywords
                </span>
              )}
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicplayStoreUrl">
              <Form.Label>
Play store
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Link"
                name="playStoreUrl"
                value={siteinfo.playStoreUrl}
                onChange={inputChange}
              />
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicappStoreUrl">
              <Form.Label>
App store
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Link"
                name="appStoreUrl"
                value={siteinfo.appStoreUrl}
                onChange={inputChange}
              />
            </Form.Group>
            <h1 className="font-weight-bold mt-3"
              style={ { fontSize: ".8em" } }>
              SOCIAL LINKS
            </h1>
            <Form.Group className="mt-3"
              controlId="formBasicfacebookUrl">
              <Form.Label>
Facebook
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Link"
                name="facebookUrl"
                value={siteinfo.facebookUrl}
                onChange={inputChange}
              />
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicinstagramUrl">
              <Form.Label>
Instagram
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Link"
                name="instagramUrl"
                value={siteinfo.instagramUrl}
                onChange={inputChange}
              />
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasictwitterUrl">
              <Form.Label>
Twitter
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Link"
                name="twitterUrl"
                value={siteinfo.twitterUrl}
                onChange={inputChange}
              />
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicyoutubeUrl">
              <Form.Label>
Youtube
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Link"
                name="youtubeUrl"
                value={siteinfo.youtubeUrl}
                onChange={inputChange}
              />
            </Form.Group>
            <Form.Group className="mt-3"
              controlId="formBasicgoogleUrl">
              <Form.Label>
Google +
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Link"
                name="googleUrl"
                value={siteinfo.googleUrl}
                onChange={inputChange}
              />
            </Form.Group>
            <Button
              variant="warning"
              className="mt-3 fw-bolder text-white relative z-10"
              disabled={loader}
              type="submit"
            >
              {loader ? "Saving..." : "Save"}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(Index);
