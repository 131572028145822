import { DatePicker } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";

import { getAllRiders, getOrdersbyRider, getRidesbyDriver } from "../../../../redux/admin/action";
import Tr from "./tr";

function Index() {

  const [invoiceData, setinvoiceData] = useState({
    from: moment().format("DD-MMM-YYYY"),
    to: moment().format("DD-MMM-YYYY"),
    rest: "",
    rideStatus: "",
  });

  const [riderList, setRidersList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [gerenating, setGerenating] = useState(false);


  const [invoices, setInvoices] = useState([]);
  const [errors, seterrors] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {

    setLoader(true);
    dispatch(getAllRiders(stopLoader));

  }, [dispatch]);

  const stopLoader = (data) => {
    setLoader(false);

    setStatusList(
      [
        { value: 'completed', label: 'Completed' },
        { value: 'driverAssigned', label: 'Assigned' },
        // { value: 'cancelled', label: 'Cancelled' },
      ]
    );

    if (data?.length) {
      setRidersList(
        data?.map((e) => {
          return {
            value: e.id,
            label: e.email,
          };
        })
      );
    }
  };

  const [loader, setLoader] = useState(false);

  const inputChange = (name) => (value) => {
    if (!value) {
      return;
    }
    setinvoiceData((p) => ({ ...p, [name]: value }));
  };

  const gerenateInvoice = (e) => {
    // console.log("Invoice Data: ===>", invoiceData);

    e.preventDefault();
    let error = [];

    if (invoiceData.from === "") {
      error.push("fromEmpty");
    }
    if (invoiceData.to === "") {
      error.push("toEmpty");
    } else if (
      invoiceData.from !== "" &&
      moment(invoiceData.to).isBefore(moment(invoiceData.from))
    ) {
      error.push("tomustless");
    }
    if (invoiceData.rest === "") {
      error.push("restEmpty");
    }
    if (invoiceData.rideStatus === "") {
      error.push("rideStatusEmpty");
    }
    if (error?.length) {
      seterrors(error);
    } else {
      seterrors([]);
      setGerenating(true);

      dispatch(getRidesbyDriver(
          invoiceData?.rest,
          moment(invoiceData.from).startOf("day").format("x"),
          moment(invoiceData.to).endOf("day").format("x"),

          stopGerenater
        )
      );
    }
  };
  const stopGerenater = (data) => {
    setGerenating(false);

    // console.log("ini mini ==> ", data);

    if (data?.length) {
      // console.log(" Invoices data pre => ", data);
      // console.log(" Invoices  => ", invoices);
      const final_invoices = data.filter( x => x.status === invoiceData?.rideStatus );

      // console.log("final ==> ", final_invoices);

      setInvoices(final_invoices);
    }
  };

  // const handleRideStatusChange = (e) => {
  //   setinvoiceData({ ...invoiceData, rideStatus: e.target.value });
  // };

  return (
    <Container fluid
      style={ { minHeight: "300px" } }>
      <Row>
        <Col lg={12}
          className="bg-white shadow-lg p-3 ">
          <Row>
            <Col lg={12}>
              <Form
                className="d-flex items-center flex-wrap gap-2"
                onSubmit={gerenateInvoice}
              >
                <Form.Group
                  className=" d-flex flex-column align-items-start justify-content-center"
                  controlId="formBasicFrom"
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <Form.Label className="w-12">
                    From:
                    </Form.Label>
                    <DatePicker
                      name="from"
                      format="DD-MMM-YYYY"
                      value={moment(invoiceData.from)}
                      onChange={inputChange("from")}
                    />
                  </div>
                  {errors.includes("fromEmpty") && (
                    <span
                      className="w-100 text-center text-danger"
                      style={ { fontSize: "12px" } }
                    >
                      Choose from date
                    </span>
                  )}
                </Form.Group>
                <Form.Group
                  className=" d-flex flex-column align-items-start justify-content-center"
                  controlId="formBasicto"
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <Form.Label className="w-6">
                      To:
                    </Form.Label>
                    <DatePicker
                      name="from"
                      format="DD-MMM-YYYY"
                      value={moment(invoiceData.to)}
                      onChange={inputChange("to")}
                    />
                  </div>
                  {errors.includes("toEmpty") && (
                    <span
                      className="w-100 text-center text-danger"
                      style={ { fontSize: "12px" } }
                    >
                      Choose to date
                    </span>
                  )}
                  {errors.includes("tomustless") && (
                    <span
                      className="w-100 text-center text-danger"
                      style={ { fontSize: "12px" } }
                    >
                      Date must be greater then from date
                    </span>
                  )}
                </Form.Group>
                <Form.Group
                  className="d-flex flex-column align-items-start justify-content-center"
                  controlId="formBasicto"
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <Form.Label className="w-12">
                      Driver:
                    </Form.Label>
                    <div style={ { width: "200px" } }>
                      <Select
                        isDisabled={loader}
                        isLoading={loader}
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={(e) =>
                          setinvoiceData((p) => ({ ...p, rest: e.value }))}
                        isSearchable={true}
                        name="riders"
                        options={riderList}
                      />
                    </div>
                  </div>
                  {errors.includes("restEmpty") && (
                    <span
                      className="w-100 text-center text-danger"
                      style={ { fontSize: "12px" } }
                    >
                      Choose Driver..
                    </span>
                  )}
                  {errors.includes("restnotfound") && (
                    <span
                      className="w-100 text-center text-danger"
                      style={ { fontSize: "12px" } }
                    >
                      Rides not founds
                    </span>
                  )}
                </Form.Group>
                
                <Form.Group
                  className="d-flex flex-column align-items-start justify-content-center"
                  controlId="formBasicto"
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <Form.Label className="w-12">Ride Status:</Form.Label>
                    <div style={{ width: '200px' }}>
                      <Select
                        isDisabled={loader}
                        isLoading={loader}
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={(e) =>
                          setinvoiceData((p) => ({ ...p, rideStatus: e.value }))
                        }
                        name="rideStatus"
                        options={statusList}
                      />
                    </div>
                  </div>
                  {/* Error handling for rideStatus */}
                  {errors.includes('rideStatusEmpty') && (
                    <span className="w-100 text-center text-danger" style={{ fontSize: '12px' }}>
                      Choose Ride Status..
                    </span>
                  )}
                </Form.Group>

                <Button
                  disabled={gerenating}
                  variant="warning"
                  className="fw-bolder text-white btn-sm m-1"
                  type="submit"
                >
                  {gerenating ? "Waiting..." : "Generate"}
                </Button>
              </Form>
            </Col>
            {invoices?.length > 0 && (
              <Col lg={12}
                className="mt-5">
                <Col
                  lg={12}
                  className="d-flex justify-content-end align-items-center mb-2"
                >
                  <Button
                    href={`/invoice/${invoiceData?.rest}?from=${invoiceData.from}&to=${invoiceData.to}&as=rider`}
                    target="_blank"
                    variant="warning"
                    className="fw-bolder text-white btn-lg m-1"
                    type="button"
                  >
                    View & Save
                  </Button>
                </Col>
                <Table striped
                  bordered
                  hover
                  responsive>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Ride #</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Pickup Location</th>
                      <th>Dropoff Location</th>
                      <th>Additional Stops</th>
                      <th>Customer Name</th>
                      <th>Fare</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices?.map((rides, index) => (
                      <Tr rides={rides}
                        key={index} 
                          index={index}
                        />
                    ))}
                  </tbody>
                </Table>
              </Col>
            )}

            {invoices?.length === 0 && (
              <Col lg={12} className="mt-5">
                <Col lg={12} className="d-flex justify-content-center align-items-center mb-2">
                  <h6>No invoice </h6>
                </Col>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
export default memo(Index);
