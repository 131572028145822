import React, { memo } from "react";

function Index({ info }) {
  return (
    <div>
      <p>
        Vehicle plate photo:
        {" "}
        <span>
          <a target="_blank"
            rel="noreferrer"
            href={info?.VehiclePlatePhoto}>
            view
          </a>
        </span>
      </p>
      <p>
        Vehical data: 
        {" "}
        <span>
          {info?.ridervehicaldata}
        </span>
      </p>
    </div>
  );
}

export default memo(Index);
