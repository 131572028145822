import { Button, Table, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import ActionBtn from "../../components/actionBtn";
import UpdateRoleModal from "../../modals/updateRoleModal";
import { deleteAdminRole } from "../../redux/admin/action";

function Index() {
  const { roles } = useSelector((state) => state.admin);

  const dispatch = useDispatch();

  const [
    selectedRole, setSelectedRole
  ] = useState(null);
  const [
    showEdit, setShowEdit
  ] = useState(false);
  const deleteRole = (record) => (cb) => {
    dispatch(deleteAdminRole(record, cb));
  };

  // console.log("Roles Page ====> ", roles);

  const columns = [
    {
      title: "Created at",
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,

      render: (e) => `${moment(Number(e)).format("DD-MMM-YY/hh:mm A")}`,
    },
    {
      title: "Role #",
      align: "center",
      dataIndex: "key",
      key: "key",
      width: 100,
      render: (_, record) => record.id.toString().split("-")[0],
    },
    {
      title: "Name",
      align: "center",
      dataIndex: "name",
      key: "name",
      width: 120,
      editable: true,
      className: " capitalize",
    },
    {
      title: "Main Tabs",
      align: "center",
      dataIndex: "accessTo",
      width: 120,
      key: "accessTo",
      render: (_, record) => {
        if (!record.specificMain) {
          return "All Access";
        }
        return <div className="capitalize"> { record?.accessTo.join(", ") || "No Access" } </div>;
      },
    },
    {
      title: "Riders Tabs",
      align: "center",
      dataIndex: "accessTo",
      width: 120,
      key: "accessTo",
      render: (_, record) => {
        if (!record.specificMain){
          return <div className="capitalize"> {"All Access"} </div>;
        }
        if (record.specificMain && record.accessTo.includes("clients")) {
          if (record.specificCustomer) {
            return <div className="capitalize"> {record.customerTabs.join(", ") || "No Access"} </div>;
          }
          return <div className="capitalize"> {"All Access"} </div>;
        }
        return <div className="capitalize"> {"No Access"} </div>;
      },
      // render: (_, record) => {
      //   if (!record.specificCustomer || !record.specificMain) {
      //     return "All Access";
      //   }
      //   return <div className="capitalize"> {record.customerTabs.join(", ") || "No Access"} </div>;
      // },
    },
    {
      title: "Driver Tabs",
      align: "center",
      dataIndex: "accessTo",
      width: 120,
      key: "accessTo",
      render: (_, record) => {
        if (!record.specificMain){
          return <div className="capitalize"> {"All Access"} </div>;
        }
        if (record.specificMain && record.accessTo.includes("drivers")) {
          if (record.specificRider) {
            return <div className="capitalize"> {record.riderTabs.join(", ") || "No Access"} </div>;
          }
          return <div className="capitalize"> {"All Access"} </div>;
        }
        return <div className="capitalize"> {"No Access"} </div>;
      },
      // render: (_, record) => {
      //   if (!record.specificRider || !record.specificMain) {
      //     return <div className="capitalize"> {"All Access"} </div>;;
      //   }
      //   return <div className="capitalize"> {record.riderTabs.join(", ") || "No Access"} </div>;
      // },
    },
    {
      title: "Admins Tabs",
      align: "center",
      dataIndex: "accessTo",
      key: "accessTo",
      width: 120,
      render: (_, record) => {
        if (!record.specificMain){
          return <div className="capitalize"> {"All Access"} </div>;
        }
        if (record.specificMain && record.accessTo.includes("admins")) {
          if (record.specificAdmin) {
            return <div className="capitalize"> {record.adminTabs.join(", ") || "No Access"} </div>;
          }
          return <div className="capitalize"> {"All Access"} </div>;
        }
        return <div className="capitalize"> {"No Access"} </div>;
      },
    },

    {
      title: "Actions",
      key: "operation",
      width: 120,

      align: "center",
      render: (_, record) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Tooltip title="Edit">
              <FaEdit
                onClick={() => {
                  setSelectedRole(record);

                  setShowEdit(true);
                }}
                style={ {
                  color: "#f97906",
                  fontSize: 16,
                  margin: "2px",
                  cursor: "pointer",
                } }
              />
            </Tooltip>
            <ActionBtn
              type={"Delete"}
              action={deleteRole(record)}
              confirmMsg={"Want to delete this member?"}
            >

              <FaTrashAlt
                style={ {
                  color: "red",
                  fontSize: 15,
                  margin: "2px",
                  cursor: "pointer",
                } }
              />
            </ActionBtn>
          </div>
        );
      },
    },
  ];

  return (
    <Container fluid
      className=" w-100  pb-5">
      <Row>
        <Col lg={12}
          className=" pl-0 ">
          <h2 className="fw-bold">
          Roles
          </h2>
        </Col>
        <Col lg={12}
          className="flex justify-end">
          <Button
            type="primary"
            className="fw-bold"
            onClick={() => {
              setSelectedRole(null);
              setShowEdit(true);
            }}
          >
            Add Role
          </Button>
        </Col>
        <Col lg={12}
          className="bg-white rounded-b-lg p-2 shadow-md">
          <Table
            bordered
            dataSource={roles}
            className="table-curved adminTable"
            columns={columns}
            pagination={false}
            scroll={ { y: 500 } }
          />
        </Col>
        {showEdit && (
          <UpdateRoleModal
            role={selectedRole}
            show={showEdit}
            onHide={() => setShowEdit(false)}
          />
        )}
      </Row>
    </Container>
  );
}
export default memo(Index);
