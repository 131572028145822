import React, { memo, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

function Index({ title, headerClass, action, renderData, lg }) {
  const [
    data, setData
  ] = useState(null);
  const [
    loader, setLoader
  ] = useState(false);
  const dispatch = useDispatch();
  const stopLoader = (value) => {
    setLoader(false);
    setData(value);
  };
  useEffect(() => {
    if (action) {
      setLoader(true);
      dispatch(action?.(stopLoader));
    }
  }, [
    action, dispatch
  ]);
  return (
    <Col lg={lg}
      className="bg-white shadow-md rounded-3 ">
      <Row>
        <Col
          className={`border-bottom rounded-t-lg livebox-head   p-3 ${headerClass}`}
        >
          <h1 className="text-white text-sm fw-bold">
            {title}
          </h1>
        </Col>
        <Col lg={12}
          className="p-3">
          {loader && <Spinner animation="border"
            size="sm" />}
          {!loader && renderData?.(data)}
        </Col>
      </Row>
    </Col>
  );
}

export default memo(Index);
